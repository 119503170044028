import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../../../../services/settings";
import { Icon } from "semantic-ui-react";
import { numberFormatter } from "../../../../../utils/constants";
import { customStyles } from "../../../../common/CommonMethods";

export default class CurrentBid extends Component {
  onView = (row) => {
    if (row.is_deal) {
      this.props.history.push(`/user/deal-view/${row.uuid}`);
    } else {
      this.props.history.push(`/user/ipo-view/${row.uuid}`);
    }
  };

  handleChange = (row) => {
    if (row.is_deal) {
      this.props.history.push(`/user/deal-view/${row.uuid}`);
    } else {
      this.props.history.push(`/user/ipo-view/${row.uuid}`);
    }
  };

  render() {
    const columns = [
      {
        name: "",
        selector: "deal_logo",
        sortable: false,
        cell: (row) => (
          <>
            <div
              className="company-logo"
              onClick={() => this.handleChange(row)}
            >
              {row.is_deal
                ? row.deal_logo && (
                    <img
                      style={{
                        maxWidth: "70px",
                        maxHeight: "70px",
                        float: "left",
                      }}
                      src={`${BaseUrl}/storage/${row.deal_logo.id}/${row.deal_logo.file_name}`}
                      alt=""
                    />
                  )
                : row.logo && (
                    <img
                      style={{
                        maxWidth: "70px",
                        maxHeight: "70px",
                        float: "left",
                      }}
                      src={`${BaseUrl}/storage/${row.logo.id}/${row.logo.file_name}`}
                      alt=""
                    />
                  )}
            </div>
          </>
        ),
      },
      {
        name: "Type",
        selector: "type",
        sortable: false,
        className: "type no-wrap",
        cell: (row) => (
          <>
            <div class="type" onClick={() => this.handleChange(row)}>
              <span>{row.is_deal ? "Placement" : "IPO"}</span>
            </div>
          </>
        ),
      },
      {
        name: "Company",
        selector: "company_name",
        style: { minWidth: "130px" },
        sortable: false,
        className: "company no-wrap",
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)}>
              <span>{row.company_name}<br/>{row.asx_code ? row.asx_code : ''}</span>
            </div>
          </>
        ),
      },
      {
        name: "Entity",
        selector: "market_capital",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {(row.myBid.entity &&
              row.myBid.entity.entity_trust &&
              `${row.myBid.entity.broker_name} - ${row.myBid.entity.entity_trust.name}`) ||
              (row.myBid.entity &&
                row.myBid.entity.entity_company &&
                `${row.myBid.entity.broker_name} - ${row.myBid.entity.entity_company.name}`) ||
              (row.myBid.entity &&
                row.myBid.entity.entity_individuals.length > 0 &&
                `${row.myBid.entity.broker_name} - ${row.myBid.entity.entity_type.type}`)}
          </div>
        ),
      },
      {
        name: "Number of Shares",
        selector: "share_count",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.myBid.share_count
              ? numberFormatter(row.myBid.share_count)
              : ""}
          </div>
        ),
      },
      {
        name: "Price",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.is_deal === 1
              ? row.capital_raise
                ? `$${row.capital_raise}`
                : ""
              : row.ipo_price
              ? `$${row.ipo_price}`
              : ""}
          </div>
        ),
      },
      {
        name: "Investment Amount",
        selector: "investment_amount",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.myBid.investment_amount
              ? numberFormatter(row.myBid.investment_amount, "$", 2)
              : ""}
          </div>
        ),
      },
      {
        name: "View Deal",
        selector: "bid_now",
        sortable: false,
        left: true,
        cell: (row) => (
          <div className="tbl-title" onClick={() => this.onView(row)}>
            <span className="bid-now">
              <Icon
                style={{ cursor: "pointer" }}
                color="blue"
                size="huge"
                name="arrow alternate circle right outline"
              />
            </span>
          </div>
        ),
      },
    ];

    return (
      <div className="md-table-normal table-responsive">
        <DataTable
          noHeader
          highlightOnHover
          columns={columns}
          data={this.props.deals}
          onRowClicked={this.handleChange}
          pointerOnHover={true}
          customStyles={customStyles}
        />
      </div>
    );
  }
}

import BidService from "../../services/bidService";
import { toast } from "../../components/common/Toast";
import { startButtonLoading, stopButtonLoading } from "./loadingActions";
import { reset } from "redux-form";

const actions = {
  FETCH_ALL_BIDS: "FETCH_ALL_BIDS",

  getAllBids: ({ dispatch }) => {
    BidService.getAllBids()
      .then(({ data }) => {
        dispatch({ type: actions.FETCH_ALL_BIDS, payload: data });
      })
      .catch(() => {});
  },

  createBid: (payload, dispatch, history) => {
    dispatch(startButtonLoading);
    BidService.createBid(payload)
      .then(({ data }) => {
        console.log(payload)
        dispatch(stopButtonLoading);
        dispatch(reset("DealsViewForm"));
        toast.success(
          "Thank you for submitting your bid, we will get back to you shortly with an allocation. "
        );
        history.push(`/user/bid/success/${payload.deal_id}/bid`);
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) {
          console.log(error)
          toast.error("Something went wrong.");
        }
      });
  },

  createIndication: (payload, dispatch, history) => {
    dispatch(startButtonLoading);
    BidService.createIndication(payload)
      .then(({ data }) => {
        dispatch(stopButtonLoading);
        dispatch(reset("DealsViewForm"));
        toast.success(
          "Thank you for submitting your bid, we will contact the lead broker and attempt to get you an allocation."
        );
        history.push(`/user/bid/success/${payload.deal_id}/indication`);
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) {
          toast.error("Something went wrong.");
        }
      });
  },

  updateIndication: (payload, dispatch, history) => {
    dispatch(startButtonLoading);
    BidService.updateIndication(payload)
      .then(({ data }) => {
        dispatch(stopButtonLoading);
        toast.success("Indication successfully updated.");
        history.push("/user/deals");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) {
          toast.error("Something went wrong.");
        }
      });
  },

  updateBid: (payload, dispatch, history) => {
    dispatch(startButtonLoading);
    BidService.updateBid(payload)
      .then(({ data }) => {
        dispatch(stopButtonLoading);
        toast.success("Bid successfully updated.");
        // history.push("/user/deals");
        history.push(`/user/bid/success/${payload.deal_id}/bid`);

      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) {
          toast.error("Something went wrong.");
        }
      });
  },

  deleteBid: (payload, dispatch, history) => {
    dispatch(startButtonLoading);
    BidService.deleteBid(payload)
      .then(({ data }) => {
        dispatch(stopButtonLoading);
        toast.success("Bid successfully cancelled.");
        history.push("/user/deals");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) {
          toast.error("Something went wrong.");
        }
      });
  },
};
export default actions;

import React, { Component } from "react";
import { Radio } from "semantic-ui-react";
import { connect } from "react-redux";
import { entityTypes } from "../../../../utils/constants";
import EntityActions from "../../../../redux/actions/entityActions";
import CompanyForm from "./CompanyForm/CompanyForm";
import TrustForm from "./TrustForm/TrustForm";
import IndividualForm from "./IndividualForm/IndividualForm";
import { deleteMediaSuccess } from "../../../../redux/actions/accountActions";
var moment = require("moment");

const companyType = ["Proprietary", "Public", "Private"];

class AddEntity extends Component {
  state = {
    value: "company",
    companyType: "",
    directorsData: {},
    directors: [],
    beneficialOwners: [],
    individualJoints: [],
    individualJointsFormValue: 1,
    individualJointsLength: "",
    uploadedIndividualDocs: [],
    trustDeedFiles: [],
    investorForms: [],
    companyExtracts: [],
    documentID: "",
    open: false,
    validation: false,
    dob: {},
    verification: [],
    verificationDocument: {},
  };

  onUpdateDirectorsCount = (value) => {
    this.setState({
      directors: value,
    });
  };

  onStatusDocumentUpload = (index, files) => {
    if (files.length) {
      let newFiles = { index, files };
      const { uploadedIndividualDocs } = this.state;
      uploadedIndividualDocs.push(newFiles);
      this.setState({
        uploadedIndividualDocs: uploadedIndividualDocs,
      });
    }
  };

  onDeedDocumentUpload = (files) => {
    this.setState({
      trustDeedFiles: files,
    });
  };

  onInvestorDocumentUpload = (files) => {
    this.setState({
      investorForms: files,
    });
  };

  onCompanyExtractUpload = (files) => {
    this.setState({
      companyExtracts: files,
    });
  };

  open = (id) => {
    this.setState({ open: true, documentID: id });
  };

  close = () => this.setState({ open: false });

  onDocumentDeleteHandler = async (id) => {
    let { deleteMedia } = this.props;
    await deleteMedia({ id: this.state.documentID });
    this.setState({ open: false });
    this.fetchEntityById();
  };

  onExtractDeleteHandler = async (id) => {
    let { deleteMedia } = this.props;
    await deleteMedia({ id: this.state.documentID });
    this.setState({ open: false });
    this.fetchEntityById();
  };

  addIndividualInIndividualForm = () => {
    this.setState((prevState) => {
      return {
        individualJointsFormValue: (prevState.individualJointsFormValue += 1),
        individualJointsLength: (prevState.individualJointsLength += 1),
      };
    });
  };

  removeIndividualInIndividualForm = () => {
    this.setState((prevState) => {
      return {
        individualJointsFormValue: (prevState.individualJointsFormValue -= 1),
      };
    });
  };

  componentDidMount = () => {
    this.fetchEntityById();
  };

  fetchEntityById = () => {
    let { fetchSingleEntity } = this.props;
    if (this.props.match.params.id) {
      fetchSingleEntity(this.props.match.params.id);
    } else {
      let { fetchSingleEntityData } = this.props;
      fetchSingleEntityData();
    }
  };

  handleChange = (e, { value }) => {
    this.setState({ value });
  };

  handleCompanyChange = (e, { companyType }) => {
    this.setState({ companyType });
  };

  componentConfig = {
    iconFiletypes: [".jpg", ".png", ".gif"],
    showFiletypeIcon: true,
    postUrl: "/uploadHandler",
  };

  companySubmitHandler = (values) => {
    const { history } = this.props;
    this.setState({ validation: true });
    values.investorForms = this.state.investorForms;
    values.companyExtracts = this.state.companyExtracts;
    this.props.createCompanyEntity(
      {
        ...this.state.verificationDocument,
        ...values,
        ...this.state.dob,
        verification:
          typeof this.state.verification === "object" &&
          this.state.verification !== null
            ? this.state.verification
            : null,
      },
      history
    );
  };

  trustSubmitHandler = (values) => {
    const { history } = this.props;
    // dispatch(focus((form: String), (field: String)));
    values.trustDeedFiles = this.state.trustDeedFiles;
    values.investorForms = this.state.investorForms;
    values.companyExtracts = this.state.companyExtracts;
    this.props.createTrustEntity(
      { ...values, ...this.state.dob, ...this.state.verificationDocument },
      history
    );
  };

  individualSubmitHandler = (values) => {
    const { history } = this.props;

    const { uploadedIndividualDocs } = this.state;
    let individuals = values.individuals.map((individual, index) => {
      let statusDocument = uploadedIndividualDocs.find(
        (uploadedIndividualDoc, fileIndex) =>
          uploadedIndividualDoc.index === index
      );
      individual.status_documents = statusDocument ? statusDocument.files : [];
      return individual;
    });
    values.individuals = individuals;
    this.props.createIndividualEntity(
      { ...values, ...this.state.dob, ...this.state.verificationDocument },
      history
    );
  };

  directorsData = () => {
    let { singleEntityData } = this.props;
    if (singleEntityData.entity_company) {
      let directorsAll = singleEntityData.entity_company.directors;
      directorsAll = directorsAll.map((director) => {
        var date = moment(director.dob, "DD/MM/YYYY").format("YYYY-MM-DD");
        director.dob = new Date(date);
        return director;
      });

      this.setState({
        directors: directorsAll,
      });
    }
  };

  beneficialOwnersData = () => {
    let { singleEntityData } = this.props;
    if (singleEntityData.entity_trust) {
      let ownersAll = singleEntityData.entity_trust.beneficial_owners;

      ownersAll = ownersAll.map((owner) => {
        var date = moment(owner.dob, "DD/MM/YYYY").format("YYYY-MM-DD");
        owner.dob = new Date(date);
        return owner;
      });

      this.setState({
        beneficialOwners: [...ownersAll],
      });
    }
  };

  individualJointData = () => {
    let { singleEntityData } = this.props;
    if (singleEntityData.entity_individuals) {
      let individualsAll = singleEntityData.entity_individuals;
      individualsAll = individualsAll.map((inidividual, key) => {
        let dob = inidividual.dob;
        var date = moment(dob, "DD/MM/YYYY").format("YYYY-MM-DD");
        inidividual.dob = new Date(date);
        delete inidividual.id;
        delete inidividual.entity_id;
        delete inidividual.created_at;
        delete inidividual.updated_at;
        return inidividual;
      });
      this.setState({
        individualJoints: individualsAll,
        individualJointsLength: individualsAll.length,
      });
    }
  };

  onUpdateDirectorsCount = (value) => {
    this.setState({
      directorCount: value,
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.singleEntityData &&
      nextProps.singleEntityData.entity_company &&
      Object.entries(nextProps.singleEntityData.entity_company).length !== 0
    ) {
      this.directorsData();
      // this.setState({ value: "company" });
    }

    if (
      nextProps.singleEntityData &&
      nextProps.singleEntityData.entity_trust &&
      Object.entries(nextProps.singleEntityData.entity_trust).length !== 0
    ) {
      this.beneficialOwnersData();
      // this.setState({ value: "trust" });
    }

    if (
      this.props.singleEntityData !== nextProps.singleEntityData &&
      nextProps.singleEntityData.entity_individuals &&
      nextProps.singleEntityData.entity_individuals.length !== 0 &&
      nextProps.singleEntityData.entity_individuals[0].id
    ) {
      this.individualJointData();
      // this.setState({ value: "individual" });
    }
  };

  setEntityFormValue = (value) => {
    this.setState({ value });
  };

  setRadioHandler = (value) => {
    this.setState({ value });
  };

  onDocumentDeleteHandler = async (id) => {
    let { deleteMedia } = this.props;
    await deleteMedia({ id: this.state.documentID });
    this.setState({ open: false });
    this.individualJointData();
  };

  handleDob = (field, value) => {
    let splittedField = field.split(".");
    let requiredField = `${
      splittedField[0] +
      "[" +
      splittedField[1] +
      "]" +
      "[" +
      splittedField[2] +
      "]"
    }`;
    let dateOfBirth = {};
    dateOfBirth[requiredField] = value;
    this.setState((prevState) => {
      return { dob: { ...prevState.dob, ...dateOfBirth } };
    });
  };

  handleDob = (field, value) => {
    let splittedField = field.split(".");
    let requiredField = `${
      splittedField[0] +
      "[" +
      splittedField[1] +
      "]" +
      "[" +
      splittedField[2] +
      "]"
    }`;
    let dateOfBirth = {};
    dateOfBirth[requiredField] = value;
    this.setState((prevState) => {
      return { dob: { ...prevState.dob, ...dateOfBirth } };
    });
  };

  handleIndividaulDob = (field, value) => {
    let splittedField = field.split(".");
    let requiredField = `${
      splittedField[0] +
      "[" +
      splittedField[1] +
      "]" +
      "[" +
      splittedField[2] +
      "]"
    }`;
    let dateOfBirth = {};
    dateOfBirth[field] = value;
    this.setState((prevState) => {
      return { dob: { ...prevState.dob, ...dateOfBirth } };
    });
  };

  // onIDUpload = (files) => {
  //   this.setState({ verification: files });
  // };

  onIDUpload = (index, files) => {
    if (files.length) {
      const { verification } = this.state;
      let newFiles = {};
      newFiles[`verification[${index}]`] = files;
      verification.push(newFiles);
      this.setState({
        verification: verification,
      });
    }
  };

  handleIdUpload = (file) => {
    this.setState((prevState) => {
      return {
        verificationDocument: { ...prevState.verificationDocument, ...file },
      };
    });
  };

  handleDirectorsDob = (field, value) => {
    let dateOfBirth = {};
    dateOfBirth[field] = value;
    this.setState((prevState) => {
      return { dob: { ...prevState.dob, ...dateOfBirth } };
    });
  };

  render() {
    let { match, singleEntityData } = this.props;
    return (
      <main className="page-create-account investor-account ">
        <div className="container">
          <div class="form-normal ">
            <div className="all-fields">
              <div class="form-normal form-create-account profile-upgrade">
                {!match.params.id ? (
                  <h1>Create Entity</h1>
                ) : (
                  <h1>Edit Entity</h1>
                )}
                <div className="row no-gutters">
                  {Object.keys(entityTypes).map((key) => {
                    return (
                      <Radio
                        label={entityTypes[key]}
                        value={key}
                        checked={this.state.value === key}
                        onChange={this.handleChange}
                        className={`col-md-4`}
                      />
                    );
                  })}
                </div>

                {this.state.value === "company" ? (
                  <CompanyForm
                    onSubmit={this.companySubmitHandler}
                    handleDirectorsDob={this.handleDirectorsDob}
                    validation={this.state.validation}
                    onUpdateDirectorsCount={this.onUpdateDirectorsCount}
                    onInvestorDocumentUpload={this.onInvestorDocumentUpload}
                    onCompanyExtractUpload={this.onCompanyExtractUpload}
                    directors={this.state.directorCount}
                    selectedDate={this.handleIndividaulDob}
                    onIDUpload={this.onIDUpload}
                    onDocumentDeleteHandler={this.onDocumentDeleteHandler}
                    onExtractDeleteHandler={this.onExtractDeleteHandler}
                    open={this.open}
                    handleIdUpload={this.handleIdUpload}
                    companyType={companyType}
                    show={this.state.open}
                    close={this.close}
                  />
                ) : null}

                {this.state.value === "trust" ? (
                  <TrustForm
                    onDeedDocumentUpload={this.onDeedDocumentUpload}
                    onInvestorDocumentUpload={this.onInvestorDocumentUpload}
                    trustDeedDocument={
                      singleEntityData && singleEntityData.entity_trust
                        ? singleEntityData.entity_trust.trust_deeds
                        : []
                    }
                    open={this.open}
                    companyType={companyType}
                    show={this.state.open}
                    close={this.close}
                    onSubmit={this.trustSubmitHandler}
                    selectedDate={this.handleDirectorsDob}
                    handleIdUpload={this.handleIdUpload}
                    handleIndividaulDob={this.handleIndividaulDob}
                    handleDirectorsDob={this.handleDirectorsDob}
                  />
                ) : null}
                {this.state.value === "individual" ? (
                  <IndividualForm
                    individualsCount={
                      !match.params.id && this.state.individualJointsFormValue
                    }
                    selectedDate={this.handleDob}
                    handleIdUpload={this.handleIdUpload}
                    addIndividual={this.addIndividualInIndividualForm}
                    removeIndividual={this.removeIndividualInIndividualForm}
                    onStatusDocumentUpload={this.onStatusDocumentUpload}
                    onSubmit={this.individualSubmitHandler}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    entities: state.entities.entities,
    form: state.form,
    singleEntityData: state.entities.singleEntity,
    initialValue: {
      number_of_directors: 1,
    },
  };
};

const {
  createCompanyEntity,
  createTrustEntity,
  createIndividualEntity,
  fetchSingleEntityData,
  updateCompanyEntity,
  updateIndividualEntity,
  updateTrustEntity,
  unmountSingleEntity,
} = EntityActions;

const mapDispatchToProps = (dispatch) => ({
  createCompanyEntity: (payload, history) =>
    createCompanyEntity({ dispatch, payload, history }),
  createTrustEntity: (payload, history) =>
    createTrustEntity({ dispatch, payload, history }),
  createIndividualEntity: (payload, history) =>
    createIndividualEntity({ dispatch, payload, history }),
  fetchSingleEntity: (payload) => {
    fetchSingleEntityData({ payload, dispatch });
  },
  updateSingleCompanyEntity: (payload, history) => {
    updateCompanyEntity({ dispatch, payload, history });
  },
  updateSingleIndividualEntity: (payload, history) => {
    updateIndividualEntity({ dispatch, payload, history });
  },
  updateSingleTrustEntity: (payload, history) => {
    updateTrustEntity({ dispatch, payload, history });
  },
  fetchSingleEntityData: (payload, history) => unmountSingleEntity(dispatch),
  deleteMedia: (payload) => deleteMediaSuccess(dispatch, payload),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEntity);

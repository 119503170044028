import React, { Component, Fragment } from "react";
import { Tabs, Modal, Button } from "antd";
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import DealActions from "../../../../redux/actions/dealActions";
import IpoActions from "../../../../redux/actions/ipoActions";
import EntityActions from "../../../../redux/actions/entityActions";
import BidActions from "../../../../redux/actions/bids.Actions";
import CurrentDeal from "./BrokerDeals/CurrentDeal";
import PastDeal from "./BrokerDeals/PastDeal";
import CurrentBid from "./InvestorDeals/CurrentBid";
import Confirmation from "./InvestorDeals/Confirmation";
import Deals180 from "./PublicDeals/deals_180";
import DealsMarket from "./PublicDeals/deals_market";
import MarketIpo from "./InvestorDeals/marketipo";
import UnlistedInvestments from "./InvestorDeals/unlistedInvestments";
import Ipo180 from "./InvestorDeals/180ipo";
import BidNowModal from "../../../common/BidNowModal";
import CurrentIpos from "./BrokerDeals/CurrentIpos";
import BidIpoModal from "../../../common/BidIpoModal";
import Loader from "../../../Loader/Loader";
import {
  sortDealsAccToDueDate,
  sortIposAccToListingDate,
  showRefreshButton,
} from "../../../../utils/constants";
import { BaseUrl } from "../../../../services/settings";
import ClosedDeals from "./ClosedDeals/ClosedDeals";
import { toast } from "../../../common/Toast";
import { Link } from "react-router-dom";
import Logo from "../../../../assets/images/180M-logo.png";
import RealestateLogo from "../../../../assets/images/180R-logo.png";
import ModalComponent from "../../../common/TermsModal";
import InvestorPreferences from "../preferences/InvestorPreferences";
import {
  getCurrentUser,
  acceptUserTerms,
} from "../../../../redux/actions/authActions";
import DatePicker from "react-datepicker";
import moment from "moment";
import InvestorTerms from "../../public/InvestorTerms";
import { accountHold } from "../../../common/HoldAlert";
import momentTimezone from "moment-timezone";

const { TabPane } = Tabs;

const dealAccessDenialMessage = `To access our deal flow, please click on your profile, add an entity and submit your entity details.`;

const dealAccessRedMessage = `For s708 (Sophisticated) Investors, access our deal flow by clicking on your profile, adding an entity, and submitting your entity details.`;
const siFormExpired = `Your current sophisticated investor form has expired. Please upload an 
updated sophisticated investor form to bid.`;

class DealsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      selectEntityOptions: [],
      showEditForm: false,
      showEditIpoForm: false,
      showIpo: false,
      activeTab: "1",
      reload: false,
      showPreference: false,
      selectedClosedDate: new Date(),
      placementType: "Placement",
      showAcceptTerms: false,
    };
  }

  onBidNow = (id, anotherBid) => {
    const { getDealStatus, currentUser, history } = this.props;
    const isApproved = currentUser.is_approved === "Approved" ? true : false;
    const isHold = currentUser.is_hold;
    const withOutEntity =
      currentUser.investor_entities &&
      currentUser.investor_entities.length === 0 &&
      currentUser.is_approved !== "Approved";
    if (!anotherBid)
      if (isApproved) {
        if (isHold){
          accountHold(this.props);
          return;
        }
        if (currentUser.si_form_expired) {
          Modal.confirm({
            width: 600,
            okButtonProps: {
              type: "primary",
              size: "large",
            },
            cancelButtonProps: {
              size: "large",
            },

            content: <p className="pconfirm">{siFormExpired}</p>,
            okText: "My Profile",
            cancelText: "Cancel",
            onOk() {
              history.push("/user/profile");
            },
            onCancel() {},
          });
        } else {
          getDealStatus(id);
          this.setState((prevState) => {
            return { show: !prevState.show };
          });
        }
      } else {
          Modal.confirm({
            width: 600,
            okButtonProps: {
              type: "primary",
              size: "large",
            },
            cancelButtonProps: {
              size: "large",
            },
            icon: (
              <QuestionCircleOutlined
                style={{ color: withOutEntity ? "#f53c44" : "#faad14" }}
              />
            ),
            content: (
              <p className="pconfirm">
                {withOutEntity
                  ? dealAccessDenialMessage
                  : "We are currently processing your account for approval"}
              </p>
            ),
            okText: "My Profile",
            cancelText: "Cancel",
            onOk() {
              history.push("/user/profile");
            },
            onCancel() {},
          });
        
        
      }
  };

  onBidIpoNow = (id) => {
    const { fetchSelectedIpoData, currentUser, history } = this.props;
    const withOutEntity =
      currentUser.investor_entities &&
      currentUser.investor_entities.length === 0 &&
      currentUser.is_approved !== "Approved";
    const isApproved = currentUser.is_approved === "Approved" ? true : false;
    const isHold = currentUser.is_hold;
    if (isApproved) {
      if (isHold){
        accountHold(this.props);
        return;
      }
      if (currentUser.si_form_expired) {
        Modal.confirm({
          width: 600,
          okButtonProps: {
            type: "primary",
            size: "large",
          },
          cancelButtonProps: {
            size: "large",
          },

          content: <p className="pconfirm">{siFormExpired}</p>,
          okText: "My Profile",
          cancelText: "Cancel",
          onOk() {
            history.push("/user/profile");
          },
          onCancel() {},
        });
      } else {
        fetchSelectedIpoData(id);
        this.setState((prevState) => {
          return { showIpo: !prevState.showIpo };
        });
      }
    } else {
      Modal.confirm({
        width: 600,
        okButtonProps: {
          type: "primary",
          size: "large",
        },
        cancelButtonProps: {
          size: "large",
        },
        content: (
          <p className="pconfirm">
            {withOutEntity
              ? dealAccessDenialMessage
              : "We are currently processing your account for approval"}
          </p>
        ),
        okText: "My Profile",
        cancelText: "Cancel",
        onOk() {
          history.push("/user/profile");
        },
        onCancel() {},
      });
    }
  };

  handleCancelIpo = () => {
    this.setState({ showIpo: false, showEditIpoForm: false });
  };

  handleCancel = () => {
    this.setState({ show: false, showEditForm: false });
  };

  onSubmitBid = async (values) => {
    console.log(values);
    let {
      createBidSubmit,
      currentDeal,
      updateIndicationSubmit,
      updateBidSubmit,
      history,
      currentUser,
      createIndicationSubmit,
    } = this.props;

    if (currentDeal.type === "180") {
      if (!values.bid_id) {
        this.setState({ show: false });
        Modal.confirm({
          title: "Confirm",
          width: 700,
          okButtonProps: {
            type: "primary",
            size: "large",
          },
          cancelButtonProps: {
            size: "large",
          },
          icon: <ExclamationCircleOutlined />,
          content: (
            <p className="pconfirm">
              {" "}
              By submitting a bid, you are making a binding and irrevocable
              offer to acquire the securities, on the terms set out in the
              information available here, in the{" "}
              {currentDeal && currentDeal.term_sheets ? (
                <a
                  target="_blank"
                  href={`${BaseUrl}storage/${currentDeal.term_sheets.id}/${currentDeal.term_sheets.file_name}`}
                >
                  term sheet
                </a>
              ) : (
                "term sheet"
              )}{" "}
              and in the{" "}
              {
                <a
                  target="_blank"
                  href="https://180markets.com.au/investor/terms&conditions"
                >
                  Investor Terms
              </a>
            }
            <span> and in the </span>
              <a
                target="_blank"
                href={`/user/deal-disclaimer/${currentDeal.uuid}`}
              >
                Investment Opportunity Disclaimer 
              </a>
            . You confirm you make each of the representations and warranties
              specified in the Investor Terms. Your bid does not guarantee that
              you will receive an allocation. If your bid is successful, you
              will be required to pay to acquire the securities in accordance
              with your bid and the Investor Terms, subject to any scale back.
            </p>
          ),
          okText: "Confirm",
          cancelText: "Cancel",
          async onOk() {
            await createBidSubmit(
              { ...values, deal_id: currentDeal.id },
              history
            );
          },
          onCancel() {},
        });
      } else {
        await updateBidSubmit(
          {
            ...values,
            deal_id: currentDeal.id,
            bidId: values.bid_id,
          },
          history
        );
        this.setState({ show: false });
      }
    }

    if (currentDeal.type === "market") {
      if (!currentDeal.myIndication) {
        await createIndicationSubmit(
          { ...values, deal_id: currentDeal.id, investor_id: currentUser.id },
          history
        );
      } else {
        await updateIndicationSubmit(
          {
            ...values,
            deal_id: currentDeal.id,
            investor_id: currentUser.id,
            bidId: currentDeal.myIndication.uuid,
          },
          history
        );
        this.setState({ show: false });
      }
    }
  };

  onSubmitIpoBid = (values) => {
    let {
      submitIpoBid,
      updateIpoBid,
      ipo,
      history,
      match,
      submitIpoIndication,
      updateIpoIndication,
    } = this.props;

    if (ipo.type === "180") {
      if (!values.bid_id) {
        this.setState({ showIpo: false });
        Modal.confirm({
          title: "Confirm",
          width: 700,
          okButtonProps: {
            type: "primary",
            size: "large",
          },
          cancelButtonProps: {
            size: "large",
          },
          icon: <ExclamationCircleOutlined />,
          content: (
            <p className="pconfirm">
              {" "}
              By submitting a bid, you are making a binding and irrevocable
              offer to acquire the securities, on the terms set out in the
              information available here, in the{" "}
              {ipo && ipo.term_sheet ? (
                <a
                  target="_blank"
                  href={`${BaseUrl}storage/${ipo.term_sheet.id}/${ipo.term_sheet.file_name}`}
                >
                  term sheet
                </a>
              ) : (
                "term sheet"
              )}{" "}
              and in the{" "}
              {
                <a
                  target="_blank"
                  href="https://180markets.com.au/investor/terms&conditions"
                >
                  Investor Terms
              </a>
            }
            <span> and in the </span>
              <a
                target="_blank"
                href={`/user/ipo-disclaimer/${ipo.uuid}`}
              >
                Investment Opportunity Disclaimer 
              </a>
            . You confirm you make each of the representations and warranties
              specified in the Investor Terms. Your bid does not guarantee that
              you will receive an allocation. If your bid is successful, you
              will be required to pay to acquire the securities in accordance
              with your bid and the Investor Terms, subject to any scale back.
            </p>
          ),
          okText: "Confirm",
          cancelText: "Cancel",
          async onOk() {
            await submitIpoBid({ ...values, id: ipo.id }, history);
          },
          onCancel() {},
        });
      } else {
        updateIpoBid({ ...values, id: ipo.id, bidId: values.bid_id }, history);
        this.setState({ showIpo: false });
      }
    }

    if (ipo.type === "market") {
      if (!ipo.myindication) {
        submitIpoIndication({ ...values, id: ipo.id }, history);
      } else {
        updateIpoIndication(
          { ...values, id: ipo.id, inidicationUuid: ipo.myindication.uuid },
          history
        );
      }
    }
  };

  componentDidMount = () => {
    this.fetchAllInitialData();
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.showPreference &&
      this.props.location.state.showPreference !== undefined
    ) {
      setTimeout(() => {
        this.setState({
          showPreference: true,
        });
      }, 3000);
    } else {
      const { currentUser } = this.props;
      if (!currentUser.terms_accepted) {
        this.setState({
          showAcceptTerms: true,
        });
      } else {
        this.closeAcceptTerms();
      }
    }

    setInterval(() => {
      let auth_token = localStorage.getItem("auth_token");
      if (auth_token) {
        this.fetchAllInitialData();
      }
    }, 60000);
  };

  fetchAllInitialData = async () => {
    const {
      fetchAllEntities,
      fetchAllDEALS,
      getAllAllocationsData,
      getAllIposData,
      getCurrentBids,
      getCurrentUser,
    } = this.props;
    getCurrentUser();
    fetchAllEntities();
    let key = 1;
    if (window.location.hash) {
      key = await this.getTabKey(window.location.hash);
    }
    const tab = await this.getTabName(key);
    console.log("tab", tab);

    if (tab === "deals") {
      fetchAllDEALS({ tab: "180deals" });
    }
    if (tab === "market") {
      fetchAllDEALS({ tab: "marketdeals" });
    }
    if (tab === "closed") {
      let date =
        this.state.selectedClosedDate === ""
          ? moment(new Date()).format("DD/MM/YYYY")
          : moment(this.state.selectedClosedDate).format("MM/YYYY");

      fetchAllDEALS({
        tab: "closeddeals",
        type: this.state.placementType,
        date,
      });
    }
    if (tab === "ipos") {
      getAllIposData({ tab: "180ipos" });
    }
    if (tab === "unlisted") {
      getAllIposData({ tab: "unlisted" });
    }

    getCurrentBids();
    // fetchAllPublic180Deals();
    // fetchAllPublicMarketDeals();
    getAllAllocationsData();
  };

  onReload = () => {
    this.setState({ reload: true });
    this.fetchAllInitialData();
    setTimeout(() => {
      this.setState({ reload: false });
    }, 500);
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.existingEntity !== nextProps.existingEntity &&
      nextProps.currentUser &&
      nextProps.currentUser.id
    ) {
      this.setState({
        selectEntityOptions:
          nextProps.existingEntity &&
          nextProps.existingEntity.map((entity) => {
            if (entity.entity_company !== null)
              return {
                value: entity.id,
                pid: entity.pid,
                broker_name: entity.broker_name,
                text: `${entity.broker_name} - ${entity.entity_company.name} ${
                  entity.entity_company.nickname
                    ? `(${entity.entity_company.nickname})`
                    : ""
                }`,
                is_si_form_expired: entity.is_si_form_expired,
              };
            else if (entity.entity_trust !== null)
              return {
                value: entity.id,
                pid: entity.pid,
                broker_name: entity.broker_name,
                text: `${entity.broker_name} - ${entity.entity_trust.name} ${
                  entity.entity_trust.nickname
                    ? `(${entity.entity_trust.nickname})`
                    : ""
                }`,
                is_si_form_expired: entity.is_si_form_expired,
              };
            else if (entity.entity_individual !== null)
              return {
                value: entity.id,
                pid: entity.pid,
                broker_name: entity.broker_name,
                text: `${entity.broker_name} - ${this.renderIndividualName(
                  entity
                )}`,
                is_si_form_expired: entity.is_si_form_expired,
              };
          }),
      });
    }

    if (
      this.props.currentUser &&
      nextProps.currentUser &&
      this.props.currentUser.terms_accepted !=
        nextProps.currentUser.terms_accepted
    ) {
      if (!nextProps.currentUser.terms_accepted) {
        this.setState({
          showAcceptTerms: true,
        });
      } else {
        this.setState({
          showAcceptTerms: false,
        });
      }
    }
  };

  renderIndividualName = (entity) => {
    let name = "";
    for (let key in entity.entity_individuals) {
      if (name.length > 0) {
        return (name +=
          " " +
          "&" +
          " " +
          entity.entity_individuals[key].given_name +
          " " +
          entity.entity_individuals[key].sur_name +
          " " +
          `${
            entity.entity_individuals[key].nickname
              ? `(${entity.entity_individuals[key].nickname})`
              : ""
          }`);
      }

      name +=
        entity.entity_individuals[key].given_name +
        " " +
        entity.entity_individuals[key].sur_name +
        " " +
        `${
          entity.entity_individuals[key].nickname
            ? `(${entity.entity_individuals[key].nickname})`
            : ""
        }`;
    }

    return name;
  };

  editBidHandler = (uuid) => {
    this.setState((prevState) => {
      return {
        showEditForm: !prevState.showEditForm,
      };
    });
  };

  editIpoBidHandler = (uuid) => {
    this.setState((prevState) => {
      return {
        showEditIpoForm: !prevState.showEditIpoForm,
      };
    });
  };

  getTabName = (key) => {
    let tab = "";
    switch (parseInt(key)) {
      case 1:
        tab = "deals";
        break;
      case 3:
        tab = "ipos";
        break;
      case 2:
        tab = "market";
        break;
      case 4:
        tab = "unlisted";
        break;
      case 7:
        tab = "closed";
        break;
      case 5:
        tab = "currentBids";
        break;
      case 6:
        tab = "confirmation";
        break;
      default:
        tab = "deals";
        break;
    }
    return tab;
  };

  getTabKey = (tab) => {
    let key = "";
    switch (tab) {
      case "#deals":
        key = 1;
        break;
      case "#ipos":
        key = 3;
        break;
      case "#market":
        key = 2;
        break;
      case "#unlisted":
        key = 4;
        break;
      case "#closed":
        key = 7;
        break;
      case "#currentBids":
        key = 5;
        break;
      case "#confirmation":
        key = 6;
        break;
      default:
        key = 1;
        break;
    }
    key = key.toString();
    return key;
  };

  callback = async (key) => {
    const { fetchAllDEALS, getAllIposData } = this.props;
    const tab = await this.getTabName(key);
    if (tab === "deals") {
      fetchAllDEALS({ tab: "180deals" });
    } else if (tab === "market") {
      fetchAllDEALS({ tab: "marketdeals" });
    } else if (tab === "closed") {
      let date =
        this.state.selectedClosedDate === ""
          ? moment(new Date()).format("DD/MM/YYYY")
          : moment(this.state.selectedClosedDate).format("MM/YYYY");
      fetchAllDEALS({
        tab: "closeddeals",
        type: this.state.placementType,
        date,
      });
    } else if (tab === "ipos") {
      getAllIposData({ tab: "180ipos" });
    } else if (tab === "unlisted") {
      getAllIposData({ tab: "unlisted" });
    }
    const history = createBrowserHistory();
    history.push(`/user/deals#${tab}`);
    this.setState({ activeTab: key });
  };

  closePreferences = () => {
    this.setState({ showPreference: false });
    const history = createBrowserHistory();
    if (history.location.state && history.location.state.showPreference) {
      let state = { ...history.location.state };
      delete state.showPreference;
      history.replace({ ...history.location, state });
    }
  };

  closeAcceptTerms = () => {
    this.setState({ showAcceptTerms: false });
  };

  showPreferences = () => {
    this.setState({ showPreference: true });
  };

  showAcceptTermsModal = () => {
    this.setState({ showAcceptTerms: true });
  };

  setStartDate = (date) => {
    this.setState(
      {
        selectedClosedDate: date,
      },
      () => {
        let date =
          this.state.selectedClosedDate === ""
            ? moment(new Date()).format("DD/MM/YYYY")
            : moment(this.state.selectedClosedDate).format("MM/YYYY");
        const { fetchAllDEALS } = this.props;
        fetchAllDEALS({
          tab: "closeddeals",
          type: this.state.placementType,
          date,
        });
      }
    );
  };

  setPlacementType = (e) => {
    this.setState(
      {
        placementType: e.target.value,
      },
      () => {
        let date =
          this.state.selectedClosedDate === ""
            ? moment(new Date()).format("DD/MM/YYYY")
            : moment(this.state.selectedClosedDate).format("MM/YYYY");
        const { fetchAllDEALS } = this.props;
        fetchAllDEALS({
          tab: "closeddeals",
          type: this.state.placementType,
          date,
        });
      }
    );
  };

  acceptTerms = () => {
    this.props.acceptUserTerms();
  };

  goToProfile = () => {
    const { history } = this.props;
    history.push("/user/profile");
  };

  render() {
    
    const {
      currentUser,
      allocations,
      allIpos,
      currentDeal,
      ipo,
      getAllIposData,
      userLoading,
    } = this.props;

    // if (userLoading){
    //   return (
    //   <main className="page-main page-deals page-all-deals">
    //     <Loader>
    //       <div className="container">
    //         <div className="page-deals page-all-deals">
    //           <div className="title">
    //             <h1>Please wait...</h1>
    //           </div>
    //         </div>
    //       </div>
    //     </Loader>
    //   </main>);
    // }


    let isInvestor = currentUser.role.some((role) => role.title === "Investor");

    const isEnitityExist = currentUser.is_approved === "Approved";

    const withEntity =
      currentUser.investor_entities &&
      currentUser.investor_entities.length > 0 &&
      currentUser.is_approved !== "Approved";

    const withOutEntity =
      currentUser.investor_entities &&
      currentUser.investor_entities.length === 0 &&
      currentUser.is_approved !== "Approved";

    const isHold = currentUser.is_hold;

    let start = momentTimezone.tz(currentUser.created_at, 'Australia/Sydney');
    let end = momentTimezone().tz('Australia/Sydney');
    let diff = momentTimezone.duration(end.diff(start));
    const createdDays = diff.days();
    let accessDeals = true;
    if (withOutEntity && createdDays >= 1){
      accessDeals = false;
    }

    return (
      <Fragment>
        <ModalComponent
          show={this.state.showPreference}
          onClose={() => this.closePreferences()}
          heading="Preferences"
        >
          <InvestorPreferences
            redirectOnSave={false}
            closeModal={this.closePreferences}
          />
        </ModalComponent>
        <ModalComponent
          show={this.state.showAcceptTerms}
          onClose={() => this.closeAcceptTerms()}
          heading="Please accept our updated Terms & Conditions to proceed."
          hideClose={true}
        >
          <InvestorTerms
            acceptButton={true}
            closeModal={this.closeAcceptTerms}
            acceptTerms={this.acceptTerms}
          />
        </ModalComponent>
        <main className="page-main page-deals page-all-deals">
          <Loader>
            <div className="container">
              <div className="page-deals page-all-deals">
                <div className="title">
                  {(isHold || (withOutEntity && accessDeals)) && (
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            class="alert alert-danger outline cursor-pointer"
                            onClick={() => this.goToProfile()}
                          >
                            <ExclamationCircleOutlined
                              className="custom"
                              height="2em"
                            />{" "}
                            &nbsp;{dealAccessRedMessage}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {withEntity && (
                    <div className="row">
                      <div className="col-md-12">
                        <div class="alert alert-warning outline">
                          <ExclamationCircleOutlined
                            className="custom"
                            height="2em"
                          />
                          &nbsp;&nbsp; We are currently processing your account
                          for approval
                        </div>
                      </div>
                    </div>
                  )}

                  {/* {isHold && (
                    <div className="row">
                      <div className="col-md-12">
                        <div class="alert alert-warning outline">
                          <ExclamationCircleOutlined
                            className="custom"
                            height="2em"
                          />
                          &nbsp;&nbsp; Your account is currently on hold
                        </div>
                      </div>
                    </div>
                  )} */}

                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Switch To:</h5> */}
                      {/* <div className="logo mt-4">
                        <a href="https://180markets.com.au" class=" mt-4">
                          <img
                            src={Logo}
                            alt="Home"
                            className="logo"
                          />
                        </a>
                      </div> */}
                      {/* <div className="logo mb-5">
                        <a
                          href="https://180realestate.com.au"
                          className="mt-1 "
                        >
                          <img
                            src={RealestateLogo}
                            alt="Home"
                            className="switch"
                            height="20"
                          />
                        </a>
                      </div> */}

                      <h1>
                        {isInvestor ? "All Deals" : "Your Deals"}{" "}
                        {/* <ReloadOutlined
                            style={{ fontSize: "20px" }}
                            onClick={this.onReload}
                            spin={this.state.reload}
                          /> */}
                      </h1>

                      {/* <Button
                        type="primary"
                        size="small"
                        onClick={() =>
                          this.props.history.push("/user/cornerstone")
                        }
                      >
                        Cornerstone
                      </Button> */}
                      {showRefreshButton(this.props.deals) ? (
                        <Button
                          className="ml-2"
                          primary
                          onClick={this.onReload}
                          size="small"
                          style={{ width: "90px" }}
                          loading={this.state.reload}
                        >
                          Refresh
                        </Button>
                      ) : null}
                      <Link to="/user/options">
                        <Button
                          className="ml-2"
                          primary
                          onClick={this.onReload}
                          size="small"
                          style={{ width: "90px" }}
                          loading={this.state.reload}
                        >
                          Options
                        </Button>
                      </Link>
                      <Link to="/user/transactions">
                        <Button
                          className="ml-2"
                          primary
                          onClick={this.onReload}
                          size="small"
                          style={{ width: "120px" }}
                          loading={this.state.reload}
                        >
                          Transactions
                        </Button>
                      </Link>
                      <br />
                      {!isInvestor && (
                        <strong style={{ fontSize: "17px" }}>
                          If you want access to All deals you will need to open
                          an Investor account.
                        </strong>
                      )}
                    </div>

                    <BidIpoModal
                      visible={this.state.showIpo}
                      selectEntityOptions={this.state.selectEntityOptions}
                      handleCancel={this.handleCancelIpo}
                      selectedIpo={ipo}
                      onSubmitBid={this.onSubmitIpoBid}
                      showEditIpoBidForm={this.state.showEditIpoForm}
                      editIpoBidHandler={this.editIpoBidHandler}
                      currentUser={currentUser}
                    />
                    <BidNowModal
                      title="Bid Now"
                      showEditForm={this.state.showEditForm}
                      visible={this.state.show}
                      editBidHandler={this.editBidHandler}
                      onBidNow={this.onBidNow}
                      handleCancel={this.handleCancel}
                      currentUser={currentUser}
                      onSubmitBid={this.onSubmitBid}
                      selectEntityOptions={this.state.selectEntityOptions}
                      currentDeal={currentDeal}
                    />
                    {currentUser.role.some(
                      (role) => role.title !== "Investor"
                    ) && (
                      <>
                        <div className="col-sm-6">
                          <button
                            onClick={() =>
                              this.props.history.push("/user/deals/create")
                            }
                            className="btn btn-normal btn-plus "
                          >
                            Add New Placement
                          </button>

                          <button
                            onClick={() =>
                              this.props.history.push("/user/create-ipo")
                            }
                            className="btn btn-normal btn-plus mr-2"
                          >
                            Add New IPO
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {!isInvestor && (
                  <Tabs defaultActiveKey="1" onChange={this.callback}>
                    <TabPane
                      tab={
                        <span className="nav-link-top ">
                          Current Placements
                        </span>
                      }
                      key="1"
                    >
                      <CurrentDeal
                        deals={
                          this.props.deals &&
                          sortDealsAccToDueDate(this.props.deals)
                        }
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span className="nav-link-top ">Closed Placements</span>
                      }
                      key="2"
                    >
                      <PastDeal
                        deals={
                          this.props.deals &&
                          sortDealsAccToDueDate(
                            this.props.deals.filter(
                              (deal) => deal.status === "Closed"
                            )
                          )
                        }
                      />
                    </TabPane>
                    <TabPane
                      tab={<span className="nav-link-top ">Current IPOs</span>}
                      key="3"
                    >
                      <CurrentIpos
                        deals={
                          allIpos &&
                          sortIposAccToListingDate(
                            allIpos.filter(
                              (ipo) => !ipo.status || ipo.status === "Active"
                            )
                          )
                        }
                        currentDeals={true}
                        getAllIposData={getAllIposData}
                      />
                    </TabPane>
                    <TabPane
                      tab={<span className="nav-link-top ">Closed IPOs</span>}
                      key="4"
                    >
                      <CurrentIpos
                        closedDeals={true}
                        deals={
                          allIpos &&
                          sortIposAccToListingDate(
                            allIpos.filter((ipo) => ipo.status === "Closed")
                          )
                        }
                        getAllIposData={getAllIposData}
                      />
                    </TabPane>
                  </Tabs>
                )}
                {isInvestor && (
                  <Tabs
                    defaultActiveKey={this.getTabKey(window.location.hash)}
                    onChange={this.callback}
                  >
                    <TabPane
                      tab={
                        <span className="nav-link-top ">180 Placements</span>
                      }
                      key="1"
                    >
                      <Deals180
                        {...this.props}
                        dealAccessDenialMessage={dealAccessDenialMessage}
                        isEnitityExist={isEnitityExist}
                        isHold={isHold}
                        onBidNow={this.onBidNow}
                        accessDeals={accessDeals}
                        dealAccessRedMessage={dealAccessRedMessage}
                        deals={
                          this.props.deals &&
                          this.props.deals.filter(
                            (deal) =>
                              (deal.status === "Active" ||
                                deal.status === "Finished") &&
                              deal.type == "180"
                          )
                        }
                      />
                    </TabPane>
                    <TabPane
                      tab={<span className="nav-link-top ">180 IPOs</span>}
                      key="3"
                    >
                      <Ipo180
                        dealAccessDenialMessage={dealAccessDenialMessage}
                        isEnitityExist={isEnitityExist}
                        isHold={isHold}
                        onBidIpoNow={this.onBidIpoNow}
                        accessDeals={accessDeals}
                        dealAccessRedMessage={dealAccessRedMessage}
                        deals={allIpos.filter(
                          (ipo) =>
                            (ipo.category === "180" &&
                              ipo.status === "Active") ||
                            (ipo.category === "180" &&
                              ipo.status === "Finished")
                        )}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span className="nav-link-top ">
                          180 Unlisted Investments
                        </span>
                      }
                      key="4"
                    >
                      <UnlistedInvestments
                        dealAccessDenialMessage={dealAccessDenialMessage}
                        isEnitityExist={isEnitityExist}
                        isHold={isHold}
                        onBidIpoNow={this.onBidIpoNow}
                        accessDeals={accessDeals}
                        dealAccessRedMessage={dealAccessRedMessage}
                        deals={allIpos.filter(
                          (ipo) =>
                            ipo.category === "Unlisted Investments" &&
                            ipo.status === "Active"
                        )}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span className="nav-link-top ">Market Placements</span>
                      }
                      key="2"
                    >
                      <DealsMarket
                        {...this.props}
                        dealAccessDenialMessage={dealAccessDenialMessage}
                        isEnitityExist={isEnitityExist}
                        isHold={isHold}
                        onBidNow={this.onBidNow}
                        accessDeals={accessDeals}
                        dealAccessRedMessage={dealAccessRedMessage}
                        deals={
                          this.props.deals &&
                          this.props.deals.filter(
                            (deal) =>
                              deal.status === "Active" && deal.type == "market"
                          )
                        }
                      />
                    </TabPane>

                    {/* <TabPane
                      tab={<span className="nav-link-top ">Market IPOs</span>}
                      key="4"
                    >
                      <MarketIpo
                        dealAccessDenialMessage={dealAccessDenialMessage}
                        isEnitityExist={isEnitityExist}
                        onBidIpoNow={this.onBidIpoNow}
                        deals={allIpos.filter(
                          (ipo) =>
                            ipo.type === "market" && ipo.status === "Active"
                        )}
                      />
                    </TabPane> */}

                    <TabPane
                      tab={<span className="nav-link-top ">Closed Raises</span>}
                      key="7"
                    >
                      <div className="container">
                        <div className="row p-2">
                          <div className="col-md-2">
                            <label>Placement/IPO</label>
                            <select
                              value={this.state.placementType}
                              onChange={(e) => this.setPlacementType(e)}
                              className="form-control"
                            >
                              <option value="Placement">
                                Closed Placements
                              </option>
                              <option value="IPO">Closed IPOs</option>
                            </select>
                          </div>
                          <div className="col-md-2">
                            <label>Select Month</label>
                            <DatePicker
                              selected={this.state.selectedClosedDate}
                              onChange={(date) => this.setStartDate(date)}
                              dateFormat="MMM yyyy"
                              showMonthYearPicker
                              showFullMonthYearPicker
                              showFourColumnMonthYearPicker
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <ClosedDeals 
                        type={this.state.placementType} 
                        accessDeals={accessDeals}
                        dealAccessRedMessage={dealAccessRedMessage}
                        />
                    </TabPane>
                    <TabPane
                      tab={<span className="nav-link-top ">Current Bids</span>}
                      key="5"
                    >
                      <CurrentBid
                        {...this.props}
                        deals={
                          this.props.currentDeals && this.props.currentDeals
                        }
                      />
                    </TabPane>
                    <TabPane
                      tab={<span className="nav-link-top ">Allocations</span>}
                      key="6"
                    >
                      <Confirmation deals={allocations} />
                    </TabPane>
                  </Tabs>
                )}
              </div>
            </div>
          </Loader>
        </main>
      </Fragment>
    );
  }
}
const {
  fetchDEALS,
  fetchCurrentDeals,
  fetchAllPublic180Deals,
  fetchAllPublicMarketDeals,
  getAllAllocations,
  getSingleDeal,
} = DealActions;
const { fetchEntities } = EntityActions;
const {
  getAllIpos,
  fetchSelectedIpo,
  submitBid,
  editIpoBid,
  submitIndication,
  editIpoIndication,
} = IpoActions;
let { createBid, updateBid, createIndication, updateIndication } = BidActions;

const mapDispatchToProps = (dispatch) => ({
  fetchAllEntities: (payload, history) =>
    fetchEntities({ dispatch, payload, history }),
  fetchAllDEALS: (payload, history) =>
    fetchDEALS({ dispatch, payload, history }),
  getCurrentBids: (payload, history) =>
    fetchCurrentDeals({ dispatch, payload, history }),
  fetchAllPublic180Deals: (payload, history) =>
    fetchAllPublic180Deals({ dispatch, payload, history }),
  fetchAllPublicMarketDeals: (payload, history) =>
    fetchAllPublicMarketDeals({ dispatch, payload, history }),
  getAllAllocationsData: () => getAllAllocations(dispatch),
  getAllIposData: (payload) => getAllIpos(dispatch, payload),
  getDealStatus: (payload) => getSingleDeal(payload, dispatch),
  createBidSubmit: (payload, history) => createBid(payload, dispatch, history),
  createIndicationSubmit: (payload, history) =>
    createIndication(payload, dispatch, history),
  updateBidSubmit: (payload, history) => updateBid(payload, dispatch, history),
  fetchSelectedIpoData: (uuid, history) => fetchSelectedIpo(uuid, dispatch),
  submitIpoBid: (payload, history) => submitBid(payload, history, dispatch),
  updateIpoBid: (payload, history) => editIpoBid(payload, history, dispatch),
  updateIndicationSubmit: (payload, history) =>
    updateIndication(payload, dispatch, history),
  submitIpoIndication: (payload, history) =>
    submitIndication(payload, history, dispatch),
  updateIpoIndication: (payload, history) =>
    editIpoIndication(payload, history, dispatch),
  getCurrentUser: () => getCurrentUser(dispatch),
  acceptUserTerms: (payload) => acceptUserTerms(dispatch, payload),
});

const mapStateToProps = (state) => {
  return {
    currentDeal: state.deals.bidStatus,
    currentUser: state.auth.currentUser,
    userLoading: state.auth.loading,
    deals: state.deals.deals,
    allocations: state.deals.allAllocations,
    currentDeals: state.deals.currentDeals,
    public180Deals: state.deals.public180Deals,
    loading: state.deals.loading,
    publicMarketDeals: state.deals.publicMarketDeals,
    allIpos: state.ipos.ipos,
    existingEntity: state.entities.entities,
    ipo: state.ipos.selectedIpo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealsPage);

import axios from "axios";
import { BaseUrl, configMultipart } from "./settings";

class StockTipService {
  getAllLeaderBoards = async () => {
    return await axios
      .get(BaseUrl + `frontend/v1/leaderboards`, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export default new StockTipService();

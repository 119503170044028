import SuperFetch from "./superfetch";
import axios from "axios";
import { BaseUrl, configMultipart } from "./settings";
import moment from "moment";

class TransactionService {
  getAll = async () => {
    let api = "transactions";
    
    return await SuperFetch.get(api).then((response) => {
      return response;
    });
  };

  getSingle = async (formInputs) => {
    return await SuperFetch.get("transactions/" + formInputs.id, formInputs).then(
      (response) => {
        return response;
      }
    );
  };

  createTransaction = async (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        if (key === "date") {
          console.log(moment(payload[key]))
          bodyFormData.append(
            "date",
            moment(payload[key]).format("DD/MM/YYYY")
          );
        } else {
          bodyFormData.append(key, payload[key]);
        }
      }
    }
    return axios
      .post(`${BaseUrl}frontend/v1/transactions`, bodyFormData, configMultipart())
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
  };

  updateTransaction = (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        if (key === "date") {
          bodyFormData.append(
            "date",
            moment(payload[key]).format("DD/MM/YYYY")
          );
        } else {
          bodyFormData.append(key, payload[key]);
        }
      } else {
        bodyFormData.append(key, "");
      }
    }

    bodyFormData.append("_method", "PUT");
    return axios
      .post(
        BaseUrl + "frontend/v1/transactions/" + payload.id,
        bodyFormData,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  deleteTransaction = async (id) => {
    return await SuperFetch.delete("transactions/" + id).then((response) => {
      return response;
    });
  };

}
export default new TransactionService();

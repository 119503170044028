import React, { Component, Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Dashboard from "./components/pages/protected/Dashboard";
import Profile from "./components/pages/protected/Profile";
import Preferences from "./components/pages/protected/Preferences";
import ProtectedRoute from "./components/ProtectedRoute";
import DealsPage from "./components/pages/protected/Deals/Deals";
import CreateDealsPage from "./components/pages/protected/Deals/CreateDeals";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import {
  logoutUser,
  getCurrentUser,
  uploadAvatar,
} from "./redux/actions/authActions";
import { showUserMenu, hideUserMenu } from "./redux/actions/appActions";
import { connect } from "react-redux";
import AsyncComponent from "./components/layout/AsyncComponent";
import AddEntityContainer from "./components/forms/Entities/AddEntity/AddEntityContainer";
import EditEntityContainer from "./components/forms/Entities/AddEntity/EditEntityContainer";
import EditDealsContainer from "./components/pages/protected/Deals/EditDealsContainer";
import DealView from "./components/forms/Deals/DealView/DealView";
import DealDisclaimer from "./components/forms/Deals/DealView/DealDisclaimer";
import IpoDisclaimer from "./components/forms/Ipo/IpoDisclaimer";
import BidSuccessMessage from "./components/forms/Deals/DealView/BidSuccessMessage/BidSuccessMessage";
import EditBidContainer from "./components/forms/Deals/DealView/EditBidContainer";
import FileView from "./components/forms/FileView/FileView";
import ErrorPage from "./components/pages/ErrorPage";
import Allocations from "./components/pages/Allocations";
import ThankUMessage from "./components/pages/ThankuPage/Thanku";
import IpoView from "./components/forms/Ipo/IpoView";
import EditIpoContainer from "./components/forms/Ipo/EditIpoContainer";
import DirectorShares from "./components/forms/DirectorShares/DirectorShares";
import LiveStreams from "./components/pages/protected/Deals/LiveStreams";
import LiveStreamsList from "./components/pages/protected/LiveStreams/LiveStreamsList";
import LiveStreamView from "./components/pages/protected/LiveStreams/LiveStreamView/LiveStreamView";
import CreateIpo from "./components/forms/Ipo/CreateIpo";
import Newsletter from "./components/forms/Newsletters/Newsletters";
import ClosedDeals from "./components/pages/protected/Deals/ClosedDeals/ClosedDeals";
import Webinars from "./components/forms/Webinars/Webinars";
import OfftheCharts from "./components/forms/OfftheCharts/OfftheCharts";
import Interviews from "./components/forms/Interviews/Interviews";
import InterviewView from "./components/forms/Interviews/InterviewView/InterviewView";
import ProtectedApproved from "./components/ProtectedApproved";
import CornerStoneComponent from "./components/forms/CornerStone/CornerStoneContainer";
import ProInvestorSeries from "./components/forms/ProInvestorSeries/ProInvestorSeries";
import { Menu, Sidebar, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Icon } from "antd";
import StockTips from "./components/pages/protected/StockTips";
import Options from "./components/pages/protected/Options";
import ReactGA from "react-ga";
import Leaderboards from "./components/pages/protected/Leaderboards";
import Transactions from "./components/pages/protected/Transactions";
import Sophisticated from "./components/pages/protected/Sophisticated";
import SophisticatedUpload from "./components/pages/protected/SophisticatedUpload";
import Review from "./components/pages/protected/Review";
import SiReview from "./components/pages/protected/SiReview";

class App extends Component {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();

    this.state = {
      visible: false,
    };
  }

  initReactGA = () => {
    ReactGA.initialize("UA-219617406-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  };


  componentDidMount() {
    // this.initReactGA();
    this.props.getCurrentUser();

    window.addEventListener("resize", this.updateDimensions);

    const windowWidth = window.innerWidth;
    if (windowWidth > 992) {
      this.updateDimensions();
    }
  }

  changeSidebar = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  updateDimensions = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth > 992) {
      if (this.state.visible) {
        this.changeSidebar();
      }
    }
  };

  triggerFile = () => {
    this.fileRef.current.click();
  };

  uploadAvatar = (e) => {
    this.props.uploadAvatar(e.target.files[0]);
  };

  render() {
    const loadingIcon = {
      right: "16px",
      top: "-37px",
      fontSize: "52px",
      position: "absolute",
    };

    const { auth } = this.props;
    const { location } = this.props;

    // const { isLoading } = this.props.app;
    const { logoutUser, showUserMenu } = this.props;

    const { currentUser } = this.props.auth;

    let { loading } = this.props;
    let { email, full_name } = currentUser;

    return (
      <Fragment>
        <Sidebar.Pushable as={Segment}>
          <Sidebar
            as={Menu}
            animation="push"
            icon="labeled"
            direction="right"
            className="app__sidebar"
            inverted
            onHide={() => this.setState}
            vertical
            visible={this.state.visible}
          >
            {/* <Menu.Item as="a">Home</Menu.Item>
            <Menu.Item as="a">Games</Menu.Item>
            <Menu.Item as="a">Channels</Menu.Item> */}
            <div class="main-nav-mobile">
              <div class="container">
                <ul class="clearfix">
                  <li className="mobile__headerImg">
                    <div className="nav-content">
                      <div className="avatar">
                        <div
                          className="picture"
                          style={{ opacity: loading ? "0.5" : 1 }}
                        >
                          <img
                            style={{ height: "100%", objectFit: "contain" }}
                            src={
                              this.props.currentUser.photo_url ||
                              this.props.currentUser.logo_url
                            }
                            alt=""
                          />
                        </div>
                        <div className="upload-picture">
                          {loading && (
                            <Icon type="loading" style={loadingIcon} />
                          )}
                          <div className="file-upload">
                            <label
                              htmlFor="file-upload-01"
                              className="btn-upload"
                              onClick={this.triggerFile}
                            ></label>
                            <input
                              type="file"
                              className="input-file"
                              id="file-upload-04"
                              ref={this.fileRef}
                              accept="image/*"
                              onChange={this.uploadAvatar}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="information">
                      <p>
                        <span className="name">{full_name}</span>
                        <span className="email">{email}</span>
                      </p>
                    </div>
                  </li>
                  <li className="sidebar__item">
                    <Link to="/who-we-are">
                      <span
                        onClick={() => {
                          if (this.state.visible) {
                            this.changeSidebar();
                          }
                        }}
                      >
                        Who We Are
                      </span>
                    </Link>
                  </li>
                  <li className="sidebar__item">
                    <Link to="/contact-us">
                      <span
                        onClick={() => {
                          if (this.state.visible) {
                            this.changeSidebar();
                          }
                        }}
                      >
                        Contact
                      </span>
                    </Link>
                  </li>
                  {/* <li className="sidebar__item">
                    <Link to="/live-now">
                      <span
                        onClick={() => {
                          if (this.state.visible) {
                            this.changeSidebar();
                          }
                        }}
                      >
                        Live Now
                      </span>
                    </Link>
                  </li> */}
                  <li className="loggedIn__sidebarDropdown">
                    <button
                      className="btn btn-light dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Resources
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <span
                        onClick={() => {
                          if (this.state.visible) {
                            this.changeSidebar();
                          }
                        }}
                      >
                        <Link
                          className="dropdown-item"
                          to="/user/director-shares"
                        >
                          Director Shares
                        </Link>
                      </span>
                      <span
                        onClick={() => {
                          if (this.state.visible) {
                            this.changeSidebar();
                          }
                        }}
                      >
                        <Link className="dropdown-item" to="/user/newsletters">
                          Newsletter
                        </Link>
                      </span>
                      <span
                        onClick={() => {
                          if (this.state.visible) {
                            this.changeSidebar();
                          }
                        }}
                      >
                        <Link className="dropdown-item" to="/user/webinars">
                          Webinars
                        </Link>
                      </span>
                      <span
                        onClick={() => {
                          if (this.state.visible) {
                            this.changeSidebar();
                          }
                        }}
                      >
                        <Link className="dropdown-item" to="/user/off-the-charts">
                          Off the Charts
                        </Link>
                      </span>
                      <span
                        onClick={() => {
                          if (this.state.visible) {
                            this.changeSidebar();
                          }
                        }}
                      >
                        <Link className="dropdown-item" to="/user/interviews">
                          18 Minutes with 180
                        </Link>
                      </span>
                      <span
                        onClick={() => {
                          if (this.state.visible) {
                            this.changeSidebar();
                          }
                        }}
                      >
                        <Link
                          className="dropdown-item"
                          to="/user/pro-investor-series"
                        >
                          Pro Investor Series
                        </Link>
                      </span>
                    </div>
                  </li>

                  <li className="loggedIn__sidebarDropdown">
                    <button
                      className="btn btn-light dropdown-toggle"
                      type="button"
                      id="profileDropDown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Profile
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="profileDropDown"
                    >
                      <span
                        onClick={() => {
                          if (this.state.visible) {
                            this.changeSidebar();
                          }
                        }}
                      >
                        <Link className="dropdown-item" to="/user/deals">
                          All Deals
                        </Link>
                      </span>
                      <span
                        onClick={() => {
                          if (this.state.visible) {
                            this.changeSidebar();
                          }
                        }}
                      >
                        <Link className="dropdown-item" to="/user/profile">
                          Profile
                        </Link>
                      </span>
                      <span
                        onClick={() => {
                          if (this.state.visible) {
                            this.changeSidebar();
                          }
                        }}
                      >
                        <Link className="dropdown-item" to="/user/preferences">
                          Preferences
                        </Link>
                      </span>
                    </div>
                  </li>

                  <li className="sidebar__item">
                    <span
                      onClick={() => {
                        if (this.state.visible) {
                          this.changeSidebar();
                        }
                      }}
                    >
                      <Link onClick={() => this.props.logoutUser()}>
                        Log Out
                      </Link>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Sidebar>
          <Sidebar.Pusher dimmed={this.state.visible}>
            <div>
              <Header
                currentUser={auth.currentUser}
                // tokenData={auth.preferences.tokenData}
                changeSidebar={this.changeSidebar}
                visible={this.state.visible}
                showUserMenu={showUserMenu}
              />
              <div>
                <Switch>
                  <Route path="/user/dashboard" location={location}>
                    <AsyncComponent>
                      <Dashboard {...this.props} />
                    </AsyncComponent>
                  </Route>

                  <ProtectedRoute
                    exact
                    path="/allocation/:token"
                    location={location}
                  >
                    <AsyncComponent>
                      <Allocations {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path="/user/newsletters"
                    location={location}
                  >
                    <AsyncComponent>
                      <Newsletter {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/deals"
                    location={location}
                    // component={DealsPage}
                  >
                    <AsyncComponent>
                      <DealsPage {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/deals/create"
                    location={location}
                  >
                    <AsyncComponent>
                      <CreateDealsPage {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/deals/edit/:id"
                    location={location}
                  >
                    <AsyncComponent>
                      <CreateDealsPage {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/add-entity"
                    location={location}
                  >
                    <AsyncComponent>
                      <AddEntityContainer {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    path="/user/bid/success/:uuid/:type"
                    location={location}
                    component={BidSuccessMessage}
                  ></ProtectedRoute>

                  <ProtectedRoute
                    path="/user/deals/closed"
                    location={location}
                    component={ClosedDeals}
                  ></ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/edit-entity/:id"
                    location={location}
                  >
                    <AsyncComponent>
                      <EditEntityContainer {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/edit-bid/:uuid/:bidid"
                    location={location}
                  >
                    <AsyncComponent>
                      <EditBidContainer {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedApproved
                    exact
                    path="/user/deal-view/:uuid"
                    location={location}
                  >
                    <AsyncComponent>
                      <DealView {...this.props} />
                    </AsyncComponent>
                  </ProtectedApproved>

                  <ProtectedApproved
                    exact
                    path="/user/deal-disclaimer/:uuid"
                    location={location}
                  >
                    <AsyncComponent>
                      <DealDisclaimer {...this.props} />
                    </AsyncComponent>
                  </ProtectedApproved>

                  <ProtectedRoute
                    exact
                    path="/user/ipo-view/:uuid"
                    location={location}
                  >
                    <AsyncComponent>
                      <IpoView {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/ipo-disclaimer/:uuid"
                    location={location}
                  >
                    <AsyncComponent>
                      <IpoDisclaimer {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/edit-ipo/:uuid/:bidid"
                    location={location}
                  >
                    <AsyncComponent>
                      <EditIpoContainer {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path="/allocations/thanks/:type"
                    location={location}
                    component={ThankUMessage}
                  ></ProtectedRoute>
                  <Route exact path="/user/file-view" location={location}>
                    <AsyncComponent>
                      <FileView {...this.props} />
                    </AsyncComponent>
                  </Route>
                  <ProtectedRoute
                    exact
                    path="/user/webinars"
                    location={location}
                  >
                    <AsyncComponent>
                      <Webinars {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/off-the-charts"
                    location={location}
                  >
                    <AsyncComponent>
                      <OfftheCharts {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <Route exact path="/user/cornerstone" location={location}>
                    <AsyncComponent>
                      <CornerStoneComponent {...this.props} />
                    </AsyncComponent>
                  </Route>

                  <Route
                    exact
                    path="/user/pro-investor-series"
                    location={location}
                  >
                    <AsyncComponent>
                      <ProInvestorSeries {...this.props} />
                    </AsyncComponent>
                  </Route>

                  <ProtectedRoute
                    exact
                    path="/user/interview-view/:uuid"
                    location={location}
                  >
                    <AsyncComponent>
                      <InterviewView {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/live-stream-view/:uuid"
                    location={location}
                  >
                    <AsyncComponent>
                      <LiveStreamView {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/profile"
                    location={location}
                  >
                    <AsyncComponent>
                      <Profile {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/director-shares"
                    location={location}
                  >
                    <AsyncComponent>
                      <DirectorShares {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/live-now"
                    location={location}
                  >
                    <AsyncComponent>
                      <LiveStreams {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/live-streams"
                    location={location}
                  >
                    <AsyncComponent>
                      <LiveStreamsList {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  {/* <ProtectedRoute
                    exact
                    path="/user/stock-tips"
                    location={location}
                  >
                    <AsyncComponent>
                      <StockTips {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute> */}

                  <ProtectedRoute
                    exact
                    path="/user/transactions"
                    location={location}
                  >
                    <AsyncComponent>
                      <Transactions {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/options"
                    location={location}
                  >
                    <AsyncComponent>
                      <Options {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/si-review"
                    location={location}
                  >
                    <AsyncComponent>
                      <SiReview {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/review"
                    location={location}
                  >
                    <AsyncComponent>
                      <Review {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/sophisticated"
                    location={location}
                  >
                    <AsyncComponent>
                      <Sophisticated {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>
                  
                  <ProtectedRoute
                    exact
                    path="/user/si-upload"
                    location={location}
                  >
                    <AsyncComponent>
                      <SophisticatedUpload {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/interviews"
                    location={location}
                  >
                    <AsyncComponent>
                      <Interviews {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/create-ipo"
                    location={location}
                  >
                    <AsyncComponent>
                      <CreateIpo {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/update-ipo/:uuid"
                    location={location}
                  >
                    <AsyncComponent>
                      <CreateIpo {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/user/preferences"
                    location={location}
                  >
                    <AsyncComponent>
                      <Preferences {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path="/leaderboard"
                    location={location}
                  >
                    <AsyncComponent>
                      <Leaderboards {...this.props} />
                    </AsyncComponent>
                  </ProtectedRoute>

                  <Route>
                    <AsyncComponent>
                      <ErrorPage {...this.props} />
                    </AsyncComponent>
                  </Route>
                </Switch>
              </div>
              <Footer
                currentUser={auth.currentUser}
                roles={auth.roles}
                onLogout={logoutUser}
              />
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser),
  showUserMenu: () => dispatch(showUserMenu),
  getCurrentUser: () => getCurrentUser(dispatch),
  hideUserMenu: () => dispatch(hideUserMenu),
  uploadAvatar: (payload) => uploadAvatar(payload, dispatch),
});

const mapStateToProps = (State, ownProps) => {
  return {
    auth: State.auth,
    currentUser: State.auth.currentUser,
    userMenu: State.app.userMenu,
    loading: State.loading.buttonLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));

import React, { Component, Fragment } from "react";
import About from "../../../assets/images/about2.jpg";
import FacebookIcon from "../../../assets/images/ico-facebook.png";
import TwitterIcon from "../../../assets/images/ico-twitter.png";
import FacebookIcon1 from "../../../assets/images/ico-facebook-01.png";
import LinkedInIcon from "../../../assets/images/linkedin-logo.png";
import TwitterIcon1 from "../../../assets/images/ico-twitter-01.png";
import GregLowe from "../../../assets/images/Greg_Lowe.jpg";
import SimonSzwarc from "../../../assets/images/BPS_0452.jpg";
import JordanKadish from "../../../assets/images/J.jpg";
import ShaunFactor from "../../../assets/images/Shaun_Factor.jpg";
import NewsletterContainer from "../../forms/Newsletter/NewsletterContainer";

import DirectImg from "../../../assets/images/CompanyDirectDeals.png";
import BrokerImg from "../../../assets/images/BrokerDeals.png";
import EaseImg from "../../../assets/images/EaseofOneAccount.png";
import FairerImg from "../../../assets/images/FairerAllocations.png";
import AlertsImg from "../../../assets/images/AlertstoCapitalRaises.png";
import IndepthImg from "../../../assets/images/IndepthAnalysis.png";
import ChooseImg from "../../../assets/images/ChooseYourOwnDealers.png";
import CompanyImg from "../../../assets/images/CompanyDirecctDeals.png";
import IconImg from "../../../assets/images/icon.png";
import ContactFooterImg from "../../../assets/images/footer_icon.png";
import NewsletterImg from "../../../assets/images/Group7903.png";
import Jake from "../../../assets/images/BPS_0433.jpg";
import Ariella from "../../../assets/images/Ariella Grunfeld.jpg";


import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
export default class WhoWeAre extends Component {
  render() {
    return (
      <>
        <main>
          <div className="about_us_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 p-5">
                  <h3>
                    180 Markets is turning the ASX around to make it fairer and
                    easier for you to access capital raises.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row padd-top">
              <div className="col-lg-6 px-5 mt-5">
                <div className="row">
                  <div className="col-lg-3 col-6 mt-3 text-center">

                      <img src={DirectImg} alt="ASX placement" />
                      <p className="sml_txt">Easy to Use</p>

                  </div>
                  <div className="col-lg-3 col-6 mt-3 text-center">

                      <img src={BrokerImg} alt="ASX mining" />
                      <p className="sml_txt">Broker Deals</p>

                  </div>
                  <div className="col-lg-3 col-6 mt-3 text-center">

                      <img src={EaseImg} alt="Entitlement offer" />
                      <p className="sml_txt">Ease of One Account</p>

                  </div>
                  <div className="col-lg-3 col-6 mt-3 text-center">

                      <img src={FairerImg} alt="Market placement" />
                      <p className="sml_txt">Fairer Allocations</p>

                  </div>
                </div>
                <div className="row mt-lg-5">
                  <div className="col-lg-3 col-6 mt-3 text-center">

                      <img src={AlertsImg} alt="Rights offer" />
                      <p className="sml_txt">Alerts to Capital Raises</p>

                  </div>
                  <div className="col-lg-3 col-6 mt-3 text-center">

                      <img src={IndepthImg} alt="Share purchase plan" />
                      <p className="sml_txt">In Depth Analysis</p>

                  </div>
                  <div className="col-lg-3 col-6 mt-3 text-center">

                      <img src={ChooseImg} alt="SPP" />
                      <p className="sml_txt">Choose Your Own Deals</p>

                  </div>
                  <div className="col-lg-3 col-6 mt-3 text-center">

                      <img src={CompanyImg} alt="S708" />
                      <p className="sml_txt">Company Direct Deals</p>

                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-5">
                <h3 className="about_heading">Our Goal</h3>
                <p className="about_p">
                  Our goal is to give investors the same access to capital
                  raises as the world’s biggest fund managers.<br/>
                  But it gets even better. You have true ownership of
                  your shares, you control your funds, and you have total access
                  to a level playing field.
                </p>
              </div>
            </div>
          </div>
          <div className="body_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mt-4">
                  <img src={GregLowe} className="img_about" alt="Greg Lowe" />
                  <div className="name_about">
                    Greg Lowe{" "}
                    <a
                      href="https://www.linkedin.com/in/greg-lowe-b301b683/"
                      target="_blank"
                    >
                      <span className="linkedin__iconOuter">
                        <Icon name="linkedin" className="linkedin__icon"></Icon>
                      </span>
                    </a>
                  </div>
                  <a
                    href="https://www.linkedin.com/in/greg-lowe-b301b683/"
                    target="_blank"
                    className="a_design"
                  >
                    CEO and Co-Founder
                  </a>

                  <p className="about_p">
                    Greg has spent over 20 years as a professional hedge fund
                    investor most notably as a partner at $3bn fund P Schoenfeld
                    Asset Management both in the U.S. and Australia.
                    <br /> <br /> Greg has actively invested across wide range
                    of products including listed equities, unlisted equities,
                    bonds, banks debt, distressed debt, foreclosure loan notes,
                    physical real estate, and various property deal structures.{" "}
                    <br /> <br /> In particular, Greg created a Special Purpose
                    Vehicle to purchase Australian distressed bank debt in 2009
                    and created a single family home portfolio in Los Angeles.
                  </p>
                </div>
                <div className="col-lg-6 mt-4">
                  <img src={ShaunFactor} className="img_about" alt="Shaun Factor"  />
                  <div className="name_about">
                    Shaun Factor{" "}
                    <a
                      href="https://www.linkedin.com/in/shaun-factor-b737084b/"
                      target="_blank"
                    >
                      <span className="linkedin__iconOuter">
                        <Icon name="linkedin" className="linkedin__icon"></Icon>
                      </span>
                    </a>
                  </div>
                  <a
                    href="https://www.linkedin.com/in/shaun-factor-b737084b/"
                    target="_blank"
                    className="a_design"
                  >
                    Co-Founder
                  </a>
                  <p className="about_p">
                  20 years experience as a full-time Share Investor, Specialising in Micro & Small Cap ASX listed Companies.<br /> <br />
Spends countless hours studying term sheets to find the best value deals with a proven track record in identifying undervalued investment opportunities.<br /> <br />
Trusted figure within the ASX small-cap community; investors frequently following him into deals.<br /> <br />
Extensive broker network offering countless IPOs, placements, and RTOs.<br /> <br />
Skilled at understanding complicated structures and seeking out creative ways to fund companies.
            
                  </p>
                </div>
              </div>



              <div className="row mt-5">

              <div className="col-lg-6 mt-4">
                  <img src={SimonSzwarc} className="img_about" />
                  <div className="name_about">
                    Simon Szwarc{" "}
                    <a
                      href="https://www.linkedin.com/in/simon-szwarc-103a27b/"
                      target="_blank"
                    >
                      <span className="linkedin__iconOuter">
                        <Icon name="linkedin" className="linkedin__icon"></Icon>
                      </span>
                    </a>
                  </div>
                  <a
                    href="https://www.linkedin.com/in/simon-szwarc-103a27b/"
                    target="_blank"
                    className="a_design"
                  >
                    Advisor
                  </a>

                  <p className="about_p">
                    Simon is the CEO & Founder of Guava Insights, an end-to-end
                    innovation and technology business delivering
                    industry-changing solutions to Australia's largest
                    organizations. <br /> <br />
                    Simon is known as one of Australia's leading evangelists on
                    innovative change, having delivered keynotes, workshops and
                    collaboration programs to leadership teams across a range of
                    industries. He has over 20 years’ experience working in the
                    identification, adoption and integration of new and
                    leading-edge technologies for Australia's largest
                    organizations.
                  </p>
                </div>


                <div className="col-lg-6 mt-4">
                  <img src={Ariella} className="img_about" alt="Ariella Grunfeld" />
                  <div className="name_about">
                    Ariella Grunfeld{" "}
                    <a
                        href="https://www.linkedin.com/in/ariella-grunfeld-b157301a2/"
                        target="_blank"
                    >
                      <span className="linkedin__iconOuter">
                        <Icon name="linkedin" className="linkedin__icon"></Icon>
                      </span>
                    </a>
                  </div>
                  <a
                      href="https://www.linkedin.com/in/ariella-grunfeld-b157301a2/"
                      target="_blank"
                      className="a_design"
                  >
                    Analyst
                  </a>

                  <p className="about_p">
                  Ariella, Head of Corporate Relations, leverages her extensive experience and passion for finance and banking to ensure investor prosperity. <br/><br/>
                  She strategically manages relationships with Australian Brokers/Advisors, facilitating seamless deal-sharing and exclusive opportunities in ASX Listed Capital Raises. Ariella's role involves building and maintaining partnerships, keeping 180 Markets at the forefront of ASX Capital Raises. <br/><br/>
                  Her expertise helps tailor investment books to suit different companies' capital structures and reach mutually beneficial outcomes. Ariella brings a special energy to our team while exploring her passion for the ASX. Outside of work, she enjoys staying active and traveling.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </main>
        <div className="side_img"></div>
        <div class="top_bg_body">
          <img class="img_fttr" src={NewsletterImg} />
        </div>
        {/* <div className=" main_bg_about">
          <h3 className="about_heading text-center">Sign up for updates</h3>
          <p className="text-center about__singUpPara p-2">
            We’ll keep you up to date with our latest news, announcements, and
            development plans. No-spam policy.
          </p>
          <div className="container">
            <div className="row">
              <div className="col-lg-7 offset-lg-2 col-8 offset-1">
                <div className="form-group mt-4">
                  <input
                    type="email"
                    className="form-control about__email"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter your email address"
                  />
                </div>
              </div>
              <div className="col-lg-2 col-3">
                <img src={IconImg} className="img-fluid btn_image" />
              </div>
            </div>
          </div>
        </div> */}
        <div className="top_footer_about">
          <div className="container">
            <div className="row about__footer">
              <div className="col-lg-8 mt_7em">
                <h3 className="top_footer_heading_about">Join Our Community</h3>
                <p className="top_footer_p_about">
                  Join the discussion of the latest news in the world finance
                  industry. Meet like-minded enthusiasts here.
                </p>
                <Link to="/register">
                  <button
                    type="button"
                    className="btn btn-primary about_ftr_btn rounded-0"
                  >
                    JOIN NOW
                  </button>
                </Link>
              </div>
              <div className="col-lg-4">
                <img src={ContactFooterImg} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

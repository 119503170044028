import TransactionService from "../../services/transactionService";
import { toast } from "../../components/common/Toast";
import {
  startButtonLoading,
  stopButtonLoading,
  startLoader,
  stopLoader,
} from "./loadingActions";
import { reset } from "redux-form";

const actions = {
  LOADING: "LOADING",

  FETCH_ALL_TRANSACTIONS: "FETCH_ALL_TRANSACTIONS",
  FETCH_ALL_TRANSACTIONS_ERROR: "FETCH_ALL_TRANSACTIONS_ERROR",
  FETCH_ALL_TRANSACTIONS_SUCCESS: "FETCH_ALL_TRANSACTIONS_SUCCESS",

  FETCH_SINGLE_TRANSACTION: "FETCH_SINGLE_TRANSACTION",
  FETCH_SINGLE_TRANSACTION_ERROR: "FETCH_SINGLE_TRANSACTION",
  FETCH_SINGLE_TRANSACTION_SUCCESS: "FETCH_SINGLE_TRANSACTION_SUCCESS",

  CREATE_TRANSACTION: "CREATE_TRANSACTION",
  CREATE_TRANSACTION_ERROR: "CREATE_TRANSACTION_ERROR",
  CREATE_TRANSACTION_SUCCESS: "CREATE_TRANSACTION_SUCCESS",

  DELETE_TRANSACTION: "DELETE_TRANSACTION",
  DELETE_TRANSACTION_ERROR: "DELETE_TRANSACTION_ERROR",
  DELETE_TRANSACTION_SUCCESS: "DELETE_TRANSACTION_SUCCESS",

  fetchTransactions: ( dispatch ) => {
    TransactionService.getAll()
      .then(({ data }) => {
        dispatch({
          type: actions.FETCH_ALL_TRANSACTIONS_SUCCESS,
          payload: data,
        });
      })
      .catch(() => {});
  },

  fetchSingleTransaction: (dispatch, payload) => {
    dispatch({
      type: actions.FETCH_SINGLE_TRANSACTION_SUCCESS,
      payload,
    });
  },

  createTransaction: ({ payload, dispatch, history }) => {
    dispatch(startButtonLoading);
    TransactionService.createTransaction(payload)
      .then(async () => {
        dispatch(stopButtonLoading);
        dispatch(reset('TransactionForm')); 
        await toast.success("Transaction Created Successfully.");
        history.push("/user/transactions");

        actions.fetchTransactions(dispatch);

      })
      .catch((error) => {
        console.log(error)
        dispatch(stopButtonLoading);

        let errorMessage = "";
        if (error.error) {
          errorMessage = error.error;
        }
        if (errorMessage) {
          toast.error("errorMessage");
        } else {
          toast.error("Something went wrong. " + errorMessage + "");
        }
      });
  },

  updateTransaction: ({payload, history, dispatch}) => {
    dispatch(startButtonLoading);
    TransactionService.updateTransaction(payload)
      .then(async () => {
        dispatch(stopButtonLoading);
        toast.success("Transaction Updated Successfully.");
        actions.fetchTransactions(dispatch);
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) toast.error("Something went wrong.");
      });
  },

  getSingleTransaction: (payload, dispatch) =>
    TransactionService.singleTransaction(payload)
      .then((res) => {
        dispatch(actions.fetchSingleStatus(dispatch, res));
      })
      .catch(() => {}),

  deleteTransaction: ({ id, dispatch }) =>
    TransactionService.deleteTransaction(id)
      .then(() => {
        toast.success("Transaction Deleted Successfully.");
        actions.fetchTransactions(dispatch);
      })
      .catch(() => {}),
};
export default actions;

import React, { useEffect } from "react";
import { Modal } from "antd";
import { useCookies } from "react-cookie";
import moment from "moment";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import SubscribeForm from "./forms/SubscribeForm";
import auth from "../services/authService";
import { toast } from "../components/common/Toast";

const globalCookies = new Cookies();

const Subscribe = (props) => {
  const { currentUser, location } = props;
  const [cookies, setCookie] = useCookies(["subscription"]);
  const [cancelled, setCancelled] = useState(true);
  const submitSubscriber = async (values) => {
    const res = await auth.submitSubscribeForm(values);
    if (res.data.success) {
      setCookie("subscription", "subscribed", {
        path: "/",
        expires: moment().add(10, "years").toDate(),
      });
      setCancelled(true);
      toast.success('Subscribed Successfully');
    } else {
      toast.error('Server error');
    }
  };
  useEffect(() => {
    let gCookies = globalCookies.getAll();
    if (Object.keys(currentUser).length === 0) {
      if (gCookies?.subscription === "subscribed") {
        setCancelled(true);
      } else {
        setTimeout(() => {
          setCancelled(false);
        }, 10000);
      }
    } else {
      setTimeout(() => {
        setCancelled(false);
      }, 10000);
    }
  }, [cookies, currentUser, location]);

  const handleCancel = () => {
    setCookie("subscription", "subscribed", {
      path: "/",
      expires: moment().add(1, "months").toDate(),
    });
    setCancelled(true);
  };
  return (
    <Modal
      visible={!cancelled}
      onCancel={() => handleCancel()}
      footer={null}
      transitionName=""
    >
      <h3>Subscribe</h3>
      <p>Subscribe for free events, video interviews & exclusive offerings</p>
      <SubscribeForm onSubmit={submitSubscriber} />
    </Modal>
  );
};

export default withRouter(Subscribe);

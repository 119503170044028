import React, { Component } from "react";
import DataTable from "react-data-table-component";
import Confirm from "../../../../common/Confirm";
import ViewBids from "../../../../common/ViewBids";
import { Link } from "react-router-dom";
import dealActions from "../../../../../redux/actions/dealActions";
import BidActions from "../../../../../redux/actions/bids.Actions";
import { connect } from "react-redux";
import { BaseUrl } from "../../../../../services/settings";
import { Icon } from "semantic-ui-react";
import {
  renderData,
  renderRefreshText,
  customStyles,
  dueDateFormat,
} from "../../../../common/CommonMethods";

class CurrentDeal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      open_view: false,
      data: [],
      getBids: "",
    };
  }

  componentDidMount() {
    this.props.getAllBids();
  }

  openBid = (id) => {
    this.setState({ open_view: true, view_dealId: id });
  };

  open = (id) => {
    this.setState({ open: true, dealId: id });
  };

  close = () => {
    this.setState({ open: false, open_view: false });
  };

  deleteDeal = (id) => {
    let { changeDealStatusToClosed } = this.props;
    changeDealStatusToClosed(id);
    this.setState({ open: false });
  };

  getBidsCount = (dealId) => {
    let res = 0;
    if (this.props.bids) {
      for (let item of this.props.bids) {
        if (item.deal_id === dealId) res++;
      }
    }
    return res;
  };

  render() {
    const handleChange = () => {};
    const columns = [
      {
        name: "",
        selector: "deal_logo",
        sortable: false,
        // style: { minWidth: "120px" },
        cell: (row) => (
          <>
            <div className="company-logo">
              {row.deal_logo && (
                <img
                  style={{
                    maxWidth: "70px",
                    maxHeight: "70px",
                    float: "left",
                  }}
                  src={`${BaseUrl}/storage/${row.deal_logo.id}/${row.deal_logo.file_name}`}
                  alt=""
                />
              )}
            </div>
          </>
        ),
      },
      {
        name: "Company",
        selector: "company_name",
        sortable: false,
        className: "company no-wrap",
        style: { textAlign: "left" },
        style: { minWidth: "130px" },
        left: true,
        cell: (row) => (
          <>
            <div>
              <span>{row.company_name && row.company_name}</span>
            </div>
          </>
        ),
      },

      {
        name: "ASX Code",
        selector: "last_price",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.asx_code && row.asx_code}</div>,
      },
      {
        name: "Last Price",
        selector: "last_price",
        sortable: false,
        left: true,
        cell: (row) => (
          <div>{row.last_price ? renderData(row.last_price) : ""}</div>
        ),
      },
      {
        name: "Capital Raise Price",
        selector: "capital_raise",
        sortable: false,
        left: true,
        cell: (row) => (
          <div>{row.capital_raise ? renderData(row.capital_raise) : ""}</div>
        ),
      },
      {
        name: "Raise Amount",
        selector: "raise_amount",
        left: true,
        cell: (row) => (
          <div>{row.raise_amount ? renderData(row.raise_amount) : ""}</div>
        ),
      },
      {
        name: "Discount",
        selector: "discount",
        sortable: false,
        left: true,
        cell: (row) => (
          <div>
            {!isNaN(parseInt(row.discount)) && `${parseFloat(row.discount)}%`}
          </div>
        ),
      },
      {
        name: "Market Capitalisation",
        selector: "market_capital",
        sortable: false,
        left: true,
        cell: (row) => (
          <div>{row.market_capital && `${renderData(row.market_capital)}`}</div>
        ),
      },
      {
        name: "Bids Due",
        selector: "bids_due",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.bids_due && dueDateFormat(row.bids_due)}</div>,
      },
      {
        name: "View Bids",
        sortable: false,
        center: true,
        overflowY: true,
        // style: { minWidth: "100px" },

        cell: (row) => (
          <div>
            {/*<Icon*/}
            {/*style={{cursor: "pointer"}}*/}
            {/*circular*/}
            {/*inverted*/}
            {/*title="view"*/}
            {/*color="blue"*/}
            {/*name="eye"*/}
            {/*onClick={() => this.openBid(row.uuid)}*/}
            {/*/>*/}
            <span
              style={{
                display: "inline-block",
                width: "33px",
                height: "33px",
                textAlign: "center",
                backgroundColor: "#2185d0",
                color: "#fff",
                borderRadius: "18px",
                padding: "5px 5px",
                cursor: "pointer",
              }}
              onClick={() => this.openBid(row.id)}
            >
              {this.getBidsCount(row.id)}
            </span>
          </div>
        ),
      },
      {
        name: "Actions",
        sortable: false,
        center: true,
        overflowY: true,

        cell: (row) => (
          <div className="btn-group">
            <Link to={`/user/deals/edit/${row.uuid}`}>
              <Icon
                style={{ cursor: "pointer" }}
                circular
                inverted
                title="Edit"
                color="blue"
                name="edit"
              />
            </Link>
            <Icon
              style={{ cursor: "pointer" }}
              title="Close Deal"
              circular
              inverted
              color="blue"
              name="remove circle"
              onClick={() => this.open(row.id)}
            />
          </div>
        ),
      },
    ];

    return (
      <div className="md-table-normal">
        <DataTable
          noHeader
          overflowY
          highlightOnHover
          responsive={true}
          columns={columns}
          data={this.props.deals}
          style={{ width: "100%" }}
          // sortIcon={<span class="ico"></span>}
          onSelectedRowsChange={handleChange}
          customStyles={customStyles}
        />
        {this.state.open_view ? (
          <ViewBids
            show={this.state.open_view}
            handleClose={this.close}
            bids={this.props.bids}
            deal_id={this.state.view_dealId}
          />
        ) : null}
        {this.state.open ? (
          <Confirm
            message="Are you sure you want to close this deal?"
            closeButtonText="Cancel"
            deleteButtonText="Yes, Close"
            show={this.state.open}
            handleDelete={() => this.deleteDeal(this.state.dealId)}
            handleClose={this.close}
          />
        ) : null}
      </div>
    );
  }
}

// deleteDeal;
const { changeDealStatus } = dealActions;
const { getAllBids } = BidActions;

const mapDispatchToProps = (dispatch) => ({
  changeDealStatusToClosed: (id) => changeDealStatus({ dispatch, id }),
  getAllBids: (payload) => getAllBids({ payload, dispatch }),
});
const mapStateToProps = (state) => {
  return {
    bids: state.bids.bids,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentDeal);

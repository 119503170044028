import axios from "axios";
import { BaseUrl, configMultipart } from "./settings";

class DirectorService {
  createIndication = async (payload) => {
    let bodyFormData = new FormData();
    for (let key in payload) {
      bodyFormData.append(key, payload[key]);
    }

    return await axios
      .post(
        BaseUrl + `frontend/v1/director-share-indications`,
        bodyFormData,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  updateShareIndication = async (payload) => {
    let bodyFormData = new FormData();
    for (let key in payload) {
      bodyFormData.append(key, payload[key]);
    }
    bodyFormData.append("_method", "PUT");

    return await axios
      .post(
        BaseUrl + `frontend/v1/director-share-indications/${payload.id}`,
        bodyFormData,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  singleIndication = (payload) => {
    return axios
      .get(
        BaseUrl + `frontend/v1/director-shares/${payload.id}`,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  alldirectorShares = (payload) => {
    let url = BaseUrl + `frontend/v1/director-shares?`;
    if (payload.date){
      url += `date=${payload.date}`
    }
    if (payload.category){
      url += `&category=${payload.category}`
    }
    return axios
      .get(url, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  fetchAllIndications = () => {
    return axios
      .get(
        BaseUrl + `frontend/v1/director-share-indications`,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}
export default new DirectorService();

import { combineReducers } from "redux";
import registerReducer from "./register";
import authReducer from "./authReducer";
import appReducer from "./appReducer";
import entityReducer from "./entityReducer";
import bidsReducer from "./bidsReducer";
import dealReducer from "./dealReducer";
import assetReducer from "./assetReducer";
import marketReducer from "./marketReducer";
import ipoReducer from "./ipoReducer";
import { reducer as formReducer } from "redux-form";
import loadingReducer from "./Loading";
import shares from "./directorReducer";
import newsletterReducer from "./newsletterReducer";
import interviewReducer from "./interviewReducer";
import webinarReducer from "./webinarReducer";
import faqReducer from "./faqReducer";
import stockTipsReducer from "./stockTipsReducer";
import transactionReducer from "./transactionReducer";
import countryReducer from "./countryReducer";
import ArticleReducer from "./articleReducer";
import LiveReducer from "./liveReducer";

export default combineReducers({
  form: formReducer,
  register: registerReducer,
  auth: authReducer,
  app: appReducer,
  entities: entityReducer,
  deals: dealReducer,
  loading: loadingReducer,
  assets: assetReducer,
  markets: marketReducer,
  bids: bidsReducer,
  ipos: ipoReducer,
  shares: shares,
  newsletters: newsletterReducer,
  interviews: interviewReducer,
  webinars: webinarReducer,
  faqs: faqReducer,
  stocktips: stockTipsReducer,
  transactions: transactionReducer,
  countries: countryReducer,
  articles: ArticleReducer,
  liveStreams: LiveReducer,
});

import axios from "axios";
import { BaseUrl, config, configMultipart } from "./settings";

const account = {
  deleteMedia: payload => {
    return axios
      .post(`${BaseUrl}frontend/v1/delete/media`, payload, config())
      .then(res => {
        return res;
      })
      .catch(error => {
        throw error;
      });
  },
  getCountries: payload => {
    return axios
      .get(`${BaseUrl}frontend/v1/countries`)
      .then(res => {
        return res;
      })
      .catch(error => {
        throw error;
      });
  }
};

export default account;

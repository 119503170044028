import ArticleService from "../../services/articleService";

const actions = {
  FETCH_ALL_ARTICLES: "FETCH_ALL_ARTICLES",
  FETCH_ALL_ARTICLES_ERROR: "FETCH_ALL_ARTICLES_ERROR",
  FETCH_ALL_ARTICLES_SUCCESS: "FETCH_ALL_ARTICLES_SUCCESS",
  FETCH_SINGLE_ARTICLE_SUCCESS: "FETCH_SINGLE_ARTICLE_SUCCESS",

  fetchArticles: (dispatch, {url , queryString} = {}) =>
    ArticleService
      .getAll({url, queryString})
      .then((payload) => {
        payload.url = url;
        dispatch({ type: actions.FETCH_ALL_ARTICLES_SUCCESS, payload });
      })
      .catch(() => {}),
  fetchSingleArticle: (dispatch, payload) =>
    ArticleService
      .getSingle(payload)
      .then(({ data }) => {
        dispatch({ type: actions.FETCH_SINGLE_ARTICLE_SUCCESS, payload: data });
      })
      .catch(() => {})
};
export default actions;
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { withRouter } from "react-router-dom";
import {  Icon, Table } from "semantic-ui-react";
import { numberFormatter } from "../../../../../utils/constants";
import { BaseUrl } from "../../../../../services/settings";
import { customStyles, renderEntity } from "../../../../common/CommonMethods";
import { Button } from "antd";
import auth from "../../../../../services/authService";
import { toast } from "../../../../../components/common/Toast";

class Confirmation extends Component {
  onView = (token) => {
    const { history } = this.props;

    history.push(`/allocation/${token}`);
  };

  onViewDeal = (row) => {
    const { history } = this.props;
    if (row.deal) {
      history.push(`/user/deal-view/${row.deal.uuid}`);
    }
    if (row.ipo) {
      history.push(`/user/ipo-view/${row.deal.uuid}`);
    }
  };

  handleChange = (row) => {
    this.props.history.push(`/allocation/${row.unique_code}`);
  };

  exportAllocations = async () => {
    let res = await auth.exportInvestorAllocations();
    if (res.data && res.data.data){
      window.open(res.data.data.url, '_blank').focus();
    } else {
      toast.error("Something went wrong.");
    }
  };

  render() {
    const columns = [
      {
        name: "",
        selector: "deal_logo",
        sortable: false,
        // style: { minWidth: "120px" },
        cell: (row) => (
          <>
            <div
              className="company-logo"
              onClick={() => this.handleChange(row)}
            >
              {row.allocation.deal.deal_logo && (
                <img
                  style={{
                    maxWidth: "70px",
                    maxHeight: "70px",
                    float: "left",
                  }}
                  src={`${BaseUrl}storage/${row.allocation.deal.deal_logo.id}/${row.allocation.deal.deal_logo.file_name}`}
                  alt=""
                />
              )}
            </div>
          </>
        ),
      },
      {
        name: "Company",
        selector: "company_name",
        sortable: false,
        className: "company no-wrap",
        style: { minWidth: "130px" },
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)}>
              {row.allocation.deal.asx_code &&
                `${row.allocation.deal.asx_code}`}{" "}
              <br />
              <span>{row.allocation.deal.company_name}</span>
            </div>
          </>
        ),
      },
      // {
      //   name: "ASX code",
      //   selector: "asx_code",
      //   sortable: false,
      //   left: true,
      //   cell: (row) => (
      //     <div onClick={() => this.handleChange(row)}>
      //       {row.allocation.deal.asx_code && `${row.allocation.deal.asx_code}`}
      //     </div>
      //   ),
      // },
      // {
      //   name: "Bid Amount",
      //   selector: "allocated",
      //   sortable: false,
      //   left: true,
      //   cell: (row) => (
      //     <div onClick={() => this.handleChange(row)}>
      //       {row.bid ? numberFormatter(row.bid.share_count) : numberFormatter(row.ipo_bid.share_count)}{" "}
      //     </div>
      //   ),
      // },
      {
        name: "Allocated shares",
        selector: "allocated",
        sortable: false,
        left: true,
        width: "230px",
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.allocation_amount && numberFormatter(row.allocation_amount)}{" "}
          </div>
        ),
      },
      {
        name: "Price",
        selector: "price",
        sortable: false,
        left: true,
        width: "230px",
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.allocation.price &&
              `$${row.allocation.price}` }
          </div>
        ),
      },
      {
        name: "Investment Amount ",
        selector: "total_price ",
        sortable: false,
        left: true,
        width: "180px",
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.allocation.price &&
              row.allocation_amount &&
              numberFormatter(
                row.allocation.price * row.allocation_amount,
                "$",
                2
              )}
          </div>
        ),
      },
      // {
      //   name: "Entity ",
      //   selector: "entity ",
      //   sortable: false,
      //   left: true,
      //   cell: (row) => (
      //     <div onClick={() => this.handleChange(row)}>
      //       {row.bid && row.bid.entity && renderEntity(row.bid.entity)}
      //     </div>
      //   ),
      // },
      {
        name: "Term Sheet",
        selector: "status",
        sortable: false,
        center: true,
        cell: (row) => (
          <>
            {row.allocation.allocation_for == "deal" &&
              row.allocation.deal &&
              row.allocation.deal.term_sheets_url && (
                <a target="_blank" href={row.allocation.deal.term_sheets_url}>
                  <Icon style={{ cursor: "pointer" }} color="blue" name="eye" />
                </a>
              )}
            {row.allocation.allocation_for == "ipo" &&
              row.allocation.ipo &&
              row.allocation.ipo.term_sheets_url && (
                <a target="_blank" href={row.allocation.ipo.term_sheets_url}>
                  <Icon style={{ cursor: "pointer" }} color="blue" name="eye" />
                </a>
              )}
          </>
        ),
      },
      {
        name: "Option",
        selector: "free_options",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row?.allocation?.deal && row.allocation.deal.free_option && row.allocation.deal.dividend_option ? `${row.allocation.deal.dividend_option}:${row.allocation.deal.free_option} ${row.allocation.deal?.option_details ? row.allocation.deal?.option_details : ''}` : ``}
            {row?.allocation?.ipo && row.allocation.ipo.free_option && row.allocation.ipo.dividend_option ? `${row.allocation.ipo.dividend_option}:${row.allocation.ipo.free_options} ${row.allocation.ipo?.option_details ? row.allocation.deal?.option_details : ''}` : ``}
          </div>
        ),
      },
      // {
      //   name: "Status",
      //   selector: "status",
      //   sortable: false,
      //   left: true,
      //   cell: (row) => (
      //     <div onClick={() => this.handleChange(row)}>
      //       {row.status && row.status}
      //     </div>
      //   ),
      // },
      {
        name: "% Change",
        selector: "percentage_change",
        sortable: false,
        left: true,
        cell: (row) => {
          let increase_rate;
          let current_price = row.allocation.allocation_for === 'deal' ? row.allocation.deal.current_price : row.allocation.ipo.current_price;
          let deal_price = row.allocation.allocation_for === 'deal' ? row.allocation.deal.capital_raise : row.allocation.ipo.ipo_price;
         
          if (current_price && deal_price){
            let increase = current_price - deal_price;
            increase_rate = increase / deal_price * 100;
          }
          return <div onClick={() => this.handleChange(row)}>{ increase_rate && `${increase_rate.toFixed(2)}%`}</div>;
        },
      },
      // {
      //   name: "View Deal",
      //   selector: "bid_now",
      //   sortable: false,
      //   left: true,
      //   cell: (row) => (
      //     <div
      //       className="tbl-title"
      //       onClick={() => this.onViewDeal(row.allocation)}
      //     >
      //       <span className="bid-now">
      //         {" "}
      //         <Icon
      //           style={{ cursor: "pointer" }}
      //           color="blue"
      //           size="huge"
      //           name="arrow alternate circle right outline"
      //         />
      //       </span>
      //     </div>
      //   ),
      // },
      {
        name: "View",
        selector: "bid_now",
        sortable: false,
        left: true,
        cell: (row) => (
          <div
            className="tbl-title"
            onClick={() => this.onView(row.unique_code)}
          >
            <span className="bid-now">
              <Icon
                style={{ cursor: "pointer" }}
                color="blue"
                size="huge"
                name="file right outline"
              />
            </span>
          </div>
        ),
      },
    ];

    return (
      <div className="md-table-normal table-responsive">
        {/* <Button className="float-right" type="primary" size="small" onClick={this.exportAllocations}>Export</Button> */}
        <DataTable
          noHeader
          highlightOnHover
          columns={columns}
          data={this.props.deals}
          onRowClicked={this.handleChange}
          pointerOnHover={true}
          sortServer={true}
          customStyles={customStyles}
        />
      </div>
    );
  }
}

export default withRouter(Confirmation);

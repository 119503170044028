import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Subscribe from "./Subscribe";

class PublicRoute extends Component {
  render() {
    let { component: Component, currentUser, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => (
          <>
            {/* {!currentUser?.id && <Subscribe currentUser />} */}
            <Component {...props} />
          </>
        )}
      />
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
});

export default connect(mapStateToProps, null)(PublicRoute);

import React, { Component } from "react";
import { connect } from "react-redux";
import { updateInvstorProfile } from "../../../redux/actions/profileActions";
import { getCurrentUser } from "../../../redux/actions/authActions";
import SophisticatedPreferenceUpload from "./preferences/SophisticatedPreferenceUpload";

class SophisticatedUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        address: null,
        middle_name: null,
      },
      wholesale_status: [],
      verification: [],
      open: false,
      documentID: "",
      siForms: [],
    };
  }

  open = (id) => {
    this.setState({ open: true, documentID: id });
  };

  close = () => this.setState({ open: false });

  componentDidMount() {}

  handleSubmit = async (values) => {
    const { history } = this.props;
    const { first_name, last_name, email, phone } = this.props.currentUser;
    await this.props.updateProfile(
      {
        ...values,
        first_name,
        last_name,
        email,
        phone,
        siForms:
          Object.entries(this.state.siForms).length > 0
            ? this.state.siForms
            : null,
        verification:
          Object.entries(this.state.verification).length > 0
            ? this.state.verification
            : null,
        redirectUrl: {
          pathname: "/user/add-entity",
          state: { redirectUri: "/user/review" },
        },
      },
      history
    );
    this.props.getCurrentUserData();
  };

  handleAlert = () => {
    this.props.clearAlert();
  };

  validateForm = () => {
    const errors = {};

    return errors;
  };

  handleUpload = (event) => {
    if (event.target.name === "wholesale_status") {
      return this.setState({
        wholesale_status: event.target.files[0],
      });
    } else {
      return this.setState({
        uploaded: {
          ...this.state.uploaded,
          verification: event.target.files,
        },
      });
    }
  };

  onIDUpload = (files) => {
    this.setState({ verification: files });
  };

  onSIUpload = (files) => {
    this.setState({ siForms: files });
  };

  wholeSaleonUpload = (files) => {
    this.setState({ wholesale_status: files });
  };

  onDocumentDeleteHandler = async (id) => {
    let { deleteMedia, getCurrentUserData } = this.props;
    await deleteMedia({ id: this.state.documentID });
    this.setState({ open: false });
    getCurrentUserData();
  };

  render() {
    let { currentUser } = this.props;
    return (
      <div className="page-create-account investor-account investor-account-01">
        <div className="form-normal form-create-account">
          <SophisticatedPreferenceUpload
            onSubmit={this.handleSubmit}
            handleUpload={this.handleUpload}
            onIDUpload={this.onIDUpload}
            onSIUpload={this.onSIUpload}
            wholeSaleonUpload={this.wholeSaleonUpload}
            verificationDocument={currentUser.uploaded_verification}
            siForms={currentUser.sophisticated_investor_forms}
            wholeSaleDocument={currentUser.wholesale_status}
            onDocumentDeleteHandler={this.onDocumentDeleteHandler}
            open={this.open}
            show={this.state.open}
            close={this.close}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (payload, history) =>
    updateInvstorProfile(dispatch, payload, history),
  getCurrentUserData: () => getCurrentUser(dispatch, {}),
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    initialValues: {
      form: state.form,
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SophisticatedUpload);

import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import {
  logoutUser,
  getCurrentUser,
  uploadAvatar,
} from "../redux/actions/authActions";
import { showUserMenu, hideUserMenu } from "../redux/actions/appActions";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Login from "../components/pages/auth/Login";
import Register from "../components/pages/auth/Register";
import Home from "../components/pages/public/Home";
import WhoWeAre from "../components/pages/public/WhoWeAre";
import Faqs from "../components/pages/public/Faqs";
import Articles from "../components/pages/public/Articles";
import ArticlesView from "../components/pages/public/Articles/view";
import StockTips from "../components/pages/protected/StockTips";
import Unsubscribed from "../components/pages/public/Unsubscribed";
import ForgotPasswordContainer from "../components/forms/ForgotPassword/ForgotPasswordContainer";
import ResetPasswordContainer from "../components/forms/ResetPassword/ResetPasswordContainer";
import Message from "../components/common/Message";
import TermCondition from "../components/pages/public/TermCondition";
import PrivacyPolicy from "../components/pages/public/PrivacyPolicy";
import ContactContainer from "../components/pages/public/Contact/ContactContainer";
import AllDeals from "../components/pages/public/AllDeals";
import DealRoute from "../components/DealRoute";
import { Menu, Sidebar, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Icon } from "antd";
import { LiveDealsButton } from "../../src/components/common";
import HowItWork from "../components/pages/public/HowItWork";
import Leaderboards from "../components/pages/protected/Leaderboards";
import ChatWithTraders from "../components/pages/public/chatwithtraders"

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.state = {
      visible: false,
    };
  }

  changeSidebar = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  updateDimensions = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth > 992) {
      if (this.state.visible) {
        this.changeSidebar();
      }
    }
  };
  componentDidMount() {
    this.props.getCurrentUser();

    window.addEventListener("resize", this.updateDimensions);

    const windowWidth = window.innerWidth;
    if (windowWidth > 992) {
      this.updateDimensions();
    }
  }

  triggerFile = () => {
    this.fileRef.current.click();
  };

  uploadAvatar = (e) => {
    this.props.uploadAvatar(e.target.files[0]);
  };

  render() {
    const loadingIcon = {
      right: "16px",
      top: "-37px",
      fontSize: "52px",
      position: "absolute",
    };

    const { currentUser, roles, preferences } = this.props.auth;

    const { location, showUserMenu } = this.props;

    let { loading } = this.props;
    let { email, full_name } = currentUser;

    return (
      <Fragment>
        <Sidebar.Pushable as={Segment}>
          <Sidebar
            as={Menu}
            animation="push"
            icon="labeled"
            direction="right"
            className="app__sidebar"
            inverted
            onHide={() => this.setState}
            vertical
            visible={this.state.visible}
          >
            {/* <Menu.Item as="a">Home</Menu.Item>
            <Menu.Item as="a">Games</Menu.Item>
            <Menu.Item as="a">Channels</Menu.Item> */}
            <div class="main-nav-mobile">
              {currentUser && currentUser.id ? (
                <div class="container">
                  <ul class="clearfix">
                    <li className="mobile__headerImg">
                      <div className="nav-content">
                        <div className="avatar">
                          <div
                            className="picture"
                            style={{ opacity: loading ? "0.5" : 1 }}
                          >
                            <img
                              style={{ height: "100%", objectFit: "contain" }}
                              src={
                                this.props.currentUser.photo_url ||
                                this.props.currentUser.logo_url
                              }
                              alt=""
                            />
                          </div>
                          <div className="upload-picture">
                            {loading && (
                              <Icon type="loading" style={loadingIcon} />
                            )}
                            <div className="file-upload">
                              <label
                                htmlFor="file-upload-01"
                                className="btn-upload"
                                onClick={this.triggerFile}
                              ></label>
                              <input
                                type="file"
                                className="input-file"
                                id="file-upload-04"
                                ref={this.fileRef}
                                accept="image/*"
                                onChange={this.uploadAvatar}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="information">
                        <p>
                          <span className="name">{full_name}</span>
                          <span className="email">{email}</span>
                        </p>
                      </div>
                    </li>
                    <li className="sidebar__item">
                      <Link to="/who-we-are">
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          Who We Are
                        </span>
                      </Link>
                    </li>
                    <li className="sidebar__item">
                      <Link to="/contact-us">
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          Contact
                        </span>
                      </Link>
                    </li>
                    <li className="sidebar__item">
                      <Link to="/live-now">
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          Live Now
                        </span>
                      </Link>
                    </li>
                    <li className="loggedIn__sidebarDropdown">
                      <button
                        className="btn btn-light dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Resources
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          <Link
                            className="dropdown-item"
                            to="/user/director-shares"
                          >
                            Director Shares
                          </Link>
                        </span>
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          <Link
                            className="dropdown-item"
                            to="/user/newsletters"
                          >
                            Newsletter
                          </Link>
                        </span>
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          <Link className="dropdown-item" to="/user/webinars">
                            Webinars
                          </Link>
                        </span>
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          <Link className="dropdown-item" to="/user/interviews">
                            18 Minutes with 180
                          </Link>
                        </span>
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          <Link
                            className="dropdown-item"
                            to="/user/pro-investor-series"
                          >
                            Pro Investor Series
                          </Link>
                        </span>
                      </div>
                    </li>

                    <li className="loggedIn__sidebarDropdown">
                      <button
                        className="btn btn-light dropdown-toggle"
                        type="button"
                        id="profileDropDown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Profile
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="profileDropDown"
                      >
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          <Link className="dropdown-item" to="/user/deals">
                            All Deals
                          </Link>
                        </span>
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          <Link className="dropdown-item" to="/user/profile">
                            Profile
                          </Link>
                        </span>
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          <Link
                            className="dropdown-item"
                            to="/user/preferences"
                          >
                            Preferences
                          </Link>
                        </span>
                      </div>
                    </li>

                    <li className="sidebar__item">
                      <span
                        onClick={() => {
                          if (this.state.visible) {
                            this.changeSidebar();
                          }
                        }}
                      >
                        <Link onClick={() => this.props.logoutUser()}>
                          Log Out
                        </Link>
                      </span>
                    </li>
                  </ul>
                </div>
              ) : (
                <div class="container">
                  <ul class="clearfix">
                    <li className="sidebar__item">
                      <Link to="/#how-it-works">
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          How It Works
                        </span>
                      </Link>
                    </li>
                    <li className="sidebar__item">
                      <Link to="/who-we-are">
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          Who We Are
                        </span>
                      </Link>
                    </li>
                    <li className="sidebar__item">
                      <Link to="/contact-us">
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          Contact
                        </span>
                      </Link>
                    </li>
                    <li className="sidebar__item">
                      <Link to="/register">
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          Create An Account
                        </span>
                      </Link>
                    </li>
                    <li className="sidebar__item">
                      <Link to="/auth/login">
                        <span
                          onClick={() => {
                            if (this.state.visible) {
                              this.changeSidebar();
                            }
                          }}
                        >
                          Login
                        </span>
                      </Link>
                    </li>
                  </ul>
                  <div class="smd-search">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div class="text-left pl-2">
                        {/* <input
                          type="text"
                          class="form-control sidebar__input"
                        /> */}
                        <LiveDealsButton />
                        {/* <button class="btn-search sidebar__searchButton"></button> */}
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </Sidebar>
          <Sidebar.Pusher dimmed={this.state.visible}>
            <Header
              currentUser={currentUser}
              showUserMenu={showUserMenu}
              tokenData={preferences.tokenData}
              changeSidebar={this.changeSidebar}
              visible={this.state.visible}
            />

            <Switch>
              <Route
                exact
                path="/auth/login"
                location={location}
                component={Login}
              />

              <Route
                exact
                path="/register"
                location={location}
                component={Register}
              />
              <Route
                exact
                path="/auth/register/investor"
                location={location}
                component={Register}
              />
              <Route
                exact
                path="/auth/register/broker"
                location={location}
                component={Register}
              />
              <Route
                exact
                path="/auth/register/company"
                location={location}
                component={Register}
              />
              <Route
                exact
                path="/password/reset/:token"
                location={location}
                component={ResetPasswordContainer}
              />
              <Route
                exact
                path="/auth/forgot-password"
                location={location}
                component={ForgotPasswordContainer}
              />
              <Route
                path="/auth/profile-status"
                location={location}
                component={Message}
              />
              <Route exact path="/contact-us" component={ContactContainer} />
              <Route exact path="/who-we-are" component={WhoWeAre} />
              <Route exact path="/all-leads" component={AllDeals} />
              <Route exact path="/chat" component={ChatWithTraders} />
              <Route exact path="/faqs" component={Faqs} />
              <Route exact path="/articles" component={Articles} />
              <Route exact path="/articles/:slug" component={ArticlesView} />
              <Route exact path="/unsubscribed/:uuid" component={Unsubscribed} />
              <Route
                exact
                path={`/:type/terms&conditions`}
                component={TermCondition}
              />
              <Route exact path="/how-it-works" component={HowItWork} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/leaderboard" component={Leaderboards} />
              <DealRoute exact path="/" component={Home} />
            </Switch>
            <Footer
              currentUser={currentUser}
              roles={roles}
              onLogout={logoutUser}
            />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Fragment>
    );
    // );
  }
}

const mapStateToProps = ({ auth, app, loading }) => ({
  auth,
  app,
  currentUser: auth.currentUser,
  userMenu: app.userMenu,
  loading: loading.buttonLoading,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser),
  showUserMenu: () => dispatch(showUserMenu),
  getCurrentUser: () => getCurrentUser(dispatch),
  hideUserMenu: () => dispatch(hideUserMenu),
  uploadAvatar: (payload) => uploadAvatar(payload, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);

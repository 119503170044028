import React, { Component } from "react";
import { connect } from "react-redux";
import liveActions from "../../../../../redux/actions/liveActions";
import { BaseUrl } from "../../../../../services/settings";
import { FileUpload } from "../../../../common/Graphics";
var moment = require("moment");

class LiveStreamView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount = () => {
    const { getSelected, match } = this.props;

    getSelected({ id: match.params.uuid });
  };

  render() {
    const { liveStream } = this.props;

    return (
      <main className="page-deal-add-doc">
        <div className="md-top-deal">
          <div className="container">
            <div className="deal-inner">
              <div className="row">
                <div className="col-md-12">
                  <div className="information">
                    <h1>{liveStream && liveStream.name}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md-tabs tab-deal-add-doc">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                {liveStream.date &&<p>
                  <b> Date: </b>{" "}
                  
                    { moment(liveStream.date, "DD/MM/YYYY").format(
                      "MMM. DD, YYYY"
                    )}
                </p>}

                <div
                  dangerouslySetInnerHTML={{
                    __html: liveStream.description,
                  }}
                />
             
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return { liveStream: state.liveStreams.selected };
};

const { getSelectedLiveStream } = liveActions;

const mapDispatchToProps = (dispatch) => ({
  getSelected: (id) => getSelectedLiveStream({ dispatch, id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveStreamView);

import actionTypes from "../actions/transactionActions";

const initialState = {
  transactions: [],
  loading: false,
  singleTransaction: {},
};

const transaction = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.FETCH_ALL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: payload,
      };
    case actionTypes.LOADING:
      return {
        ...state,
        loading: payload,
      };

    case actionTypes.FETCH_SINGLE_TRANSACTION_SUCCESS:
      return {
        ...state,
        singleTransaction: payload.data,
      };

    default:
      return state;
  }
};

export default transaction;

import FaqService from "../../services/faqService";


const actions = {
  LOADING: "LOADING",
  FETCH_ALL_FAQS_SUCCESS: "FETCH_ALL_FAQS_SUCCESS",
  fetchFaqs: ({ dispatch }) =>
    FaqService.getAll()
      .then(({ data }) => {
        dispatch({
          type: actions.FETCH_ALL_FAQS_SUCCESS,
          payload: data,
        });
      })
      .catch(() => {}),
};
export default actions;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { FormField } from "../layout/FormField";
import { email, required } from "redux-form-validators";
import { Field, reduxForm, Form } from "redux-form";
import ButtonLoader from "../common/ButtonLoader";

class SubscribeForm extends Component {
  state = {
    name: null,
    email: null,
  };

  render() {
    let { handleSubmit } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <div className="all-fields">
          <div className="field">
            <label className="title">Name</label>
            <div className="field-input">
              <Field
                className="form-control entity__input"
                type="text"
                name="name"
                component={FormField}
                validate={[required()]}
              />
            </div>
          </div>
          <div className="field">
            <label className="title">Email</label>
            <div className="field-input">
              <Field
                className="form-control entity__input"
                type="text"
                name="email"
                component={FormField}
                validate={[required(), email()]}
              />
            </div>
          </div>
       
        </div>
        <div className="row">
          <div className="button-group">
            <div className="col-sm-12">
              <ButtonLoader className="btn btn-primary btn-medium">
                Subscribe
              </ButtonLoader>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default reduxForm({ form: "SubscribeForm" })(withRouter(SubscribeForm));

import React, { Component, Fragment } from "react";
import { FormField } from "../../../../layout/FormField";
import { required } from "redux-form-validators";
import { Field, Form, reduxForm, FieldArray } from "redux-form";
import { connect } from "react-redux";
import ButtonLoader from "../../../../common/ButtonLoader";
import EntityActions from "../../../../../redux/actions/entityActions";
import NewDropzone from "../../../../common/NewDropzone";
import { scrollToFirstError } from "../../../../../utils/scroller";
import { RenderDirectors } from "../RenderDirector/RenderDirector";
import { withRouter } from "react-router-dom";

class CompanyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      directors: [],
      validation: false,
      dobStartDate: "",
    };
  }

  onDirectorChangeHandler = (fields, value) => {
    if (value > 0) {
      let obj = {};
      for (let i = 0; i < value; i++) {
        {
          obj[i] = i;
        }
      }
      this.setState({ directors: [...Object.entries(obj)] });
    } else {
      this.setState({ directors: [] });
    }
  };

  onUpdateDirectors = (value) => {
    this.props.onUpdateDirectorsCount(value);
  };

  componentDidMount = () => {
    let dobStartDate = new Date();
    dobStartDate.setFullYear(dobStartDate.getFullYear() - 18);
    this.setState({ dobStartDate });
  };

  handleSubmit = () => {
    this.setState({ validation: true });
    this.props.handleSubmit();
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.directors || nextProps.directors !== this.props.directors) {
      this.onDirectorChangeHandler(null, nextProps.directors);
    }
  };

  validationHandler = () => {
    this.setState({ validation: true });
  };

  statusOfApplicant = ["Yes", "No"];
  render() {
    const { edit } = this.props;

    return (
      <div>
        <Form onSubmit={this.props.handleSubmit} style={{ marginTop: "40px" }}>
          <div className="all-fields">
            <div className="row mb-3">
              <div className="col-sm-12">
                <div className="field">
                  <label className="title">Company Name *</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="name"
                      autoFocus
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Trading Name (Optional)</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="trading_name"
                      autoFocus
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div>

              {/* <div className="col-sm-6">
              <div className="field">
                <label className="title">Registration Body</label>
                <div className="field-input">
                  <Field
                    className="form-control"
                    name="registration_body"
                    component={FormField}
                    type="text"
                    validate={[required()]}
                  />
                </div>
              </div>
            </div> */}
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">
                    Account Designation (Optional)
                  </label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="account_designation"
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">
                    Tax File Number (TFN) (Optional)
                  </label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="tfn"
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Address *</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="address"
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-3">
                <div className="field">
                  <label className="title">Suburb *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name="suburb"
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="field">
                  <label className="title">Postcode *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name="postcode"
                      component={FormField}
                      type="number"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="field">
                  <label className="title">State *</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      type="text"
                      name="state"
                      component={FormField}
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="field">
                  <label className="title">Country *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name="country"
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-sm-4">
              <div className="field">
                <label className="title">Account Nickname</label>
                <div className="field-input">
                  <Field
                    className="form-control entity__input"
                    name="nickname"
                    autoFocus
                    component={FormField}
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="field">
                <label className="title">ABN / ACN</label>
                <div class="field-input">
                  <Field
                    className="form-control entity__input"
                    name="abn"
                    component={FormField}
                    type="text"
                    validate={[]}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="field">
                <label className="title">Country of Incorporation</label>
                <div class="field-input">
                  <Field
                    className="form-control entity__input"
                    name="country_of_incorporation"
                    component={FormField}
                    type="text"
                    validate={[]}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-sm-8">
              <div className="field">
                <label className="title">Company Type *</label>
                <div class="field-input form-group row mb-3">
                  {this.props.companyType.map((element) => {
                    return (
                      <Field
                        name="company_type"
                        component={FormField}
                        type="radio"
                        value={element}
                        label={element}
                        validate={[required()]}
                        className={`radio-inline`}
                      />
                    );
                  })}
                </div>
                <p style={{ fontSize: "12px", color: "red " }}>
                  {this.state.validation &&
                    this.props.form.CompanyForm &&
                    this.props.form.CompanyForm.syncErrors &&
                    this.props.form.CompanyForm.syncErrors.company_type &&
                    `This field is  ${this.props.form.CompanyForm.syncErrors.company_type}`}
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className="row mb-3">
              <div className="col">
                <div className="field">DVP Information</div>
              </div>
            </div>
            <div className="row mb-3">
              {/* <div className="col-sm-4">
                <div className="field">
                  <label className="title">Broker Name</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="broker_name"
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="field">
                  <label className="title">Broker Phone Number</label>
                  <div className="field-input ">
                    <Field
                      className="form-control entity__input"
                      name="broker_phone"
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="field">
                  <label className="title">Broker Email</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="broker_email"
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-sm-4">
                <div className="field">
                  <label className="title">PID</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="pid"
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="field">
                  <label className="title">HIN</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="hin"
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Fragment>
            <NewDropzone
              multiple={true}
              buttonLabel="Choose document"
              title={"Sophisticated Investor Form"}
              multiDocuments={this.props.investorForms}
              onUpload={(files) => {
                this.props.onInvestorDocumentUpload(files);
              }}
              onDocumentDeleteHandler={this.props.onDocumentDeleteHandler}
              open={this.props.open}
              close={this.props.close}
              show={this.props.show}
                disableDelete={true}

            />
          </Fragment> */}

          <Fragment>
            <NewDropzone
              multiple={true}
              buttonLabel="Choose document"
              title={"Company Extract"}
              multiDocuments={this.props.companyExtracts}
              onUpload={(files) => {
                this.props.onCompanyExtractUpload(files);
              }}
              onDocumentDeleteHandler={this.props.onExtractDeleteHandler}
              open={this.props.open}
              close={this.props.close}
              show={this.props.show}
            />
          </Fragment>

          <FieldArray
            name="directors"
            component={RenderDirectors}
            prevProps={this.props}
            number_field_name="number_of_directors"
            prefix="directors"
            selectedDate={this.props.selectedDate}
            prevDirectors={
              this.props.initialValues &&
              this.props.initialValues.number_of_directors
            }
            fileList={
              this.props.initialValues && this.props.initialValues.directors
            }
            fileName="directors"
          />
          <div className="row mb-3">
            <div className="button-group">
              <div className="col-sm-6">
                <ButtonLoader
                  type="submit"
                  className="btn btn-primary btn-medium"
                  onClick={this.validationHandler}
                >
                  Submit
                </ButtonLoader>
              </div>
              {edit && (
                <div className="col-sm-6">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-medium"
                    onClick={() => this.props.history.push("/user/profile")}
                  >
                    Back to Profile
                  </button>
                </div>
              )}
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.auth.currentUser,
    form: state.form,
    existingEntity: state.entities.entities,
  };
};

const { unmountSingleEntity } = EntityActions;

const mapDispatchToProps = (dispatch) => ({
  fetchSingleEntityData: (payload, history) => unmountSingleEntity(dispatch),
});

export default reduxForm({
  form: "CompanyForm",
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyForm)));

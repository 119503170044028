import React, { Component } from "react";
import { Radio } from "semantic-ui-react";
import { entityTypes, sliceObject } from "../../../../utils/constants";
import EntityActions from "../../../../redux/actions/entityActions";
import CompanyForm from "./CompanyForm/CompanyForm";
import TrustForm from "./TrustForm/TrustForm";
import IndividualForm from "./IndividualForm/IndividualForm";
import { connect } from "react-redux";
import { deleteMediaSuccess } from "../../../../redux/actions/accountActions";
var moment = require("moment");

const companyType = ["Proprietary", "Public", "Private"];

class AddEntity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "company",
      companyType: "",
      directorsData: {},
      directors: [],
      beneficialOwners: [],
      individualJoints: [],
      individualJointsFormValue: 1,
      individualJointsLength: "",
      uploadedIndividualDocs: [],
      trustDeedFiles: [],
      documentID: "",
      open: false,
      directorCount: 0,
      investorForms: [],
      dob: "",
      verificationDocument: {},
      companyExtracts: []
    };
    this.myRef = React.createRef(); // Create a ref object
  }

  onStatusDocumentUpload = (index, files) => {
    if (files.length) {
      let newFiles = { index, files };
      const { uploadedIndividualDocs } = this.state;
      uploadedIndividualDocs.push(newFiles);
      this.setState({
        uploadedIndividualDocs: uploadedIndividualDocs,
      });
    }
  };

  onDeedDocumentUpload = (files) => {
    this.setState({
      trustDeedFiles: files,
    });
  };

  open = (id) => {
    console.log(id);
    this.setState({ open: true, documentID: id });
  };

  close = () => this.setState({ open: false });

  onDocumentDeleteHandler = async (id) => {
    let { deleteMedia } = this.props;
    await deleteMedia({ id: this.state.documentID });
    let { fetchSingleEntityData } = this.props;
    fetchSingleEntityData();
    this.fetchEntityById();
    this.setState({ open: false });
  };

  onInvestorDocumentUpload = (files) => {
    this.setState({
      investorForms: files,
    });
  };

  addIndividualInIndividualForm = () => {
    this.setState({
      individualJointsLength: (this.state.individualJointsLength += 1),
    });
  };

  removeIndividualInIndividualForm = (index) => {
    this.setState({
      individualJointsLength: (this.state.individualJointsLength -= 1),
    });
  };

  componentDidMount = () => {
    this.fetchEntityById();
  };

  fetchEntityById = () => {
    let { fetchSingleEntity } = this.props;
    if (this.props.match.params.id) {
      fetchSingleEntity(this.props.match.params.id);
    } else {
      let { fetchSingleEntityData } = this.props;
      fetchSingleEntityData();
    }
  };

  handleChange = (e, { value }) => {
    this.setState({ value });
  };

  handleCompanyChange = (e, { companyType }) => {
    this.setState({ companyType });
  };

  componentConfig = {
    iconFiletypes: [".jpg", ".png", ".gif"],
    showFiletypeIcon: true,
    postUrl: "/uploadHandler",
  };
  scrollToTop() {
    this.myRef.current.scrollTo(0, 0);
  }

  companySubmitHandler = async (values) => {
    const {
      history,
      match,
      singleEntityData,
      updateSingleCompanyEntity,
    } = this.props;
    values["directors"] = values["directors"].slice(
      0,
      values["number_of_directors"]
    );

    let dobOfDirector = sliceObject(
      this.state.dob,
      Object.keys(this.state.dob).slice(0, values.number_of_directors)
    );
    if (match.params.id) {
      values.investorForms = this.state.investorForms;
      values.companyExtracts = this.state.companyExtracts;
      await updateSingleCompanyEntity(
        {
          ...this.state.verificationDocument,
          ...values,
          ...dobOfDirector,
          id: singleEntityData.id,
        },
        history
      );
      this.fetchEntityById();
    }
  };

  trustSubmitHandler = (values) => {
    const {
      history,
      match,
      singleEntityData,
      updateSingleTrustEntity,
    } = this.props;

    values.trustDeedFiles = this.state.trustDeedFiles;
    values.investorForms = this.state.investorForms;

    values = {
      ...values,
      beneficial: {
        ...values.beneficial,
        individuals: values.beneficial.individuals.map((individual) => {
          return {
            ...individual,
            // dob: moment(individual.dob).format("L"),
          };
        }),
        companies: values.beneficial.companies.map((company) => {
          return {
            ...company,
            directors: company.directors
              .slice(0, company.number_of_directors)
              .map((director) => {
                return {
                  ...director,
                  // dob: moment(director.dob).format("L"),
                };
              }),
          };
        }),
      },
    };

    if (match.params.id) {
      updateSingleTrustEntity(
        {
          ...values,
          ...this.state.dob,
          ...this.state.verificationDocument,
          id: singleEntityData.id,
        },
        history
      );
      this.fetchEntityById();
    }
  };

  individualSubmitHandler = (values) => {
    const {
      history,
      updateSingleIndividualEntity,
      singleEntityData,
      match,
    } = this.props;

    const { uploadedIndividualDocs } = this.state;
    let individuals = values.individuals.map((individual, index) => {
      let statusDocument = uploadedIndividualDocs.find(
        (uploadedIndividualDoc, fileIndex) =>
          uploadedIndividualDoc.index === index
      );
      individual.status_documents = statusDocument ? statusDocument.files : [];
      return individual;
    });

    values.individuals = individuals;

    if (match.params.id) {
      updateSingleIndividualEntity(
        {
          ...values,
          ...this.state.dob,
          ...this.state.verificationDocument,
          id: singleEntityData.id,
        },
        history
      );
      this.fetchEntityById();
    }
  };

  componentWillReceiveProps = (nextProps) => {
    let { singleEntityData } = nextProps;

    if (
      this.props.singleEntityData.entity_company !=
        singleEntityData.entity_company &&
      singleEntityData.entity_company !== null
    ) {
      this.setState({ value: "company" });
    }
    if (
      this.props.singleEntityData.entity_trust !=
        singleEntityData.entity_trust &&
      singleEntityData.entity_trust !== null
    ) {
      this.setState({ value: "trust" });
    }
    if (
      this.props.singleEntityData.entity_individuals !=
        singleEntityData.entity_individuals &&
      singleEntityData.entity_individuals &&
      singleEntityData.entity_individuals.length
    ) {
      this.setState({ value: "individual" });
    }
    if (
      nextProps.singleEntityData.entity_company !==
        this.props.singleEntityData.entity_company ||
      (this.props.singleEntityData.entity_company &&
        this.props.singleEntityData.entity_company.directors !==
          this.props.singleEntityData.entity_company.directors)
    ) {
      let { singleEntityData } = nextProps;
      if (singleEntityData.entity_company) {
        let directorsAll = singleEntityData.entity_company.directors;
        directorsAll = directorsAll.map((director) => {
          var date = moment(director.dob, "DD/MM/YYYY").format("YYYY-MM-DD");
          director.dob = new Date(date);
          return director;
        });

        this.setState({
          directors: directorsAll,
          directorCount: directorsAll.length,
        });
      }
    }

    if (
      nextProps.singleEntityData.entity_trust !==
      this.props.singleEntityData.entity_trust
    ) {
      let { singleEntityData } = nextProps;
      if (singleEntityData.entity_trust) {
        let ownersAll = singleEntityData.entity_trust;

        ownersAll = ownersAll.beneficial_individuals.map((individual) => {
          var date = moment(individual.dob, "DD/MM/YYYY").format("YYYY-MM-DD");
          individual.dob = new Date(date);
          return individual;
        });

        this.setState({
          beneficialOwners: [...ownersAll],
        });
      }
    }

    if (
      nextProps.singleEntityData.entity_individuals !==
      this.props.singleEntityData.entity_individuals
    ) {
      let { singleEntityData } = nextProps;
      if (singleEntityData.entity_individuals) {
        let individualsAll = singleEntityData.entity_individuals;
        individualsAll = individualsAll.map((inidividual, key) => {
          let dob = inidividual.dob;
          if (dob) {
            var date = moment(dob, "DD/MM/YYYY").format("YYYY-MM-DD");
            inidividual.dob = new Date(date);
          } else {
            delete inidividual.dob;
          }
          delete inidividual.entity_id;
          delete inidividual.created_at;
          delete inidividual.updated_at;
          return inidividual;
        });
        this.setState({
          individualJoints: individualsAll,
          individualJointsLength: individualsAll.length
            ? individualsAll.length
            : 1,
        });
      }
    }
  };

  setEntityFormValue = (value) => {
    this.setState({ value });
  };

  setRadioHandler = (value) => {
    this.setState({ value });
  };

  onUpdateDirectorsCount = (value) => {
    this.setState({
      directorCount: value,
    });
  };

  onVerificationDeleteHandler = async (id) => {
    let { deleteMedia } = this.props;
    await deleteMedia({ id: this.state.documentID });
    let { fetchSingleEntityData } = this.props;
    fetchSingleEntityData();
    this.fetchEntityById();
    this.setState({ open: false });
  };

  handleDob = (field, value) => {
    let splittedField = field.split(".");
    let requiredField = `${
      splittedField[0] +
      "[" +
      splittedField[1] +
      "]" +
      "[" +
      splittedField[2] +
      "]"
    }`;
    let dateOfBirth = {};
    dateOfBirth[requiredField] = value;
    this.setState((prevState) => {
      return { dob: { ...prevState.dob, ...dateOfBirth } };
    });
  };

  handleIdUpload = (file) => {
    this.setState((prevState) => {
      return {
        verificationDocument: { ...prevState.verificationDocument, ...file },
      };
    });
  };

  handleIndividaulDob = (field, value) => {
    let splittedField = field.split(".");
    let requiredField = `${
      splittedField[0] +
      "[" +
      splittedField[1] +
      "]" +
      "[" +
      splittedField[2] +
      "]"
    }`;
    let dateOfBirth = {};
    dateOfBirth[field] = value;
    this.setState((prevState) => {
      return { dob: { ...prevState.dob, ...dateOfBirth } };
    });
  };

  handleDirectorsDob = (field, value) => {
    let dateOfBirth = {};
    dateOfBirth[field] = value;
    this.setState((prevState) => {
      return { dob: { ...prevState.dob, ...dateOfBirth } };
    });
  };


  onCompanyExtractUpload = (files) => {
    this.setState({
      companyExtracts: files,
    });
  };

  onExtractDeleteHandler = async (id) => {
    let { deleteMedia } = this.props;
    await deleteMedia({ id: this.state.documentID });
    this.setState({ open: false });
    this.fetchEntityById();
  };

  render() {
    let { match, singleEntityData } = this.props;

    return (
      <main className="page-create-account investor-account ">
        <div className="container">
          <div class="form-normal ">
            <div className="all-fields">
              <div class="form-normal form-create-account profile-upgrade">
                {!match.params.id ? (
                  <>
                    <h1>Create Entity</h1>
                    <div className="row no-gutters">
                      {Object.keys(entityTypes).map((key) => {
                        return (
                          <Radio
                            label={entityTypes[key]}
                            value={key}
                            checked={this.state.value === key}
                            onChange={this.handleChange}
                            className={`col-md-4`}
                          />
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <h1>Edit Entity</h1>
                )}

                {this.state.value === "company" && (
                  <CompanyForm
                    edit
                    onSubmit={this.companySubmitHandler}
                    handleDirectorsDob={this.handleDirectorsDob}
                    setRadioValue={this.setRadioHandler}
                    open={this.open}
                    ref={this.myRef}
                    show={this.state.open}
                    close={this.close}
                    selectedDate={this.handleIndividaulDob}
                    handleDirectorsDob={this.handleDirectorsDob}
                    onInvestorDocumentUpload={this.onInvestorDocumentUpload}
                    onExtractDeleteHandler={this.onExtractDeleteHandler}
                    onDocumentDeleteHandler={this.onDocumentDeleteHandler}
                    onCompanyExtractUpload={this.onCompanyExtractUpload}
                    companyType={companyType}
                    investorForms={
                      singleEntityData && singleEntityData.entity_company
                        ? singleEntityData.entity_company
                            .sophisticated_investor_forms
                        : []
                    }
                    companyExtracts={
                      singleEntityData && singleEntityData.entity_company
                        ? singleEntityData.entity_company
                            .company_extracts
                        : []
                    }
                    onUpdateDirectorsCount={this.onUpdateDirectorsCount}
                    directors={this.state.directorCount}
                    onVerificationDeleteHandler={
                      this.onVerificationDeleteHandler
                    }
                    handleIdUpload={this.handleIdUpload}
                    initialValues={
                      singleEntityData.entity_company &&
                      Object.assign(
                        {},
                        {
                          nickname: singleEntityData.entity_company.nickname,
                          broker_name: singleEntityData.broker_name,
                          broker_email: singleEntityData.broker_email,
                          broker_phone: singleEntityData.broker_phone,
                          name: singleEntityData.entity_company.name,
                          trading_name:
                            singleEntityData.entity_company.trading_name,
                          address: singleEntityData.entity_company.address,
                          suburb: singleEntityData.entity_company.suburb,
                          state: singleEntityData.entity_company.state,
                          postcode: singleEntityData.entity_company.postcode,
                          country: singleEntityData.entity_company.country,
                          abn: singleEntityData.entity_company.abn,
                          country_of_incorporation:
                            singleEntityData.entity_company
                              .country_of_incorporation,
                          company_type:
                            singleEntityData.entity_company.company_type,
                          tfn: singleEntityData.entity_company.tfn,
                          registration_body:
                            singleEntityData.entity_company.registration_body,
                          account_designation:
                            singleEntityData.entity_company.account_designation,
                          status_of_applicant:
                            singleEntityData.entity_company.status_of_applicant,
                          participant_name:
                            singleEntityData.entity_company.participant_name,
                          pid: singleEntityData.pid,
                          // arbn: singleEntityData.entity_company.arbn,
                          number_of_directors:
                            singleEntityData.entity_company.number_of_directors,
                          hin: singleEntityData.hin,
                          directors: singleEntityData.entity_company.directors.map(
                            (director) => {
                              return {
                                given_name: director.given_name,
                                id: director.id,
                                title: director.title,
                                dob: director.dob,
                                country_of_birth: director.country_of_birth,
                                country_of_citizenship:
                                  director.country_of_citizenship,
                                surname: director.surname,
                                beneficial_company_id:
                                  director.beneficial_company_id,
                                verification: director.verification,
                              };
                            }
                          ),
                        }
                      )
                    }
                  />
                )}

                {this.state.value === "trust" && (
                  <TrustForm
                    edit
                    selectedDate={this.handleDirectorsDob}
                    handleDirectorsDob={this.handleDirectorsDob}
                    beneficialOwners={
                      singleEntityData &&
                      singleEntityData.entity_trust &&
                      singleEntityData.entity_trust.number_of_beneficial_owners
                    }
                    handleIndividaulDob={this.handleIndividaulDob}
                    setRadioValue={this.setRadioHandler}
                    verification={
                      singleEntityData.entity_trust &&
                      singleEntityData.entity_trust &&
                      singleEntityData.entity_trust.beneficial_individuals
                    }
                    companyType={companyType}
                    initialValues={
                      singleEntityData.entity_trust &&
                      Object.assign(
                        {},
                        {
                          nickname: singleEntityData.entity_trust.nickname,
                          broker_name: singleEntityData.broker_name,
                          broker_email: singleEntityData.broker_email,
                          broker_phone: singleEntityData.broker_phone,
                          name: singleEntityData.entity_trust.name,
                          trading_name:
                            singleEntityData.entity_trust.trading_name,
                          abn: singleEntityData.entity_trust.abn,
                          tfn: singleEntityData.entity_trust.tfn,
                          trust_type: singleEntityData.entity_trust.trust_type,
                          type_of_trust:
                            singleEntityData.entity_trust.type_of_trust,
                          country_of_establishment:
                            singleEntityData.entity_trust
                              .country_of_establishment,
                          account_designation:
                            singleEntityData.entity_trust.account_designation,
                          trustee_business_name:
                            singleEntityData.entity_trust.trustee_business_name,
                          settlor_name:
                            singleEntityData.entity_trust.settlor_name,
                          address: singleEntityData.entity_trust.address,
                          suburb: singleEntityData.entity_trust.suburb,
                          state: singleEntityData.entity_trust.state,
                          postcode: singleEntityData.entity_trust.postcode,
                          country: singleEntityData.entity_trust.country,
                          status_of_applicant:
                            singleEntityData.entity_trust.status_of_applicant,
                          number_of_beneficial_owners:
                            singleEntityData.entity_trust
                              .number_of_beneficial_owners,
                          participant_name:
                            singleEntityData.entity_trust.participant_name,
                          pid: singleEntityData.pid,
                          hin: singleEntityData.hin,
                          beneficial_owners: this.state.beneficialOwners,
                          number_of_personals:
                            singleEntityData.entity_trust.beneficial_individuals
                              .length,
                          number_of_companies:
                            singleEntityData.entity_trust.beneficial_companies
                              .length,

                          beneficial: {
                            companies: singleEntityData.entity_trust.beneficial_companies.map(
                              (company) => {
                                return {
                                  id: company.id,
                                  company_name: company.company_name,
                                  account_designation:
                                    company.account_designation,
                                  tfn: company.tfn,
                                  address: company.address,
                                  suburb: company.suburb,
                                  postcode: company.postcode,
                                  state: company.state,
                                  country: company.country,
                                  abn: company.abn,
                                  country_of_incroporation:
                                    company.country_of_incroporation,
                                  company_type: company.company_type,
                                  number_of_directors: company.directors.length,
                                  directors: company.directors.map(
                                    (director) => {
                                      return {
                                        given_name: director.given_name,
                                        id: director.id,
                                        title: director.title,
                                        dob: moment(
                                          director.dob,
                                          "DD/MM/YYYY"
                                        ).format("YYYY-MM-DD"),
                                        country_of_birth:
                                          director.country_of_birth,
                                        country_of_citizenship:
                                          director.country_of_citizenship,
                                        surname: director.surname,
                                        beneficial_company_id:
                                          director.beneficial_company_id,
                                        verification: director.verification,
                                      };
                                    }
                                  ),
                                };
                              }
                            ),
                            individuals: singleEntityData.entity_trust.beneficial_individuals.map(
                              (individual) => {
                                return {
                                  id: individual.id,
                                  full_name: individual.full_name,
                                  address: individual.address,
                                  suburb: individual.suburb,
                                  postcode: individual.postcode,
                                  state: individual.state,
                                  country: individual.country,
                                  country_of_birth: individual.country_of_birth,
                                  country_of_citizenship:
                                    individual.country_of_citizenship,
                                  tfn: individual.tfn,
                                  dob: moment(
                                    individual.dob,
                                    "DD/MM/YYYY"
                                  ).format("YYYY-MM-DD"),
                                };
                              }
                            ),
                          },
                        }
                      )
                    }
                    onInvestorDocumentUpload={this.onInvestorDocumentUpload}
                    onDeedDocumentUpload={this.onDeedDocumentUpload}
                    handleIdUpload={this.handleIdUpload}
                    onVerificationDeleteHandler={
                      this.onVerificationDeleteHandler
                    }
                    onDocumentDeleteHandler={this.onDocumentDeleteHandler}
                    trustDeedDocument={
                      singleEntityData && singleEntityData.entity_trust
                        ? singleEntityData.entity_trust.trust_deeds
                        : []
                    }
                    investorForms={
                      singleEntityData && singleEntityData.entity_trust
                        ? singleEntityData.entity_trust
                            .sophisticated_investor_forms
                        : []
                    }
                    open={this.open}
                    show={this.state.open}
                    close={this.close}
                    onSubmit={this.trustSubmitHandler}
                  />
                )}
                {this.state.value === "individual" && (
                  <IndividualForm
                    edit
                    selectedDate={this.handleDob}
                    handleIdUpload={this.handleIdUpload}
                    onVerificationDeleteHandler={
                      this.onVerificationDeleteHandler
                    }
                    individualsCount={
                      match.params.id && this.state.individualJointsLength
                    }
                    addIndividual={this.addIndividualInIndividualForm}
                    removeIndividual={this.removeIndividualInIndividualForm}
                    onStatusDocumentUpload={this.onStatusDocumentUpload}
                    initialValues={
                      this.props.singleEntityData.entity_individuals && {
                        individuals: this.state.individualJoints,
                        broker_name: this.props.singleEntityData.broker_name,
                        broker_phone: this.props.singleEntityData.broker_phone,
                        broker_email: this.props.singleEntityData.broker_email,
                        pid: this.props.singleEntityData.pid,
                        hin: this.props.singleEntityData.hin,
                      }
                    }
                    individualId={match.params.id}
                    onSubmit={this.individualSubmitHandler}
                    onDocumentDeleteHandler={this.onDocumentDeleteHandler}
                    open={this.open}
                    show={this.state.open}
                    close={this.close}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    entities: state.entities.entities,
    form: state.form,
    singleEntityData: state.entities.singleEntity,
    beneficial_owners: state.entities.beneficial_owners,
    initialValue: {
      number_of_directors: 1,
    },
  };
};

const {
  createCompanyEntity,
  createTrustEntity,
  createIndividualEntity,
  fetchSingleEntityData,
  updateCompanyEntity,
  updateIndividualEntity,
  updateTrustEntity,
  unmountSingleEntity,
} = EntityActions;

const mapDispatchToProps = (dispatch) => ({
  createCompanyEntity: (payload, history) =>
    createCompanyEntity({ dispatch, payload, history }),
  createTrustEntity: (payload, history) =>
    createTrustEntity({ dispatch, payload, history }),
  createIndividualEntity: (payload, history) =>
    createIndividualEntity({ dispatch, payload, history }),
  fetchSingleEntity: (payload) => {
    fetchSingleEntityData({ payload, dispatch });
  },
  updateSingleCompanyEntity: (payload, history) => {
    updateCompanyEntity({ dispatch, payload, history });
  },
  updateSingleIndividualEntity: (payload, history) => {
    updateIndividualEntity({ dispatch, payload, history });
  },
  updateSingleTrustEntity: (payload, history) => {
    updateTrustEntity({ dispatch, payload, history });
  },
  fetchSingleEntityData: (payload, history) => unmountSingleEntity(dispatch),
  deleteMedia: (payload) => deleteMediaSuccess(dispatch, payload),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEntity);

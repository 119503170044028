import axios from "axios";
import { BaseUrl, configMultipart } from "./settings";

class LiveService {
  getAll = (payload) => {
    let url = BaseUrl + `frontend/v1/live-streams?`;
    // if (payload.date){
    //   url += `date=${payload.date}`
    // }
    // if (payload.category){
    //   url += `&category=${payload.category}`
    // }
    return axios
      .get(url, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  getSelected = async ({ payload }) => {
    return await axios
      .get(BaseUrl + `frontend/v1/live-streams/${payload.id}`, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  getCurrentLiveStream = async () => {
    return await axios
      .get(BaseUrl + `frontend/v1/live-stream`, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  getCurrentNotification = async () => {
    return await axios
      .get(BaseUrl + `frontend/v1/notification`, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}
export default new LiveService();

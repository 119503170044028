import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { Field, Form, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { FormField } from "../../layout/FormField";
import ButtonLoader from "../../common/ButtonLoader";
import { requiredChecked } from "../../../utils/customValidators";
import { numberFormatter } from "../../../utils/constants";
import { renderEntity } from "../../common/CommonMethods";

class Allocations extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderIndividualName = (entity) => {
    let name = "";
    for (let key in entity.entity_individuals) {
      if (name.length > 0) {
        return (name +=
          " " +
          "&" +
          " " +
          entity.entity_individuals[key].given_name +
          " " +
          entity.entity_individuals[key].sur_name);
      }

      name +=
        entity.entity_individuals[key].given_name +
        " " +
        entity.entity_individuals[key].sur_name;
    }

    return name;
  };

  render() {
    const {
      allocations,
      entities,
      handleSubmit,
      onDeclineHandler,
      selectEntity,
      entity,
      splitAllocationSelect,
      split,
      handleSharesAmount,
    } = this.props;

    const entitiesOptions = entities.map((entity) => {
      if (entity.entity_company !== null)
        return {
          text: entity.entity_company.name,
          value: entity.id,
        };
      else if (entity.entity_trust !== null)
        return {
          text: entity.entity_trust.name,
          value: entity.id,
        };
      else if (entity.entity_individual !== null)
        return {
          text: this.renderIndividualName(entity),
          value: entity.id,
        };
    });

    return (
      <main className="page-signup page-create-account brocker-account ">
        <div className="container">
          {allocations && allocations.allocation && (
            <div class="form-normal form-create-account">
              <h2>Allocation</h2>
              <p>
                Congratulations, <br />
                You have received the following allocation in{" "}
                {allocations.allocation.allocation_for === "deal"
                  ? allocations.allocation.deal.company_name
                  : allocations.allocation.ipo.company_name}{" "}
                <br />
                Please review and accept the allocation below.
              </p>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Offeror</Table.Cell>
                    <Table.Cell>
                      <strong>
                        {allocations.allocation.allocation_for === "deal"
                          ? allocations.allocation.deal.company_name
                          : allocations.allocation.ipo.company_name}{" "}
                        <br />
                        ASX:{" "}
                        {allocations.allocation.allocation_for === "deal"
                          ? allocations.allocation.deal.asx_code
                          : allocations.allocation.ipo.asx_code}{" "}
                      </strong>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Structure</Table.Cell>
                    <Table.Cell>
                      {allocations.allocation.allocation_for === "deal"
                        ? "Placement"
                        : allocations.allocation.ipo &&
                          allocations.allocation.ipo.category == 180
                        ? "IPO"
                        : "Unlisted Investment"}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Securities</Table.Cell>
                    <Table.Cell>
                      Ordinary fully paid shares in{" "}
                      {allocations.allocation.allocation_for === "deal"
                        ? allocations.allocation.deal.company_name
                        : allocations.allocation.ipo.company_name}{" "}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Entity</Table.Cell>
                    <Table.Cell>
                      {allocations.bid &&
                        allocations.bid.entity &&
                        renderEntity(allocations.bid.entity)}
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      Number of securities allocated to you
                    </Table.Cell>
                    <Table.Cell>
                      {numberFormatter(allocations.allocation_amount)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Price</Table.Cell>
                    <Table.Cell>
                      ${allocations.allocation.price} per security
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Total price for your allocation</Table.Cell>
                    <Table.Cell>
                      $
                      {(
                        allocations.allocation.price *
                        allocations.allocation_amount
                      ).toFixed(2)}
                    </Table.Cell>
                  </Table.Row>
                  {allocations.allocation.settlement_type === "DVP" && (
                    <Table.Row>
                      <Table.Cell>Settlement</Table.Cell>
                      <Table.Cell>
                        {allocations.allocation.settlement_date}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row>
                    <Table.Cell>
                      {allocations.allocation.settlement_type === "DVP"
                        ? "DVP Details"
                        : " Settlement details"}
                    </Table.Cell>
                    <Table.Cell>
                      {allocations.allocation.settlement_type === "DVP" ? (
                        allocations.allocation.allocation_for === "deal" ? (
                          <div>
                            <p>
                              Broker Name :{" "}
                              {allocations.bid.entity &&
                                allocations.bid.entity.broker_name}
                            </p>
                            {/* <p>
                              Broker Phone :{" "}
                              {allocations.bid.entity &&
                                allocations.bid.entity.broker_phone}
                            </p>
                            <p>
                              Broker Email :{" "}
                              {allocations.bid.entity &&
                                allocations.bid.entity.broker_email}
                            </p> */}
                            <p>
                              PID :{" "}
                              {allocations.bid.entity &&
                                allocations.bid.entity.pid}
                            </p>
                            <p>
                              HIN :{" "}
                              {allocations.bid.entity &&
                                allocations.bid.entity.hin}
                            </p>
                            <br />
                          </div>
                        ) : (
                          <div>
                            <p>
                              Broker Name :{" "}
                              {allocations.ipo_bid.entity &&
                                allocations.ipo_bid.entity.broker_name}
                            </p>
                            <p>
                              Broker Phone :{" "}
                              {allocations.ipo_bid.entity &&
                                allocations.ipo_bid.entity.broker_phone}
                            </p>
                            <p>
                              Broker Email :{" "}
                              {allocations.ipo_bid.entity &&
                                allocations.ipo_bid.entity.broker_email}
                            </p>
                            <p>
                              Pid :{" "}
                              {allocations.ipo_bid.entity &&
                                allocations.ipo_bid.entity.pid}
                            </p>
                            <p>
                              Hin :{" "}
                              {allocations.ipo_bid.entity &&
                                allocations.ipo_bid.entity.hin}
                            </p>
                          </div>
                        )
                      ) : allocations.allocation.settlement_type ===
                        "180 Fund Transfer" ? (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: allocations.allocation.settlement_details,
                            }}
                          />
                          <div>
                            Reference: {allocations.invoice_number}
                          </div>
                          <b>
                            Please quote the above Reference when making
                            payment.
                          </b>
                        </>
                      ) : (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: allocations.allocation.settlement_details,
                            }}
                          />
                        </>
                      )}
                    </Table.Cell>
                  </Table.Row>
                  {allocations.allocation.allocation_for == "deal" &&
                    allocations.allocation.deal.free_option && allocations.allocation.deal.dividend_option && (
                      <Table.Row>
                        <Table.Cell>Options</Table.Cell>
                        <Table.Cell>
                          {`${allocations.allocation.deal.dividend_option}:${allocations.allocation.deal.free_option} ${allocations.allocation.deal?.option_details}`}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  {allocations.allocation.allocation_for == "ipo" && allocations.allocation.ipo.dividend_option &&
                    allocations.allocation.ipo.free_options && (
                      <Table.Row>
                        <Table.Cell>Options</Table.Cell>
                        <Table.Cell>
                          {`${allocations.allocation.ipo.dividend_option}:${allocations.allocation.ipo.free_options} ${allocations.allocation.ipo?.option_details}`}
                        </Table.Cell>
                      </Table.Row>
                    )}
                </Table.Body>
              </Table>
              <div className="allocation-details" style={{ marginBottom: 50 }}>
                <p>
                  We require you to acknowledge your allocation by{" "}
                  {allocations.allocation.acceptance_date}{" "}
                  {allocations.allocation.acceptance_time}. By doing so, you
                  acknowledge that you are required to acquire the above
                  securities accordance with the{" "}
                  <b>
                    <u>
                      <a
                        target="_blank"
                        href="https://180.instaging.net/investor/terms&conditions"
                      >
                        Investor Terms
                      </a>
                    </u>
                  </b>
                  , including any additional or varied terms specified by the
                  Broker in the{" "}
                  <b>
                    {allocations.allocation.allocation_for === "deal"
                      ? allocations.allocation.deal.term_sheets_url && (
                          <a
                            target="_blank"
                            href={allocations.allocation.deal.term_sheets_url}
                          >
                            <u>Broker Terms</u>
                          </a>
                        )
                      : allocations.allocation.ipo.term_sheets_url && (
                          <a
                            target="_blank"
                            href={allocations.allocation.ipo.term_sheets_url}
                          >
                            Broker Terms
                          </a>
                        )}{" "}
                  </b>
                  . You are confirming that you have read and understood the
                  Investor Terms and agree to be bound by them.
                </p>

                <p>
                  By acknowledging your allocation you are also confirming that
                  you have read and understood the following additional
                  acknowledgements:
                  <ul style={{ padding: "12px 27px" }}>
                    <li style={{ listStyleType: "disc" }}>
                      No disclosure document has been lodged with ASIC.
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      Any other Additional Acknowledgements specified in the
                      Term Sheet.
                    </li>
                  </ul>
                </p>

                <p>
                  Payment must be made in the manner indicated above by{" "}
                  {allocations.allocation.settlement_date}. Failure to do so
                  could result in loss to {allocations.allocation.broker_name},{" "}
                  {allocations.allocation.allocation_for === "deal"
                    ? allocations.allocation.deal.company_name
                    : allocations.allocation.ipo.company_name}{" "}
                  and us and, under the Investor Terms, you have provided an
                  indemnity to us for such loss.
                </p>
                <p>
                  Any capitalised terms used but not defined have the meaning
                  given to them in the Investor Terms.
                </p>
              </div>
              {allocations && allocations.status === "Accepted" ? (
                <h3>
                  This allocation was accepted on the {allocations.signed_date}.
                </h3>
              ) : (
                <Form
                  onSubmit={handleSubmit}
                  style={{
                    background: "transparent",
                    boxShadow: "none",
                    padding: 0,
                  }}
                >
                  {/* remove false to enable split */}
                  {entities.length > 1 &&
                    allocations.allocation.lock_split !== 1 && (
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="field">
                            <Field
                              className="form-control"
                              onChange={selectEntity}
                              name="change_entity"
                              component={FormField}
                              type="switch"
                              id="change_entity"
                              label={<p>Change Entity</p>}
                              htmlFor="change_entity"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {entity && (
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="field">
                          <Field
                            className="form-control"
                            name="entity_id"
                            component={FormField}
                            type="select"
                            validate={[required()]}
                            options={entitiesOptions}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {entities.length > 1 &&
                    allocations.allocation.lock_split !== 1 && (
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="field">
                            <Field
                              className="form-control custom-control-input"
                              onChange={splitAllocationSelect}
                              name="split_allocation"
                              component={FormField}
                              type="switch"
                              id="split_allocation"
                              label={<p>Split Allocation</p>}
                              htmlFor="split_allocation"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                  {split && (
                    <div className="row">
                      {handleSharesAmount() && (
                        <div class="alert alert-danger col-sm-12" role="alert">
                          <p>
                            Total share amount must be equal to allocated amount{" "}
                            {allocations.allocation_amount}.
                          </p>
                        </div>
                      )}
                      <div className="col-sm-6"></div>
                      <div className="col-sm-6">
                        <p>Share Amount</p>
                      </div>
                      {entitiesOptions.map((entity) => {
                        return (
                          <>
                            <div className="col-sm-6 mt-2">
                              <div className="field">
                                <p>{entity.text}</p>
                              </div>
                            </div>
                            <div className="col-sm-6 mt-2">
                              <div className="field">
                                <Field
                                  className="form-control"
                                  name={`share_count-${entity.value}`}
                                  component={FormField}
                                  type="number"
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  )}
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="field">
                        <Field
                          className="form-control"
                          name="status"
                          component={FormField}
                          type="checkbox"
                          label={<p>I accept the allocation.</p>}
                          requiredError="Please accept the allocation."
                          validate={[required(), requiredChecked()]}
                          htmlFor="status"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="button-group">
                      <div className="col-sm-6">
                        <ButtonLoader
                          type="submit"
                          className="btn btn-primary btn-medium"
                        >
                          Accept
                        </ButtonLoader>
                      </div>
                      <div className="col-sm-6">
                        {parseInt(allocations.allocation.is_hot_deal) === 1 ? (
                          <ButtonLoader
                            style={{
                              backgroundColor: "white",
                              color: "red",
                              border: "1px red solid",
                            }}
                            type="button"
                            onClick={onDeclineHandler}
                            className="btn btn-outline-primary btn-medium "
                          >
                            Decline
                          </ButtonLoader>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </div>
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
});

export default reduxForm({ form: "Allocations", enableReinitialize: true })(
  connect(mapStateToProps)(Allocations)
);

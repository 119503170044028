import React, { Component, useSearchParams } from "react";
import { FormField } from "../layout/FormField";
import { required, email, length } from "redux-form-validators";
import { Field, reduxForm, Form } from "redux-form";
import { connect } from "react-redux";
import { checkEmail, registerUser } from "../../redux/actions/authActions";
import ButtonLoader from "../common/ButtonLoader";
import TermsModal from "../common/TermsModal";
import { Link } from "react-router-dom";
import { Select } from "antd";
import {
  matchPasswords,
  minLength,
} from "../../utils/customValidators";
import ReactCodeInput from "react-verification-code-input";
import auth from "../../services/authService";
import { toast } from "../common/Toast";
import { CheckCircleOutlined } from '@ant-design/icons';
import "../../assets/css/register.scss";

const { Option } = Select;

class InvestorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTerms: false,
      step: 1,
      country_id: 1,
      code: null,
      phone: null,
      errors: {},
      full_phone: null,
      verfied: true,
      recaptchaToken: null,
      redirectedFromStream: false,
    };
  }

  componentDidMount(){
    const redirect = new URLSearchParams(this.props.history.location.search).get("redirect")
    this.setState({
      redirectedFromStream: redirect === '/live-now',
    });
  }

  onBlurHandler = (e) => {
    let { checkEmailExistence } = this.props;
    checkEmailExistence(e.target.value);
  };

  onePhoneChange = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };

  openModal = () => {
    this.setState({
      showTerms: true,
    });
  };
  handleCountryChange = (value) => {
    this.setState({
      country_id: value,
    });
  };

  verifyCodeHandler = (value) => {
    this.setState({
      code: value,
    });
  };

  onChange = (value) => {
    this.setState({
      verfied: true,
      recaptchaToken: value,
    });
  }

  handleOk = (values) => {
    const { register } = this.props;
    if (register && register.emailError) {
      return;
    }
    this.setState({
      visible: true,
      phone: values.phone,
      formData: { ...values, role: "Investor" },
    });
    let errors = {};
    let phone = this.state.phone;
    const { countries } = this.props;
    let country_id = this.state.country_id;
    let country = countries.find((country) => country.id === country_id);
    let countryCode = country ? `+${country.phonecode}` : "";
    if (!phone || phone === "") {
      errors.phone = "Phone number is required";
    } else {
      if (phone.length < 8) {
        errors.phone = "Invalid phone number";
      }
    }
    if (!country_id || country_id === "") {
      errors.country_id = "Country is required";
    }
    if (phone && phone.length && phone[0] === "0") {
      phone = phone.replace(/0/, countryCode);
    } else {
      phone = countryCode + phone;
    }
    this.setState({
      errors,
      full_phone: phone,
    });
    if (Object.keys(errors).length) {
      return;
    }
    if (this.state.step === 1) {
      if (this.state.verfied === true) {
        auth
          .verifyPhone({ phone, "g-recaptcha-response": this.state.recaptchaToken })
          .then(async (data) => {
            if (data.success) {
              this.setState({
                verificationCode: true,
                step: 2,
              });
              // await toast.success("Verification Code Sent");
            } else {
              await toast.error("Failed to send verification code");
            }
          })
          .catch((error) => {
            if (error && error.errors && error.errors['g-recaptcha-response']) {
              toast.error("Invalid captcha");
            } else {
              toast.error("Server Error");
            }
          });
      } else {
        toast.error("Please Verify Recaptcha");
      }
    } else {
      let code = this.state.code;
      if (!code || code === "") {
        errors.code = "Verification Code is required";
        this.setState({
          errors,
        });
      }
      if (Object.keys(errors).length) {
        return;
      }
      auth
        .verifyCode({ phone, code })
        .then(async (data) => {
          if (data.verified) {
            const { history } = this.props;
            await this.props.registerUser({ ...this.state.formData, phone: phone.replace("+", "") }, history);
          } else {
            await toast.error("Verification failed: Try again");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Server Error");
        });
    }
  };

  render() {
    let { handleSubmit, register } = this.props;
    const options = this.props.countries.map((d) => (
      <Option value={d.id} code={`+${d.phonecode}`}>
        <img
          style={{ height: "15px", width: "20px" }}
          src={require(`../../assets/images/flags/${d.iso.toLowerCase()}.png`)}
          alt="Home"
          className="logo"
        />{" "}
        (+{d.phonecode})
      </Option>
    ));

    return (
      <main
        className={
          !this.props.home ? `page-main page-signup  pt-0 new-signup ${!this.state.redirectedFromStream ? 'login' : ''}` : "page-signup"
        }
      >
        <div className={!this.props.home ? `container-fluid p-0 bg-light-blue` : ""}>
          {this.state.step === 1 && (
            <div className={!this.props.home ? "row r-row" : ""}>
              {!this.props.home && <div className="col-md-3 bg-r-dark d-none d-lg-block">
                <div className="stripes"></div>
              </div>}

              <div className={!this.props.home ? "col-lg-9 col-12" : ""}>
                <div class={!this.props.home ? "form-account-create  vw-60" : "form-normal form-account-create"}>
                  {!this.props.home && this.state.redirectedFromStream && (
                    <>
                      <h1 class="r-title">Hold up. You’ll need to join 180 to watch the live stream.</h1>
                      <div className="sub-heading py-2">Don’t worry, its FREE and you’ll also get access to:</div>
                      <div className="row py-5 no-gutters">
                        <div className="col-md-6 checklists">
                          <div class="d-flex justify-content-start align-items-start">
                            <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '23px', color: '#0066FF' }} /> &nbsp;</div><div>Small Cap News and Trends</div>
                          </div>
                        </div>
                        <div className="col-md-6 checklists">
                          <div class="d-flex justify-content-start align-items-start">
                            <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '23px', color: '#0066FF' }} /> &nbsp;</div><div>Expert Trader Insights</div>
                          </div>
                        </div>
                        <div className="col-md-6 checklists">
                          <div class="d-flex justify-content-start align-items-start">
                            <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '23px', color: '#0066FF' }} /> &nbsp;</div><div>Interviews with Expert Traders</div>
                          </div>
                        </div>
                        <div className="col-md-6 checklists">
                          <div class="d-flex justify-content-start align-items-start">
                            <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '23px', color: '#0066FF' }} /> &nbsp;</div><div>Daily Stock Updates</div>
                          </div>
                        </div>
                        <div className="col-md-6 checklists">
                          <div class="d-flex justify-content-start align-items-start">
                            <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '23px', color: '#0066FF' }} /> &nbsp;</div>
                            <div>Market Alerts</div>
                          </div>
                        </div>
                        <div className="col-md-6 checklists">
                          <div class="d-flex justify-content-start align-items-start">
                            <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '23px', color: '#0066FF' }} /> &nbsp;</div><div>Investment Resources</div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {!this.props.home && !this.state.redirectedFromStream && (
                    <>
                      <h1 class="r-title pb-4">Register</h1>
                    </>
                  )}
                  <div className="form-bg">
                    <Form onSubmit={handleSubmit(this.handleOk)}>
                      <div className="all-fields">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="field">
                              <div className="field-input">
                                <Field
                                  className="form-control entity__input"
                                  name="first_name"
                                  component={FormField}
                                  type="text"
                                  validate={[required()]}
                                  placeholder="First Name"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="field">
                              <div class="field-input">
                                <Field
                                  className="form-control entity__input"
                                  name="last_name"
                                  component={FormField}
                                  type="text"
                                  validate={[required()]}
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="field">
                              <div class="field-input">
                                <Field
                                  className="form-control entity__input"
                                  type="text"
                                  name="email"
                                  component={FormField}
                                  onChange={(e) => this.onBlurHandler(e)}
                                  validate={[required(), email()]}
                                  placeholder="Email"
                                />
                                <div className="form__field-error">
                                  {register.emailError ? register.emailError : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="field">
                              <div class="field-input">
                                <Select
                                  showSearch
                                  placeholder="Select a country"
                                  optionFilterProp="children"
                                  onChange={this.handleCountryChange}
                                  filterOption={(input, option) => {
                                    return (
                                      option.props &&
                                      option.props.code
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    );
                                  }}
                                  value={this.state.country_id}
                                >
                                  {options}
                                </Select>
                                <span class="text-danger">
                                  {this.state.errors.country_id}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-8">
                            <div className="field">
                              <div class="field-input">
                                <Field
                                  placeholder="Phone Number"
                                  className="form-control entity__input"
                                  name="phone"
                                  component={FormField}
                                  type="number"
                                  onChange={(e) => this.onePhoneChange(e)}
                                  validate={[required()]}
                                />
                                <span class="text-danger">
                                  {this.state.errors.phone}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="field">
                              <div class="field-input">
                                <Field
                                  className="form-control entity__input"
                                  name="password"
                                  component={FormField}
                                  type="password"
                                  placeholder="Password"
                                  validate={[required(), minLength(6)]}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="field">
                              <div class="field-input">
                                <Field
                                  className="form-control entity__input"
                                  name="password_confirmation"
                                  component={FormField}
                                  type="password"
                                  placeholder="Confirm Password"
                                  validate={[
                                    required(),
                                    matchPasswords(),
                                    length({ min: 6 }),
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="field">
                            <Field
                              className="form-control entity__input"
                              name="terms_conditions"
                              component={FormField}
                              type="checkbox"
                              label={
                                <p>
                                  I accept the{" "}
                                  <Link
                                    to={"/investor/terms&conditions"}
                                    target="_blank"
                                  >{`Terms & Conditions`}</Link>{" "}
                                  and{" "}
                                  <Link
                                    to={"/privacy-policy"}
                                    target="_blank"
                                  >{`Privacy Policy`}</Link>
                                </p>
                              }
                              requiredError="Please accept our terms and conditions and privacy policy to continue"
                              validate={[required()]}
                              htmlFor="any"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-sm-12 mb-4">
                          <ReCAPTCHA
                            sitekey={CAPTCHA_KEY}
                            onChange={this.onChange}
                          />
                        </div>
                      </div> */}
                      <div className="row">
                          <div className="col-md-12">
                            <ButtonLoader
                              
                              type="submit"
                              className="btn btn-primary btn-medium btn-block"
                              disabled={!this.state.verfied}
                            >
                              Submit
                            </ButtonLoader>
                        </div>
                      </div>
                    </Form>
                  </div>
                  {!this.props.home && <div className="login py-4">Already have an account? <Link className="login-link" to="/auth/login" >Log In</Link></div>}
                </div>
              </div>
            </div>
          )}
          {this.state.step === 2 && (
            <div class="form-normal form-create-account verify-code">
              <h1 class="text-center mt-2">Verify Phone Number</h1>
              <h6 class="text-center">
                Please enter 6 digit verification code sent to{" "}
                {this.state.full_phone}
              </h6>
              <Form onSubmit={handleSubmit(this.handleOk)}>
                <div className="all-fields">
                  <div className="row">
                    <div className="col-sm-12">
                      <ReactCodeInput
                        className="verify-phone-input"
                        onChange={this.verifyCodeHandler}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-8 mx-auto mt-5">
                      <ButtonLoader
                        type="submit"
                        className="btn btn-primary btn-medium btn-block"
                      >
                        Verify
                      </ButtonLoader>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-8 mx-auto">
                      A code has been sent to your number
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 text-center mt-5">
                      Incorrect mobile number?{" "}
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          this.setState({
                            step: 1,
                          });
                        }}
                      >
                        Change it
                      </a>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          )}
          {/* Terms and conditions modal */}
          <TermsModal
            heading={`Terms & Conditions`}
            show={this.state.showTerms}
            onClose={() =>
              this.setState({
                showTerms: false,
              })
            }
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </TermsModal>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    register: state.register,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkEmailExistence: (email) => checkEmail(dispatch, email),
  registerUser: (payload, history) => registerUser(dispatch, payload, history),
});

export default reduxForm({ form: "InvestorForm" })(
  connect(mapStateToProps, mapDispatchToProps)(InvestorForm)
);

import { addValidator } from "redux-form-validators";
import { MIN_BID_AMOUNT } from "./constants";
import Validators from 'redux-form-validators'

export var matchPassword = addValidator({
  validator: function (options, value, allValues) {
    if (allValues.new_password != value) {
      return {
        defaultMessage: "Password does not match",
      };
    }
  },
});
export var matchPasswords = addValidator({
  validator: function (options, value, allValues) {
    if (allValues.password != value) {
      return {
        defaultMessage: "does not match with password field",
      };
    }
  },
});

export var requiredChecked = addValidator({
  validator: function (options, value, allValues) {
    if (!allValues.status) {
      return {
        defaultMessage: "Please accept the allocation.",
      };
    }
  },
});

export var passwordValidator = addValidator({
  validator: function (options, value, allValues) {
    if (
      !allValues.password.match(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$"
      )
    ) {
      return {
        defaultMessage:
          "Password should have one uppercase, one lowercase, one number, one special character, minimum 8 characters",
      };
    }
  },
});

export var zipcpde = addValidator({
  validator: function (options, value, allValues) {
    if (!allValues.zip.match(/^[\w\-\s]+$/)) {
      return {
        defaultMessage: "Invalid zip code",
      };
    }
  },
});

export var checkAccountNumber = addValidator({
  validator: function (options, value, allValues) {
    var regex = /^[0-9a-zA-Z]{8,29}$/;
    if (!allValues.account_number.match(regex)) {
      if (
        allValues.account_number.length < 9 ||
        allValues.account_number.length > 28
      ) {
        return {
          defaultMessage: "Minimum 9 and maximum 28 characters required",
        };
      } else {
        return {
          defaultMessage: "Invalid account number",
        };
      }
    }
  },
});
export var checkString = addValidator({
  validator: function (options, value, allValues) {
    var regex = /^[a-zA-Z ]+$/;
    if (!allValues.type_of_account.match(regex)) {
      return {
        defaultMessage: "Invalid account type",
      };
    }
  },
});
export var checkBankName = addValidator({
  validator: function (options, value, allValues) {
    var regex = /^[a-zA-Z ]+$/;

    if (!allValues.bank.match(regex)) {
      return {
        defaultMessage: "Invalid bank name",
      };
    }
  },
});
export var checkBranchName = addValidator({
  validator: function (options, value, allValues) {
    var regex = /^[a-zA-Z ]+$/;

    if (!allValues.branch_name.match(regex)) {
      return {
        defaultMessage: "Invalid branch name",
      };
    }
  },
});

export var checkSwiftCode = addValidator({
  validator: function (options, value, allValues) {
    var regex = /^[0-9a-zA-Z]{8,11}$/;
    if (!allValues.swift_code.match(regex)) {
      if (allValues.swift_code.length < 8 || allValues.swift_code.length > 11) {
        return {
          defaultMessage: "Minimum 8 and maximum 11 characters required",
        };
      } else {
        return {
          defaultMessage: "Invalid swift code",
        };
      }
    }
  },
});

export var amountValidator = addValidator({
  validator: function (options, value, allValues) {
    var regex = /^[0-9]+\.?[0-9]*$/;

    if (!allValues[options].match(regex)) {
      return {
        defaultMessage: "Invalid amount",
      };
    }
  },
});

export const phoneNumber = (value) =>
  value && !/^(0|[0-9][0-9]{9})$/i.test(value)
    ? "must be 10 or more characters"
    : undefined;

export const phoneNumber10 = (value) =>
  value && !/^(0|[0-9][0-9]{9})$/i.test(value)
    ? "must be 10 characters"
    : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min ? `must be ${min} or more characters` : undefined;

export const shares = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");

  return onlyNums;
};

export var bidAmount = addValidator({
  validator: function (options, value, allValues) {
    if (parseFloat(value) > MIN_BID_AMOUNT) {
      return {
        defaultMessage: `Min bid amount is $${MIN_BID_AMOUNT}.`,
      };
    }
  },
});

export var shareAmount = addValidator({
  validator: function (options, value, allValues) {
    if (parseFloat(value) > MIN_BID_AMOUNT) {
      return {
        defaultMessage: `Min bid amount is $${MIN_BID_AMOUNT}.`,
      };
    }
  },
});

export const preventDot = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");

  return onlyNums;
};

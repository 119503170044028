import axios from "axios";
import { BaseUrl, configMultipart } from "./settings";
import moment from "moment";

class DealService {
  createIpo = async (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        if (key === "listing_date") {
          bodyFormData.append(
            "listing_date",
            moment(payload[key]).format("DD/MM/YYYY")
          );
        } else if (key === "bids_due") {
          bodyFormData.append(
            "bids_due",
            moment(payload[key]).format("DD/MM/YYYY")
          );
        } else {
          bodyFormData.append(key, payload[key]);
        }
      }
    }

    bodyFormData.append("status", "Active");
    return await axios
      .post(BaseUrl + `frontend/v1/ipos`, bodyFormData, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  updateIpo = async (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        if (key === "listing_date") {
          bodyFormData.append(
            "listing_date",
            moment(payload[key]).format("DD/MM/YYYY")
          );
        } else if (key === "bids_due") {
          bodyFormData.append(
            "bids_due",
            moment(payload[key]).format("DD/MM/YYYY")
          );
        }  else {
          bodyFormData.append(key, payload[key]);
        }
      }
    }

    bodyFormData.append("_method", "PUT");
    return await axios
      .post(
        BaseUrl + `frontend/v1/ipos/${payload.id}`,
        bodyFormData,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  deleteIpo = async (id) => {
    return await axios
      .delete(BaseUrl + `frontend/v1/ipos/${id}`, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  closeIpo = async (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        if (key === "listing_date") {
          bodyFormData.append(
            "listing_date",
            moment(payload[key]).format("DD/MM/YYYY")
          );
        } else if (key === "bids_due") {
          bodyFormData.append(
            "bids_due",
            moment(payload[key]).format("DD/MM/YYYY")
          );
        }  else {
          bodyFormData.append(key, payload[key]);
        }
      }
    }

    return await axios
      .post(
        BaseUrl + `frontend/v1/ipos/${payload.id}/change-status`,
        bodyFormData,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  createIpoIndication = async (payload) => {
    let bodyFormData = new FormData();
    bodyFormData.append("investment_amount", payload.investment_amount);
    bodyFormData.append("ipo_id", payload.id);

    return await axios
      .post(
        BaseUrl + `frontend/v1/ipo-indications`,
        bodyFormData,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  updateIpoIndication = async (payload) => {
    let bodyFormData = new FormData();
    bodyFormData.append("investment_amount", payload.investment_amount);
    bodyFormData.append("ipo_id", payload.id);
    bodyFormData.append("_method", "PUT");

    return await axios
      .post(
        BaseUrl + `frontend/v1/ipo-indications/${payload.inidicationUuid}`,
        bodyFormData,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  createIpoBid = async (payload) => {
    let bodyFormData = new FormData();
    bodyFormData.append("investment_amount", payload.investment_amount);
    bodyFormData.append("ipo_id", payload.id);
    bodyFormData.append("entity_id", payload.entity_id);
    bodyFormData.append("share_count", payload.share_count);

    return await axios
      .post(BaseUrl + `frontend/v1/ipo-bids`, bodyFormData, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  updateIpoBid = async (payload) => {
    let bodyFormData = new FormData();
    bodyFormData.append("investment_amount", payload.investment_amount);
    bodyFormData.append("ipo_id", payload.id);
    bodyFormData.append("entity_id", payload.entity_id);
    bodyFormData.append("share_count", payload.share_count);
    bodyFormData.append("_method", "PUT");

    return await axios
      .post(
        BaseUrl + `frontend/v1/ipo-bids/${payload.bidId}`,
        bodyFormData,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  allIpos = ({ tab }) => {
    let api = BaseUrl + `frontend/v1/ipos`;
    if (tab){
      api += `?tab=${tab}`;
    }
    return axios
      .get(api, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  getSingleIpo = (uuid) => {
    return axios
      .get(BaseUrl + `frontend/v1/ipos/${uuid}`, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}
export default new DealService();

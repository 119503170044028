import React, { Fragment, Component } from "react";
import { FormField } from "../layout/FormField";
import { required, email, length } from "redux-form-validators";
import { Field, reduxForm, Form } from "redux-form";
import Entities from "./Entities/EntityFormContainer";
import RemoteSubmitButton from "./RemoteButton";
import NewDropzone from "../common/NewDropzone";
import DraggerComponent from "../common/DraggerComponent/DraggerComponent";
import { scrollToFirstError } from "../../utils/scroller";
import {
  phoneNumber,
} from "../../utils/customValidators";
import { ExclamationCircleOutlined } from "@ant-design/icons";

class InvestorForm extends Component {
  render() {
    let { handleSubmit, currentUser } = this.props;
    const isEnitityExist = currentUser.is_approved === "Approved";

    const withEntity =
      currentUser.investor_entities &&
      currentUser.investor_entities.length > 0 &&
      currentUser.is_approved !== "Approved";

    const withOutEntity =
      currentUser.investor_entities &&
      currentUser.investor_entities.length === 0 &&
      currentUser.is_approved !== "Approved";
      
    const dealAccessDenialMessage = `For s708 (Sophisticated) Investors, access our deal flow by clicking on your profile, adding an entity, and submitting your entity details.`;

    return (
      <main className="page-signup page-create-account ">
        <div className="container">
          <div class="form-normal form-create-account profile-upgrade">
            <h1>Profile</h1>
            <div className="title">
              {withOutEntity && (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <div class="alert alert-danger outline cursor-pointer" onClick={() => this.goToProfile()}>
                        <ExclamationCircleOutlined
                          className="custom"
                          height="2em"
                        />{" "}
                        &nbsp;{dealAccessDenialMessage}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {withEntity && (
                <div className="row">
                  <div className="col-md-12">
                    <div class="alert alert-warning outline">
                      <ExclamationCircleOutlined
                        className="custom"
                        height="2em"
                      />
                      &nbsp;&nbsp; We are currently processing your account for approval
                    </div>
                  </div>
                </div>
              )}
            </div>
            
            <Form onSubmit={handleSubmit}>
              <div className="all-fields">
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <div className="field">
                      <label className="title">First Name</label>
                      <div class="field-input">
                        <Field
                          className="form-control entity__input"
                          name="first_name"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="field">
                      <label className="title">Middle Name</label>
                      <div class="field-input">
                        <Field
                          className="form-control entity__input"
                          name="middle_name"
                          component={FormField}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="field">
                      <label className="title">Last Name</label>
                      <div class="field-input">
                        <Field
                          className="form-control entity__input"
                          name="last_name"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Phone</label>
                      <div class="field-input">
                        <Field
                          className="form-control entity__input"
                          name="phone"
                          component={FormField}
                          type="text"
                          validate={[required(), phoneNumber]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="field">
                      <label className="title">Email</label>
                      <div class="field-input">
                        <Field
                          className="form-control entity__input"
                          type="text"
                          name="email"
                          component={FormField}
                          validate={[required(), email()]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3" style={{ marginTop: 50 }}>
                  {/* <div className="col-sm-6">
                    <h3>ID verification documents</h3>
                    <p>
                      Acceptable forms of identification include:
                      <br />
                      <br />
                      <Field
                        className="form-control entity__input"
                        name="form_of_identifications[passport]"
                        component={FormField}
                        type="checkbox"
                        label={<p>Australian Passport</p>}
                        htmlFor="any"
                      />
                      <br />
                      <Field
                        className="form-control entity__input"
                        name="form_of_identifications[driver_license]"
                        component={FormField}
                        type="checkbox"
                        label={<p>Driver’s License</p>}
                        htmlFor="any"
                      />
                      <br />
                      <Field
                        className="form-control entity__input"
                        name="form_of_identifications[aus_id_card]"
                        component={FormField}
                        type="checkbox"
                        label={<p>Australian Identity Cards</p>}
                        htmlFor="any"
                      />
                    </p>
                  </div> */}
                  <div className="col-sm-12">
                    <Fragment>
                      <NewDropzone
                        multiple={true}
                        buttonLabel="Choose document"
                        title={"Sophisticated Investor Form"}
                        onDocumentDeleteHandler={
                          this.props.onDocumentDeleteHandler
                        }
                        multiDocuments={this.props.siForms}
                        onUpload={(files) => {
                          this.props.onSIUpload(files);
                        }}
                        open={this.props.open}
                        close={this.props.close}
                        show={this.props.show}
                        disableDelete={true}
                        profile
                      />
                    </Fragment>
                  </div>
                </div>
                <div style={{ marginTop: 50 }}>
                  <Entities {...this.props} />
                </div>

                <RemoteSubmitButton />
              </div>
            </Form>
          </div>
        </div>
      </main>
    );
  }
}

export default reduxForm({
  form: "InvestorProfileForm",
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(InvestorForm);

import React from "react";
import { Link, withRouter } from "react-router-dom";

const Review = () => {
  return (
    <div className="page-create-account investor-account investor-account-01">
      <div className="form-normal form-create-account message-page">
        <div className="row is-button-group">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <h3 className="text-center">
              We are processing your account for approval
            </h3>
          </div>
        </div>
        <div className="row is-button-group  w-100">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <Link
              to="/user/deals"
              className="btn btn-primary btn-medium btn-block"
            >
              Go to deals
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Review);

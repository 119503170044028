import IpoService from "../../services/ipoService";
import { toast } from "../../components/common/Toast";
import { startButtonLoading, stopButtonLoading } from "./loadingActions";

const actions = {
  GET_ALL_IPOS: "GET_ALL_IPOS",
  GET_SINGLE_IPO: "GET_SINGLE_IPO",

  getAllIposData: (dispatch, payload) => {
    return dispatch({
      type: actions.GET_ALL_IPOS,
      payload,
    });
  },

  getSingleIpo: (dispatch, payload) => {
    return dispatch({
      type: actions.GET_SINGLE_IPO,
      payload,
    });
  },

  createNewIpo: (dispatch, payload, history) => {
    dispatch(startButtonLoading);

    IpoService.createIpo(payload)
      .then((res) => {
        dispatch(stopButtonLoading);
        history.push("/user/deals");
        toast.success("IPO created successfully.");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);

        if (error) toast.error("Something went wrong.");
      });
  },

  updateIpoById: (dispatch, payload, history) => {
    dispatch(startButtonLoading);

    IpoService.updateIpo(payload)
      .then((res) => {
        dispatch(stopButtonLoading);
        history.push("/user/deals");
        toast.success("Ipo updated successfully.");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);

        if (error) toast.error("Something went wrong.");
      });
  },

  deleteIpoById: (payload, history, dispatch) => {
    dispatch(startButtonLoading);

    IpoService.deleteIpo(payload)
      .then((res) => {
        dispatch(stopButtonLoading);

        toast.success("Ipo deleted successfully.");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);

        if (error) toast.error("Something went wrong.");
      });
  },

  closeSelectedIpo: (payload, history, dispatch) => {
    dispatch(startButtonLoading);

    IpoService.closeIpo(payload)
      .then((res) => {
        dispatch(stopButtonLoading);

        toast.success("Ipo closed successfully.");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);

        if (error) {
          if (error.response.data.error) {
            return toast.error(error.response.data.error);
          }

          toast.error("Something went wrong.");
        }
      });
  },

  getAllIpos: (dispatch, payload) =>
    IpoService.allIpos(payload)
      .then((res) => {
        dispatch(actions.getAllIposData(dispatch, res));
      })
      .catch((error) => {
        if (error) toast.error("Something went wrong.");
      }),

  fetchSelectedIpo: (uuid, dispatch) =>
    IpoService.getSingleIpo(uuid)
      .then((res) => {
        dispatch(actions.getSingleIpo(dispatch, res));
      })
      .catch((error) => {
        if (error) toast.error("Something went wrong.");
      }),

  submitIndication: (payload, history, dispatch) => {
    dispatch(startButtonLoading);

    IpoService.createIpoIndication(payload)
      .then((res) => {
        dispatch(stopButtonLoading);

        toast.success(
          "Thank you for submitting your bid, we will contact the lead broker and attempt to get you an allocation."
        );
        history.push("/user/deals");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);

        if (error) toast.error("Something went wrong.");
      });
  },

  submitBid: (payload, history, dispatch) => {
    dispatch(startButtonLoading);

    IpoService.createIpoBid(payload)
      .then((res) => {
        dispatch(stopButtonLoading);

        toast.success(
          "Thank you for submitting your bid, we will get back to you shortly with an allocation. "
        );
        history.push(`/user/bid/success/${payload.id}/bid`);
      })
      .catch((error) => {
        dispatch(stopButtonLoading);

        if (error) toast.error("Something went wrong.");
      });
  },

  editIpoBid: (payload, history, dispatch) => {
    dispatch(startButtonLoading);

    IpoService.updateIpoBid(payload)
      .then((res) => {
        dispatch(stopButtonLoading);
        toast.success("Bid successfully updated.");
        // history.push("/user/deals");
        history.push(`/user/bid/success/${payload.id}/bid`);

      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) toast.error("Something went wrong.");
      });
  },

  editIpoIndication: (payload, history, dispatch) => {
    dispatch(startButtonLoading);

    IpoService.updateIpoIndication(payload)
      .then((res) => {
        dispatch(stopButtonLoading);
        toast.success("Indication successfully updated.");
        history.push("/user/deals");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) toast.error("Something went wrong.");
      });
  },
};

export default actions;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button, Select } from "antd";
import InvestorForm from "../../forms/InvestorForm";
import {
  registerUser,
  registerFor,
  changePendingMessage,
} from "../../../redux/actions/authActions";
import countryActions from "../../../redux/actions/countryActions";
import RegisterType from "./RegisterType";
import BrokerForm from "../../forms/BrokerForm";
import auth from "../../../services/authService";
import { toast } from "../../common/Toast";
const { Option } = Select;

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploaded: [],
      visible: false,
      confirmLoading: false,
      phone: null,
      code: null,
      countryCode: "61",
      country_id: 1,
      okText: "Verify",
      verificationCode: false,
      formData: null,
      errors: {},
      step: 1,
      verfied: false,
      recaptchaToken: null,
    };
  }

  componentDidMount() {
    const { fetchCountries } = this.props;
    fetchCountries();
  }

  componentWillUnmount = () => {
    let { changePendingMessageStatus } = this.props;
    changePendingMessageStatus();
  };

  onLogoUpload = (files) => {
    this.setState({ uploaded: files });
  };

  investorRegisterHandler = (values) => {
    this.setState({
      visible: true,
      phone: values.phone,
      formData: { ...values, role: "Investor" },
    });
  };

  brokerRegisterHandler = (values) => {
    let { uploaded } = this.state;
    if (uploaded) {
      values.logo = uploaded;
    }
    this.setState({
      // visible: true,
      phone: values.phone,
      formData: { ...values, role: "Broker" },
    });

    const { countries } = this.props;

    let phone = this.state.phone;
    let country_id = this.state.country_id;
    let country = countries.find(country => country.id === country_id);
    let countryCode = country ? `+${country.phonecode}` : '';
    let errors = {};
    if (!phone || phone === "") {
      errors.phone = "Phone number is required";
    } else {
      if (phone.length !== 10) {
        errors.phone = "Invalid phone number";
      }
    }
    if (!country_id || country_id === "") {
      errors.country_id = "Country is required";
    }
    if (phone && phone.length && phone[0] === "0") {
      phone = phone.replace(/0/, countryCode);
    } else {
      phone = countryCode + phone;
    }
    this.setState({
      errors,
      full_phone: phone,
    });
    if (Object.keys(errors).length) {
      return;
    }

    if (this.state.step === 1) {
      if (this.state.verfied === true) {
        auth
          .verifyPhone({ phone, "g-recaptcha-response": this.state.recaptchaToken })
          .then(async (data) => {
            if (data.success) {
              this.setState({
                verificationCode: true,
                step: 2,
              });
              // await toast.success("Verification Code Sent");
            } else {
              await toast.error("Failed to send verification code");
            }
          })
          .catch((error) => {
            if (error && error.errors && error.errors['g-recaptcha-response']) {
              toast.error("Invalid captcha");
            } else {
              toast.error("Server Error");
            }
          });
      } else {
        toast.error("Please Verify Recaptcha");
      }
    } else {
      let code = this.state.code;
      if (!code || code === "") {
        errors.code = "Verification Code is required";
        this.setState({
          errors,
        });
      }
      if (Object.keys(errors).length) {
        return;
      }
      auth
        .verifyCode({ phone, code })
        .then(async (data) => {
          if (data.verified) {
            const { history } = this.props;
            await this.props.register({ ...this.state.formData }, history);
          } else {
            await toast.error("Verification failed: Try again");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Server Error");
        });
    }
  };

  companyRegisterHandler = (values) => {
    let { uploaded } = this.state;
    if (uploaded) {
      values.logo = uploaded;
    }
    this.setState({
      // visible: true,
      phone: values.phone,
      formData: { ...values, role: "Company" },
    });
    const { countries } = this.props;

    let phone = this.state.phone;
    let country_id = this.state.country_id;
    let country = countries.find(country => country.id === country_id);
    let countryCode = country ? `+${country.phonecode}` : '';
    let errors = {};
    if (!phone || phone === "") {
      errors.phone = "Phone number is required";
    } else {
      if (phone.length !== 10) {
        errors.phone = "Invalid phone number";
      }
    }
    if (!country_id || country_id === "") {
      errors.country_id = "Country is required";
    }
    if (phone && phone.length && phone[0] === "0") {
      phone = phone.replace(/0/, countryCode);
    } else {
      phone = countryCode + phone;
    }
    this.setState({
      errors,
      full_phone: phone,
    });
    if (Object.keys(errors).length) {
      return;
    }

    if (this.state.step === 1) {
      if (this.state.verfied === true) {
        auth
          .verifyPhone({ phone, "g-recaptcha-response": this.state.recaptchaToken })
          .then(async (data) => {
            if (data.success) {
              this.setState({
                verificationCode: true,
                step: 2,
              });
              // await toast.success("Verification Code Sent");
            } else {
              await toast.error("Failed to send verification code");
            }
          })
          .catch((error) => {
            if (error && error.errors && error.errors['g-recaptcha-response']) {
              toast.error("Invalid captcha");
            } else {
              toast.error("Server Error");
            }
          });
      } else {
        toast.error("Please Verify Recaptcha");
      }
    } else {
      let code = this.state.code;
      if (!code || code === "") {
        errors.code = "Verification Code is required";
        this.setState({
          errors,
        });
      }
      if (Object.keys(errors).length) {
        return;
      }
      auth
        .verifyCode({ phone, code })
        .then(async (data) => {
          if (data.verified) {
            const { history } = this.props;
            await this.props.register({ ...this.state.formData }, history);
          } else {
            await toast.error("Verification failed: Try again");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Server Error");
        });
    }
  };


  setVisible(visible) {
    this.setState({ visible });
  }
  setConfirmLoading(confirmLoading) {
    this.setState({ confirmLoading });
  }

  handleOk = () => {
    this.setConfirmLoading(true);

    const { countries } = this.props;

    let phone = this.state.phone;
    let country_id = this.state.country_id;
    let country = countries.find(country => country.id === country_id);
    let countryCode = country ? `+${country.phonecode}` : '';
    let errors = {};
    if (!phone || phone === "") {
      errors.phone = "Phone number is required";
    } else {
      if (phone.length !== 10) {
        errors.phone = "Invalid phone number";
      }
    }
    if (!country_id || country_id === "") {
      errors.country_id = "Country is required";
    }
    let code = this.state.code;
    if (phone && phone.length && phone[0] === "0") {
      phone = phone.replace(/0/, countryCode);
    } else {
      phone = countryCode + phone;
    }
    this.setState({
      errors,
    });
    if (this.state.verificationCode) {
      if (!code || code === "") {
        errors.code = "Verification Code is required";
        this.setState({
          errors,
        });
      }
      if (Object.keys(errors).length) {
        this.setConfirmLoading(false);
        return;
      }
      auth
        .verifyCode({ phone, code })
        .then(async (data) => {
          if (data.verified) {
            const { history } = this.props;
            this.props.register(this.state.formData, history);
            this.setVisible(false);
          } else {
            await toast.error("Verification failed: Try again");
          }
          this.setConfirmLoading(false);
        })
        .catch((error) => {
          toast.error("Server Error");
          this.setConfirmLoading(false);
        });
    } else {
      if (Object.keys(errors).length) {
        this.setConfirmLoading(false);
        return;
      }
      auth
        .verifyPhone({ phone })
        .then(async (data) => {
          if (data.success) {
            this.setState({
              verificationCode: true,
            });
            await toast.success("Verification Code Sent");
          } else {
            await toast.error("Failed to send verification code");
          }
          this.setConfirmLoading(false);
        })
        .catch((error) => {
          toast.error("Server Error");
          this.setConfirmLoading(false);
        });
    }
  };

  handleCancel = () => {
    this.setVisible(false);
    this.setState({
      verificationCode: false,
      phone: null,
      code: null,
    });
  };

  showModal = () => {
    this.setVisible(true);
  };

  inputHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "phone") {
      let phone = e.target.value;
      this.setState({
        formData: {
          ...this.state.formData,
          phone,
        },
      });
      let errors = { ...this.state.errors };
      if (phone.length > 10) {
        errors[e.target.name] = "Invalid phone number";
      } else {
        errors[e.target.name] = null;
      }
      this.setState({
        errors,
      });
    }
  };

  handleCountryChange = (value) => {
    this.setState({
      country_id: value,
    });
  };

  verifyCodeHandler = (value) => {
    this.setState({
      code: value,
    });
  };

  onChange = (value) => {
    this.setState({
      verfied: true,
      recaptchaToken: value,
    });
  }

  setStep = () => {
    this.setState({
      step: 1
    });
  }

  render() {
    let { match, regState } = this.props;
    const options = this.props.countries.map((d) => (
      <Option value={d.id}>{d.name} (+{d.phonecode})</Option>
    ));

    return (
      <>
        <Modal
          title="VERIFY YOUR PHONE NUMBER"
          visible={this.state.visible}
          onOk={this.handleOk}
          confirmLoading={this.state.confirmLoading}
          okText={this.state.okText}
          onCancel={this.handleCancel}
          okButtonProps={{
            className: "btn btn-primary btn-block",
            style: { marginLeft: "0px", height: "43px" },
          }}
          cancelButtonProps={{ hidden: true }}
        >
          <form>
            <div class="form-group">
              <Select
                showSearch
                placeholder="Select a country"
                optionFilterProp="children"
                onChange={this.handleCountryChange}
                filterOption={(input, option) => {
                  return (
                    option.props &&
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                value={this.state.country_id}
              >
                {options}
              </Select>
            </div>
            <div class="form-group">
              <input
                type="number"
                className="form-control entity__input"
                name="phone"
                onChange={this.inputHandler}
                value={this.state.phone}
                autocomplete="off"
                placeholder="Phone Number"
              />
              <span class="text-danger">{this.state.errors.phone}</span>
            </div>
            {this.state.verificationCode && (
              <div class="form-group">
                <input
                  type="number"
                  className="form-control entity__input"
                  name="code"
                  onChange={this.inputHandler}
                  value={this.state.code}
                  autocomplete="off"
                  placeholder="Verification Code"
                />
                <span class="text-danger">{this.state.errors.code}</span>
              </div>
            )}
          </form>
        </Modal>
        {match.path === "/auth/register/investor" ? (
          <InvestorForm
            onSubmit={this.investorRegisterHandler}
            initialValues={{ experienced: 0 }}
            countries={this.props.countries}
            {...this.props}
          />
        ) : match.path === "/auth/register/broker" ? (
          <BrokerForm
            onSubmit={this.brokerRegisterHandler}
            onLogoUpload={this.onLogoUpload}
            registerState={regState}
            role={"Broker"}
            onreCaptchaChange={this.onChange}
            verfied={this.state.verfied}
            handleCountryChange={this.handleCountryChange}
            country_id={this.state.country_id}
            countries={this.props.countries}
            step={this.state.step}
            full_phone={this.state.full_phone}
            verifyCodeHandler={this.verifyCodeHandler}
            errors={this.state.errors}
            setStep={this.setStep}
          />
        ) : match.path === "/auth/register/company" ? (
          <BrokerForm
            onSubmit={this.companyRegisterHandler}
            onLogoUpload={this.onLogoUpload}
            registerState={regState}
            role={"Company"}
            onreCaptchaChange={this.onChange}
            verfied={this.state.verfied}
            handleCountryChange={this.handleCountryChange}
            country_id={this.state.country_id}
            countries={this.props.countries}
            step={this.state.step}
            full_phone={this.state.full_phone}
            verifyCodeHandler={this.verifyCodeHandler}
            errors={this.state.errors}
            setStep={this.setStep}

          />
        ) : (
          <RegisterType onSelect={this.props.registerFor} />
        )}
      </>
    );
  }
}

const { fetchCountries } = countryActions;

const mapStateToProps = (state) => ({
  optedType: state.register.optedType,
  regState: state.register,
  countries: state.countries.countries,
});

const mapDispatchToProps = (dispatch) => ({
  register: (payload, history) => registerUser(dispatch, payload, history),
  registerFor: (payload) => registerFor(dispatch, payload),
  fetchCountries: (payload) => fetchCountries(dispatch, payload),
  changePendingMessageStatus: () => dispatch(changePendingMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);

import React, { useEffect, useState } from "react";
var moment = require("moment");

export const LiveNow = ({ liveStream }) => {
  const [time, setTime] = useState(null);
  useEffect(() => {
    setInterval(() => {
      var duration = moment.duration(
        moment(
          `${liveStream.date} ${liveStream.time}`,
          "DD/MM/YYYY H:mm:ss"
        ).diff(moment())
      );
      // duration in hours
      var hours = parseInt(duration.asHours());
      // duration in minutes
      var minutes = parseInt(duration.asMinutes()) % 60;
      let text = `in `;
      if (hours > 1) {
        text += `${hours} hours`;
      } else {
        text += `${hours} hour`;
      }
      if (minutes > 0) {
        text += ` and `;
        if (minutes > 1) {
          text += `${minutes} minutes`;
        } else {
          text += `${minutes} minute`;
        }
      }
      setTime(`${text}`);
    }, 1000);
  }, []);

  return (
    <>
      <div
        className={``}
      >
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            className=""
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              paddingRight: "0",
              marginRight: "0",
              color: "#347CF6",
              fontSize: "18px",
              alignItems: "center",
              height: "50px",
              fontWeight: "400",
              paddingTop: "100px",
              paddingBottom: "50px"
            }}
          >
            
              {!liveStream.live_now && (
                <div className="text-uppercase pt-8" style={{fontSize: '22px' }}>
                  LIVE STREAM STARTING {time}
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

import React, { Component } from "react";
import Cardblock from "../../common/Cardblock";
import { withRouter } from "react-router-dom";
import "../../../assets/css/plans.scss";
import bgTop from "../../../assets/css/img/bg_circles.svg";
import pro from "../../../assets/css/img/plan-pro.svg";
import starter from "../../../assets/css/img/plan-starter.svg";
import { CheckCircleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
class RegisterType extends Component {
  onUserTypeSelection = (type) => {
    this.props.onSelect(type);
  };

  render() {
    return (
      <main className="page-main page-signup new-plans ">
        <div className="container-fluid">
          
          <div className="top-content account_title">
            <div className="title text-center">Get your share</div>
            <div className="sub-title text-center">Start your investment journey, today!</div>
            <div className="title-desc text-center py-2">
              <p className="text-center">
                Take advantage of our industry experience and personal relationships with brokers and ASX listed companies. Whether you’re just looking for the basics, or chasing access to tomorrow’s deals, today. We’ve got just the account for you:
              </p>
            </div>
          </div>
          <div className="section-content">
            <div className="row relative">
              <div className="col-md-6 starter-plan">
                <div className="card h-100">
                  <div className="card-body text-center">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title-image">
                          <img src={starter} alt="starter" />
                        </div>
                        <Link to="/auth/register/investor" className="hover-none">
                          <div className="title-text font-primary">
                            180 Starter
                          </div>
                        </Link>
                        
                        <div className="desc">
                          Perfect if you’re interested in learning about ASX Small Caps and the general Market, but not ready to invest. <br /><b>Enjoy FREE access to:</b>
                        </div>
                      </div>
                    </div>
                    <div className="row py-4">
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>Small Cap News and Trends</div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>Interviews with Directors & Expert Traders</div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>Market Alerts</div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>Live Streaming of Market Updates</div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>Expert Trader Insights</div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>Daily Stock Mover Updates</div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>Education & Investment Resources</div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        &nbsp;
                      </div>
                    </div>
                    <div className="row button-bottom">
                      <div className="col-md-12">
                        <Link to="/auth/register/investor">
                          <button type="submit" className="btn btn-primary btn-block">
                            Get Started
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pro-plan">
                <div className="card">
                  <div className="card-body text-center">
                    <div className="row">
                      <div className="col-md-12">
                      <div className="title-image">
                          <img src={pro} alt="pro" />
                        </div>
                        <Link to="/auth/register/investor" className="hover-none">
                          <div className="title-text ">
                            180 Pro
                          </div>
                        </Link>
                        
                        <div className="desc">
                          Perfect if you’re a Sophisticated Investor* looking to get early and easy access to the best ASX Placements and IPO’s. <br /><b>Enjoy FREE access to:</b>
                        </div>
                      </div>
                    </div>
                    <div className="row py-4">
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div><b>Everything in 180 Starter, PLUS:</b></div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>ASX Capital Raises</div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>Priority Bidding & Allocations</div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>180 Cornerstone </div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>Share Trade Management Support</div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>Free Share Sales**</div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>3 Months Free Brokerage**</div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>Personalised Trade Analysis & History</div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>Exclusive Investor Events</div>
                        </div>
                      </div>
                      <div className="col-md-12 checklists">
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="r-icon"><CheckCircleOutlined style={{ fontSize: '16px', color: '#0066FF' }} /> &nbsp;</div><div>IOS + Android App so you can bid on the go</div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <Link to="/auth/register/investor">
                          <button type="submit" className="btn btn-dark btn-block">
                            Get Started
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
              <div className="circle-bg">
                  <img src={bgTop} alt="top" />
                </div>
            </div>
            <div className="row">
              <div className="col-md-12 terms pt-4">
                *Verification step at sign-up required. Net assets of at least $2.5 million; or Gross income of at least $250,000 per year for the last two financial years. <br />
                ** Terms & Conditions Apply.
              </div>
            </div>
          </div>
        </div>
        <div className="top-left">
            <img src={bgTop} alt="top" />
          </div>
      </main>
    );
  }
}

export default withRouter(RegisterType);

import React, { Component } from "react";
import Slider from "react-slick";
import "./closed_deal.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import marketLogo from "../../../../assets/images/180M-logo.png";
import { connect } from "react-redux";
import DealActions from "../../../../redux/actions/dealActions";
import { BaseUrl } from "../../../../services/settings";
import { Icon } from "antd";
import Marquee from "react-fast-marquee";

class ClosedDeals extends Component {
  constructor(props) {
    super(props);

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  componentDidMount() {
    this.props.fetchAllClosedDeals();
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      speed: 5000,
      autoplaySpeed: 0,
      pauseOnHover: false,
      cssEase: "linear",
      adaptiveHeight: true,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1700,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
            dots: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
      // className: "closed-slider"
    };

    const { publicClosedDeals } = this.props;

    const DealCard = (props) => {
      const { deal } = props;
      return (
        <div className="col p-1 box d-md-block" style={{ minHeight: "290px", maxWidth: '320px' }}>
          <div className="row cstm-min-height">
            <div className="col-4 pr-0">
              {deal.deal_logo && (
                <img
                  className="img-fluid"
                  style={{
                    // objectFit: "contain",
                    maxWidth: "100%",
                  }}
                  src={`${BaseUrl}/storage/${deal.deal_logo.id}/${deal.deal_logo.file_name}`}
                  alt=""
                />
              )}
            </div>
            <div className="col-8">
              <h3 className="slider_heading">{deal.company_name}</h3>
              <p>{deal.asx_code}</p>
            </div>
          </div>
          <div className="row p-4">
            <div className="w50 border p-2 m-1">
              <div className="box-txt">Last Price</div>
              <div className="box-num">
                <>
                  {deal.current_price !== null ? `${deal.current_price}` : "-"}
                  {deal.difference ? (
                    <div
                      className={`${
                        deal.difference > 0 ? "font-green" : "font-red"
                      }`}
                    >
                      {deal.difference > 0 ? (
                        <Icon
                          type="caret-up"
                          style={{ fontSize: "16px", color: "green" }}
                        />
                      ) : (
                        <Icon
                          type="caret-down"
                          style={{ fontSize: "16px", color: "red" }}
                        />
                      )}
                      {deal.difference ? `${deal.difference}%` : ""}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              </div>
            </div>
            <div className="w50 border p-2 m-1">
              <div className="box-txt">Capital Raise Price</div>
              <div className="box-num">
                {deal.capital_raise !== null ? `${deal.capital_raise}` : "-"}
              </div>
            </div>
            <div className="col border p-2 m-1">
              <div className="box-txt">MARKET CAP</div>
              <div className="box-num">
                {deal.market_capital !== null ? `${deal.market_capital}` : "-"}
              </div>
            </div>
          </div>
        </div>
        // <div className="closed-slider text-center row">
        //   <div className="c-d-block">
        //     <div className="company-logo">
        //       {deal.deal_logo && (
        //         <img
        //           style={{
        //             display: "block",
        //             marginLeft: "auto",
        //             marginRight: "auto",
        //           }}
        //           src={`${BaseUrl}/storage/${deal.deal_logo.id}/${deal.deal_logo.file_name}`}
        //           alt=""
        //         />
        //       )}
        //     </div>
        //     <div className="c-info">
        //       ASX Code: {deal.asx_code}
        //       <br />
        //       Last Price: ${deal.current_price}
        //       <br />
        //       Cap Raise Price: ${deal.capital_raise}
        //       <br />
        //       Market Cap: ${deal.market_capital}
        //     </div>
        //   </div>
        // </div>
      );
    };

    return (
      <div>
        <div class="controls-top">
          {/* <a
            class="btn-floating"
            href="#multi-item-example"
            data-slide="prev"
            onClick={this.previous}
          >
            <i class="fa fa-chevron-left"></i>
          </a>
          <a
            class="btn-floating"
            href="#multi-item-example"
            data-slide="next"
            onClick={this.next}
          >
            <i class="fa fa-chevron-right"></i>
          </a> */}
        </div>
        <Marquee delay={"5"}>
          {publicClosedDeals &&
            publicClosedDeals.length &&
            publicClosedDeals
              .filter((deal) => deal.previous_placements !== "No")
              .map((deal) => <DealCard deal={deal} />)}
        </Marquee>
      </div>
    );
  }
}

const { fetchAllClosedDeals } = DealActions;

const mapDispatchToProps = (dispatch) => ({
  fetchAllClosedDeals: (payload, history) =>
    fetchAllClosedDeals({ dispatch, payload, history }),
});
const mapStateToProps = (state) => {
  return {
    publicClosedDeals: state.deals.publicClosedDeals,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClosedDeals);

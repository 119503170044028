/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";
// import LiveStreamsTable from "./LiveStreamsTable/LiveStreamsTable";
import { connect } from "react-redux";
import liveActions from "../../../../redux/actions/liveActions";
import LiveStreamsTable from "./LiveStreamsTable/LiveStreamsTable";
import { Modal } from "antd";
import moment from "moment";

class LiveStreams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      recordingUrl: null,
    };
  }

  componentDidMount = () => {
    const { getAllLiveStreamsData } = this.props;
    getAllLiveStreamsData({});
  };

  onView = (data) => {
    this.setState({
      isModalVisible: true,
      recordingUrl: data.recording,
    });
  };

  cancelHandler = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  render() {
    const { liveStreams } = this.props;

    const nonLiveOnly = [...liveStreams].filter(
      (liveStream) => !liveStream.live_now 
      &&  moment().diff(moment(`${liveStream.date} ${liveStream.time}`, 'DD/MM/YYYY H:mm:ss')) >= 0
    );

    return (
      <main className="page-main">
        <div className="container">
          {liveStreams.length > 0 ? (
            <div className="page-deals page-all-deals">
              {nonLiveOnly.length > 0 && (
                <div>
                  <div className="title">
                    <div className="row">
                      <div className="col-sm-9">
                        <h1>Previous live streams</h1>
                      </div>
                    </div>
                  </div>
                  <LiveStreamsTable
                    liveStreams={nonLiveOnly}
                    onView={this.onView}
                    history={this.props.history}
                  />
                  <Modal
                    visible={this.state.isModalVisible}
                    footer={null}
                    onCancel={this.cancelHandler}
                    width={620}
                    transitionName=""
                  >
                    <iframe
                      style={{ width: 565, height: 315 }}
                      src={this.state.recordingUrl}
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      allowfullscreen
                    />
                  </Modal>
                </div>
              )}
             
            </div>
          ) : (
            <div className="page-deals page-all-deals">
              <div className="title">
                <div className="row">
                  <div className="col-sm-9">
                    <h1>Live Streams</h1>
                  </div>
                </div>
              </div>
              <h3>No Records Found!</h3>
            </div>
          )}
        </div>
      </main>
    );
  }
}

const { getAllLiveStreams } = liveActions;

const mapDispatchToProps = (dispatch) => ({
  getAllLiveStreamsData: (payload) => getAllLiveStreams(dispatch, payload),
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    liveStreams: state.liveStreams.liveStreams,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveStreams);

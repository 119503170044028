import DirectorService from "../../services/directorService";
import { toast } from "../../components/common/Toast";
import { startButtonLoading, stopButtonLoading } from "./loadingActions";
import { reset } from "redux-form";

const actions = {
  GET_ALL_DIRECTOR_SHARES: "GET_ALL_DIRECTOR_SHARES",
  GET_SINGLE_IPO: "GET_SINGLE_IPO",
  GET_ALL_INDICATIONS: "GET_ALL_INDICATIONS",
  GET_SINGLE_INDICATION: "GET_SINGLE_INDICATION",

  getAllDirectorShresData: (dispatch, payload) => {
    return dispatch({
      type: actions.GET_ALL_DIRECTOR_SHARES,
      payload,
    });
  },

  getAllIndications: (dispatch, payload) => {
    return dispatch({
      type: actions.GET_ALL_INDICATIONS,
      payload,
    });
  },

  getSingleIndication: (dispatch, payload) => {
    return dispatch({
      type: actions.GET_SINGLE_INDICATION,
      payload,
    });
  },

  submitIndication: (dispatch, payload) => {
    dispatch(startButtonLoading);
    DirectorService.createIndication(payload)
      .then((res) => {
        dispatch(stopButtonLoading);
        // dispatch(getAllIndications(dispatch, res.data));
        toast.success(
          "Thank you for submitting your bid, we will contact the lead broker and attempt to get you an allocation."
        );
        dispatch(reset("BidDirectorShares"));
        actions.getAllDirectorSharesIndications(dispatch);
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) toast.error("Something went wrong.");
      });
  },

  updateIndication: (dispatch, payload) => {
    dispatch(startButtonLoading);
    DirectorService.updateShareIndication(payload)
      .then((res) => {
        dispatch(stopButtonLoading);
        toast.success("Indication updated successfully.");
        dispatch(reset("BidDirectorShares"));
        actions.getAllDirectorSharesIndications(dispatch);
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) toast.error("Something went wrong.");
      });
  },

  fetchSingleIndication: (dispatch, payload) =>
    DirectorService.singleIndication(payload)
      .then((res) => {
        dispatch(actions.getSingleIndication(dispatch, res.data));
      })
      .catch((error) => {
        if (error) toast.error("Something went wrong.");
      }),

  getAllDirectorShares: (dispatch, payload) =>
    DirectorService.alldirectorShares(payload)
      .then((res) => {
        dispatch(actions.getAllDirectorShresData(dispatch, res));
      })
      .catch((error) => {
        if (error) toast.error("Something went wrong.");
      }),

  getAllDirectorSharesIndications: (dispatch) =>
    DirectorService.fetchAllIndications()
      .then((res) => {
        dispatch(actions.getAllIndications(dispatch, res.data));
      })
      .catch((error) => {
        if (error) toast.error("Something went wrong.");
      }),
};

export default actions;

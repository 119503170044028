import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class DealRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    let { currentUser } = this.props;
    this.setState({
      user: currentUser,
    });
  }

  render() {
    let { component: Component, ...rest } = this.props;
    let { user } = this.state;
    return (
      <Route
        {...rest}
        render={(props) =>
          !user?.id ? (
            <Component {...props} />
          ) : (
            <Redirect to={"/user/deals"} />
          )
        }
      />
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
});

export default connect(mapStateToProps, null)(DealRoute);

import React, { Component } from "react";
import { connect } from "react-redux";
import dealActions from "../../../../redux/actions/dealActions";
import { withRouter } from "react-router-dom";

class Disclaimer extends Component {

  componentDidMount = () => {
    let {
      getSingleDealData,
      match,
      currentUser,
      history,
    } = this.props;
    const isApproved = currentUser.is_approved === "Approved" ? true : false;
    if (!isApproved) {
      history.push("/user/deals");
    }
    getSingleDealData(match.params.uuid);
  };

  render() {
    let { currentDeal } = this.props;

    return (
      <main class="page-main">
        <div class="bl-about">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="text-about">
                  <h1>{`Investment Opportunity Disclaimer`}</h1>

                  <p>This Investment Opportunity correspondence has been prepared by <strong>{currentDeal && currentDeal.company_name} {currentDeal && currentDeal.category == 180 && currentDeal.asx_code && (<span>- ASX:{currentDeal.asx_code}</span>)}</strong>
                  {currentDeal && currentDeal.broker_name && (<span> (<strong>{currentDeal && currentDeal.broker_name}</strong>)</span>)}
                  <span> </span>the company promoting the Investment Opportunity. The purpose of this Investment Opportunity correspondence is to provide the recipient of this Investment Opportunity correspondence (Recipient) with pertinent information concerning this Investment Opportunity to assist the Recipient to make its own assessment of a possible investment and decide whether to proceed with further investigations. It is supplied to the Recipient on the understanding that it is not to be used for any other purpose.</p>
                  <p>This Investment Opportunity correspondence contains statements, opinions, projections, forecasts, and other material (forward-looking statements), based on various assumptions. Those assumptions may or may not prove to be correct. None of the NCG entities or their respective officers, employees, agents, advisers, makes any representation as to the accuracy or likelihood of fulfilment of the forward-looking statements or any of the assumptions upon which they are based. Past performance is no indication of future performance.</p>
                  <p><u>NO REPRESENTATION OR WARRANTY</u></p>
                  <p>No representation or warranty, express or implied, is made as to the fairness, accuracy, completeness or correctness of the statements, estimates, opinions, conclusions, and other information contained in this investor letter. {currentDeal && currentDeal.company_name} does not have any responsibility to update or correct any information contained in this document. Any forecasts and hypothetical examples are subject to uncertainty and are not guaranteed and should not be relied upon. To the maximum extent permitted by law, {currentDeal && currentDeal.company_name} and its affiliates and related bodies corporate, and their respective officers, directors, employees, agents, and advisors (relevant party), accept no responsibility or liability for any information provided in this Investment Opportunity correspondence, including any forward-looking statements and do not provide any warranty of accuracy or reliability in relation to such information or accept any liability to any person who relies on it.</p>
                  <p>180 Markets Pty Ltd (ACN 638 381 129) (180 Markets) is a Corporate Authorised Representative (#1280199) of Non Correlated Capital Pty Ltd (AFSL 499882 ACN 143 882 562) (NCC). The information contained in this Investment Opportunity was not prepared by NCC but was prepared by other parties. While NCC has no reason to believe that the information is inaccurate, the truth or accuracy of the information contained in the Investment Opportunity cannot be warranted or guaranteed. Anyone viewing this Investment Opportunity must obtain and rely upon their own independent financial, investment, tax and legal advice and inquiries. NCC will scrutinise some deals both external broker co-ordinated as well as non-broker 180 Market/Ignite co-ordinated opportunities at its discretion.</p>
                  <p>180 Markets is authorised to advise (general advice only) and deal in wholesale investors only as that term is defined in the Corporations Act 2001. Under no circumstances does 180 Markets take into account the investment objectives, financial situation or particular needs of any particular person. It is not intended to take the place of professional advice and you should not take action on specific issues in reliance on this information. You are advised to obtain your own investment, financial, tax and legal advice before taking any action. Neither NCC nor any of their related parties, their employees or directors, officers, provide any warranty of accuracy or reliability in relation to such information or accept any liability to any person who relies on it.</p>
                  <p>The information contained in the Investment Opportunity are confidential and may not be reproduced in whole or in part.</p>
                  <p>Any investment involves risk of capital loss in whole or in part, and the risk of loss of future and previous earnings.</p>
                  <p><u>ACCEPTANCE</u></p>
                  <p>Each Recipient of this correspondence or any entity or person receiving this Investment Opportunity represents, warrants, and confirms that it accepts the qualifications, limitations and disclaimers set out in this document.</p>
                  <p>You expressly understand and agree that NCC will not be liable for any direct, indirect, special, incidental, consequential or exemplary damages suffered by you, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible assets (even where NCC has been advised of the possibility of such damages).</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    existingEntity: state.entities.entities,
    currentDeal: state.deals.singleDeal,
    currentUser: state.auth.currentUser,
  };
};

let { singlePublicDeal, getSingleDeal } = dealActions;

const mapDispatchToProps = (dispatch) => ({
  getSingleDealData: (payload) => singlePublicDeal(payload, dispatch),
  getDealStatus: (payload) => getSingleDeal(payload, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Disclaimer));

import SuperFetch from "./superfetch";
import axios from "axios";
import { BaseUrl, configMultipart } from "./settings";
import moment from "moment";

class DealService {
  getAll = async ({ tab, date, type }) => {
    let api = "deals";
    if (tab) {
      api += `?tab=${tab}`;
    }
    if (type) {
      api += `&type=${type}`;
    }
    if (date) {
      api += `&date=${date}`;
    }
    return await SuperFetch.get(api).then((response) => {
      return response;
    });
  };

  getAllPublic = async (formInputs) => {
    return await SuperFetch.get("deals/list", formInputs).then((response) => {
      return response;
    });
  };

  getAllClosedDeals = async (formInputs) => {
    return await SuperFetch.get("closed-deals", formInputs).then((response) => {
      return response;
    });
  };

  getCurrentBids = async (formInputs) => {
    return await SuperFetch.get("current-deals", formInputs).then(
      (response) => {
        return response;
      }
    );
  };

  getSingle = async (formInputs) => {
    return await SuperFetch.get("deals/" + formInputs.id, formInputs).then(
      (response) => {
        return response;
      }
    );
  };

  createDeal = async (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        if (key === "bids_due") {
          bodyFormData.append(
            "bids_due",
            moment(payload[key]).format("DD/MM/YYYY")
          );
        } else if (key === "settlement_date") {
          bodyFormData.append(
            "settlement_date",
            moment(payload[key]).format("DD/MM/YYYY")
          );
        } else {
          bodyFormData.append(key, payload[key]);
        }
      }
    }
    return axios
      .post(`${BaseUrl}frontend/v1/deals`, bodyFormData, configMultipart())
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
  };

  updateDeal = (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        if (key === "bids_due") {
          bodyFormData.append(
            "bids_due",
            moment(payload[key]).format("DD/MM/YYYY")
          );
        } else if (key === "settlement_date") {
          bodyFormData.append(
            "settlement_date",
            moment(payload[key]).format("DD/MM/YYYY")
          );
        } else {
          bodyFormData.append(key, payload[key]);
        }
      } else {
        bodyFormData.append(key, "");
      }
    }

    bodyFormData.append("_method", "PUT");
    return axios
      .post(
        BaseUrl + "frontend/v1/deals/" + payload.id,
        bodyFormData,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  deleteDeal = async (id) => {
    return await SuperFetch.delete("deals/" + id).then((response) => {
      return response;
    });
  };

  changeDealStatus = async (id) => {
    let bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("status", "Closed");
    return await axios
      .post(
        BaseUrl + "frontend/v1/deals/change",
        bodyFormData,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  singleDeal = async (id) => {
    return await SuperFetch.get("deals/" + id).then((response) => {
      return response;
    });
  };

  singlePublicDeal = async (id) => {
    return await SuperFetch.get("deals/detail/" + id).then((response) => {
      return response;
    });
  };

  allocations = async (id) => {
    return await SuperFetch.get("allocations/" + id).then((response) => {
      return response;
    });
  };

  acceptAllocation = async (payload) => {
    return await axios
      .post(
        BaseUrl + `frontend/v1/allocations/${payload.id}/accept`,
        payload
        // configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  declineAllocation = async (payload) => {
    let bodyFormData = new FormData();
    bodyFormData.append("status", payload.status);

    return await axios
      .post(
        BaseUrl + `frontend/v1/allocations/${payload.id}/decline`,
        bodyFormData,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  allAllocations = () => {
    return axios
      .get(BaseUrl + `frontend/v1/allocations`, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  getOptions = () => {
    return axios
      .get(BaseUrl + `frontend/v1/options`, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}
export default new DealService();

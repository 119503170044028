import account from "../../services/accountService";

const actions = {
  FETCH_ALL_COUNTRIES: "FETCH_ALL_COUNTRIES",
  FETCH_ALL_COUNTRIES_ERROR: "FETCH_ALL_COUNTRIES_ERROR",
  FETCH_ALL_COUNTRIES_SUCCESS: "FETCH_ALL_COUNTRIES_SUCCESS",

  fetchCountries: (dispatch) => {
    account
      .getCountries()
      .then(({ data }) => {
        dispatch({ type: actions.FETCH_ALL_COUNTRIES_SUCCESS, payload: data.data });
      })
      .catch(() => {});
  },
};
export default actions;

import axios from "axios";
import { BaseUrl, configMultipart } from "./settings";
import SuperFetch from "./superfetch";

class BidService {
  getAllBids = async (formInputs) => {
    return await SuperFetch.get("my-deals/bids", formInputs).then(
      (response) => {
        return response;
      }
    );
  };

  createBid = (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        bodyFormData.append(key, payload[key]);
      }
    }

    return axios
      .post(BaseUrl + "frontend/v1/bids", bodyFormData, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  createIndication = (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        bodyFormData.append(key, payload[key]);
      }
    }

    return axios
      .post(
        BaseUrl + "frontend/v1/indications",
        bodyFormData,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  updateBid = (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        bodyFormData.append(key, payload[key]);
      }
    }

    bodyFormData.append("_method", "PUT");

    return axios
      .post(
        BaseUrl + "frontend/v1/bids/" + payload.bidId,
        bodyFormData,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  updateIndication = (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        bodyFormData.append(key, payload[key]);
      }
    }

    bodyFormData.append("_method", "PUT");

    return axios
      .post(
        BaseUrl + "frontend/v1/indications/" + payload.bidId,
        bodyFormData,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  deleteBid = (payload) => {
    return axios
      .delete(BaseUrl + "frontend/v1/bids/" + payload.bidId, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export default new BidService();

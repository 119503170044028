import React, { Component } from "react";
import { connect } from "react-redux";
import WebinarAction from "../../../redux/actions/webinarActions";
import { sortWebinarsAccToDate } from "../../../utils/constants";

class Webinars extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { getWebinars } = this.props;
    getWebinars();
  };

  render() {
    const { webinars } = this.props;

    return (
      <main className="page-main page-deals page-all-deals">
        <div className="container">
          <div className="page-deals page-all-deals">
            <div className="title">
              <div className="row">
                <div className="col-sm-9">
                  <h1 style={{ marginBottom: 30 }}>Weekly Wrap</h1>
                </div>
              </div>
              {webinars &&
                sortWebinarsAccToDate(webinars)
                  .filter((webinar) => webinar.category === "Webnair")
                  .map((webinar) => (
                    <>
                      <h3>{webinar.name}</h3>
                      <iframe
                        style={{ width: 560, height: 315 }}
                        src={webinar.video_url}
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowfullscreen
                      />
                      <br /> <br />
                    </>
                  ))}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    webinars: state.webinars.webinars,
  };
};

const { getAllWebinars } = WebinarAction;

const mapDispatchToProps = (dispatch) => ({
  getWebinars: () => getAllWebinars(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Webinars);

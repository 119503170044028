import React, { Component } from "react";
import { LogoCompany, FileUpload } from "../../../common/Graphics";
import DealsViewForm from "./DealsViewForm/DealsViewForm";
import { connect } from "react-redux";
import EntityActions from "../../../../redux/actions/entityActions";
import dealActions from "../../../../redux/actions/dealActions";
import { withRouter } from "react-router-dom";
import BidActions from "../../../../redux/actions/bids.Actions";
import { BaseUrl } from "../../../../services/settings";
import moment from "moment";

import Confirm from "../../../common/Confirm";
import ModalComponent from "../../../common/Modal";
import { renderData } from "../../../common/CommonMethods";
const queryString = require("query-string");

class DealView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectEntityOptions: [],
      open: false,
      selectedBid: null,
    };
  }

  componentDidMount = () => {
    let {
      getSingleDealData,
      fetchAllEntities,
      match,
      currentUser,
      getDealStatus,
      bidStatus,
    } = this.props;
    if (currentUser && localStorage.getItem("auth_token")) {
      fetchAllEntities();
      getDealStatus(match.params.uuid);
    }
    getSingleDealData(match.params.uuid);
    console.log(bidStatus);
  };

  renderIndividualName = (entity) => {
    let name = "";
    for (let key in entity.entity_individuals) {
      if (name.length > 0) {
        return (name +=
          " " +
          "&" +
          " " +
          entity.entity_individuals[key].given_name +
          " " +
          entity.entity_individuals[key].sur_name +
          " " +
          `${
            entity.entity_individuals[key].nickname
              ? `(${entity.entity_individuals[key].nickname})`
              : ""
          }`);
      }

      name +=
        entity.entity_individuals[key].given_name +
        " " +
        entity.entity_individuals[key].sur_name +
        " " +
        `${
          entity.entity_individuals[key].nickname
            ? `(${entity.entity_individuals[key].nickname})`
            : ""
        }`;
    }

    return name;
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.existingEntity &&
      this.props.existingEntity !== nextProps.existingEntity &&
      nextProps.currentUser.id
    ) {
      this.setState({
        selectEntityOptions:
          nextProps.existingEntity &&
          nextProps.existingEntity.map((entity) => {
            if (entity.entity_company !== null)
              return {
                value: entity.id,
                pid: entity.pid,
                text: `${entity.broker_name} - ${entity.entity_company.name} ${
                  entity.entity_company.nickname
                    ? `(${entity.entity_company.nickname})`
                    : ""
                }`,
                //value: entity.id,
              };
            else if (entity.entity_trust !== null)
              return {
                value: entity.id,
                pid: entity.pid,
                text: `${entity.broker_name} - ${entity.entity_trust.name} ${
                  entity.entity_trust.nickname
                    ? `(${entity.entity_trust.nickname})`
                    : ""
                }`,
                //value: entity.id,
              };
            else if (entity.entity_individual !== null)
              return {
                value: entity.id,
                pid: entity.pid,
                text: `${entity.broker_name} - ${this.renderIndividualName(
                  entity
                )}`,
                //value: entity.id,
              };
          }),
      });
    }
  };

  cancelBidHanler = (id) => {
    this.setState({ open: false });
    let { cancelBid, history } = this.props;
    cancelBid({ bidId: id }, history);
  };

  onSubmitBid = async (values) => {
    let {
      updateBidSubmit,
      currentDeal,
      bidStatus,
      currentUser,
      updateIndicationSubmit,
      history,
    } = this.props;
    if (bidStatus.myBid) {
      await updateBidSubmit(
        {
          ...values,
          deal_id: currentDeal.id,
          bidId: bidStatus.myBid.uuid,
        },
        history
      );
    } else if (bidStatus.myIndication) {
      await updateIndicationSubmit(
        {
          ...values,
          deal_id: currentDeal.id,
          investor_id: currentUser.id,
          bidId: bidStatus.myIndication.uuid,
        },
        history
      );
    }
  };

  entityNotUsedYet = (selectedbid) => {
    return [...this.state.selectEntityOptions].filter((entity) => {
      return !(
        this.props.bidStatus.myBids &&
        this.props.bidStatus.myBids.some(
          (bid) =>
            selectedbid &&
            bid.entity_id === entity.value &&
            bid.id !== selectedbid.id
        )
      );
    });
  };

  render() {
    let { currentDeal, currentUser, bidStatus, location } = this.props;
    const parsed = queryString.parse(location.search);

    const selectedBid =
      bidStatus &&
      bidStatus.myBids &&
      this.props.bidStatus.myBids.length > 0 &&
      this.props.bidStatus.myBids.find(
        (bid) => bid.uuid === this.props.match.params.bidid
      );
    return (
      <main className="page-deal-add-doc">
        <div className="md-top-deal">
          <div className="container">
            <div className="deal-inner">
              <div className="row">
                <div className="col-md-2">
                  <div className="logo">
                    <div className="inner">
                      <img
                        src={
                          currentDeal &&
                          currentDeal.deal_logo &&
                          `${BaseUrl}/storage/${currentDeal.deal_logo.id}/${currentDeal.deal_logo.file_name}`
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="information">
                    <h1>{currentDeal && currentDeal.company_name}</h1>
                    <p className="sub-title">
                      ASX:
                      {currentDeal &&
                        currentDeal.asx_code &&
                        currentDeal.asx_code}
                    </p>
                    <div className="detail">
                      <div className="row">
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Last Price</p>
                            <p className="number">
                              $
                              {currentDeal &&
                                currentDeal.last_price &&
                                currentDeal.last_price}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Capital Raise Price</p>
                            <p className="number">
                              {currentDeal &&
                                currentDeal.capital_raise &&
                                `$${currentDeal.capital_raise}`}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Raise Amount</p>
                            <p className="number">
                              {currentDeal &&
                                currentDeal.raise_amount &&
                                `${renderData(currentDeal.raise_amount)}`}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Discount </p>
                            <p className="number">
                              {currentDeal && currentDeal.discount
                                ? currentDeal.discount + " %"
                                : ""}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inner">
                            <p className="text">Market Capitalisation</p>
                            <p className="price">
                              {currentDeal &&
                                currentDeal.market_capital &&
                                currentDeal.market_capital}
                            </p>
                          </div>
                        </div>
                        {currentDeal && currentDeal.free_option && currentDeal.dividend_option && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Free Options</p>
                              <p className="price">
                                {`${currentDeal.dividend_option}:${currentDeal.free_option} ${currentDeal?.option_details}`}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md-tabs tab-deal-add-doc">
          <div className="container">
            <div className="md-tabs tab-deal-add-doc">
              <div className="container">
                <div className="row">
                  <div className="col-md-7">
                    <p>
                      <b>Bids Due: </b>{" "}
                      {currentDeal &&
                        currentDeal.bids_due &&
                        moment(currentDeal.bids_due, "DD/MM/YYYY HH:mm").format(
                          "MMM. DD, YYYY hh:mm A"
                        )}
                    </p>
                    <p>
                      <b>Settlement Date: </b>{" "}
                      {currentDeal &&
                        currentDeal.settlement_date &&
                        moment(
                          currentDeal && currentDeal.settlement_date,
                          "DD/MM/YYYY"
                        ).format("MMM. DD, YYYY")}
                    </p>

                    <div
                      dangerouslySetInnerHTML={{
                        __html: currentDeal && currentDeal.details,
                      }}
                    />
                    <p>{}</p>
                    <div className="row mt-5">
                      <div className="row mt-5">
                        {currentDeal && currentDeal.information_overview && (
                          <div className="col-md-12 mb-4">
                            <div className="smd-file-detail clearfix">
                              <div className="file-image">
                                <img src={FileUpload} alt="" width={42} />
                              </div>
                              <div className="file-meta">
                                <span className="name">
                                  Company Presentation
                                </span>
                                <div className="view clearfix">
                                  <a
                                    target="_blank"
                                    href={`${BaseUrl}storage/${currentDeal.information_overview.id}/${currentDeal.information_overview.file_name}`}
                                  >
                                    View Document
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.props.currentDeal && (
                          <div className="col-md-12 mb-4">
                            {currentDeal && currentDeal.term_sheets && (
                              <div className="smd-file-detail clearfix">
                                <div className="file-image">
                                  <img src={FileUpload} alt="" width={42} />
                                </div>
                                <div className="file-meta">
                                  <span className="name">Term Sheet</span>
                                  <div className="view clearfix">
                                    <a
                                      target="_blank"
                                      href={`${BaseUrl}storage/${currentDeal.term_sheets.id}/${currentDeal.term_sheets.file_name}`}
                                    >
                                      View document
                                    </a>
                                  </div>
                                  <ModalComponent
                                    fileName={currentDeal.term_sheets.file_name}
                                    modalOpen={this.state.modalOpen}
                                    handleClose={this.modalClose}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {currentDeal && currentDeal.additional_document && (
                          <div className="col-md-12 mb-4">
                            <div className="smd-file-detail clearfix">
                              <div className="file-image">
                                <img src={FileUpload} alt="" width={42} />
                              </div>
                              <div className="file-meta">
                                <span className="name">
                                  {currentDeal.additional_document_name}
                                </span>
                                <div className="view clearfix">
                                  <a
                                    target="_blank"
                                    href={`${BaseUrl}storage/${currentDeal.additional_document.id}/${currentDeal.additional_document.file_name}`}
                                  >
                                    View Document
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <p style={{ marginTop: 30 }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: currentDeal.details,
                          }}
                        />

                        <br />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: currentDeal.helpful_links,
                          }}
                        />

                        <br />
                        {currentDeal && currentDeal.type === "180" && (
                          <i>
                            180 Markets PTY LTD reserves the right to close this
                            deal early.
                            <br />
                            180 Markets PTY LTD or its associated entities may
                            invest in this deal.
                          </i>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5">
                    {currentUser.role &&
                    currentUser.role.some(
                      (role) => role.title === "Investor"
                    ) &&
                    currentUser.is_approved === "Approved" &&
                    bidStatus ? (
                      <div className="md-deal-form">
                        <div className="form-normal">
                          <DealsViewForm
                            initialValues={
                              selectedBid
                                ? {
                                    entity_id: selectedBid.entity_id,
                                    share_count: selectedBid.share_count,
                                    investment_amount:
                                      selectedBid.investment_amount,
                                  }
                                : {
                                    investment_amount:
                                      bidStatus.myIndication &&
                                      bidStatus.myIndication.investment_amount,
                                  }
                            }
                            currentUser={currentUser}
                            onSubmit={this.onSubmitBid}
                            selectEntityOptions={this.entityNotUsedYet(
                              selectedBid
                            )}
                            capRaisePrice={
                              currentDeal && currentDeal.capital_raise
                            }
                            currentDeal={currentDeal}
                          />
                        </div>
                      </div>
                    ) : null}
                    {currentUser.role &&
                      currentUser.role.some(
                        (role) => role.title === "Investor"
                      ) &&
                      currentUser.is_approved === "Pending" && (
                        <div className="md-deal-form">
                          <div className="form-normal dealBidMessage">
                            <div className="deal-form-content">
                              <p className="bidMessage">
                                Complete your profile to bid for deal.
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    {currentUser.role &&
                      currentUser.role.some(
                        (role) => role.title !== "Investor"
                      ) && (
                        <div className="md-deal-form">
                          <div className="form-normal dealBidMessage">
                            <div className="deal-form-content">
                              <p className="bidMessage">
                                Register as an investor to bid for deal.
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    {!currentUser ||
                      (!localStorage.getItem("auth_token") && (
                        <div className="md-deal-form">
                          <div className="form-normal dealBidMessage">
                            <div className="deal-form-content">
                              <p className="bidMessage">
                                Login to bid for deal.
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    existingEntity: state.entities.entities,
    currentDeal: state.deals.singleDeal,
    currentUser: state.auth.currentUser,
    bidStatus: state.deals.bidStatus,
  };
};

const { fetchEntities } = EntityActions;
let { singlePublicDeal, getSingleDeal } = dealActions;
let { updateBid, deleteBid, updateIndication } = BidActions;

const mapDispatchToProps = (dispatch) => ({
  fetchAllEntities: (payload, history) =>
    fetchEntities({ dispatch, payload, history }),
  getSingleDealData: (payload) => singlePublicDeal(payload, dispatch),
  getDealStatus: (payload) => getSingleDeal(payload, dispatch),
  updateBidSubmit: (payload, history) => updateBid(payload, dispatch, history),
  cancelBid: (payload, history) => deleteBid(payload, dispatch, history),
  updateIndicationSubmit: (payload, history) =>
    updateIndication(payload, dispatch, history),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DealView));

import React, { Component } from "react";
import { connect } from "react-redux";
import { updateSinglePreference } from "../../../redux/actions/profileActions";
import InvestorNotifications from "../../forms/fields/InvestorNotifications";
import ButtonLoader from "../../common/ButtonLoader";

class InvestorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      success: false,
      notifications: [
        {
          key: "eachindividualdeal",
          label: "Alert me for each individual deal",
          value: "Each individual deal",
          current: true,
        },
        {
          key: "onceaday",
          label: "Please alert me once a day of new deals",
          value: "Once a day",
          current: false,
        },
        {
          key: "newsletter",
          label: "I only want to receive the weekly newsletter",
          value: "Weekly newsletter",
          current: false,
        },
        {
          key: "no",
          label: "I dont want any emails at all",
          value: "No Emails",
          current: false,
        },
      ],
    };
  }

  componentDidMount() {}

  onSubmit = () => {
    this.setState({
      processing: true,
    });
    const { updateSinglePreference, match } = this.props;
    updateSinglePreference({
      notifications: this.state.notifications.find((n) => n.current).value,
      uuid: match.params.uuid,
    });
    this.setState({
      success: true,
    });
  };

  handleNotification = (event, value) => {
    let newNotifications = this.state.notifications.map((item) => {
      if (item.value === value) item.current = true;
      else item.current = false;

      return item;
    });

    this.setState({
      notifications: newNotifications,
    });
  };

  render() {
    return (
      <div className="page-create-account investor-account investor-account-01">
        {!this.state.success ? (
          <div className="form-normal form-create-account p-100 ">
            <h3>Select your setting</h3>
            <InvestorNotifications
              label=""
              data={this.state.notifications}
              handleChange={this.handleNotification}
            />
            <ButtonLoader
              onClick={() => this.onSubmit()}
              className="btn btn-primary btn-medium mt-4"
              disabled={this.state.processing}
              loading={this.state.loading}
            >
              SUBMIT
            </ButtonLoader>
          </div>
        ) : (
          <div className="form-normal form-create-account p-100 text-center">
            <h5>Notification setting changed successfully</h5>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateSinglePreference: (payload) =>
    updateSinglePreference(dispatch, payload),
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorProfile);

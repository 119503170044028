import actionTypes from "../actions/liveActions";

const initialState = {
  liveStreams: [],
  loading: false,
  selected: {},
  current: null,
};

const liveStreams = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.GET_ALL_LIVE_STREAMS:
      return {
        ...state,
        liveStreams: payload.data.data,
      };
    
    case actionTypes.FETCH_LIVE_STREAM:
      return {
        ...state,
        selected: { ...payload },
      };
    
    case actionTypes.CURRENT_LIVE_STREAM:
      return {
        ...state,
        current: payload && payload.id ? { ...payload } : null,
      };
    
    case actionTypes.CURRENT_NOTIFICATION:
      return {
        ...state,
        currentNotification: payload && payload.id ? { ...payload } : null,
      };

    default:
      return state;
  }
};

export default liveStreams;

import SuperFetch from "./superfetch";

class FaqService {
  getAll = async (formInputs) => {
    return await SuperFetch.get("faqs", formInputs).then((response) => {
      return response;
    });
  };
}
export default new FaqService();

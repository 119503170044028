import axios from "axios";
import { BaseUrl, configMultipart } from "./settings";

class WebinarsService {
  getWebinars = () => {
    return axios
      .get(BaseUrl + "frontend/v1/webnairs", configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export default new WebinarsService();

//App
const APP_LOADING_STARTS = "APP_LOADING_STARTS";
const APP_LOADING_ENDS = "APP_LOADING_ENDS";
const STOP_LOADER = "STOP_LOADER";
const SHOW_USER_MENU = "SHOW_USER_MENU";
const START_LOADER = "START_LOADER";
const HIDE_USER_MENU = "HIDE_USER_MENU";

//Entry Form
const REGISTRATION_PROCESS_STARTED = "REGISTRATION_PROCESS_STARTED";
const REGISTRATION_PROCESS_ENDED = "REGISTRATION_PROCESS_ENDED";
const REGISTRATION_VALIDATION_FAILED = "REGISTRATION_VALIDATION_FAILED";
const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
const REGISTRATION_FAILED = "REGISTRATION_FAILED";
const REGISTRATION_TYPE = "REGISTRATION_TYPE";
const CLEAR_REGISTRATON_ALERTS = "CLEAR_REGISTRATON_ALERTS";

//Auth
const USER_TOKEN_DATA = "USER_TOKEN_DATA";
const UPDATE_AUTH = "UPDATE_AUTH";
const USER_LOGOUT = "USER_LOGOUT";
const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
const USER_LOGIN_PROCESS_STARTED = "USER_LOGIN_PROCESS_STARTED";
const USER_LOGIN_PROCESS_ENDED = "USER_LOGIN_PROCESS_ENDED";
const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";
const USER_LOGIN_NOTIFICATION = "USER_LOGIN_NOTIFICATION";
const CLEAR_LOGIN_NOTIFICATION = "CLEAR_LOGIN_NOTIFICATION";
const CLEAR_LOGIN_WARNING = "CLEAR_LOGIN_WARNING";
const REGISTRATION_ERROR_MESSAGE = "REGISTRATION_ERROR_MESSAGE";
const USER_LOGIN_PENDING = "USER_LOGIN_PENDING";
const CHANGE_EMAIL_PENDING_STATE = "CHANGE_EMAIL_PENDING_STATE";

//Profile
const START_PROFILE_UPDATE = "START_PROFILE_UPDATE";
const END_PROFILE_UPDATE = "END_PROFILE_UPDATE";
const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
const PROFILE_UPDATE_FAILED = "PROFILE_UPDATE_FAILED";
const CLEAR_PROFILE_ALERT = "CLEAR_PROFILE_ALERT";

//preferences
const START_PREFERENCE_UPDATE = "START_PREFERENCE_UPDATE";
const END_PREFERENCE_UPDATE = "END_PREFERENCE_UPDATE";
const PREFERENCE_UPDATE_SUCCESS = "PREFERENCE_UPDATE_SUCCESS";
const PREFERENCE_UPDATE_FAILED = "PREFERENCE_UPDATE_FAILED";
const CLEAR_PREFERENCE_ALERT = "CLEAR_PREFERENCE_ALERT";

//Loading
const START_BUTTON_LOADING = "START_BUTTON_LOADING";
const STOP_BUTTON_LOADING = "STOP_BUTTON_LOADING";
const PROFILE_STATUS = "PROFILE_STATUS";

const AUTH_LOADING_START = "AUTH_LOADING_START";
const AUTH_LOADING_ENDS = "AUTH_AUTH_LOADING_ENDSLOADING_START";

export {
  START_LOADER,
  STOP_LOADER,
  USER_TOKEN_DATA,
  CLEAR_PROFILE_ALERT,
  CLEAR_PREFERENCE_ALERT,
  START_PREFERENCE_UPDATE,
  END_PREFERENCE_UPDATE,
  PREFERENCE_UPDATE_SUCCESS,
  PREFERENCE_UPDATE_FAILED,
  APP_LOADING_STARTS,
  APP_LOADING_ENDS,
  REGISTRATION_PROCESS_STARTED,
  REGISTRATION_VALIDATION_FAILED,
  REGISTRATION_PROCESS_ENDED,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILED,
  CLEAR_REGISTRATON_ALERTS,
  REGISTRATION_TYPE,
  USER_LOGOUT,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  USER_LOGIN_PROCESS_ENDED,
  USER_LOGIN_PROCESS_STARTED,
  USER_LOGIN_NOTIFICATION,
  CLEAR_LOGIN_WARNING,
  CLEAR_LOGIN_NOTIFICATION,
  UPDATE_AUTH,
  START_PROFILE_UPDATE,
  END_PROFILE_UPDATE,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILED,
  HIDE_USER_MENU,
  SHOW_USER_MENU,
  REGISTRATION_ERROR_MESSAGE,
  USER_LOGIN_PENDING,
  CHANGE_EMAIL_PENDING_STATE,
  START_BUTTON_LOADING,
  STOP_BUTTON_LOADING,
  PROFILE_STATUS,
  AUTH_LOADING_ENDS,
  AUTH_LOADING_START
};

/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";
// import LiveStreamsTable from "./LiveStreamsTable/LiveStreamsTable";
import { connect } from "react-redux";
import liveActions from "../../../../redux/actions/liveActions";
import LiveStreamsTable from "./LiveStreamsTable/LiveStreamsTable";
import { Modal } from "antd";
import { LiveNow } from "./LiveNow";
import moment from "moment";

class LiveStreams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      recordingUrl: null,
    };
  }

  componentDidMount = () => {
    const { getAllLiveStreamsData } = this.props;
    getAllLiveStreamsData({});
  };

  onView = (data) => {
    this.setState({
      isModalVisible: true,
      recordingUrl: data.recording,
    });
  };

  cancelHandler = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  render() {
    const { liveStreams } = this.props;

    const liveOnly = [...liveStreams].filter(
      (liveStream) => liveStream.live_now
    );
    const nonLiveOnly = [...liveStreams].filter(
      (liveStream) => !liveStream.live_now
    );

    const nextUpcoming = nonLiveOnly
    .filter(live => {
      const date = moment(`${live.date} ${live.time}`, "DD/MM/YYYY H:mm:ss").format('YYYY-MM-DD H:mm:ss');
      return new Date().getTime() <= new Date(date).getTime()
    })
    .sort((a, b) => {
      const adate = moment(`${a.date} ${a.time}`, "DD/MM/YYYY H:mm:ss").format('YYYY-MM-DD H:mm:ss');
      const bdate = moment(`${b.date} ${b.time}`, "DD/MM/YYYY H:mm:ss").format('YYYY-MM-DD H:mm:ss');
      return  new Date(bdate).getTime() - new Date(adate).getTime()
    });
    if ((!liveOnly || liveOnly.length === 0) && (!nextUpcoming && nextUpcoming.length === 0)){
      return (<></>);
    }

    return (
      <main className={liveOnly && liveOnly.length > 0 ? 'page-main': ''}>
        {liveOnly && liveOnly.length > 0 && <div style={{ position: "relative" }}>
          <div className="live-top-section">
            <div
              style={{ color: "#fff", fontSize: "20px", paddingTop: "40px" }}
            >
              Never miss a beat.
            </div>
            <h1 className="middleTitle" style={{paddingTop: '10px'}}>
              Get the latest, as it happens, LIVE.
            </h1>
          </div>
          <div className="live-bottom-section">
            <div className="striped-line"></div>
          </div>
          <div
            className="live-window-absolute"
          >
            <div
              className="live-window"
            >
              {
                liveOnly.map((liveStream, index) => (
                  <div className="livestream-container">
                  {
                    index === 0 && <div
                    dangerouslySetInnerHTML={{
                      __html: liveStream && liveStream.embed,
                    }}
                    />
                  }
                  </div>
                ))}
            </div>
          </div>
        </div>}

        {nextUpcoming && nextUpcoming.length > 0 && <LiveNow liveStream={nextUpcoming ? nextUpcoming[0] : null} />}
        
        <div className="container" style={{display: 'none'}}>
          {liveStreams.length > 0 ? (
            <div className="page-deals page-all-deals">
              {nonLiveOnly.length > 0 && (
                <div>
                  <div className="title">
                    <div className="row">
                      <div className="col-sm-9">
                        <h1>Live Streams</h1>
                      </div>
                    </div>
                  </div>
                  <LiveStreamsTable
                    liveStreams={nonLiveOnly}
                    onView={this.onView}
                    history={this.props.history}
                  />
                  <Modal
                    visible={this.state.isModalVisible}
                    footer={null}
                    onCancel={this.cancelHandler}
                    width={620}
                    transitionName=""
                  >
                    <iframe
                      style={{ width: 565, height: 315 }}
                      src={this.state.recordingUrl}
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      allowfullscreen
                    />
                  </Modal>
                </div>
              )}
             
            </div>
          ) : (
            <div className="page-deals page-all-deals">
              <div className="title">
                <div className="row">
                  <div className="col-sm-9">
                    <h1>Live Streams</h1>
                  </div>
                </div>
              </div>
              <h3>No Records Found!</h3>
            </div>
          )}
        </div>
        <h1 className="middleTitle" style={{paddingTop: '0px'}}>Bid early, get allocation priority</h1>
      </main>
    );
  }
}

const { getAllLiveStreams } = liveActions;

const mapDispatchToProps = (dispatch) => ({
  getAllLiveStreamsData: (payload) => getAllLiveStreams(dispatch, payload),
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    liveStreams: state.liveStreams.liveStreams,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveStreams);

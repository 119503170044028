import SuperFetch from './superfetch';

class ArticleService {

  fetchRequest = (url) => fetch(`${url}`, {
    method: 'GET',
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())

    .catch((error) => {
      return { error: "server error" };
    });

  getAll = async ({url, queryString} = {}) => {
    if (url){
      return await this.fetchRequest(url).then(response => {
        return response;
      });
    }
    let rUrl = `articles`;
    if (queryString){
      rUrl = `articles?`+queryString;
    }
    return await SuperFetch.get(rUrl).then(response => {
      return response;
    });
  };

  getSingle = async ({slug}) => {
    return await SuperFetch.get(`articles/by-slug/${slug}`).then(response => {
      return response;
    });
  };

}
export default new ArticleService();
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../../../../services/settings";
import BidActions from "../../../../../redux/actions/bids.Actions";
import { withRouter, Link } from "react-router-dom";
import ViewBids from "../../../../common/ViewBids";
import Confirm from "../../../../common/Confirm";
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import IpoActions from "../../../../../redux/actions/ipoActions";
import {
  renderData,
  renderRefreshText,
  customStyles,
} from "../../../../common/CommonMethods";

class CurrentIpos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      open_view: false,
      data: [],
      getBids: "",
      ipoId: "",
      bids: [],
      openIpo: false,
    };
  }

  onView = (uuid) => {
    const { history } = this.props;

    history.push(`/user/ipo-view/${uuid}`);
  };

  getBidsCount = (dealId) => {
    let res = 0;
    if (this.props.bids) {
      for (let item of this.props.bids) {
        if (item.deal_id === dealId) res++;
      }
    }
    return res;
  };

  openBid = (id) => {
    this.setState({ open_view: true, view_dealId: id });
  };

  openIpoBid = (bids) => {
    this.setState({ openIpo: true, bids: bids });
  };

  open = (id) => {
    this.setState({ open: true, ipoId: id });
  };

  close = () => {
    this.setState({ open: false, open_view: false, openIpo: false });
  };

  deleteSelectedIpo = async (id) => {
    let { deleteIpo, getAllIposData } = this.props;
    await deleteIpo(id);

    getAllIposData();
    this.setState({ open: false });
  };

  closedSelectedIpo = async (id) => {
    let { closeIpo, getAllIposData } = this.props;
    await closeIpo({ id, status: "Closed" });

    getAllIposData();
    this.setState({ open: false });
  };

  render() {
    const handleChange = () => {};
    const columns = [
      {
        name: "",
        selector: "deal_logo",
        sortable: false,
        // style: { minWidth: "120px" },
        cell: (row) => (
          <>
            <div className="company-logo">
              {row.logo && (
                <img
                  style={{
                    maxWidth: "70px",
                    maxHeight: "70px",
                    float: "left",
                  }}
                  src={`${BaseUrl}/storage/${row.logo.id}/${row.logo.file_name}`}
                  alt=""
                />
              )}
            </div>
          </>
        ),
      },
      {
        name: "Company",
        selector: "company_name",
        sortable: false,
        style: { minWidth: "130px" },
        className: "company-name",
        cell: (row) => (
          <>
            <div>
              <span>{row.company_name} </span>
            </div>
          </>
        ),
      },
      {
        name: "ASX code",
        selector: "asx_code",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.asx_code && `${row.asx_code}`}</div>,
      },
      {
        name: "Raise Amount",
        selector: "raise_amount",
        sortable: false,
        left: true,
        cell: (row) => (
          <div> {row.raise_amount ? renderData(row.raise_amount) : ""}</div>
        ),
      },

      {
        name: "Market Capitalisation",
        selector: "price",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.market_cap && `${renderData(row.market_cap)}`}</div>,
      },
      {
        name: "Proposed List Date ",
        selector: "total_price ",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.listing_date && row.listing_date}</div>,
      },
      ,
      {
        name: "View Bids",
        sortable: false,
        center: true,
        overflowY: true,
        cell: (row) => (
          <div>
            <span
              style={{
                display: "inline-block",
                width: "33px",
                height: "33px",
                textAlign: "center",
                backgroundColor: "#2185d0",
                color: "#fff",
                borderRadius: "18px",
                padding: "5px 5px",
                cursor: "pointer",
              }}
              onClick={() => this.openIpoBid(row.bids)}
            >
              {row.bids && row.bids.length}
            </span>
          </div>
        ),
      },
      {
        name: "Actions",
        sortable: false,
        center: true,
        overflowY: true,

        cell: (row) =>
          this.props.currentDeals ? (
            <div className="btn-group">
              <Link to={`/user/update-ipo/${row.uuid}`}>
                <Icon
                  style={{ cursor: "pointer" }}
                  circular
                  inverted
                  title="Edit"
                  color="blue"
                  name="edit"
                />
              </Link>
              <Icon
                style={{ cursor: "pointer" }}
                title="Close Deal"
                circular
                inverted
                color="blue"
                name="remove circle"
                onClick={() => this.open(row.id)}
              />
              {this.state.open && (
                <Confirm
                  message="Are you sure you want to close this IPO?"
                  closeButtonText="Cancel"
                  deleteButtonText="Yes, Close"
                  show={this.state.open}
                  handleDelete={() => this.closedSelectedIpo(this.state.ipoId)}
                  handleClose={this.close}
                />
              )}
            </div>
          ) : (
            <div className="btn-group">
              <Icon
                style={{ cursor: "pointer" }}
                title="Close Deal"
                circular
                inverted
                color="blue"
                name="remove circle"
                onClick={() => this.open(row.id)}
              />
              {this.state.open && (
                <Confirm
                  message="Are you sure you want to delete this IPO?"
                  closeButtonText="Cancel"
                  deleteButtonText="Yes, Delete"
                  show={this.state.open}
                  handleDelete={() => this.deleteSelectedIpo(this.state.ipoId)}
                  handleClose={this.close}
                />
              )}
            </div>
          ),
      },
    ];

    return (
      <div className="md-table-normal table-responsive">
        <DataTable
          noHeader
          highlightOnHover
          // expandableRows
          columns={columns}
          data={this.props.deals}
          // selectableRows
          // sortIcon={<span class="ico"></span>}
          onSelectedRowsChange={handleChange}
          customStyles={customStyles}
        />
        {this.state.open_view ? (
          <ViewBids
            show={this.state.open_view}
            handleClose={this.close}
            bids={this.props.bids}
            deal_id={this.state.view_dealId}
          />
        ) : null}
        {this.state.openIpo && (
          <ViewBids
            show={this.state.openIpo}
            handleClose={this.close}
            bids={this.state.bids}
            // deal_id={this.state.view_dealId}
          />
        )}
      </div>
    );
  }
}

const { getAllBids } = BidActions;
const { deleteIpoById, closeSelectedIpo } = IpoActions;

const mapDispatchToProps = (dispatch) => ({
  getAllBids: (payload) => getAllBids({ payload, dispatch }),
  deleteIpo: (id, history) => deleteIpoById(id, history, dispatch),
  closeIpo: (id, history) => closeSelectedIpo(id, history, dispatch),
});

const mapStateToProps = (state) => {
  return {
    bids: state.bids.bids,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CurrentIpos)
);

import React from "react";
import NumberFormat from "react-number-format";
var moment = require("moment");

export const MIN_BID_AMOUNT = process.env.REACT_APP_MIN_BID_AMOUNT;

export const entityTypes = {
  company: "Company",
  trust: "Trust / Superannuation Fund",
  individual: "Individual / Joint",
};

export const dateFormatHandler = (dateValue) => {
  var date = moment(dateValue, "DD/MM/YYYY").format("YYYY-MM-DD");
  return new Date(date);
};

export const numberFormatter = (number, prefix, toFix) => {
  return (
    <NumberFormat
      value={toFix ? parseFloat(number).toFixed(2) : parseFloat(number)}
      displayType={"text"}
      thousandSeparator={true}
      prefix={prefix}
      renderText={(value) => <span>{value}</span>}
    />
  );
};

export const timeFormatToSort = (date) => {
  if (!date) {
    return;
  }
  const time = date.split("/");

  return moment(`${time[1] + "/" + time[0] + "/" + time[2]}`).unix();
};

export const sortDealsAccToDueDate = (deals) => {
  return deals
    .sort((b, a) => {
      let firstTime = timeFormatToSort(a.bids_due);
      let secondTime = timeFormatToSort(b.bids_due);
      firstTime = !isNaN(firstTime) ? firstTime : "";
      secondTime = !isNaN(secondTime) ? secondTime : "";

      return secondTime - firstTime;
    })
    .map((deal) => {
      return {
        ...deal,
        bids_due:
          deal.bids_due &&
          moment(deal.bids_due, "DD/MM/YYYY").format("MMM. DD, YYYY"),
      };
    });
};

export const sortIposAccToListingDate = (ipos) => {
  return ipos
    .sort((b, a) => {
      let firstTime = timeFormatToSort(a.listing_date);
      let secondTime = timeFormatToSort(b.listing_date);
      firstTime = !isNaN(firstTime) ? firstTime : "";
      secondTime = !isNaN(secondTime) ? secondTime : "";

      return secondTime - firstTime;
    })
    .map((ipo) => {
      return {
        ...ipo,
        listing_date:
          ipo.listing_date &&
          moment(ipo.listing_date, "DD/MM/YYYY").format("MMM. DD, YYYY"),
      };
    });
};

export const sortDirectorSharesAccToDate = (shares) => {
  return shares
    .sort((a, b) => {
      let firstTime = timeFormatToSort(a.date);
      let secondTime = timeFormatToSort(b.date);
      firstTime = !isNaN(firstTime) ? firstTime : "";
      secondTime = !isNaN(secondTime) ? secondTime : "";

      return secondTime - firstTime;
    })
    .map((ipo) => {
      return {
        ...ipo,
        date:
          ipo.date && moment(ipo.date, "DD/MM/YYYY").format("MMM DD, YYYY"),
      };
    });
};

export const sortNewsLettersAccToDate = (newsletters) => {
  return newsletters
    .sort((a, b) => {
      let firstTime = timeFormatToSort(a.date);
      let secondTime = timeFormatToSort(b.date);
      firstTime = !isNaN(firstTime) ? firstTime : "";
      secondTime = !isNaN(secondTime) ? secondTime : "";

      return secondTime - firstTime;
    })
    .map((newsletter) => {
      return {
        ...newsletter,
        date:
          newsletter.date &&
          moment(newsletter.date, "DD/MM/YYYY").format("MMM. DD, YYYY"),
      };
    });
};

export const sortInterviewsAccToDate = (iterviews) => {
  return iterviews
    .sort((a, b) => {
      let firstTime = timeFormatToSort(a.interview_date);
      let secondTime = timeFormatToSort(b.interview_date);
      firstTime = !isNaN(firstTime) ? firstTime : "";
      secondTime = !isNaN(secondTime) ? secondTime : "";

      return secondTime - firstTime;
    })
    .map((interview) => {
      return {
        ...interview,
        date:
          interview.interview_date &&
          moment(interview.interview_date, "DD/MM/YYYY").format("MMM DD, YYYY"),
      };
    });
};

export const sliceObject = (object, keys) => {
  return Object.keys(object)
    .filter(function (key) {
      return keys.indexOf(key) >= 0;
    })
    .reduce(function (acc, key) {
      acc[key] = object[key];
      return acc;
    }, {});
};

export const sortClosedDealsAccToDate = (deals) => {
  return deals
    .filter((deal) => deal.status === "Closed")
    .sort((a, b) => {
      let firstTime = moment(a.created_at).unix();
      let secondTime = moment(b.created_at).unix();
      firstTime = !isNaN(firstTime) ? firstTime : "";
      secondTime = !isNaN(secondTime) ? secondTime : "";

      return secondTime - firstTime;
    })
    .map((deal) => {
      return {
        ...deal,
        date:
          deal.created_at && moment(deal.created_at).format("MMM. DD, YYYY"),
      };
    });
};

export const sortWebinarsAccToDate = (webinars) => {
  return webinars
    .sort((a, b) => {
      let firstTime = moment(a.created_at).unix();
      let secondTime = moment(b.created_at).unix();
      firstTime = !isNaN(firstTime) ? firstTime : "";
      secondTime = !isNaN(secondTime) ? secondTime : "";

      return secondTime - firstTime;
    })
    .map((webinar) => {
      return {
        ...webinar,
        date:
          webinar.created_at &&
          moment(webinar.created_at).format("MMM. DD, YYYY"),
      };
    });
};

export const showRefreshButton = (deals) => {
  let show = false;
  for (let index in deals) {
    if (
      !deals[index].last_price ||
      !deals[index].capital_raise ||
      !deals[index].raise_amount
    ) {
      show = true;
    }
  }

  return show;
};

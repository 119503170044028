import actionTypes from "../actions/faqActions";

const initialState = {
  all: [],
};

const faq = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.FETCH_ALL_FAQS_SUCCESS:
      return {
        ...state,
        all: payload,
      };
    case actionTypes.LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};

export default faq;

import { Icon } from "antd";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../../../services/settings";
import {
  numberFormatter,
  sortDirectorSharesAccToDate,
} from "../../../../utils/constants";

class DirectorSharesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const columns = [
      {
        name: "",
        selector: "deal_logo",
        sortable: false,
        cell: (row) => (
          <>
            <div className="company-logo">
              {row.logo && (
                <img
                  style={{
                    maxWidth: "70px",
                    maxHeight: "70px",
                    float: "left",
                  }}
                  src={`${BaseUrl}/storage/${row.logo.id}/${row.logo.file_name}`}
                  alt=""
                />
              )}
            </div>
          </>
        ),
      },

      {
        name: "Date",
        selector: "date",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.date && `${row.date}`}</div>,
      },
      {
        name: "Code",
        selector: "code",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.code && `${row.code}`}</div>,
      },
      {
        name: "Buy/Sell",
        selector: "company-name",
        sortable: false,
        left: true,
        cell: (row) => (
          <div className="company-name text-capitalize">{row.category && row.category}</div>
        ),
      },
      {
        name: "Type",
        selector: "company-name",
        sortable: false,
        left: true,
        cell: (row) => (
          <div className="company-name">{row.type && row.type}</div>
        ),
      },
      {
        name: "Company",
        selector: "company_name",
        sortable: false,
        className: "company no-wrap",
        cell: (row) => (
          <>
            <div class="company-name">
              <span>{row.company_name}</span>
            </div>
          </>
        ),
      },
      {
        name: "Director",
        selector: "company-name",
        sortable: false,
        left: true,
        cell: (row) => (
          <div className="company-name">{row.date && `${row.director}`}</div>
        ),
      },
      {
        name: "Share Units ",
        selector: "amount ",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.amount && numberFormatter(row.amount)}</div>,
      },
      {
        name: "Price",
        selector: "price",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.price && `$${row.price}`}</div>,
      },
      {
        name: "Value ",
        selector: "value ",
        sortable: false,
        left: true,
        cell: (row) => (
          <div>
            {row.category && (
              <>
                {row.category === "buy" ? (
                  <Icon
                    type="caret-up"
                    style={{ fontSize: "16px", color: "green" }}
                  />
                ) : (
                  <Icon
                    type="caret-down"
                    style={{ fontSize: "16px", color: "red" }}
                  />
                )}
              </>
            )}
            {row.value && numberFormatter(row.value, "$", 2)}
          </div>
        ),
      },
      // {
      //   name: "Current Price ",
      //   selector: "current_price",
      //   sortable: false,
      //   left: true,
      //   cell: (row) => (
      //     <div>{row.current_price && numberFormatter(row.current_price, "$", 2)}</div>
      //   ),
      // },
      // {
      //   name: "% Change",
      //   selector: "difference",
      //   sortable: true,
      //   left: true,
      //   cell: (row) => (
      //     <div>{row.difference && row.difference+'%'}</div>
      //   ),
      // },
    ];

    return (
      <div className="md-table-normal table-responsive">
        <DataTable
          noHeader
          highlightOnHover
          columns={columns}
          pagination={true}
          paginationPerPage={100}
          paginationRowsPerPageOptions={[10, 50, 100, 200, 300, 400, 500]}
          paginationTotalRows={this.props.shares.length}
          data={sortDirectorSharesAccToDate(this.props.shares)}
        />
      </div>
    );
  }
}

export default DirectorSharesTable;

import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";

export default class TermsModal extends Component {
  render() {
    const { show, onClose, heading, hideClose } = this.props;
    return (
      <Fragment>
        <Modal
          show={show}
          onHide={onClose}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton={!hideClose}>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.props.children}</Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

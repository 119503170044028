import React, { createRef, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../../../services/settings";
import { Icon } from "semantic-ui-react";
import { Modal, Spin } from "antd";

const NewsLetterTable = ({ newsletters }) => {
  const videoRef = createRef();
  const [youtubeUrl, setYoutubeUrl] = useState("");

  const [isModalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const cancelHandler = () => {
    setModalVisible(false);
    setYoutubeUrl(null);
  };

  const openVideo = (url) => {
    setLoading(true);
    setYoutubeUrl(`${url}?autoplay=1`);
    setModalVisible(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const columns = [
    {
      name: "",
      selector: "deal_logo",
      sortable: false,
      cell: (row) => (
        <>
          <div className="company-logo">
            {row.logo && (
              <img
                style={{
                  maxWidth: "70px",
                  maxHeight: "70px",
                  float: "left",
                }}
                src={`${BaseUrl}/storage/${row.logo.id}/${row.logo.file_name}`}
                alt=""
              />
            )}
          </div>
        </>
      ),
    },
    {
      name: "Date",
      selector: "company-name",
      sortable: false,
      left: true,
      cell: (row) => (
        <div className="company-name">{row.date && `${row.date}`}</div>
      ),
    },
    {
      name: "Description",
      selector: "company-name",
      sortable: false,
      left: true,
      cell: (row) => (
        <div className="company-name">
          {row.description && `${row.description}`}
          <br />
          {row.embedded_url && (
            <a
              href="javascript:void(0);"
              onClick={() => openVideo(row.embedded_url)}
            >
              Play Video
            </a>
          )}
        </div>
      ),
    },
    {
      name: "Newsletter",
      selector: "company-name",
      sortable: false,
      left: true,
      cell: (row) => (
        <div>
          {row.newsletter && (
            <a
              href={`${BaseUrl}storage/${row.newsletter.id}/${row.newsletter.file_name}`}
            >
              <Icon name="file" size="huge" color="blue" /> View Newsletter
            </a>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="md-table-normal table-responsive">
      <DataTable
        noHeader
        highlightOnHover
        columns={columns}
        data={newsletters}
      />
      <Modal
        className="newsletter"
        visible={isModalVisible}
        footer={null}
        onCancel={() => cancelHandler()}
        width={850}
      >
        {loading && (
          <Spin
            className="newsletter-spinner"
            tip="Loading..."
            style={{ width: "850px", height: "315px" }}
          ></Spin>
        )}
        <iframe
          ref={videoRef}
          width="800"
          height="315"
          frameBorder="0"
          src={youtubeUrl}
          allow='autoplay'
        ></iframe>
      </Modal>
    </div>
  );
};

export default NewsLetterTable;

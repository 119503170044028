import React, { Component } from "react";
import { reduxForm, Field, Form } from "redux-form";
import { FormField } from "../../layout/FormField";
import { required, email, length } from "redux-form-validators";
import { connect } from "react-redux";
import { checkEmail } from "../../../redux/actions/authActions";
import ButtonLoader from "../../common/ButtonLoader";
import { Link } from "react-router-dom";
import {
  phoneNumber,
  minLength,
  matchPasswords,
} from "../../../utils/customValidators";
import NewDropzone from "../../common/NewDropzone";
import ReCAPTCHA from "react-google-recaptcha";
import { Select } from "antd";
import ReactCodeInput from "react-verification-code-input";

const { Option } = Select;

const CAPTCHA_KEY = process.env.REACT_APP_REPCAPTCHA_KEY;
class BrokerRegisterForm extends Component {
  onBlurHandler = (e) => {
    let { checkEmailExistence } = this.props;
    checkEmailExistence(e.target.value);
  };

  render() {
    const { 
      handleSubmit, 
      register, 
      handleCountryChange, 
      country_id, 
      countries, 
      step,
      full_phone,
      verifyCodeHandler,
      errors,
    } = this.props;
    const options = countries.map((d) => (
      <Option value={d.id} code={`+${d.phonecode}`}>
        <img
          style={{ height: "15px", width: "20px" }}
          src={require(`../../../assets/images/flags/${d.iso.toLowerCase()}.png`)}
          alt="Home"
          className="logo"
        />{" "}
        (+{d.phonecode})
      </Option>
    ));
    return (
    <main
        className={
          !this.props.home ? "page-main page-signup page-create-account" : "page-signup"
        }
    >
      <div class="form-normal form-register">
        {step === 1 && (
          <Form onSubmit={handleSubmit}>
            <div className="all-fields">
              <div className="row">
                <div className="col-sm-6">
                  <div className="field">
                    <div className="field-input">
                      <Field
                        className="form-control broker__formInput"
                        name="first_name"
                        placeholder="First Name"
                        component={FormField}
                        type="text"
                        validate={[required()]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="field">
                    <div className="field-input">
                      <Field
                        className="form-control broker__formInput"
                        name="last_name"
                        placeholder="Last Name"
                        component={FormField}
                        type="text"
                        validate={[required()]}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="field">
                    <div className="field-input">
                      <Field
                        className="form-control broker__formInput"
                        name="email"
                        placeholder="Email Address"
                        component={FormField}
                        onChange={(e) => this.onBlurHandler(e)}
                        type="text"
                        validate={[required(), email()]}
                      />
                      <div className="form__field-error">
                        {register.emailError ? register.emailError : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <div className="field">
                    <div class="field-input">
                      <Select
                        showSearch
                        placeholder="Select a country"
                        optionFilterProp="children"
                        onChange={handleCountryChange}
                        filterOption={(input, option) => {
                          return (
                            option.props &&
                            option.props.code
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                        value={country_id}
                      >
                        {options}
                      </Select>
                      <span class="text-danger">
                      {errors.country_id}
                    </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="field">
                    <div className="field-input">
                      <Field
                        className="form-control broker__formInput"
                        name="phone"
                        placeholder="Phone Number"
                        component={FormField}
                        type="number"
                        validate={[required()]}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="field">
                    <div className="field-input">
                      <Field
                        className="form-control broker__formInput"
                        name="password"
                        placeholder="Password"
                        autoComplete="new-password"
                        component={FormField}
                        type="password"
                        validate={[required(), length({ min: 6 })]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="field">
                    <div className="field-input">
                      <Field
                        className="form-control broker__formInput"
                        name="password_confirmation"
                        component={FormField}
                        type="password"
                        placeholder="Confirm Password"
                        autoComplete="new-password"
                        validate={[
                          required(),
                          matchPasswords(),
                          length({ min: 6 }),
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {this.props.role === "Broker" && (
                <div className="row">
                  <div className="col-sm-12">
                    <div className="field">
                      <div className="field-input">
                        <Field
                          className="form-control broker__formInput"
                          placeholder="AFSL or Authorised Representative Number"
                          name="afsl"
                          component={FormField}
                          type="text"
                          validate={[required()]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-sm-6">
                  <div className="field">
                    <div className="field-input">
                      <Field
                        className="form-control broker__formInput"
                        name="company_name"
                        placeholder="Company Name"
                        component={FormField}
                        type="text"
                        validate={[required()]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <NewDropzone
                    multiple={false}
                    buttonLabel="Choose document"
                    title={"Upload Logo"}
                    accept="image/*"
                    onUpload={this.props.onLogoUpload}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="field">
                    <Field
                      className="form-control broker__formInput"
                      name="terms_conditions"
                      component={FormField}
                      type="checkbox"
                      label={
                        <p>
                          I accept the{" "}
                          <Link
                            to={"/broker/terms&conditions"}
                            target="_blank"
                          >{`Terms & Conditions`}</Link>{" "}
                          and{" "}
                          <Link
                            to={"/privacy-policy"}
                            target="_blank"
                          >{`Privacy Policy`}</Link>
                        </p>
                      }
                      requiredError="Accept our terms and conditions"
                      validate={[required()]}
                      htmlFor="any"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 mb-4">
                  <ReCAPTCHA
                    sitekey={CAPTCHA_KEY}
                    onChange={this.props.onreCaptchaChange}
                  />
                </div>
              </div>
              <div class="row">
                <div class="button-group hl-button-group">
                  <div class="col-sm-12">
                    <ButtonLoader
                      type="submit"
                      className="btn btn-primary btn-medium"
                      disabled={!this.props.verfied}
                    >
                      GET ACCESS TO OUR DEALS
                    </ButtonLoader>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
        {step === 2 && (
          <div class="form-normal form-create-account verify-code">
            <h1 class="text-center mt-2">Verify Phone Number</h1>
            <h6 class="text-center">
              Please enter 6 digit verification code sent to{" "}
              {full_phone}
            </h6>
            <Form onSubmit={handleSubmit}>
              <div className="all-fields">
                <div className="row">
                  <div className="col-sm-12">
                    <ReactCodeInput
                      className="verify-phone-input"
                      onChange={verifyCodeHandler}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-8 mx-auto mt-5">
                    <ButtonLoader
                      type="submit"
                      className="btn btn-primary btn-medium btn-block"
                    >
                      Verify
                    </ButtonLoader>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-8 mx-auto">
                    A code has been sent to your number
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 text-center mt-5">
                    Incorrect mobile number?{" "}
                    <a
                      href="javascript:void(0);"
                      onClick={() => {
                        this.setState({
                          step: 1,
                        });
                      }}
                    >
                      Change it
                    </a>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        )}
      </div>
    </main>  
    );
  }
}

const mapStateToProps = (state) => {
  return {
    register: state.register,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkEmailExistence: (email) => checkEmail(dispatch, email),
});

export default reduxForm({ form: "BrokerRegisterForm" })(
  connect(mapStateToProps, mapDispatchToProps)(BrokerRegisterForm)
);

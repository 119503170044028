import React, { Component } from "react";
import { FormField } from "../layout/FormField";
import Validators, { acceptance, required } from "redux-form-validators";
import { Field, reduxForm, Form } from "redux-form";
import ButtonLoader from "../common/ButtonLoader";
import { withRouter } from "react-router-dom";
import TermsModal from "../common/TermsModal";

class StockTipForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validate: false,
      showTerms: false,
    };
    Object.assign(Validators.messages, {
      acceptance: {
        id: "form.errors.acceptance",
        defaultMessage: " must be accepted",
      },
    });
  }

  validate = () => {
    this.setState({ validate: true });
  };

  render() {
    let { handleSubmit, reduxForms } = this.props;
    const anonymous = reduxForms?.StockTipForm?.values?.anonymous;
    return (
      <div class=" deal-form-content">
        <Form onSubmit={handleSubmit}>
          <h3>Add Stock Tip</h3>

          <div className="all-fields">
            {!anonymous && (
              <div className="row">
                <div className="col-md-12">
                  <div className="field">
                    <label className="title">Nickname</label>
                    <div class="field-input">
                      <Field
                        className="form-control"
                        name="nickname"
                        component={FormField}
                        type="text"
                        validate={[required()]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="field">
                  <div class="field-input">
                    <Field
                      className="form-control"
                      name="anonymous"
                      component={FormField}
                      type="checkbox"
                      validate={[required()]}
                      checked={true}
                      value="1"
                      label={"Anonymous"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="field">
                  <label className="title">ASX Code</label>
                  <div class="field-input">
                    <Field
                      className="form-control"
                      name="asx_code"
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="field">
                  <div class="field-input">
                    <Field
                      className="form-control"
                      name="terms"
                      component={FormField}
                      type="checkbox"
                      validate={[acceptance()]}
                      label={
                        <p>
                          I accept the{" "}
                          <a
                            href="javascript:void(0);"
                            onClick={() => {
                              this.setState({
                                showTerms: true,
                              });
                            }}
                          >{`Terms & Conditions`}</a>{" "}
                        </p>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="button-group">
                <div className="col-xs-12 col-md-12 col-md-6">
                  <ButtonLoader
                    onClick={this.validate}
                    type="submit"
                    className="btn btn-primary btn-medium"
                  >
                    Submit
                  </ButtonLoader>
                </div>
              </div>
            </div>
          </div>
        </Form>
        <TermsModal
          heading={`Terms & Conditions`}
          show={this.state.showTerms}
          onClose={() =>
            this.setState({
              showTerms: false,
            })
          }
        >
          <ol>
            <li>
              180 Markets Pty Ltd ACN 638 381 129 (<strong>180 Markets</strong>)
              is conducting this promotion.
            </li>
            <li>
              Information on how to enter and the prizes form part of these
              Terms and Conditions. Participation in this promotion is deemed
              acceptance of these Terms and Conditions.
            </li>
            <li>
              Entry is only open to Australian residents aged
              <strong> </strong>18<strong> </strong>years or over.
            </li>
            <li>
              Associates and employees of 180 Markets are ineligible to enter.
            </li>
            <li>
              The promotion commences on the 17<sup>th</sup> of December 2021
              and entries close at 11:59pm on the 31
              <sup>st</sup> of December 2021 (
              <strong>Promotional Period</strong>).
            </li>
            <li>
              To enter, individuals must:
              <ul
                style={{
                  listStyleType: "disc",
                  marginLeft: "36px",
                }}
              >
                <li>
                  submit an application at www.180markets.com.au (
                  <strong>Website</strong>);
                </li>
                <li>
                  nominate a Share listed on ASX by the end of the Promotional
                  Period (<strong>Share Tip</strong>);
                </li>
              </ul>
            </li>
          </ol>

          <ol start="7">
            <li>
              180 Markets reserves the right, at any time, to verify the
              validity of entries and entrants (including an entrant&rsquo;s
              identity, age and place of residence) and reserves the right, in
              its sole discretion, to disqualify any individual who 180 Markets
              has reason to believe has breached any of these Terms and
              Conditions, tampered with the entry process or engaged in any
              unlawful or other improper misconduct calculated to jeopardise
              fair and proper conduct of the<strong> </strong>
              promotion. Failure by 180 Markets to enforce any of its rights at
              any stage does not constitute a waiver of those rights. 180
              Markets&#39; legal rights to recover damages or other compensation
              from such an offender are reserved. Winners must upon request
              supply suitable identification at the time that 180 Markets
              delivers the prize.
            </li>
            <li>
              Incomplete or indecipherable<strong> </strong>entries will be
              deemed invalid.
            </li>
            <li>
              Limit on entries:
              <ol
                style={{
                  listStyleType: "lower-alpha",
                }}
              >
                <li>Generally, only one (1) entry is permitted per person;</li>
                <li>
                  180 Markets may permit a person to submit multiple entries, as
                  specified on the Website.
                </li>
              </ol>
            </li>
          </ol>

          <ol start="10">
            <li>
              Provided that an entrant complies with the limit on entries in
              clause 9 and these Terms and Conditions generally, an entrant is
              not prohibited from winning multiple prizes.
            </li>
            <li>
              If there is a dispute concerning the conduct of this promotion
              (including as to the identity of an entrant) or in respect to
              claiming a prize, 180 Markets will consider the entrant&#39;s
              grievances however reserves the right to resolve the dispute in
              the manner it determines most appropriate, acting reasonably.
            </li>
            <li>
              Winner determination:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  The determination of the winner will take place as at the end
                  of each period specified in clause 13
                  <strong></strong>(<strong>Determination Date</strong>).
                </li>
                <li>
                  The winner will be determined having regard to:
                  <ul
                    style={{
                      listStyleType: "disc",
                      marginLeft: "18px",
                    }}
                  >
                    <li>
                      the percentage increase/decrease of the price of the Share
                      Tip for the relevant period; Adjusted for
                      dividends/corporate actions.
                    </li>
                    <li>
                      where more than one entry has the same percentage
                      increase/decrease and that percentage increase/decrease
                      entitles the entries to a prize, the prize will be shared
                      equally between each entity.
                    </li>
                  </ul>
                </li>
                <li>
                  The winners will be notified in writing within 5 business days
                  of each Determination Date.
                </li>
                <li>The winners will be published online at the Website; or</li>
              </ol>
            </li>
          </ol>

          <ol start="13">
            <li>
              The prizes will be:
              <ul
                style={{
                  listStyleType: "disc",
                  marginLeft: "36px",
                }}
              >
                <li>
                  highest percentage increase in the last traded price on 31
                  December 2021 to the last traded price on 31 March 2022:
                  $1,500;
                </li>
                <li>
                  highest percentage increase in the last traded price on 31
                  December 2021 to the last traded price on 30 June 2022:
                  $1,500;
                </li>
                <li>
                  highest percentage increase in the last traded price on 31
                  December 2021 to the last traded price on 30 September 2022:
                  $1,500;
                </li>
                <li>
                  highest percentage increase in the last traded price on 31
                  December 2021 to the last traded price on 31 December 2022:
                  $10,000; and
                </li>
                <li>
                  highest percentage decrease in the last traded price on 31
                  December 2021 to the last traded price on 31 December 2022:
                  $500.
                </li>
              </ul>
            </li>
            <li>
              If for any reason a winner does not take or claim a prize (or an
              element of a prize) by the time stipulated by 180 Markets, then
              the prize (or that element of the prize) will be forfeited.
            </li>
            <li>Total prize pool value is $15,000.&nbsp;</li>
            <li>
              Prizes, or any unused portion of a prize, are not transferable or
              exchangeable, unless otherwise specified.
            </li>
            <li>
              Entrants agree that they are fully responsible for any materials
              they submit via the promotion including but not limited to
              comments, recordings and images (<strong>Content</strong>). 180
              Markets shall not be liable in any way for such Content to the
              full extent permitted by law. 180 Markets may remove or decline to
              publish any Content without notice for any reason whatsoever.
            </li>
            <li>
              Entrants warrant and agree that:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  they are not in possession of any information regarding the
                  Share Tip which has not been disclosed to the market and is
                  not otherwise prevented from trading in the Share Tip;
                </li>
                <li>
                  they will not submit any Content that is unlawful or
                  fraudulent, or that 180 Markets may deem in breach of any
                  intellectual property, privacy, publicity or other rights,
                  defamatory, obscene, derogatory, pornographic, sexually
                  inappropriate, violent, abusive, harassing, threatening,
                  objectionable with respect to race, religion, origin or
                  gender, not suitable for children aged under 15, or otherwise
                  unsuitable for publication;
                </li>
                <li>
                  their Content shall not contain viruses or cause injury or
                  harm to any person or entity;
                </li>
                <li>
                  they will obtain prior consent from any person or from the
                  owner(s) of any property that appears in their Content;
                </li>
                <li>
                  the Content is the original artistic or literary work of the
                  entrant that does not infringe the rights of any third party;
                </li>
                <li>
                  they consent to any use of the Content which may otherwise
                  infringe the Content creator&rsquo;s/creators&rsquo; moral
                  rights pursuant to the <em>Copyright Act 1968</em> (Cth) and
                  warrant that they have the full authority to grant these
                  rights; and
                </li>
                <li>
                  they will comply with all applicable laws and regulations,
                  including without limitation, those governing trading, market
                  misconduct, copyright, content, defamation, privacy, publicity
                  and the access or use of others&#39; computer or communication
                  systems.
                </li>
              </ol>
              <p style={{ marginLeft: 0, marginRight: 0 }}>
                Without limiting any other terms herein, the entrant agrees to
                indemnify 180 Markets for any breach of the above terms.
              </p>
            </li>
          </ol>

          <ol start="19">
            <li>
              As a condition of entering this promotion, each entrant licenses
              and grants 180 Markets, its affiliates and sub-licensees a
              non-exclusive, royalty-free, perpetual, worldwide, irrevocable,
              and sub-licensable right to use, reproduce, modify, adapt, publish
              and display their entry (which shall include Content) for any
              purpose, including but not limited to future promotional,
              marketing or publicity purposes, in any media, without
              compensation, restriction on use, attribution or liability.
            </li>
            <li>
              Entrants consent to 180 Markets using their name, likeness, image
              and/or voice in the event they are a winner (including photograph,
              film and/or recording of the same) in any media for an unlimited
              period without remuneration for the purpose of promoting this
              promotion (including any outcome), and promoting the business of
              180 Markets.
            </li>
            <li>
              If this promotion is interfered with in any way or is not capable
              of being conducted as reasonably anticipated due to any reason
              beyond the reasonable control of 180 Markets, including but not
              limited to technical difficulties, market manipulation,
              unauthorised intervention or fraud, 180 Markets reserves the
              right, in its sole discretion, to the fullest extent permitted by
              law: (a) to disqualify any entrant; or (b) subject to any written
              directions from a regulatory authority, to modify, suspend,
              terminate or cancel the promotion, as appropriate.
            </li>
            <li>
              Nothing in these Terms and Conditions limits, excludes or modifies
              or purports to limit, exclude or modify
              <strong>
                the statutory&nbsp;consumer guarantees as provided under
                the&nbsp;Competition and Consumer Act (Cth)
                or&nbsp;similar&nbsp;consumer protection laws
              </strong>{" "}
              in the States and Territories of Australia (
              <strong>Non-Excludable Guarantees</strong>). Except for any
              liability that cannot by law be excluded, including the
              Non-Excludable Guarantees, 180 Markets (including&nbsp;its
              respective officers, employees and agents) excludes all liability
              (including negligence), for any personal injury; or any loss or
              damage (including loss of opportunity); whether direct, indirect,
              special or consequential, arising in any way out of
              the&nbsp;promotion.
            </li>
            <li>
              Except for any liability that cannot by law be excluded, including
              the Non-Excludable Guarantees,&nbsp;180 Markets
              (including&nbsp;its respective officers, employees and
              agents)&nbsp;is not responsible for and excludes all liability
              (including negligence), for any personal injury; or any loss or
              damage (including loss of opportunity); whether direct, indirect,
              special or consequential, arising in any way out of: (a) any
              technical difficulties or equipment malfunction (whether or not
              under 180 Markets&rsquo;s control); (b) any theft, unauthorised
              access or third party interference; (c) any entry that is late,
              lost, altered, damaged or misdirected (whether or not after their
              receipt by 180 Markets) due to any reason beyond the reasonable
              control of 180 Markets; (d) any variation in prize value to that
              stated in these Terms and Conditions; (e) any tax liability
              incurred by a winner or entrant; or (f) use of a prize.
            </li>
            <li>
              Any cost associated with accessing the Website is the
              entrant&rsquo;s responsibility and is dependent on the Internet
              service provider used. The use of any automated entry software or
              any other mechanical or electronic means that allows an entrant to
              automatically enter repeatedly is prohibited and will render all
              entries submitted by that entrant invalid.
            </li>
            <li>
              180 Markets collects personal information in order to conduct the
              promotion and may, for this purpose, disclose such information to
              third parties, including but not limited to agents, contractors,
              service providers, prize suppliers and, as required, to Australian
              regulatory authorities, and may also use and handle personal
              information as set out in its privacy policy. Entry is conditional
              on providing this information. 180 Markets may, for an indefinite
              period, unless otherwise advised by the entrant, use the
              information for promotional, marketing, publicity, research and
              profiling purposes, including sending electronic messages or
              telephoning the entrant. Entrants should direct any request to opt
              out, access, update or correct information to 180 Markets via the
              Website. All entries become the property of 180 Markets. Visit the
              Website for 180 Markets&#39; privacy policy, which contains
              information about how entrants may seek to access or correct their
              personal information held by 180 Markets or complain about a
              breach of their privacy, and how 180 Markets will deal with that
              complaint<strong>.</strong> 180 Markets is unlikely to send
              information overseas.
            </li>
          </ol>
        </TermsModal>
      </div>
    );
  }
}

export default reduxForm({
  form: "StockTipForm",
})(withRouter(StockTipForm));

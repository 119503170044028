import actionTypes from "../actions/stockTipsActions";

const initialState = {
  list: [],
  leaderBoards: [],
};

const stocktipReducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case actionTypes.FETCH_ALL_LEADER_BOARDS:
      return {
        ...state,
        leaderBoards: payload,
      };

    default:
      return state;
  }
};

export default stocktipReducer;

import React from "react";
import { Link, withRouter } from "react-router-dom";

const SiReview = () => {
  return (
    <div className="page-create-account investor-account investor-account-01">
      <div className="form-normal form-create-account message-page">
        <div className="row is-button-group">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <p className="text-center">
              Unfortunately, the information provided indicates that you do not
              qualify as a Sophisticated Investor and therefore cannot invest on
              the 180 Markets platform. If you do not believe this is the case,
              please email info@180markets.com.au and we will be in touch to
              discuss further.
            </p>
          </div>
        </div>
        <div className="row is-button-group  w-100">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <Link
              to="/user/deals"
              className="btn btn-primary btn-medium btn-block"
            >
              Go to deals
            </Link>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 mt-2">
            <Link
              to="/user/sophisticated"
              className="btn btn-outline btn-medium btn-block"
            >
              Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SiReview);

import actionTypes from "../actions/articleActions";

const initialState = {
  articles: {},
  singleArticle: null,
  loading: false
};

const article = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.FETCH_ALL_ARTICLES_SUCCESS:
      let newData = payload;
      if (payload.url){
        newData.data = [...state.articles.data, ...payload.data]
      }
      return {
        ...state,
        articles: newData
      };

    case actionTypes.FETCH_SINGLE_ARTICLE_SUCCESS:
      return {
        ...state,
        singleArticle: payload
      };  
    default:
      return state;
  }
};

export default article;

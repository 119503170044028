import React, { Component } from "react";
import { Modal } from "antd";
import DealsViewForm from "../forms/Deals/DealView/DealsViewForm/DealsViewForm";
import { Form, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";
import { isNullLiteral } from "@babel/types";

class BidNowModal extends Component {
  state = {
    loading: false,
    visible: false,
    anotherBid: false,
    selectedBid: null,
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  onBidNow = (id) => {
    this.setState({
      anotherBid: true,
      selectedBid: null,
    });

    this.props.onBidNow(id, true);
  };

  editBidHandler = (id, selectedBid) => {
    this.setState({
      anotherBid: false,
      selectedBid: selectedBid !== undefined ? selectedBid : null,
    });

    this.props.editBidHandler(id);
  };

  handleCancel = (payload) => {
    this.setState({
      anotherBid: false,
      selectedBid: null,
    });
    this.props.handleCancel(payload);
  };

  onSubmitBid = (payload) => {
    this.setState({
      anotherBid: false,
      selectedBid: null,
    });
    this.props.onSubmitBid(payload);
    this.props.handleCancel(payload);

  };

  entityNotUsedYet = (selectedbid) => {
    return [...this.props.selectEntityOptions].filter((entity) => {
      return !(
        this.props.currentDeal.myBids &&
        this.props.currentDeal.myBids.some((bid) => (bid.entity_id === entity.value && selectedbid && bid.id !== selectedbid.id))
      );
    });
  }

  render() {
    const {
      visible,
      handleCancel,
      currentUser,
      onSubmitBid,
      currentDeal,
      bidStatus,
      selectEntityOptions,
      handleSubmit,
      editBidHandler,
      showEditForm,
    } = this.props;
    const { selectedBid, anotherBid } = this.state;
    let entitiesNotUsed = [...selectEntityOptions].filter((entity) => {
      return !(
        currentDeal.myBids &&
        currentDeal.myBids.some((bid) => bid.entity_id === entity.value)
      );
    });
    return (
      <div>
        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          {currentUser.role &&
            currentUser.role.some((role) => role.title === "Investor") &&
            currentUser.is_approved === "Approved" &&
            currentDeal.type === "180" &&
            currentDeal.status === "Active" &&
            ((currentDeal.myBids.length === 0 && !anotherBid) ||
              (currentDeal.myBids.length > 0 && anotherBid)) && (
              <DealsViewForm
                currentUser={currentUser}
                onSubmit={this.onSubmitBid}
                selectEntityOptions={entitiesNotUsed}
                capRaisePrice={currentDeal && currentDeal.capital_raise}
                currentDeal={currentDeal}
                bidStatus={bidStatus}
                modal={true}
                title={selectedBid && selectedBid ? `Update Bid For ${currentDeal.company_name}` : `Submit Bid For ${currentDeal.company_name}`}
                buttonTitle={selectedBid && selectedBid ? "Update" : "Submit"}
                initialValues={
                  currentDeal &&
                  selectedBid && {
                    bid_id: selectedBid ? selectedBid.uuid : null,
                    entity_id: selectedBid.entity_id,
                    share_count: selectedBid.share_count,
                    investment_amount: selectedBid.investment_amount,
                  }
                }
              />
            )}
          {currentUser.role &&
            currentUser.role.some((role) => role.title === "Investor") &&
            currentUser.is_approved === "Approved" &&
            currentDeal.type === "market" &&
            !currentDeal.myIndication && (
              <DealsViewForm
                currentUser={currentUser}
                onSubmit={this.onSubmitBid}
                selectEntityOptions={selectEntityOptions}
                capRaisePrice={currentDeal && currentDeal.capital_raise}
                currentDeal={currentDeal}
                bidStatus={bidStatus}
                modal={true}
                title={
                  currentDeal && currentDeal.myBid ? `Update Bid For ${currentDeal.company_name}` : `Submit Bid For ${currentDeal.company_name}`
                }
                buttonTitle={
                  currentDeal && currentDeal.myBid ? "Update" : "Submit"
                }
              />
            )}

          {currentDeal.type === "market" && showEditForm && (
            <DealsViewForm
              currentUser={currentUser}
              onSubmit={this.onSubmitBid}
              selectEntityOptions={selectEntityOptions}
              capRaisePrice={currentDeal && currentDeal.capital_raise}
              currentDeal={currentDeal}
              bidStatus={bidStatus}
              modal={true}
              title={
                currentDeal && currentDeal.myIndication
                  ? "Update Bid"
                  : "Submit Bid"
              }
              buttonTitle={
                currentDeal && currentDeal.myIndication ? "Update" : "Submit"
              }
              initialValues={
                currentDeal &&
                currentDeal.myIndication && {
                  investment_amount: currentDeal.myIndication.investment_amount,
                }
              }
            />
          )}

          {currentDeal.type === "180" && showEditForm && (
            <DealsViewForm
              currentUser={currentUser}
              onSubmit={this.onSubmitBid}
              selectEntityOptions={this.entityNotUsedYet(selectedBid)}
              capRaisePrice={currentDeal && currentDeal.capital_raise}
              currentDeal={currentDeal}
              bidStatus={bidStatus}
              modal={true}
              title={currentDeal && selectedBid ? `Update Bid For ${currentDeal.company_name}` : `Submit Bid For ${currentDeal.company_name}`}
              buttonTitle={currentDeal && selectedBid ? "Update" : "Submit"}
              initialValues={
                currentDeal &&
                selectedBid && {
                  bid_id: selectedBid ? selectedBid.uuid : null,
                  entity_id: selectedBid.entity_id,
                  share_count: selectedBid.share_count,
                  investment_amount: selectedBid.investment_amount,
                }
              }
            />
          )}
          {currentDeal.type === "180" &&
            !showEditForm &&
            !anotherBid &&
            currentDeal.myBids.length > 0 &&
            currentDeal.status !== "Closed" && (
              <div>
                <h3>Change Bid</h3>
                {currentDeal.myBids.map((bid, index) => (
                  <>
                    <div style={{ height: "180px" }}>
                      <div>
                        <b>Entity:</b> {bid.entity?.entity_name}
                      </div>
                      <div>
                        <b>Share Count:</b> {bid.share_count}
                      </div>
                      <div className="mb-2">
                        <b>Investment Amount:</b>
                        <NumberFormat
                          value={bid.investment_amount}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </div>
                      {bid.allocation_investor_count === 0 && (
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-medium btn-block mt-4"
                          onClick={() =>
                            this.editBidHandler(currentDeal.uuid, bid)
                          }
                        >
                          Change Bid
                        </button>
                      )}
                    </div>
                  </>
                ))}
                {entitiesNotUsed.length > 0 && (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-default btn-medium btn-block mt-4"
                      onClick={() => this.onBidNow(currentDeal.uuid)}
                    >
                      Bid with another entity
                    </button>
                  </>
                )}
              </div>
            )}

          {currentDeal.type === "market" &&
            !showEditForm &&
            currentDeal.myIndication && (
              <div style={{ height: "200px" }}>
                <h3>Change Indication</h3>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-medium btn-block mt-4"
                  onClick={() => editBidHandler(currentDeal.uuid)}
                >
                  Change Indication
                </button>
              </div>
            )}

          {currentUser.role &&
            currentUser.role.some((role) => role.title === "Investor") &&
            currentUser.is_approved === "Pending" && (
              <div className="md-deal-form">
                <div className="form-normal dealBidMessage">
                  <div className="deal-form-content">
                    <p className="bidMessage">
                      Complete your profile to bid for deals
                    </p>
                    <button
                      className="btn btn-outline-primary btn-block"
                      onClick={() => this.props.history.push("/user/profile")}
                    >
                      Go to Profile
                    </button>
                  </div>
                </div>
              </div>
            )}
          {currentDeal ? (
            currentDeal.status === "Closed" ||
            currentDeal.status === "Finished" ? (
              <div className="md-deal-form">
                <div className="form-normal dealBidMessage">
                  <div
                    className="deal-form-content"
                    style={{ textAlign: "center" }}
                  >
                    <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                      This deal is now closed.
                    </p>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}
          {currentDeal ? (
            currentDeal.status === "Suspended" ? (
              <div className="md-deal-form">
                <div className="form-normal dealBidMessage">
                  <div
                    className="deal-form-content"
                    style={{ textAlign: "center" }}
                  >
                    <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                      This deal is already suspened.
                    </p>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}
        </Modal>
      </div>
    );
  }
}

export default withRouter(reduxForm({ form: "BidNowModal" })(BidNowModal));

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { logoutUser } from "../../redux/actions/authActions";

import $ from "jquery";

const scrollToBroker = ({ selector }) => {
  // setTimeout(() => {
  if (selector === "#for-brokers" || selector === "#for-investors") {
    $(selector).trigger("click");
  }

  if ($(selector).position())
    $("html, body").animate(
      {
        scrollTop: $(selector).offset().top - 50,
      },
      1
    );
  setTimeout(() => {
    $("html, body").stop();
  }, 1000);
};

const scrollToHowItWorks = (selector) => {
  if (selector.position())
    $("html, body").animate(
      {
        scrollTop: $(selector).position().top + 900,
      },
      1000
    );
};

const menu = (props) => (
  <Menu>
    <Menu.Item>
      {/* <Link to="/user/stock-tips" onClick={() => props.handleClick()}>
        Stock Tips
      </Link> */}
      <Link to="/user/interviews" onClick={() => props.handleClick()}>
        18 Minutes with 180
      </Link>
      <Link to="/user/webinars" onClick={() => props.handleClick()}>
        Weekly Wrap
      </Link>
      <Link to="/user/Off-the-charts" onClick={() => props.handleClick()}>
        Off the Charts
      </Link>
      <Link to="/user/pro-investor-series" onClick={() => props.handleClick()}>
        Pro Investor Series
      </Link>
      <Link to="/user/director-shares" onClick={() => props.handleClick()}>
        Director Shares
      </Link>
      {/* <Link to="/user/options" onClick={() => props.handleClick()}>
        Options
      </Link>
      <Link to="/user/transactions" onClick={() => props.handleClick()}>
        Transactions
      </Link> */}
      {/*<Link to="/user/newsletters" onClick={() => props.handleClick()}>*/}
      {/*  Newsletters*/}
      {/*</Link>*/}
    </Menu.Item>
  </Menu>
);

const PublicNav = (props) => (
  <ul className="clearfix nav_bar_b4 nostyle">
    <li>
      <Link to={"/"} onClick={() => props.handleClick()}>
        Home
      </Link>
    </li>
    <li>
      <Link to={"/how-it-works"} onClick={() => props.handleClick()}>
        How It Works
      </Link>
    </li>
    <li>
      <Link to={"/who-we-are"} onClick={() => props.handleClick()}>
        Who We Are
      </Link>
    </li>
    {/* <li>
      <Link to={"/articles"} onClick={() => props.handleClick()}>
        Articles
      </Link>
    </li> */}

    <li>
      <Link to={"/faqs"} onClick={() => props.handleClick()}>
        FAQ
      </Link>
    </li>
    <li>
      <Link to={"/contact-us"} onClick={() => props.handleClick()}>
        Contact
      </Link>
    </li>

    <li>
      <Link to={"/register"} onClick={() => props.handleClick()}>
        Create An Account
      </Link>
    </li>
    <li>
      <Link to={"/auth/login"} onClick={() => props.handleClick()}>
        Login
      </Link>
    </li>
  </ul>
);

const ProtectedNav = (props) => (
  <ul className="nostyle clearfix loggedIn__list">
    <li>
      <Link to={"/how-it-works"} onClick={() => props.handleClick()}>
        How It Works
      </Link>
    </li>
    <li>
      <Link to={"/who-we-are"} onClick={() => props.handleClick()}>
        Who We Are
      </Link>
    </li>
    <li>
      <Link to={"/faqs"} onClick={() => props.handleClick()}>
        FAQ
      </Link>
    </li>
    <li>
      <Link to={"/contact-us"} onClick={() => props.handleClick()}>
        Contact
      </Link>
    </li>
    {/* <li>
      <Link to={"/live-now"} onClick={() => props.handleClick()}>
        Live Now
      </Link>
    </li> */}
    <li>
      <Dropdown overlay={() => menu(props)} trigger={props.mobile && ["click"]}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Resources <DownOutlined style={{ fontSize: "16px" }} />
        </a>
      </Dropdown>
    </li>
  </ul>
);

class Navigation extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props;
    this.brokerRef = React.createRef();

    history.listen((location, action) => {
      if (location.pathname === "/" && location.hash) {
        scrollToBroker({ selector: location.hash });
      } else {
        let selector = "#root";
        if ($(selector).position())
          $("html, body").animate(
            {
              scrollTop: $(selector).offset().top - 50,
            },
            1
          );
        setTimeout(() => {
          $("html, body").stop();
        }, 1000);
      }
    });
  }

  componentDidMount() {
    const { history } = this.props;
    if (history.location.pathname === "/") {
      if (history.location.hash)
        scrollToBroker({ selector: history.location.hash });
    }
  }

  componentDidUpdate(prevProps) {
    const { history } = this.props;
    if (this.props.location !== prevProps.location) {
      if (history.location.hash)
        scrollToBroker({
          selector: history.location.hash,
          forwardedRef: this.brokerRef,
        });
    }
  }

  onLogout = () => {
    this.props.logoutUser();
  };

  render() {
    const { auth } = this.props;

    let token = localStorage.getItem("auth_token");
    return token && auth.currentUser ? (
      <ProtectedNav
        {...this.props}
        forwardedRef={this.brokerRef}
        onLogout={this.onLogout}
        handleClick={this.props.handleClick}
      />
    ) : (
      <PublicNav
        handleClick={this.props.handleClick}
        {...this.props}
        forwardedRef={this.brokerRef}
      />
    );
  }
}

const mapStateToProps = (State) => ({
  currentUser: State.auth.currentUser,
  auth: State.auth,
});
const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Navigation));

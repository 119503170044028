import React from "react";
import { Modal } from "antd";
import {
    QuestionCircleOutlined,
  } from "@ant-design/icons";

export const accountHold = props => {
  const { history } = props;
  Modal.confirm({
    width: 600,
    okButtonProps: {
      type: "primary",
      size: "large",
    },
    cancelButtonProps: {
      size: "large",
    },
    icon: <QuestionCircleOutlined style={{ color: "#faad14" }} />,
    content: (
      <p className="pconfirm">
        {"Your account is currently on hold"}
      </p>
    ),
    okText: "My Profile",
    cancelText: "Cancel",
    onOk() {
      history.push("/user/profile");
    },
    onCancel() {},
  });
};

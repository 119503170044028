import React, { Component } from "react";
import { Modal } from "antd";
import bid from "../../../../../assets/images/bid.png";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../../../../services/settings";
import { withRouter } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import {
  renderData,
  renderRefreshText,
  customStyles,
} from "../../../../common/CommonMethods";

class MarketIpo extends Component {
  onView = (uuid) => {
    const { isEnitityExist, history, dealAccessDenialMessage } = this.props;
    if (isEnitityExist) {
      history.push(`/user/ipo-view/${uuid}`);
    } else {
      Modal.confirm({
        // title: "Confirm",
        width: 600,
        okButtonProps: {
          type: "primary",
          size: "large",
        },
        cancelButtonProps: {
          size: "large",
        },
        // icon: <ExclamationCircleOutlined />,

        content: <p className="pconfirm">{dealAccessDenialMessage}</p>,
        okText: "My Profile",
        cancelText: "Cancel",
        onOk() {
          history.push("/user/profile");
        },
        onCancel() {},
      });
    }
  };

  handleChange = (row) => {
    const { isEnitityExist, history, dealAccessDenialMessage } = this.props;
    if (isEnitityExist) {
      this.props.history.push(`/user/ipo-view/${row.uuid}`);
    } else {
      Modal.confirm({
        width: 600,
        okButtonProps: {
          type: "primary",
          size: "large",
        },
        cancelButtonProps: {
          size: "large",
        },
        // title: "Confirm",
        // icon: <ExclamationCircleOutlined />,
        content: <p className="pconfirm">{dealAccessDenialMessage}</p>,
        okText: "My Profile",
        cancelText: "Cancel",
        onOk() {
          history.push("/user/profile");
        },
        onCancel() {},
      });
    }
  };

  render() {
    const columns = [
      {
        name: "",
        selector: "deal_logo",
        sortable: false,
        // style: { minWidth: "120px" },
        cell: (row) => (
          <>
            <div
              className="company-logo"
              onClick={() => this.handleChange(row)}
            >
              {row.logo && (
                <img
                  style={{
                    maxWidth: "70px",
                    maxHeight: "70px",
                    float: "left",
                  }}
                  src={`${BaseUrl}/storage/${row.logo.id}/${row.logo.file_name}`}
                  alt=""
                />
              )}
            </div>
          </>
        ),
      },
      {
        name: "Company",
        selector: "company_name",
        sortable: false,
        className: "company no-wrap",
        style: { minWidth: "130px" },
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)} c>
              <span>{row.company_name} </span>
            </div>
          </>
        ),
      },
      {
        name: "ASX code",
        selector: "asx_code",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.asx_code && `${row.asx_code}`}
          </div>
        ),
      },
      {
        name: "Raise Amount",
        selector: "raise_amount",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.raise_amount ? renderData(row.raise_amount) : ""}
          </div>
        ),
      },
      {
        name: "IPO Price",
        selector: "ipo_price",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.ipo_price ? renderData(row.ipo_price) : ""}
          </div>
        ),
      },
      {
        name: "Market Capitalisation",
        selector: "price",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.market_cap && `${renderData(row.market_cap)}`}
          </div>
        ),
      },
      {
        name: "Proposed List Date ",
        selector: "total_price ",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.listing_date && row.listing_date}
          </div>
        ),
      },
      {
        name: "View Deal",
        selector: "bid_now",
        sortable: false,
        left: true,
        cell: (row) => (
          <div className="tbl-title" onClick={() => this.onView(row.uuid)}>
            <span className="bid-now">
              <Icon
                style={{ cursor: "pointer" }}
                color="blue"
                size="huge"
                name="arrow alternate circle right outline"
              />
            </span>
          </div>
        ),
      },
      {
        name: "Express Interest",
        selector: "bid_now",
        left: true,
        cell: (row) => (
          <div className="tbl-title">
            <img
              src={bid}
              style={{ cursor: "pointer", width: "38px" }}
              onClick={() => this.props.onBidIpoNow(row.uuid)}
            />
          </div>
        ),
      },
    ];

    return (
      <div className="md-table-normal table-responsive">
        <h5
          style={{ textAlign: "left", fontSize: "18px", marginBottom: "20px" }}
        >
          we are currently seeking access to these placements. Stay tuned.
        </h5>

        <DataTable
          noHeader
          highlightOnHover
          columns={columns}
          data={this.props.deals}
          onRowClicked={this.handleChange}
          pointerOnHover={true}
          sortServer={true}
          noDataComponent={
            <div className="p-4">
              Stand by! More great IPOs are on their way!
            </div>
          }
          customStyles={customStyles}
        />
      </div>
    );
  }
}

export default withRouter(MarketIpo);

import React, {Component} from "react";
import traders from "../../../assets/images/traders2.png";
import t1 from "../../../assets/images/CompanyDirectDeals.png";
import t4 from "../../../assets/images/IndepthAnalysis.png";
import t2 from "../../../assets/images/ChooseYourOwnDealers.png";
import t3 from "../../../assets/images/FairerAllocations.png";


import HomeVideoImg from "../../../assets/images/big-video-image.jpeg";

export default class ChatWithTraders extends Component {

    render() {
        return (
            <main className="page-main home-page pt-5">
                <div class="main_bg">
                    <div className="container">
                        <div className="nav-tab-bg how-it-works" id="how-it-works">

                            <div className="row chatwithtraders" style={{paddingLeft: '15%', paddingRight: '15%'}}>
                                <div className="col-lg-12">
                                    <h3 className="hero_hedading text-center"style={{marginBottom: 30}}>
                                        Welcome to 180 Markets!
                                    </h3>
                                </div>
                                <div className="col-md-3">
                                    <img
                                        src={t1}
                                        className=""
                                    />
                                    <p>Access to ASX Capital Raises, IPOs,
                                        & exclusive 180Markets lead raises.</p>
                                </div>
                                <div className="col-md-3">
                                    <img
                                        src={t2}
                                        className=""
                                        width="70px"
                                    />
                                    <p>No sign-up fee, no cost to participate
                                        ....free ALWAYS!</p>
                                </div>
                                <div className="col-md-3">
                                    <img
                                        src={t3}
                                        className=""
                                    />
                                    <p>Invest alongside the founders of 180
                                    Markets in Exclusive 180 Markets lead
                                        manged deals.</p>
                                </div>
                                <div className="col-md-3">
                                    <img
                                        src={t4}
                                        className=""
                                    />
                                    <p>Track your bids, allocations & live deals
                                        seamlessly via the website or app.</p>
                                </div>


                                <div className="col-md-12 text-center mt-5">
                                    <p><b>Don't miss out on the next 10-bagger!</b></p>

                                    <a className="btn btn-outline-primary" href="/register">
                                        REGISTER NOW</a><br/>

                                    <img
                                        src={traders}
                                        className=""
                                        width="200"
                                    />

                                    <p className="p_text_sm">
                                        * For approval, please ensure that you upload your Sophisticated Investor form,
                                        a copy of your ID, & your brokerage account details
                                    </p>

                                </div>




                            </div>

                                </div>
                            </div>
                </div>
            </main>
        );
    }
}

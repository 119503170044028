import React, { Component } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import ViewBids from "../../../../common/ViewBids";
import { BaseUrl } from "../../../../../services/settings";
import { Icon } from "semantic-ui-react";
import Confirm from "../../../../common/Confirm";
import dealActions from "../../../../../redux/actions/dealActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  renderData,
  renderRefreshText,
  customStyles,
  dueDateFormat,
} from "../../../../common/CommonMethods";

class PastDeal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      open_view: false,
    };
  }

  open = (id) => {
    this.setState({ open: true, dealId: id });
  };

  close = () => this.setState({ open: false, open_view: false });

  deleteDeal = (id) => {
    let { deleteSingleDeal } = this.props;
    deleteSingleDeal(id);
    this.setState({ open: false });
  };

  onView = (id) => {
    this.props.history.push(`/user/deal-view/${id}`);
  };

  openBid = (id) => {
    this.setState({ open_view: true, view_dealId: id });
  };

  getBidsCount = (dealId) => {
    let res = 0;
    if (this.props.bids) {
      for (let item of this.props.bids) {
        if (item.deal_id === dealId) res++;
      }
    }
    return res;
  };

  render() {
    const handleChange = () => {};
    const columns = [
      {
        name: "",
        selector: "deal_logo",
        sortable: false,
        // style: { minWidth: "120px" },
        cell: (row) => (
          <>
            <div className="company-logo">
              {row.deal_logo && (
                <img
                  style={{
                    maxWidth: "70px",
                    maxHeight: "70px",
                    float: "left",
                  }}
                  src={`${BaseUrl}/storage/${row.deal_logo.id}/${row.deal_logo.file_name}`}
                  alt=""
                />
              )}
            </div>
          </>
        ),
      },
      {
        name: "Company",
        selector: "company_name",
        sortable: false,
        className: "company no-wrap",
        style: { minWidth: "130px" },
        cell: (row) => (
          <>
            <div>
              <span>{row.company_name}</span>
            </div>
          </>
        ),
      },
      {
        name: "ASX Code",
        selector: "asx_code",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.asx_code && row.asx_code}</div>,
      },
      {
        name: "Last Price",
        selector: "last_price",
        sortable: false,
        left: true,
        cell: (row) => (
          <div> {row.last_price ? renderData(row.last_price) : ""}</div>
        ),
      },

      {
        name: "Capital Raise Price",
        selector: "capital_raise",
        sortable: false,
        left: true,
        cell: (row) => (
          <div>{row.capital_raise ? renderData(row.capital_raise) : ""}</div>
        ),
      },
      {
        name: "Raise Amount",
        selector: "raise_amount",
        left: true,
        cell: (row) => (
          <div>{row.raise_amount ? row.raise_amount : ""}</div>
        ),
      },
      {
        name: "Discount",
        selector: "discount",
        sortable: false,
        left: true,
        cell: (row) => (
          <div>
            {!isNaN(parseInt(row.discount))
              ? parseFloat(row.discount) + "%"
              : ""}
          </div>
        ),
      },
      {
        name: "Market Capitalisation",
        selector: "market_capital",
        sortable: false,
        left: true,
        cell: (row) => (
          <div>{row.market_capital && `${renderData(row.market_capital)}`}</div>
        ),
      },
      {
        name: "Bids Due",
        selector: "bids_due",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.bids_due && dueDateFormat(row.bids_due)}</div>,
      },
      {
        name: "View Bids",
        sortable: false,
        center: true,
        overflowY: true,
        // style: { minWidth: "100px" },

        cell: (row) => (
          <div>
            <span
              style={{
                display: "inline-block",
                width: "33px",
                height: "33px",
                textAlign: "center",
                backgroundColor: "#2185d0",
                color: "#fff",
                borderRadius: "18px",
                padding: "5px 5px",
                cursor: "pointer",
              }}
              onClick={() => this.openBid(row.id)}
            >
              {this.getBidsCount(row.id)}
            </span>
          </div>
        ),
      },
      {
        name: "Actions",
        sortable: false,
        centre: true,
        overflowY: true,

        cell: (row) => (
          <div className="btn-group">
            <Icon
              style={{ cursor: "pointer" }}
              title="Delete"
              circular
              inverted
              color="blue"
              name="remove circle"
              onClick={() => this.open(row.uuid)}
            />

            <Confirm
              message="Are you sure you want to delete this deal?"
              show={this.state.open}
              handleDelete={() => this.deleteDeal(row.uuid)}
              handleClose={this.close}
            />
          </div>
        ),
      },
    ];

    return (
      <div className="md-table-normal table-responsive">
        <DataTable
          noHeader
          highlightOnHover
          // expandableRows
          columns={columns}
          data={this.props.deals}
          // sortIcon={<span class="ico"></span>}
          onSelectedRowsChange={handleChange}
          customStyles={customStyles}
        />
        {this.state.open_view ? (
          <ViewBids
            show={this.state.open_view}
            handleClose={this.close}
            bids={this.props.bids}
            deal_id={this.state.view_dealId}
          />
        ) : null}
        {this.state.open ? (
          <Confirm
            message="Are you sure you want to close this deal?"
            closeButtonText="Cancel"
            deleteButtonText="Yes, Close"
            show={this.state.open}
            handleDelete={() => this.deleteDeal(this.state.dealId)}
            handleClose={this.close}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bids: state.bids.bids,
  };
};

const { deleteDeal } = dealActions;

const mapDispatchToProps = (dispatch) => ({
  deleteSingleDeal: (id) => deleteDeal({ dispatch, id }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PastDeal));

import React, { Component } from "react";
import { reduxForm, Field, Form, change } from "redux-form";
import { connect } from "react-redux";
import { FormField, DatePickerField } from "../../layout/FormField";
import ButtonLoader from "../../common/ButtonLoader";
import { required } from "redux-form-validators";
import { scrollToFirstError } from "../../../utils/scroller";
import { bindActionCreators } from "redux";

class TransactionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeHander = () => {
    setTimeout(() => {
      this.calculation();
    }, 100);
  };


  calculation = () => {
    const { form } = this.props;
    let brokerage = form?.TransactionForm?.values?.brokerage;
    if (!brokerage || brokerage === ""){
      brokerage = 0
    }
    let no_of_shares = form?.TransactionForm?.values?.no_of_shares;
    if (!no_of_shares || no_of_shares === ""){
      no_of_shares = 0
    }
    let price = form?.TransactionForm?.values?.price;
    if (!price || price === ""){
      price = 0
    }
    let type = form?.TransactionForm?.values?.type;
    if (type !== "") {
      if (type === 'Buy'){
        this.props.change(
          "TransactionForm",
          "total_amount",
          parseFloat((price * no_of_shares) + parseFloat(brokerage)).toFixed(2)
        );
      } else if (type === 'Sell') {
        this.props.change(
          "TransactionForm",
          "total_amount",
          parseFloat((price * no_of_shares) - parseFloat(brokerage)).toFixed(2)
        );
      } else {
        this.props.change(
          "TransactionForm",
          "total_amount",
          parseFloat(price * no_of_shares).toFixed(2)
        );
      }
    }
  }

  render() {
    let { handleSubmit, paramsID, title, form } = this.props;
    let type = form.TransactionForm?.values?.type;
    return (
      <>
        <h1>{title}</h1>
        <Form onSubmit={handleSubmit}>
          <div className="all-fields">
            <div className="row">
              <div className="col-sm-12">
                <div className="field">
                  <div className="field-input">
                    <label className="title">Type </label>
                    <div className="field-input">
                      <Field
                        className="form-control"
                        name="type"
                        component={FormField}
                        type="select"
                        validate={[required()]}
                        onChange={this.onChangeHander}
                        options={
                          type !== "Placement"
                            ? [
                                {
                                  text: "Buy",
                                  value: "Buy",
                                },
                                {
                                  text: "Sell",
                                  value: "Sell",
                                },
                              ]
                            : [
                                {
                                  text: "Placement",
                                  value: "Placement",
                                },
                              ]
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Company Name</label>
                  <div className="field-input">
                    <Field
                      className="form-control"
                      name="company_name"
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">ASX Code</label>
                  <div className="field-input">
                    <Field
                      className="form-control"
                      name="asx_code"
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="field">
                  <div className="field-input">
                    <label className="title">Select Entity </label>
                    <div className="field-input">
                      <Field
                        className="form-control"
                        name="entity_id"
                        component={FormField}
                        type="select"
                        options={this.props.entities && this.props.entities}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Date</label>
                  <div className="field-input">
                    <Field
                      className="form-control"
                      name="date"
                      component={DatePickerField}
                      type="text"
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6">
                <div className="field">
                  <label className="title">Number Of Shares</label>
                  <div className="field-input">
                    <Field
                      className="form-control"
                      name="no_of_shares"
                      component={FormField}
                      onChange={this.onChangeHander}
                      type="number"
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6 ">
                <div className="field">
                  <label className="title">Price </label>
                  <div className="field-input">
                    <Field
                      className="form-control"
                      name="price"
                      component={FormField}
                      onChange={this.onChangeHander}
                      type="number"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="field">
                  <label className="title">Total Amount </label>
                  <div className="field-input">
                    <Field
                      className="form-control"
                      name="total_amount"
                      component={FormField}
                      type="number"
                      defaultValue={10}
                      value={10}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="field">
                  <label className="title">Brokerage Fee</label>
                  <div className="field-input">
                    <Field
                      className="form-control"
                      name="brokerage"
                      component={FormField}
                      type="number"
                      onChange={this.onChangeHander}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ButtonLoader
            className="btn btn-primary btn-medium"
            onClick={this.validate}
          >
            {paramsID ? "Update" : "Save"}
          </ButtonLoader>

          <button
            type="button"
            onClick={() => this.props.onCancel()}
            className="btn btn-outline-primary btn-medium ml-2"
          >
            Cancel
          </button>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
};

export default reduxForm({
  form: "TransactionForm",
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(connect(mapStateToProps, mapDispatchToProps)(TransactionForm));

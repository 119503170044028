import React, { Component } from "react";
import { Select } from "antd";
import { connect } from "react-redux";
import ArticlesActions from "../../../../redux/actions/articleActions";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./styles.scss";
import defaultArticle from "../../../../assets/images/default-article.jpeg";
const { Option } = Select;

class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultImage: defaultArticle,
      filters: {
        category: ''
      }
    };
  }

  callback = (key) => {
    this.setState({ activeTab: key });
  };
  open = (id) => {
    this.setState({ open: true, documentID: id });
  };

  close = () => this.setState({ open: false });

  componentDidMount() {
    this.props.fetchArticles();
  }

  loadMore = (url) => {
    const queryString = new URLSearchParams(this.state.filters).toString();
    if (queryString){
      url +=`&${queryString}`;
    }
    this.props.fetchArticles({url});
  };

  replaceImage = (error) => {
    error.target.src = this.state.defaultImage;
  };

  handleCategoryChange = (value) => {
    this.setState({
      filters: {
        category: value
      }
    }, () => {
      const queryString = new URLSearchParams(this.state.filters).toString();
      this.props.fetchArticles({queryString});
    })
    
  }

  render() {
    return (
      <main className="page-deal-add-doc articles">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="offset-md-2 col-md-8 mt-4">
              <div class="d-flex justify-content-between">
              <h3 class="hero_hedading">Articles</h3>
              <Select
                size="large"
                style={{width: '200px'}}
                showSearch
                placeholder="Select a categoy"
                optionFilterProp="children"
                onChange={this.handleCategoryChange}
                filterOption={(input, option) => {
                  return (
                    option.props &&
                    option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  value={this.state.filters.category}
                >
                  <Option value="">All</Option>
                  <Option value="General">General</Option>
                  <Option value="Sophisticated Investor">Sophisticated Investor</Option>
                </Select>
                </div>
                <div className="row g-5">
                  {this.props.articles &&
                    this.props.articles.data &&
                    this.props.articles.data.map((article, key) => (
                      <div className="col-lg-4 mt-5" key={key}>
                        <div className="post-entry d-block small-post-entry-v">
                          <div className="thumbnail">
                            <Link to={`/articles/${article?.slug}`}>
                              <img
                                src={article.featured_image_url}
                                alt="Image"
                                onError={this.replaceImage}
                              />
                            </Link>
                          </div>
                          <div className="content">
                            <h2 className="heading mb-3">{article.title}</h2>
                            <div className="content-text mb-3"><b>{article.category}</b></div>
                            <div
                              className="content-text"
                              dangerouslySetInnerHTML={{
                                __html:  `${article.content.substring(0, 150)}${article.content.length > 150 ? '...' : ''}`,
                              }}
                            ></div>
                          </div>

                          <div className="view-btn">
                            <Link to={`/articles/${article?.slug}`}>
                              <Button className="btn btn-primary about_ftr_btn rounded-0 active">
                                View
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="row g-5">
                  {this.props.articles &&
                    this.props.articles.data &&
                    this.props.articles.data.length == 0 && (
                      <div className="col-lg-12 mt-5 text-center">
                         No Records Found!
                      </div>
                    )}
                </div>
                <div className="row">
                  <div className="offset-md-4 col-md-4 mt-5">
                    {this.props?.articles?.next_page_url && (
                      <Button
                        className="btn btn-primary about_ftr_btn rounded-0 active btn-block"
                        onClick={() =>
                          this.loadMore(this.props?.articles?.next_page_url)
                        }
                      >
                        Load More
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  articles: state.articles.articles,
});

const { fetchArticles } = ArticlesActions;

const mapDispatchToProps = (dispatch) => ({
  fetchArticles: (payload) => fetchArticles(dispatch, payload),
});

export default connect(mapStateToProps, mapDispatchToProps)(Articles);

import axios from "axios";
import { BaseUrl, config, configMultipart, guestConfig } from "./settings";

const auth = {
  me: () => {
    return axios
      .get(`${BaseUrl}frontend/v1/user`, config())
      .then(({ data, status }) => {
        if (status === 200) {
          return { currentUser: data };
        }
        return { currentUser: {}, error: "Unauthorized" };
      })
      .catch((error) => ({
        currentUser: {},
        error,
      }));
  },
  profile: {
    broker: (payload) => {
      let bodyFormData = new FormData();

      for (var key in payload) {
        if (payload[key] && payload[key] !== undefined) {
          bodyFormData.append(key, payload[key]);
        }
      }
      // let bodyFormData = new FormData();
      // bodyFormData.set("first_name", first_name);
      // bodyFormData.set("last_name", last_name);
      // bodyFormData.set("email", email);
      // bodyFormData.set("company_name", company_name);
      // bodyFormData.set("phone", phone);
      // bodyFormData.set("description", description);
      // if (logo !== null) {
      //   bodyFormData.append("logo", logo);
      // }

      return axios
        .post(
          `${BaseUrl}frontend/v1/brokers/profile`,
          bodyFormData,
          configMultipart()
        )
        .then(({ data }) => {
          return data;
        })
        .catch((error) => {
          throw error.response.data;
        });
    },

    investor: (payload) => {
      let bodyFormData = new FormData();

      for (var key in payload) {
        if (payload[key] && payload[key] !== undefined) {
          if (key === "verification") {
            Object.values(payload[key]).forEach((verification, index) => {
              bodyFormData.append("verification[" + index + "]", verification);
            });
          } else if (key === "siForms") {
            Object.values(payload[key]).forEach((verification, index) => {
              bodyFormData.append(
                "sophisticated_investor_forms[" + index + "]",
                verification
              );
            });
          } else if (key === "form_of_identifications") {
            let count = 0;
            Object.keys(payload[key]).forEach((subkey, index) => {
              if (payload[key][subkey]) {
                bodyFormData.append(
                  "form_of_identifications[" + count + "]",
                  subkey
                );
                count += 1;
              }
            });
          } else {
            bodyFormData.append(key, payload[key]);
          }
        }
      }
      return axios
        .post(
          `${BaseUrl}frontend/v1/investors/profile`,
          bodyFormData,
          configMultipart()
        )
        .then(({ data }) => {
          return data;
        })
        .catch((error) => {
          throw error.response.data;
        });
    },
  },
  preferences: {
    investor: (payload) => {
      return axios
        .post(
          `${BaseUrl}frontend/v1/investors/profile-settings`,
          payload,
          config()
        )
        .then((response) => {
          let { data, status } = response;
          if (status === 200) {
            return {
              currentUser: data,
              message: "Preferences Updated successfully",
              error: null,
            };
          }
          return { currentUser: null, message: null, error: "Failed" };
        })
        .catch((error) => ({ currentUser: null, message: null, error }));
    },
    updateSingle: (payload) => {
      return axios
        .post(
          `${BaseUrl}frontend/v1/investors/update-single-preference`,
          payload
        )
        .then((response) => {})
        .catch((error) => ({ currentUser: null, message: null, error }));
    },
  },
  acceptTerms: (payload) => {
    return axios
      .post(
        `${BaseUrl}frontend/v1/investors/accept-terms`,
        payload,
        configMultipart()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => ({ currentUser: null, message: null, error }));
  },
  register: (payload) => {
    let bodyFormData = new FormData();
    for (var key in payload) {
      if (payload[key] && payload[key] !== undefined) {
        bodyFormData.append(key, payload[key]);
      }
    }

    return axios
      .post(`${BaseUrl}frontend/v1/register`, bodyFormData, configMultipart())
      .then(({ data, status }) => {
        if (status === 200) {
          const { error, message, token } = data;
          return { currentUser: data.user, token, error, message };
        }
        return { currentUser: {}, error: "Unauthorized" };
      })
      .catch((error) => {
        throw error;
      });
  },
  login: (params) => {
    return axios
      .post(`${BaseUrl}frontend/v1/login`, params)
      .then(({ data }) => {
        let { token, user, error, message } = data;
        if (error) {
          return { error, message };
        }
        return {
          token,
          payload: { currentUser: user },
          success: true,
          message: "Login successfull!",
        };
      })
      .catch((error) => {
        throw error.response.data;
      });
  },
  verifyPhone: async (params) => {
    return axios
      .post(`${BaseUrl}frontend/v1/verify-phone`, params, guestConfig())
      .then(({ data }) => {
        console.log(data);
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
  },
  verifyCode: (params) => {
    return axios
      .post(`${BaseUrl}frontend/v1/verify-code`, params)
      .then(({ data }) => {
        console.log(data);
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
  },
  emailCheck: (email) => {
    return axios
      .post(`${BaseUrl}frontend/v1/email-check`, { email })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
  },
  exportInvestorAllocations: async () => {
    return await axios.get(
      `${BaseUrl}frontend/v1/investors/allocations-excel`,
      config()
    );
  },

  forgotPassword: (data) => {
    return axios
      .post(`${BaseUrl}frontend/v1/forgot-password`, data)
      .then(({ data, status }) => {
        if (data) {
          return { data, status };
        }
      })
      .catch((error) => {
        throw error.response;
      });
  },

  contactUs: (data) => {
    return axios
      .post(`${BaseUrl}frontend/v1/contact-us`, data)
      .then(({ data, status }) => {
        if (data) {
          return { data, status };
        }
      })
      .catch((error) => {
        return error;
      });
  },

  resetPassword: (data) => {
    return axios
      .post(`${BaseUrl}frontend/v1/reset-password`, data)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
  },
  uploadAvatar: (data) => {
    let bodyFormData = new FormData();
    bodyFormData.set("photo", data);
    return axios
      .post(
        `${BaseUrl}frontend/v1/user/upload/avatar`,
        bodyFormData,
        configMultipart()
      )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error.response.data;
      });
  },

  submitStockTip: (data) => {
    return axios
      .post(`${BaseUrl}frontend/v1/stock-tips`, data, configMultipart())
      .then(({ data, status }) => {
        if (data) {
          return { data, status };
        }
      })
      .catch((error) => {
        return error;
      });
  },

  submitSubscribeForm: (data) => {
    return axios
      .post(`${BaseUrl}frontend/v1/subscribe-contact`, data, configMultipart())
      .then(({ data, status }) => {
        if (data) {
          return { data, status };
        }
      })
      .catch((error) => {
        return { success: false };
      });
  },
};

export default auth;

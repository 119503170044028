import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import { submitStockTip } from "../../../../redux/actions/profileActions";
import { deleteMediaSuccess } from "../../../../redux/actions/accountActions";
import StockTipForm from "../../../forms/StockTipForm";
import { TabPane } from "react-bootstrap";
import DataTable from "react-data-table-component";
import StockTipsActions from "../../../../redux/actions/stockTipsActions";
class StockTips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploaded: {
        newFile: [],
      },
      logo_url: null,
      activeTab: 1,
      open: false,
    };
  }

  callback = (key) => {
    this.setState({ activeTab: key });
  };
  open = (id) => {
    this.setState({ open: true, documentID: id });
  };

  close = () => this.setState({ open: false });

  componentDidMount() {
    this.props.getAllLeaderBoards();
  }

  handleSubmit = (values) => {
    if (values.anonymous) {
      values.nickname = "Anonymous";
    }
    this.props.submitStockTip(values);
  };

  render() {
    let { currentUser } = this.props;
    const { activeTab } = this.state;

    const columns = [
      {
        name: "",
        selector: "logo",
        sortable: false,
        // style: { minWidth: "120px" },
        cell: (row) => (
          <>
            <div className="" style={{padding: '20px'}}>
              {row.logo && (
                <img
                  style={{
                    maxWidth: "70px",
                    maxHeight: "70px",
                    float: "left",
                  }}
                  src={`${row.logo}`}
                  alt=""
                />
              )}
            </div>
          </>
        ),
      },
      {
        name: "Nickname",
        selector: "nickname",
        left: true,
      },
      {
        name: "ASX Code",
        selector: "asx_code",
        left: true,
      },
      {
        name: "Starting Price",
        selector: "starting_price",
        left: true,
        cell: (row) => (
          <>
            <div className="">
              {row.starting_price && row.starting_price !== 'NA' ?  `$${row.starting_price}`: ''}
            </div>
          </>
        ),
      },
      {
        name: "Current Price",
        selector: "current_price",
        cell: (row) => (
          <>
            <div className="">
              {row.current_price && row.current_price !== 'NA' ?  `$${row.current_price}`: ''}
            </div>
          </>
        ),
      },
      {
        name: "% Change",
        selector: "difference",
        left: true,
        cell: (row) => (
          <>
            <div className="">
              {row.difference && row.difference !== 'NA' ?  `${row.difference}%`: ''}
            </div>
          </>
        ),
      },
    ];
    return (
      <main className="page-deal-add-doc">
        <div className="container">
          <div className="row">
            {/*<div className="col-md-5">*/}
            {/*  <div className="md-deal-form mb-20">*/}
            {/*    /!*<p*!/*/}
            {/*    /!*  style={{*!/*/}
            {/*    /!*    textAlign: "center",*!/*/}
            {/*    /!*    paddingTop: 80,*!/*/}
            {/*    /!*    paddingBottom: 150,*!/*/}
            {/*    /!*    fontSize: 24,*!/*/}
            {/*    /!*  }}*!/*/}
            {/*    /!*>*!/*/}
            {/*    /!*  Entries for our 2022 Mega Stock Tipping are closed.*!/*/}
            {/*    /!*  /!* <br /> *!/*!/*/}
            {/*    /!*  We will provide an up to date leader board on this page.*!/*/}
            {/*    /!*  /!* <br /> *!/*!/*/}
            {/*    /!*  Good luck!*!/*/}
            {/*    /!*</p>*!/*/}

            {/*    /!*<p>*!/*/}
            {/*    /!*  <strong>*!/*/}
            {/*    /!*    180 Markets Mega Stock Tipping Competition*!/*/}
            {/*    /!*  </strong>*!/*/}
            {/*    /!*</p>*!/*/}
            {/*    /!*<p>*!/*/}
            {/*    /!*  <u>Are you Australia’s greatest stock tipper?</u>*!/*/}
            {/*    /!*</p>*!/*/}
            {/*    /!*<p>Showcase your skills and win big with 180 Markets!</p>*!/*/}
            {/*    /!*<p>*!/*/}
            {/*    /!*  <strong>*!/*/}
            {/*    /!*    We are giving away $15,000 and you don’t even need to*!/*/}
            {/*    /!*    be a sophisticated investor to participate!*!/*/}
            {/*    /!*  </strong>*!/*/}
            {/*    /!*</p>*!/*/}
            {/*    /!*<p>*!/*/}
            {/*    /!*  The rules for the 2022 stock tipping competition are as follows:*!/*/}
            {/*    /!*</p>*!/*/}
            {/*    /!*<ul style={{ listStyleType: "disc", marginLeft: '30px' }}>*!/*/}
            {/*    /!*  <li>*!/*/}
            {/*    /!*    All investors signed up to our platform will receive 1 tip in the*!/*/}
            {/*    /!*    competition (you will need to add your details under Profile,*!/*/}
            {/*    /!*    add entity and upload your ID).*!/*/}
            {/*    /!*  </li>*!/*/}
            {/*    /!*  <li>*!/*/}
            {/*    /!*    To receive an additional tip you will need to have a fully*!/*/}
            {/*    /!*    approved 180 Markets account(Sophisticated Investors only).*!/*/}
            {/*    /!*  </li>*!/*/}
            {/*    /!*  <li>*!/*/}
            {/*    /!*    For every person you refer to 180 Markets, you will receive*!/*/}
            {/*    /!*    an extra tip.*!/*/}
            {/*    /!*  </li>*!/*/}
            {/*    /!*  <li>*!/*/}
            {/*    /!*    To receive the extra tip, the investor that signs up needs to*!/*/}
            {/*    /!*    mention your name in the "how did you hear about us"*!/*/}
            {/*    /!*    pop up when registering.*!/*/}
            {/*    /!*  </li>*!/*/}
            {/*    /!*  <li>You may choose any stock listed on the ASX.</li>*!/*/}
            {/*    /!*  <li>*!/*/}
            {/*    /!*    The winner is the entrant whose selected stock performs*!/*/}
            {/*    /!*    the best in percentage terms.*!/*/}
            {/*    /!*  </li>*!/*/}
            {/*    /!*  <li>Enter your tips before midnight on December 31st.</li>*!/*/}
            {/*    /!*  <li>*!/*/}
            {/*    /!*    In the case of a tie, prize money will be split amongst winners.*!/*/}
            {/*    /!*  </li>*!/*/}
            {/*    /!*</ul>*!/*/}

            {/*    /!*<p>*!/*/}
            {/*    /!*  The competition winner will receive*!/*/}
            {/*    /!*  <strong>$10,000</strong> at the end of 2022.*!/*/}
            {/*    /!*</p>*!/*/}

            {/*    /!*<p>*!/*/}
            {/*    /!*  Quarterly prizes of $1,500 will also be awarded*!/*/}
            {/*    /!*  for the leader at the end of March, June & September.*!/*/}
            {/*    /!*</p>*!/*/}

            {/*    /!*<p>*!/*/}
            {/*    /!*  The loser, measured by the worst performing stock in percentage*!/*/}
            {/*    /!*  terms, will win $500!*!/*/}
            {/*    /!*</p>*!/*/}

            {/*    /!*<p>*!/*/}
            {/*    /!*  <strong>Tip smart and good luck!</strong>*!/*/}
            {/*    /!*</p>*!/*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/* <div class=" col-md-5"> */}
              {/*<div className="md-deal-form mb-20">*/}
              {/*  {currentUser?.tip_limit_left > 0 ? (*/}
              {/*    <div className="form-normal stock-form">*/}
              {/*      <StockTipForm*/}
              {/*        onSubmit={this.handleSubmit}*/}
              {/*        open={this.open}*/}
              {/*        show={this.state.open}*/}
              {/*        close={this.close}*/}
              {/*        {...this.props}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  ) : (*/}
              {/*    <div class="form-normal stock-form stock-form-no-limit text-center">*/}
              {/*      <h3>*/}
              {/*        Thanks for the tip You have reached your tips limit.*/}
              {/*        Refer a friend to increase your limit.*/}
              {/*      </h3>*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*</div>*/}
            {/* </div> */}
            <div className="offset-md-2 col-md-8 mt-4">
              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane
                  tab={<span className="nav-link-top ">Top 30</span>}
                  key="1"
                ></TabPane>

                <TabPane
                  tab={<span className="nav-link-top ">Bottom 30</span>}
                  key="2"
                ></TabPane>
              </Tabs>

              {parseInt(activeTab) === 1 ? (
                <>
                  <div className="row">
                    <div className="col-sm-9">
                      <h1 style={{ fontSize: "40", fontWeight: "500" }}>
                        Top 30
                      </h1>
                      <DataTable
                        noHeader
                        overflowY
                        highlightOnHover
                        responsive={true}
                        columns={columns}
                        data={this.props.leaderBoards?.leaderboards}
                        style={{ width: "100%" }}
                        pointerOnHover={true}
                        sortServer={true}
                        noDataComponent={<div className="p-4">Loading...</div>}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-sm-9">
                      <h1 style={{ fontSize: "40", fontWeight: "500" }}>
                        Bottom 30
                      </h1>
                      <DataTable
                        noHeader
                        overflowY
                        highlightOnHover
                        responsive={true}
                        columns={columns}
                        data={this.props.leaderBoards?.rankings}
                        style={{ width: "100%" }}
                        pointerOnHover={true}
                        sortServer={true}
                        noDataComponent={<div className="p-4">Loading...</div>}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  reduxForms: state.form,
  currentUser: state.auth.currentUser,
  leaderBoards: state.stocktips.leaderBoards,
  initialValues: {
    form: state.form,
    asx_code: "",
    nickname: "",
    anonymous: false,
    terms: false,
  },
});

const { getAllLeaderBoards } = StockTipsActions;

const mapDispatchToProps = (dispatch) => ({
  submitStockTip: (payload) => submitStockTip(dispatch, payload),
  getAllLeaderBoards: () => getAllLeaderBoards(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockTips);

import React, { Component } from "react";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { Button } from "antd";
import { Icon, Table } from "semantic-ui-react";
import DealActions from "../../../../redux/actions/dealActions";
import { customStyles, renderData } from "../../../common/CommonMethods";
import { BaseUrl } from "../../../../services/settings";
import { numberFormatter } from "../../../../utils/constants";

class Options extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getOptions();
  }

  render() {
    const columns = [
      {
        name: "",
        selector: "deal_logo",
        left: true,
        sortable: false,
        // style: { minWidth: "120px" },
        cell: (row) => (
          <>
            <div className="company-logo">
              {row.allocation.deal.deal_logo && (
                <img
                  style={{
                    maxWidth: "70px",
                    maxHeight: "70px",
                    float: "left",
                  }}
                  src={`${BaseUrl}storage/${row.allocation.deal.deal_logo.id}/${row.allocation.deal.deal_logo.file_name}`}
                  alt=""
                />
              )}
            </div>
          </>
        ),
      },
      {
        name: "Company",
        selector: "company_name",
        sortable: false,
        className: "company no-wrap",
        style: { minWidth: "130px" },
        left: true,
        cell: (row) => (
          <>
            <div className="wrap">
              {row.allocation.deal.asx_code &&
                `${row.allocation.deal.asx_code}`}{" "}
              <br />
              <span>{row.allocation.deal.company_name}</span>
            </div>
          </>
        ),
      },
      {
        name: "Code of Options",
        selector: "code_of_options",
        sortable: false,
        left: true,
        width: "230px",
        cell: (row) => <div>{row?.allocation?.deal?.code_of_options} </div>,
      },
      {
        name: "Option Ratio",
        selector: "no_of_options",
        sortable: false,
        center: true,
        width: "230px",
        cell: (row) => (
          <>
            <div>
              {row?.allocation?.deal &&
                row?.allocation?.deal.free_option &&
                row?.allocation?.deal.dividend_option &&
                `${row?.allocation?.deal.dividend_option}:${row?.allocation?.deal.free_option}`}{" "}
              <br />{" "}
              {`${
                row.allocation.deal ? row.allocation.deal.option_details : ""
              }`}
            </div>
            <div>
              {row?.allocation?.ipo &&
                row.allocation.ipo.free_options &&
                row.allocation.ipo.dividend_option &&
                `${row.allocation.ipo.dividend_option}:${row.allocation.ipo.free_options}`}{" "}
              <br />{" "}
              {`${row.allocation.ipo ? row.allocation.ipo.option_details : ""}`}
            </div>
          </>
        ),
      },
      {
        name: "Exercise Price",
        selector: "exercise_price",
        sortable: false,
        center: true,
        width: "230px",
        cell: (row) => (
          <div>
            {row?.allocation?.deal?.exercise_price
              ? `$${row?.allocation?.deal?.exercise_price}`
              : ""}
          </div>
        ),
      },
      {
        name: "Time to Expiry",
        selector: "time_to_expiry",
        sortable: false,
        center: true,
        width: "230px",
        cell: (row) => (
          <div>
            {row?.allocation?.deal.time_to_expiry
              ? row?.allocation?.deal.time_to_expiry > 1
                ? `${row?.allocation?.deal.time_to_expiry} years`
                : `1 year`
              : ""}
          </div>
        ),
      },
      // {
      //   name: "View",
      //   selector: "bid_now",
      //   sortable: false,
      //   left: true,
      //   cell: (row) => (
      //     <div>
      //       {row?.allocation?.deal?.free_option_document_url && (
      //         <a
      //           className="tbl-title"
      //           href={row?.allocation?.deal?.free_option_document_url}
      //         >
      //           <span className="bid-now">
      //             <Icon
      //               style={{ cursor: "pointer" }}
      //               color="blue"
      //               size="huge"
      //               name="file right outline"
      //             />
      //           </span>
      //         </a>
      //       )}
      //     </div>
      //   ),
      // },
    ];

    return (
      <div className="md-table-normal table-responsive">
        <DataTable
          noHeader
          highlightOnHover
          columns={columns}
          data={this.props.options}
          onRowClicked={this.handleChange}
          pointerOnHover={true}
          sortServer={true}
          customStyles={customStyles}
        />
      </div>
    );
  }
}

const { getOptions } = DealActions;

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  options: state.deals.options,
});

const mapDispatchToProps = (dispatch) => ({
  getOptions: (payload) => getOptions(dispatch, payload),
});

export default connect(mapStateToProps, mapDispatchToProps)(Options);

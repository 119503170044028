import axios from "axios";
import { BaseUrl, configMultipart } from "./settings";
import { data } from "jquery";

class CounterStoneService {
  postCounterStone = (data) => {
    return axios
      .post(BaseUrl + "frontend/v1/cornerstones", data, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  postSuggestion = (data) => {
    return axios
      .post(BaseUrl + "frontend/v1/suggestions", data, configMultipart())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export default new CounterStoneService();

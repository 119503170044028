import React, { Component } from "react";
import InputFile from "./fields/InputFile";
import { connect } from "react-redux";
import { FormField } from "../layout/FormField";
import { required, email, length } from "redux-form-validators";
import { Field, reduxForm, Form } from "redux-form";
import ButtonLoader from "../common/ButtonLoader";
import { checkEmail } from "../../redux/actions/authActions";
import { matchPasswords } from "../../utils/customValidators";
import NewDropzone from "../common/NewDropzone";
import TermsModal from "../common/TermsModal";
import { Link } from "react-router-dom";
import { phoneNumber, minLength } from "../../utils/customValidators";
import ReCAPTCHA from "react-google-recaptcha";
import ReactCodeInput from "react-verification-code-input";
import auth from "../../services/authService";
import { toast } from "../common/Toast";
import { Select } from "antd";

const { Option } = Select;

const CAPTCHA_KEY = process.env.REACT_APP_REPCAPTCHA_KEY;
class BrokerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTerms: false,
    };
  }

  onBlurHandler = (e) => {
    let { checkEmailExistence } = this.props;
    checkEmailExistence(e.target.value);
  };

  openModal = () => {
    this.setState({
      showTerms: true,
    });
  };

  render() {
    let { handleSubmit,
      onLogoUpload,
      registerState,
      register,
      handleCountryChange,
      country_id,
      countries,
      step,
      full_phone,
      verifyCodeHandler,
      errors,
      verfied,
      onreCaptchaChange,
      setStep
    } = this.props;
    const options = countries.map((d) => (
      <Option value={d.id} code={`+${d.phonecode}`}>
        <img
          style={{ height: "15px", width: "20px" }}
          src={require(`../../assets/images/flags/${d.iso.toLowerCase()}.png`)}
          alt="Home"
          className="logo"
        />{" "}
        (+{d.phonecode})
      </Option>
    ));
    return (
      <main className="page-main page-signup page-create-account">
        <div className="container">
          {registerState && registerState.register ? (
            <h3 style={{ textAlign: "center" }}>
              Thank you for your registration. We are currently reviewing your
              account. We will notify you by email when the account is approved
              and ready to upload deals.
            </h3>
          ) : (
            <div className="form-normal form-create-account">
              {step === 1 && (
                <>
                  <h1>Create a {this.props.role} account</h1>
                  <Form onSubmit={handleSubmit}>
                    <div className="all-fields">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="field">
                            <label className="title">First Name</label>
                            <div className="field-input">
                              <Field
                                className="form-control entity__input"
                                name="first_name"
                                component={FormField}
                                type="text"
                                validate={[required()]}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="field">
                            <label className="title">Last Name</label>
                            <div className="field-input">
                              <Field
                                className="form-control entity__input"
                                name="last_name"
                                component={FormField}
                                type="text"
                                validate={[required()]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="field">
                            <label className="title">Email</label>
                            <div className="field-input">
                              <Field
                                className="form-control entity__input"
                                name="email"
                                component={FormField}
                                onChange={(e) => this.onBlurHandler(e)}
                                type="text"
                                validate={[required(), email()]}
                              />
                              <div className="form__field-error">
                                {register.emailError ? register.emailError : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="field">
                            <label className="title">Country Code</label>
                            <div class="field-input">
                              <Select
                                showSearch
                                placeholder="Select a country"
                                optionFilterProp="children"
                                onChange={handleCountryChange}
                                filterOption={(input, option) => {
                                  return (
                                    option.props &&
                                    option.props.code
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }}
                                value={country_id}
                              >
                                {options}
                              </Select>
                              <span class="text-danger">
                                {errors.country_id}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-8">
                          <div className="field">
                            <label className="title">Phone Number</label>
                            <div className="field-input">
                              <Field
                                className="form-control entity__input"
                                name="phone"
                                component={FormField}
                                type="number"
                                validate={[required(), minLength(10)]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="field">
                            <label className="title">Password</label>
                            <div className="field-input">
                              <Field
                                className="form-control entity__input"
                                name="password"
                                component={FormField}
                                type="password"
                                validate={[required(), length({ min: 6 })]}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="field">
                            <label className="title">Confirm Password</label>
                            <div className="field-input">
                              <Field
                                className="form-control entity__input"
                                name="password_confirmation"
                                component={FormField}
                                type="password"
                                validate={[
                                  required(),
                                  matchPasswords(),
                                  length({ min: 6 }),
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="field">
                            <label className="title">Company Name</label>
                            <div className="field-input">
                              <Field
                                className="form-control entity__input"
                                name="company_name"
                                component={FormField}
                                type="text"
                                validate={[required()]}
                              />
                            </div>
                          </div>
                          {this.props.role === "Broker" && (
                            <div className="field">
                              <label className="title">
                                AFSL or Authorised Representative Number
                              </label>
                              <div className="field-input">
                                <Field
                                  className="form-control entity__input"
                                  name="afsl"
                                  component={FormField}
                                  type="text"
                                  validate={[required()]}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-sm-6">
                          <NewDropzone
                            multiple={false}
                            buttonLabel="Choose document"
                            title={"Upload Logo"}
                            accept="image/*"
                            // singleDocuments={this.props.wholeSaleDocument}
                            onUpload={this.props.onLogoUpload}
                          // onDocumentDeleteHandler={
                          //   this.props.onDocumentDeleteHandler
                          // }
                          // open={this.props.open}
                          // close={this.props.close}
                          // show={this.props.show}
                          />
                          {/* <InputFile onUpload={onLogoUpload} accept="image/*" /> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="field">
                            <Field
                              className="form-control entity__input"
                              name="terms_conditions"
                              component={FormField}
                              type="checkbox"
                              label={
                                <p>
                                  I accept the{" "}
                                  <Link
                                    to={"/broker/terms&conditions"}
                                    target="_blank"
                                  >{`Terms & Conditions`}</Link>{" "}
                                  and{" "}
                                  <Link
                                    to={"/privacy-policy"}
                                    target="_blank"
                                  >{`Privacy Policy`}</Link>
                                </p>
                              }
                              requiredError="Accept our terms and conditions"
                              validate={[required()]}
                              htmlFor="any"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mb-4">
                          <ReCAPTCHA
                            sitekey={CAPTCHA_KEY}
                            onChange={onreCaptchaChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="button-group">
                          <div className="col-xs-12 col-sm-6 col-md-6">
                            <ButtonLoader className="btn btn-primary btn-medium" disabled={!verfied}>
                              Create Account
                            </ButtonLoader>
                          </div>
                          {localStorage.getItem("auth_token") && (
                            <div className="col-xs-12 col-sm-6 col-md-6">
                              <button className="btn btn-outline-primary btn-medium">
                                Go to Main Page
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              )}
              {step === 2 && (
                <div class="form-normal form-create-account verify-code">
                  <h1 class="text-center mt-2">Verify Phone Number</h1>
                  <h6 class="text-center">
                    Please enter 6 digit verification code sent to{" "}
                    {full_phone}
                  </h6>
                  <Form onSubmit={handleSubmit}>
                    <div className="all-fields">
                      <div className="row">
                        <div className="col-sm-12">
                          <ReactCodeInput
                            className="verify-phone-input"
                            onChange={verifyCodeHandler}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-8 mx-auto mt-5">
                          <ButtonLoader
                            type="submit"
                            className="btn btn-primary btn-medium btn-block"
                          >
                            Verify
                          </ButtonLoader>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-8 mx-auto">
                          A code has been sent to your number
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 text-center mt-5">
                          Incorrect mobile number?{" "}
                          <a
                            href="javascript:void(0);"
                            // onClick={() => {
                            //   this.setState({
                            //     step: 1,
                            //   });
                            // }}
                              onClick={setStep}
                          >
                            Change it
                          </a>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    register: state.register,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkEmailExistence: (email) => checkEmail(dispatch, email),
});

export default reduxForm({ form: "BrokerForm" })(
  connect(mapStateToProps, mapDispatchToProps)(BrokerForm)
);

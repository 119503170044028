import React, { Component } from "react";
import Faq from "react-faq-component";
import { connect } from "react-redux";
import faqActions from "../../../redux/actions/faqActions";

class Faqs extends Component {
  componentDidMount() {
    this.props.fetchFaqs();
  }

  render() {
    let rows = this.props.faqs.map((faq) => {
      return {
        title: faq.question,
        content: faq.answer,
      };
    });
    console.log(rows);
    const data = {
      rows: rows,
    };

    const styles = {
      titleTextSize: "32px",
      titleTextFontWeight: "300",
      rowTitleColor: "#0096DA",
      rowContentPaddingBottom: "20px",
      rowContentPaddingLeft: "20px",
    };

    const config = {
      animate: true,
      //   tabFocus: true,
    };

    return (
      <main class="page-main">
        <div class="bl-about">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="page-deals page-all-deals">
                  <div class="title">
                    <div class="row">
                      <div class="col-sm-9">
                        <h1>Frequently Asked Questions</h1>
                      </div>
                    </div>
                  </div>
                  <Faq data={data} styles={styles} config={config} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const { fetchFaqs } = faqActions;

const mapDispatchToProps = (dispatch) => ({
  fetchFaqs: (payload, history) => fetchFaqs({ dispatch, payload, history }),
});
const mapStateToProps = (state) => {
  return {
    faqs: state.faqs.all,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Faqs);

import LiveService from "../../services/liveService";
import { toast } from "../../components/common/Toast";
import { startButtonLoading, stopButtonLoading } from "./loadingActions";

const actions = {
  GET_ALL_LIVE_STREAMS: "GET_ALL_LIVE_STREAMS",
  FETCH_LIVE_STREAM: "FETCH_LIVE_STREAM",
  CURRENT_LIVE_STREAM: "CURRENT_LIVE_STREAM",
  CURRENT_NOTIFICATION: "CURRENT_NOTIFICATION",

  getAll: (dispatch, payload) => {
    return dispatch({
      type: actions.GET_ALL_LIVE_STREAMS,
      payload,
    });
  },

  getAllLiveStreams: (dispatch, payload) =>
    LiveService.getAll(payload)
      .then((res) => {
        dispatch(actions.getAll(dispatch, res));
      })
      .catch((error) => {
        if (error) toast.error("Something went wrong.");
      }),

    
  getSelected: (dispatch, payload) => {
    dispatch({
      type: actions.FETCH_LIVE_STREAM,
      payload,
    });
  },

    
  getCurrent: (dispatch, payload) => {
    dispatch({
      type: actions.CURRENT_LIVE_STREAM,
      payload,
    });
  },
    
  getNotification: (dispatch, payload) => {
    dispatch({
      type: actions.CURRENT_NOTIFICATION,
      payload,
    });
  },

  getSelectedLiveStream: ({ dispatch, id }) => {
    dispatch(startButtonLoading);
    LiveService.getSelected({ payload: id })
      .then((res) => {
        dispatch(stopButtonLoading);
        actions.getSelected(dispatch, res.data.data);
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) toast.error("Something went wrong.");
      });
  },

  getCurrentLiveStream: (dispatch) => {
    dispatch(startButtonLoading);
    LiveService.getCurrentLiveStream()
      .then((res) => {
        dispatch(stopButtonLoading);
        actions.getCurrent(dispatch, res.data.data.id ? res.data.data: null);
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) toast.error("Something went wrong.");
      });
  },

  getCurrentNotification: (dispatch) => {
    dispatch(startButtonLoading);
    LiveService.getCurrentNotification()
      .then((res) => {
        dispatch(stopButtonLoading);
        actions.getNotification(dispatch, res.data.data.id ? res.data.data: null);
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) toast.error("Something went wrong.");
      });
  },

};

export default actions;

import DealService from "../../services/dealService";
import { toast } from "../../components/common/Toast";
import {
  startButtonLoading,
  stopButtonLoading,
  startLoader,
  stopLoader,
} from "./loadingActions";

const actions = {
  LOADING: "LOADING",

  FETCH_ALL_DEALS: "FETCH_ALL_DEALS",
  FETCH_ALL_DEALS_ERROR: "FETCH_ALL_DEALS_ERROR",
  FETCH_ALL_DEALS_SUCCESS: "FETCH_ALL_DEALS_SUCCESS",

  FETCH_ALL_PUBLIC_DEALS: "FETCH_ALL_PUBLIC_DEALS",
  FETCH_ALL_PUBLIC_DEALS_ERROR: "FETCH_ALL_PUBLIC_DEALS_ERROR",
  FETCH_ALL_PUBLIC_DEALS_SUCCESS: "FETCH_ALL_PUBLIC_DEALS_SUCCESS",

  FETCH_ALL_PUBLIC_DEALS_MARKET: "FETCH_ALL_PUBLIC_DEALS_MARKET",
  FETCH_ALL_PUBLIC_DEALS_MARKET_ERROR: "FETCH_ALL_PUBLIC_DEALS_MARKET_ERROR",
  FETCH_ALL_PUBLIC_DEALS_MARKET_SUCCESS:
    "FETCH_ALL_PUBLIC_DEALS_MARKET_SUCCESS",

  FETCH_SINGLE_ENTITY: "FETCH_SINGLE_ENTITY",
  FETCH_SINGLE_ENTITY_ERROR: "FETCH_SINGLE_ENTITY",
  FETCH_SINGLE_ENTITY_SUCCESS: "FETCH_SINGLE_ENTITY_SUCCESS",
  FETCH_SINGLE_DEAL_SUCCESS: "FETCH_SINGLE_DEAL_SUCCESS",

  CREATE_ENTITY: "CREATE_ENTITY",
  CREATE_ENTITY_ERROR: "CREATE_ENTITY_ERROR",
  CREATE_ENTITY_SUCCESS: "CREATE_ENTITY_SUCCESS",

  DELETE_ENTITY: "DELETE_ENTITY",
  DELETE_ENTITY_ERROR: "DELETE_ENTITY_ERROR",
  DELETE_ENTITY_SUCCESS: "DELETE_ENTITY_SUCCESS",
  FETCH_SINGLE_DEAL_STATUS: "FETCH_SINGLE_DEAL_STATUS",
  FETCH_ALL_CURRENT_DEALS_SUCCESS: "FETCH_ALL_CURRENT_DEALS_SUCCESS",
  FETCH_ALL_CLOSED_DEALS_SUCCESS: "FETCH_ALL_CLOSED_DEALS_SUCCESS",
  CHANGE_DEAL_STATUS: "CHANGE_DEAL_STATUS",
  GET_ALLOCATIONS: "GET_ALLOCATIONS",
  GET_ALL_ALLOCATIONS: "GET_ALL_ALLOCATIONS",
  DECLINE_ALLOCATION: "DECLINE_ALLOCATION",
  GET_ALL_OPTIONS: "GET_ALL_OPTIONS",

  dealSuccess(payload) {
    return { type: actions.FETCH_ALL_DEALS_SUCCESS, payload };
  },

  fetchCurrentDeals: ({ dispatch }) =>
    DealService.getCurrentBids()
      .then(({ data }) => {
        dispatch({
          type: actions.FETCH_ALL_CURRENT_DEALS_SUCCESS,
          payload: data,
        });
      })
      .catch(() => {}),

  fetchAllClosedDeals: ({ dispatch }) =>
    DealService.getAllClosedDeals()
      .then(({ data }) => {
        let placement = [];
        if (data) {
          for (let item of data) {
            placement.push(item);
          }
        }
        dispatch({
          type: actions.FETCH_ALL_CLOSED_DEALS_SUCCESS,
          type: actions.FETCH_ALL_CLOSED_DEALS_SUCCESS,
          payload: placement,
        });
      })
      .catch(() => {}),

  fetchAllPublic180Deals: ({ dispatch }) => {
    dispatch({
      type: actions.LOADING,
      payload: true,
    });
    DealService.getAllPublic()
      .then(({ data }) => {
        let placement = [];
        if (data) {
          for (let item of data) {
            if (item.type === "180") placement.push(item);
          }
        }

        dispatch({
          type: actions.FETCH_ALL_PUBLIC_DEALS_SUCCESS,
          payload: placement,
        });
        setTimeout(() => {
          dispatch({
            type: actions.LOADING,
            payload: false,
          });
        }, 2000);
      })
      .catch(() => {
        dispatch({
          type: actions.LOADING,
          payload: false,
        });
      });
  },
  fetchAllPublicMarketDeals: ({ dispatch }) =>
    DealService.getAllPublic()
      .then(({ data }) => {
        let market = [];
        if (data) {
          for (let item of data) {
            if (item.type === "market") market.push(item);
          }
        }
        dispatch({
          type: actions.FETCH_ALL_PUBLIC_DEALS_MARKET_SUCCESS,
          payload: market,
        });
      })
      .catch(() => {}),

  fetchDEALS: ({ dispatch, payload }) => {
    startLoader({ dispatch, type: "AllDeals" });
    DealService.getAll(payload)
      .then(({ data }) => {
        stopLoader({ dispatch, type: "AllDeals" });
       
          dispatch({ type: actions.FETCH_ALL_DEALS_SUCCESS, payload: data });
      })
      .catch(() => {
        stopLoader({ dispatch, type: "AllDeals" });
      });
  },

  fetchSingleDeal: (dispatch, payload) => {
    dispatch({
      type: actions.FETCH_SINGLE_DEAL_SUCCESS,
      payload,
    });
  },

  fetchSingleStatus: (dispatch, payload) => {
    dispatch({
      type: actions.FETCH_SINGLE_DEAL_STATUS,
      payload,
    });
  },

  getAllocationsData: (dispatch, payload) => {
    dispatch({
      type: actions.GET_ALLOCATIONS,
      payload,
    });
  },

  getAllAllocationsData: (dispatch, payload) => {
    return dispatch({
      type: actions.GET_ALL_ALLOCATIONS,
      payload,
    });
  },

  getOptionsData: (dispatch, payload) => {
    return dispatch({
      type: actions.GET_ALL_OPTIONS,
      payload,
    });
  },

  createDeal: ({ payload, dispatch, history }) => {
    dispatch(startButtonLoading);
    DealService.createDeal(payload)
      .then(async () => {
        dispatch(stopButtonLoading);

        await toast.success("Deal Created Successfully.");
        history.push("/user/deals");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);

        let errorMessage = "";
        if (error.error) {
          errorMessage = error.error;
        }
        if (errorMessage) {
          toast.error("errorMessage");
        } else {
          toast.error("Something went wrong. " + errorMessage + "");
        }
      });
  },

  updateDeal: (payload, history, dispatch) => {
    dispatch(startButtonLoading);
    DealService.updateDeal(payload)
      .then(async () => {
        dispatch(stopButtonLoading);
        toast.success("Deal Updated Successfully.");

        await actions.fetchDEALS({ dispatch });
        history.push("/user/deals");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) toast.error("Something went wrong.");
      });
  },

  acceptAllocationStatus: (payload, history, dispatch) => {
    dispatch(startButtonLoading);
    DealService.acceptAllocation(payload)
      .then(async () => {
        dispatch(stopButtonLoading);
        toast.success("Allocation accepted Successfully.");

        await actions.fetchDEALS({ dispatch });
        history.push("/allocations/thanks/accept");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) toast.error("Something went wrong.");
      });
  },

  declineAllocationStatus: (payload, history, dispatch) => {
    dispatch(startButtonLoading);
    DealService.declineAllocation(payload)
      .then(async () => {
        dispatch(stopButtonLoading);
        toast.success("Allocation declined Successfully.");

        await actions.fetchDEALS({ dispatch });
        history.push("/allocations/thanks/decline");
      })
      .catch((error) => {
        dispatch(stopButtonLoading);
        if (error) toast.error("Something went wrong.");
      });
  },

  getSingleDeal: (payload, dispatch) =>
    DealService.singleDeal(payload)
      .then((res) => {
        dispatch(actions.fetchSingleStatus(dispatch, res));
      })
      .catch(() => {}),

  getAllocations: (payload, dispatch) =>
    DealService.allocations(payload)
      .then((res) => {
        dispatch(actions.getAllocationsData(dispatch, res));
      })
      .catch(() => {}),

  getAllAllocations: (dispatch) =>
    DealService.allAllocations()
      .then((res) => {
        dispatch(actions.getAllAllocationsData(dispatch, res));
      })
      .catch((error) => {
        if (error) toast.error("Something went wrong.");
      }),

  getOptions: (dispatch) =>
    DealService.getOptions()
      .then((res) => {
        dispatch(actions.getOptionsData(dispatch, res));
      })
      .catch((error) => {
        if (error) toast.error("Something went wrong.");
      }),

  singlePublicDeal: (payload, dispatch) =>
    DealService.singlePublicDeal(payload)
      .then((res) => {
        dispatch(actions.fetchSingleDeal(dispatch, res));
      })
      .catch(() => {}),

  deleteDeal: ({ id, dispatch }) =>
    DealService.deleteDeal(id)
      .then(() => {
        toast.success("Deal Deleted Successfully.");

        dispatch(actions.fetchDEALS({ dispatch }));
      })
      .catch(() => {}),

  changeDealStatus: ({ id, dispatch }) =>
    DealService.changeDealStatus(id)
      .then(() => {
        toast.success("Deal Closed Successfully.");

        dispatch(actions.fetchDEALS({ dispatch }));
      })
      .catch(() => {}),
};
export default actions;

import { toast } from "../../components/common/Toast";

import {
  FETCH_USER,
  START_PROFILE_UPDATE,
  UPDATE_AUTH,
  START_PREFERENCE_UPDATE,
  END_PREFERENCE_UPDATE,
  PREFERENCE_UPDATE_SUCCESS,
  PREFERENCE_UPDATE_FAILED,
} from "./constants";

import auth from "../../services/authService";
import { startButtonLoading, stopButtonLoading } from "./loadingActions";
import { reset } from "redux-form";
import { getCurrentUser } from "./authActions";

const updatePreferences = (dispatch, payload, history, redirectOnSave) => {
  console.log(redirectOnSave);
  dispatch({ type: START_PREFERENCE_UPDATE });
  auth.preferences
    .investor(payload)
    .then((data) => {
      let { currentUser } = data;
      if (currentUser !== null) {
        dispatch({
          type: UPDATE_AUTH,
          payload: { currentUser: currentUser.data },
        });
        dispatch({ type: PREFERENCE_UPDATE_SUCCESS, payload: data.message });
      } else {
        dispatch({ type: PREFERENCE_UPDATE_FAILED, payload: data.error });
      }
      dispatch({ type: END_PREFERENCE_UPDATE });
      toast.success("Preferences updated successfully.");
      if (redirectOnSave) {
        console.log("history", history);
        history.push("/user/profile");
      }
    })
    .catch((error) => {
      console.log(error);

      dispatch({ type: END_PREFERENCE_UPDATE });
      dispatch({ type: PREFERENCE_UPDATE_FAILED, payload: error });
    });
};

const updateSinglePreference = (dispatch, payload, history) => {
  auth.preferences
    .updateSingle(payload)
    .then((data) => {})
    .catch((error) => {});
};

const clearAlert = {
  type: "CLEAR_PROFILE_ALERT",
};

const updateProfile = (dispatch, payload) => {
  dispatch({ type: START_PROFILE_UPDATE });
  dispatch(startButtonLoading);

  auth.profile
    .broker(payload)
    .then(({ data }) => {
      dispatch(stopButtonLoading);
      toast.success("Profile Updated Successfully.");
      dispatch({ type: "UPDATE_BROKER_PROFILE", payload: data });

    })
    .catch((error) => {
      dispatch(stopButtonLoading);

      let errorMessage = "";
      if (error.errors && error.errors.email) {
        errorMessage = error.errors.email[0];
      }
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error("Something went wrong. " + errorMessage + "");
      }
    });
};

const updateInvstorProfile = (dispatch, payload, history) => {
  dispatch({ type: START_PROFILE_UPDATE });
  auth.profile
    .investor(payload)
    .then(({ data }) => {
      toast.success("Profile Updated Successfully.");
      dispatch({ type: "UPDATE_BROKER_PROFILE", payload: data });

      if (payload.redirectUrl){
        history.push(payload.redirectUrl);
      }
    })
    .catch((error) => {
      let errorMessage = "";
      if (error.errors && error.errors.email) {
        errorMessage = error.errors.email[0];
      }
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error("Something went wrong. " + errorMessage + "");
      }
    });
};

const submitStockTip = (dispatch, payload) => {
  auth
    .submitStockTip(payload)
    .then(({ data }) => {
      if (data.data.id) {
        getCurrentUser(dispatch);
        dispatch(reset("StockTipForm"));
        toast.success("Stock Tip Added Successfully");
      } else {
        throw data.data.error;
      }
    })
    .catch((error) => {
      console.log(error);
      toast.error(error);
    });
};

export {
  updateProfile,
  updateInvstorProfile,
  updatePreferences,
  clearAlert,
  updateSinglePreference,
  submitStockTip,
};

import React from "react";
import MobileLogo from "../../assets/images/Group 7967.png";
import AppleStoreImg from "../../assets/images/Group-7968.png";
import GoogleStoreImg from "../../assets/images/Group7969.png";
function TopFooter() {
  return (
    <>
      <div className="top_footer">
        <div className="container">
          <div
            className="row"
            // style={{
            //   margin: "0 64px",
            // }}
          >
            <div className="col-lg-5">
              <img src={MobileLogo} className="img-fluid app__img" />
            </div>
            <div className="col-lg-6 offset-lg-1 top__footerText mt_12em px-5 pb-5">
              <h3 className="top_footer_heading">Get the App</h3>
              <p className="top_footer_p">
                View and bid on live placements and IPOs
              </p>
              <div className="top_footer_images">
                <div className="row">
                  <div className="col-6 pr-0 col-lg-4">
                    <a
                      href="https://apps.apple.com/us/app/180-markets/id1530301129"
                      target="_blank"
                    >
                      {" "}
                      <img
                        src={AppleStoreImg}
                        className="border footer_top_logo ml-0"
                      />{" "}
                    </a>
                  </div>
                  <div className="col-6 pl-0 col-lg-4">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.markets.app"
                      target="_blank"
                    >
                      {" "}
                      <img
                        src={GoogleStoreImg}
                        className="border footer_top_logo"
                      />{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopFooter;

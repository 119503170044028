import { toast } from "../../components/common/Toast";
import StockTipsService from "../../services/stockTipsService";

const actions = {
    FETCH_ALL_LEADER_BOARDS: "FETCH_ALL_LEADER_BOARDS",

  allLeaderBoards: (dispatch, payload) => {
    dispatch({
      type: actions.FETCH_ALL_LEADER_BOARDS,
      payload,
    });
  },

  getAllLeaderBoards: (dispatch) => {
    StockTipsService.getAllLeaderBoards()
      .then((res) => {
        actions.allLeaderBoards(dispatch, res.data.data);
      })
      .catch((error) => {
        if (error) toast.error("Something went wrong.");
      });
  },

};

export default actions;

import React, { Component } from "react";
import { connect } from "react-redux";
import ArticleActions from "../../../../redux/actions/articleActions";

class ArticleView extends Component {
  componentDidMount() {
    const { match } = this.props;
    this.props.fetchSingleArticle({ slug: match.params.slug });
  }

  render() {
    const { article } = this.props;
    return (
      <main className="page-deal-add-doc">
        {article && article.id ? (
          <div className="container">
            <div className="row">
              <div className="offset-md-2 col-md-8 mt-4">
                <div className="row">
                  <div className="col-md-12">
                    <h3 class="hero_hedading text-center how_it_work">{ article.title }</h3>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-12">
                    {article.featured_image_url && (
                      <img
                        className="img-fluid"
                        src={`${article.featured_image_url}`}
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className="row my-5">
                  <div className="col-md-12">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: article.content,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center py-5">
            No data found!
          </div>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  article: state.articles.singleArticle,
});

const { fetchSingleArticle } = ArticleActions;

const mapDispatchToProps = (dispatch) => ({
  fetchSingleArticle: (payload) => fetchSingleArticle(dispatch, payload),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleView);

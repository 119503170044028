import { Tabs } from "antd";
import React, { Component } from "react";
import { TabPane } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import StockTipsActions from "../../../../redux/actions/stockTipsActions";
import { customStyles } from "../../../common/CommonMethods";

class LeaderBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
    };
  }

  componentDidMount() {
    this.props.getAllLeaderBoards();
  }

  callback = (key) => {
    this.setState({ activeTab: key });
  };

  render() {
    const { activeTab } = this.state;

    const columns = [
      {
        name: "Nickname",
        selector: "nickname",
        left: true,
      },
      {
        name: "ASX Code",
        selector: "asx_code",
        left: true,
      },
      {
        name: "% Change",
        selector: "difference",
        cell: (row) => (
          <>
            <div className="company-logo">
              {row.difference && (
                `${row.difference}%`
              )}
            </div>
          </>
        ),
      },
    ];

    return (
      <main className="page-deal-add-doc">
        <div className="container">
          <div className="row">
            <div class="col-md-12">
              <div className="md-deal-form mb-20">
                <div className="page-deals page-all-deals">
                  <div className="title">
                    <div className="row">
                      <div className="col-md-6  mx-auto">
                        <Tabs defaultActiveKey="1" onChange={this.callback}>
                          <TabPane
                            tab={<span className="nav-link-top ">Top 30</span>}
                            key="1"
                          ></TabPane>

                          <TabPane
                            tab={
                              <span className="nav-link-top ">Bottom 30</span>
                            }
                            key="2"
                          ></TabPane>
                        </Tabs>
                     
                        {parseInt(activeTab) === 1 ? (
                          <>
                            <div className="row">
                              <div className="col-sm-9">
                                <h1
                                  style={{ fontSize: "40", fontWeight: "500" }}
                                >
                                  Top 30
                                </h1>
                                <DataTable
                                  noHeader
                                  overflowY
                                  highlightOnHover
                                  responsive={true}
                                  columns={columns}
                                  data={this.props.leaderBoards?.leaderboards}
                                  style={{ width: "100%" }}
                                  pointerOnHover={true}
                                  sortServer={true}
                                  noDataComponent={
                                    <div className="p-4">Loading...</div>
                                  }
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="row">
                              <div className="col-sm-9">
                                <h1
                                  style={{ fontSize: "40", fontWeight: "500" }}
                                >
                                  Bottom 30
                                </h1>
                                <DataTable
                                  noHeader
                                  overflowY
                                  highlightOnHover
                                  responsive={true}
                                  columns={columns}
                                  data={this.props.leaderBoards?.rankings}
                                  style={{ width: "100%" }}
                                  pointerOnHover={true}
                                  sortServer={true}
                                  noDataComponent={
                                    <div className="p-4">Loading...</div>
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const { getAllLeaderBoards } = StockTipsActions;

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  leaderBoards: state.stocktips.leaderBoards,
});

const mapDispatchToProps = (dispatch) => ({
  getAllLeaderBoards: () => getAllLeaderBoards(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaderBoard);

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import HomeRegisterForm from "./InvestorRegisterForm";
import { registerUser } from "../../../redux/actions/authActions";
import InvestorRegisterForm from "./InvestorRegisterForm";
import BrokerRegisterForm from "./BrokerRegisterForm";
import BrokerForm from "../../forms/BrokerForm";
import { toast } from "../../common/Toast";
import auth from "../../../services/authService";
class HomeRegisterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploaded: [],
      verfied: false,
      recaptchaToken: null,
      country_id: 1,
      step: 1,
      full_phone: null,
      code: null,
      errors: {},
      phone: null,
    };
  }

  handleSubmit = values => {
    values.password_confirmation = values.password;
    const { history, role } = this.props;
    let { uploaded } = this.state;
    if (uploaded) {
      values.logo = uploaded;
    }
    this.setState({
      phone: values.phone,
    });
    let errors = {};
    let phone = this.state.phone;
    const { countries } = this.props;
    let country_id = this.state.country_id;
    let country = countries.find((country) => country.id === country_id);
    let countryCode = country ? `+${country.phonecode}` : "";
    if (!phone || phone === "") {
      errors.phone = "Phone number is required";
    } else {
      if (phone.length < 8) {
        errors.phone = "Invalid phone number";
      }
    }
    if (!country_id || country_id === "") {
      errors.country_id = "Country is required";
    }
    if (phone && phone.length && phone[0] === "0") {
      phone = phone.replace(/0/, countryCode);
    } else {
      phone = countryCode + phone;
    }
    this.setState({
      errors,
      full_phone: phone,
    });
    if (Object.keys(errors).length) {
      return;
    }

    if (this.state.step === 1) {
      if (this.state.verfied === true) {
        auth
          .verifyPhone({ phone, "g-recaptcha-response": this.state.recaptchaToken })
          .then(async (data) => {
            if (data.success) {
              this.setState({
                verificationCode: true,
                step: 2,
              });
              // await toast.success("Verification Code Sent");
            } else {
              await toast.error("Failed to send verification code");
            }
          })
          .catch((error) => {
            if (error && error.errors && error.errors['g-recaptcha-response']) {
              toast.error("Invalid captcha");
            } else {
              toast.error("Server Error");
            }
          });
      } else {
        toast.error("Please Verify Recaptcha");
      }
    } else {
      let code = this.state.code;
      if (!code || code === "") {
        errors.code = "Verification Code is required";
        this.setState({
          errors,
        });
      }
      if (Object.keys(errors).length) {
        return;
      }
      auth
        .verifyCode({ phone, code })
        .then(async (data) => {
          if (data.verified) {
            const { history } = this.props;
            await this.props.register({ ...values, role, "g-recaptcha-response": this.state.recaptchaToken }, history);
          } else {
            await toast.error("Verification failed: Try again");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Server Error");
        });
    }
  };

  onLogoUpload = files => {
    this.setState({ uploaded: files });
  };

  onChange = (value) => {
    this.setState({
      verfied: true,
      recaptchaToken: value,
    });
  }

  handleCountryChange = (value) => {
    this.setState({
      country_id: value,
    });
  };

  verifyCodeHandler = (value) => {
    this.setState({
      code: value,
    });
  };

  render() {
    const { role } = this.props;
    return (
      <Fragment>
        {role === "Investor" && (
          <InvestorRegisterForm
            onSubmit={this.handleSubmit}
            role={role}
            onLogoUpload={this.onLogoUpload}
          />
        )}
        {role === "Broker" && (
          <BrokerRegisterForm
            onSubmit={this.handleSubmit}
            role={role}
            onLogoUpload={this.onLogoUpload}
            onreCaptchaChange={this.onChange}
            verfied={this.state.verfied}
            handleCountryChange={this.handleCountryChange}
            country_id={this.state.country_id}
            countries={this.props.countries}
            step={this.state.step}
            full_phone={this.state.full_phone}
            verifyCodeHandler={this.verifyCodeHandler}
            errors={this.state.errors}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  optedType: state.register.optedType,
  regState: state.register,
  countries: state.countries.countries,
});

const mapDispatchToProps = dispatch => ({
  register: (payload, history) => registerUser(dispatch, payload, history)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeRegisterContainer);

import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ViewBids from "../../../../common/ViewBids";
import { BaseUrl } from "../../../../../services/settings";
import Confirm from "../../../../common/Confirm";
import dealActions from "../../../../../redux/actions/dealActions";
import { connect } from "react-redux";
import { Icon } from "antd";
import { withRouter } from "react-router-dom";
import {
  customStyles,
  renderData,
  dueDateFormat,
} from "../../../../common/CommonMethods";
class ClosedDeals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      open_view: false,
    };
  }

  componentDidMount = () => {};

  open = (id) => {
    this.setState({ open: true, dealId: id });
  };

  close = () => this.setState({ open: false, open_view: false });

  deleteDeal = (id) => {
    let { deleteSingleDeal } = this.props;
    deleteSingleDeal(id);
    this.setState({ open: false });
  };

  onView = (id) => {
    this.props.history.push(`/user/deal-view/${id}`);
  };

  openBid = (id) => {
    this.setState({ open_view: true, view_dealId: id });
  };

  getBidsCount = (dealId) => {
    let res = 0;
    if (this.props.bids) {
      for (let item of this.props.bids) {
        if (item.deal_id === dealId) res++;
      }
    }
    return res;
  };

  render() {
    const handleChange = () => {};
    const columns = [
      {
        name: "",
        selector: "deal_logo",
        sortable: false,
        // style: { minWidth: "120px" },
        cell: (row) => (
          <>
            <div className="company-logo">
              {row.deal_logo && (
                <img
                  style={{
                    maxWidth: "70px",
                    maxHeight: "70px",
                    float: "left",
                  }}
                  src={`${BaseUrl}/storage/${row.deal_logo.id}/${row.deal_logo.file_name}`}
                  alt=""
                />
              )}
            </div>
          </>
        ),
      },
      {
        name: "Company",
        selector: "company_name",
        sortable: false,
        className: "company no-wrap",
        style: { minWidth: "130px" },
        cell: (row) => (
          <>
            <div class="wrap">
              <span>{row.company_name}</span>
            </div>
          </>
        ),
      },
      {
        name: "ASX Code",
        selector: "asx_code",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.asx_code && row.asx_code}</div>,
      },
      {
        name: "Pre-Placement Price",
        selector: "last_price",
        sortable: false,
        left: true,
        cell: (row) => <div>{row.last_price && `$${row.last_price}`}</div>,
      },
     

      {
        name: "Capital Raise Price",
        selector: "capital_raise",
        sortable: false,
        left: true,
        cell: (row) => (
          <div>{row.capital_raise ? `$${row.capital_raise}` : ""}</div>
        ),
      },
      {
        name: "Raise Amount",
        selector: "raise_amount",
        left: true,
        cell: (row) => (
          <div>{row.raise_amount ? `${renderData(row.raise_amount)}` : ""}</div>
        ),
      },
      {
        name: "Discount",
        selector: "discount",
        sortable: false,
        left: true,
        cell: (row) => (
          <div>
            {!isNaN(parseInt(row.discount))
              ? parseFloat(row.discount) + "%"
              : ""}
          </div>
        ),
      },
      {
        name: "Market Capitalisation",
        selector: "market_capital",
        sortable: false,
        left: true,
        cell: (row) => (
          <div>{row.market_capital && `${renderData(row.market_capital)}`}</div>
        ),
      },
      {
        name: "Last Price",
        selector: "current_price",
        sortable: false,
        left: true,
        cell: (row) => (
          <div>{row.current_price ? `$${row.current_price}` : ""}</div>
        ),
      },
      {
        name: "Percentage Change",
        sortable: false,
        left: true,
        cell: (row) => (
          <>
            {row.difference ? (
              <div className={`${row.difference > 0 ? 'font-green' : 'font-red'}`}>
                {row.difference > 0 ? (
                  <Icon type="caret-up" style={{ fontSize: '16px', color: 'green' }}/>
                ) : (
                  <Icon type="caret-down" style={{ fontSize: '16px', color: 'red' }}/>
                )}
                {row.difference ? `${row.difference}%` : ""}
              </div>
            ) : (
              ""
            )}
          </>
        ),
      },
      // {
      //   name: "Bids Due",
      //   selector: "bids_due",
      //   sortable: false,
      //   left: true,
      //   cell: (row) => <div>{row.bids_due && row.bids_due}</div>,
      // },
    ];

    const ipoColumns = [
      {
        name: "",
        selector: "deal_logo",
        sortable: false,
        cell: (row) => (
          <>
            <div
              className="company-logo"
              onClick={() => this.handleChange(row)}
            >
              {row.logo && (
                <img
                  style={{
                    maxWidth: "70px",
                    maxHeight: "70px",
                    float: "left",
                  }}
                  src={`${BaseUrl}/storage/${row.logo.id}/${row.logo.file_name}`}
                  alt=""
                />
              )}
            </div>
          </>
        ),
      },
      {
        name: "Company",
        selector: "company_name",
        sortable: false,
        className: "company no-wrap",
        style: { minWidth: "130px" },
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)}>
              <span>{row.company_name} </span>
            </div>
          </>
        ),
      },
      {
        name: "ASX code",
        selector: "asx_code",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.asx_code && `${row.asx_code}`}
          </div>
        ),
      },
      {
        name: "Raise Amount",
        selector: "raise_amount",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.raise_amount ? renderData(row.raise_amount) : ""}
          </div>
        ),
      },
      {
        name: "IPO Price",
        selector: "ipo_price",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.ipo_price ? renderData(row.ipo_price) : ""}
          </div>
        ),
      },
      {
        name: "Market Capitalisation",
        selector: "price",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.market_cap && `${renderData(row.market_cap)}`}
          </div>
        ),
      },
      {
        name: "Proposed List Date ",
        selector: "total_price ",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.listing_date && row.listing_date}
          </div>
        ),
      },
      {
        name: "Bids Due",
        selector: "bids_due",
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.bids_due && dueDateFormat(row.bids_due)}
          </div>
        ),
      },
      {
        name: "Percentage Change",
        sortable: false,
        left: true,
        cell: (row) => (
          <>
            {row.difference ? (
              <div className={`${row.is_increasing ? 'font-green' : 'font-red'}`}>
                {row.is_increasing ? (
                  <Icon type="caret-up" style={{ fontSize: '16px', color: 'green' }}/>
                ) : (
                  <Icon type="caret-down" style={{ fontSize: '16px', color: 'red' }}/>
                )}
                {row.difference ? `${row.difference}%` : ""}
              </div>
            ) : (
              ""
            )}
          </>
        ),
      },
    ];
    const { accessDeals, dealAccessRedMessage } = this.props;

    return (
      <div className="md-table-normal">
        {/* <h5>Last 50 placements on 180 Markets</h5> */}
        <DataTable
          noHeader
          highlightOnHover
          columns={this.props.type === "Placement" ? columns : ipoColumns}
          pagination={false}
          paginationPerPage={100}
          paginationRowsPerPageOptions={[10, 50, 100, 200, 300, 400, 500]}
          paginationTotalRows={this.props.deals.length}
          data={accessDeals ? this.props.deals : []}
          sortServer={true}
          onSelectedRowsChange={handleChange}
          customStyles={customStyles}
          noDataComponent={accessDeals ? (<div className="p-4"> Stand by! More great Placements are on their way!</div>) : (<div className="deal-access-error outline cursor-pointer">{dealAccessRedMessage}</div>)}
        />
        {this.state.open_view ? (
          <ViewBids
            show={this.state.open_view}
            handleClose={this.close}
            bids={this.props.bids}
            deal_id={this.state.view_dealId}
          />
        ) : null}
        {this.state.open ? (
          <Confirm
            message="Are you sure you want to close this deal?"
            closeButtonText="Cancel"
            deleteButtonText="Yes, Close"
            show={this.state.open}
            handleDelete={() => this.deleteDeal(this.state.dealId)}
            handleClose={this.close}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bids: state.bids.bids,
    deals: state.deals.deals,
  };
};

const { fetchDEALS, deleteDeal } = dealActions;

const mapDispatchToProps = (dispatch) => ({
  deleteSingleDeal: (id) => deleteDeal({ dispatch, id }),
  fetchAllDEALS: (payload, history) =>
    fetchDEALS({ dispatch, payload, history }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClosedDeals));

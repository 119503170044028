import actions from "../actions/countryActions";

const initialState = {
  isLoading: null,
  countries: []
};

const countryReducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case actions.FETCH_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: payload
      };

    default:
      return state;
  }
};

export default countryReducer;

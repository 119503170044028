import React, { Component } from "react";
import { Modal } from "antd";
import moment from "moment";
import NumberFormat from "react-number-format";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FileUpload } from "../../../common/Graphics";
import DealsViewForm from "./DealsViewForm/DealsViewForm";
import { connect } from "react-redux";
import EntityActions from "../../../../redux/actions/entityActions";
import dealActions from "../../../../redux/actions/dealActions";
import { withRouter } from "react-router-dom";
import BidActions from "../../../../redux/actions/bids.Actions";
import { BaseUrl } from "../../../../services/settings";
import ModalComponent from "../../../common/Modal";
import { renderData } from "../../../common/CommonMethods";

const queryString = require("query-string");

class DealView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectEntityOptions: [],
      open: false,
      openLightBox: false,
      modalOpen: false,
      file: "",
      anotherBid: false,
      selectedBid: null,
    };
  }

  openLightBox = (file) => {
    this.setState({ openLightBox: true, file });
  };

  closeLightBox = () => {
    this.setState({ openLightBox: false });
  };

  modalOpen = () => {
    this.setState({ modalOpen: true });
  };

  modalClose = () => {
    this.setState({ modalOpen: false });
  };

  componentDidMount = () => {
    let {
      getSingleDealData,
      fetchAllEntities,
      match,
      currentUser,
      getDealStatus,
      history,
    } = this.props;
    if (currentUser && localStorage.getItem("auth_token")) {
      fetchAllEntities();
      getDealStatus(match.params.uuid);
    }
    const isApproved = currentUser.is_approved === "Approved" ? true : false;
    if (!isApproved) {
      history.push("/user/deals");
    }
    getSingleDealData(match.params.uuid);
  };

  renderIndividualName = (entity) => {
    let name = "";
    for (let key in entity.entity_individuals) {
      if (name.length > 0) {
        return (name +=
          " " +
          "&" +
          " " +
          entity.entity_individuals[key].given_name +
          " " +
          entity.entity_individuals[key].sur_name +
          " " +
          `${
            entity.entity_individuals[key].nickname
              ? `(${entity.entity_individuals[key].nickname})`
              : ""
          }`);
      }

      name +=
        entity.entity_individuals[key].given_name +
        " " +
        entity.entity_individuals[key].sur_name +
        " " +
        `${
          entity.entity_individuals[key].nickname
            ? `(${entity.entity_individuals[key].nickname})`
            : ""
        }`;
    }

    return name;
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.existingEntity !== nextProps.existingEntity &&
      nextProps.currentUser.id
    ) {
      this.setState({
        selectEntityOptions:
          nextProps.existingEntity &&
          nextProps.existingEntity.map((entity) => {
            if (entity.entity_company !== null)
              return {
                value: entity.id,
                pid: entity.pid,
                text: `${entity.broker_name} - ${entity.entity_company.name} ${
                  entity.entity_company.nickname
                    ? `(${entity.entity_company.nickname})`
                    : ""
                }`,
                is_si_form_expired: entity.is_si_form_expired,
              };
            else if (entity.entity_trust !== null)
              return {
                value: entity.id,
                pid: entity.pid,
                text: `${entity.broker_name} - ${entity.entity_trust.name} ${
                  entity.entity_trust.nickname
                    ? `(${entity.entity_trust.nickname})`
                    : ""
                }`,
                //value: entity.id,
                is_si_form_expired: entity.is_si_form_expired,
              };
            else if (entity.entity_individual !== null)
              return {
                value: entity.id,
                pid: entity.pid,
                text: `${entity.broker_name} - ${this.renderIndividualName(
                  entity
                )}`,
                //value: entity.id,
                is_si_form_expired: entity.is_si_form_expired,
              };
          }),
      });
    }
  };

  cancelBidHanler = (id) => {
    let { cancelBid, history } = this.props;

    this.setState({ open: false });
    cancelBid({ bidId: id }, history);
  };

  editBidHandler = (id, bidId) => {
    const { location } = this.props;

    const parsed = queryString.parse(location.search);
    if (parsed) {
      if (parseInt(parsed.bid) === 1) {
        this.props.history.push(`/user/edit-bid/${id}/${bidId}?bid=1`);
      } else {
        this.props.history.push(`/user/edit-bid/${id}/${bidId}`);
      }
    } else {
      this.props.history.push(`/user/edit-bid/${id}/${bidId}`);
    }
  };

  onSubmitBid = async (values) => {
    let {
      createBidSubmit,
      currentDeal,
      bidStatus,
      currentUser,
      createIndicationSubmit,
      history,
      match,
    } = this.props;
    if (currentDeal.type === "market") {
      createIndicationSubmit(
        {
          ...values,
          deal_id: currentDeal.id,
          investor_id: currentUser.id,
        },
        history
      );
    } else if (currentDeal.type === "180") {
      Modal.confirm({
        title: "Confirm",
        width: 700,
        okButtonProps: {
          type: "primary",
          size: "large",
        },
        cancelButtonProps: {
          size: "large",
        },
        icon: <ExclamationCircleOutlined />,
        content: (
          <p className="pconfirm">
            By submitting a bid, you are making a binding and irrevocable offer
            to acquire the securities, on the terms set out in the information
            available here, in the{" "}
            {currentDeal && currentDeal.term_sheets ? (
              <a
                target="_blank"
                href={`${BaseUrl}storage/${currentDeal.term_sheets.id}/${currentDeal.term_sheets.file_name}`}
              >
                Term sheet
              </a>
            ) : (
              "Term sheet"
            )}{" "}
            and in the{" "}
            {
              <a
                target="_blank"
                href="https://180markets.com.au/investor/terms&conditions"
              >
                Investor Terms
              </a>
            }
            <span> and in the </span>
              <a
                target="_blank"
                href={`/user/deal-disclaimer/${match.params.uuid}`}
              >
                Investment Opportunity Disclaimer 
              </a>
            . You confirm you make each of the representations and warranties
            specified in the Investor Terms. Your bid does not guarantee that
            you will receive an allocation. If your bid is successful, you will
            be required to pay to acquire the securities in accordance with your
            bid and the Investor Terms, subject to any scale back.
          </p>
        ),
        okText: "Confirm",
        cancelText: "Cancel",
        async onOk() {
          await createBidSubmit(
            {
              ...values,
              deal_id: currentDeal.id,
              deal_uuid: match.params.uuid,
            },
            history
          );
        },
        onCancel() {},
      });
    }
  };

  onBidNow = (id) => {
    this.setState({
      anotherBid: true,
      selectedBid: null,
    });
    // this.props.onBidNow(id, true);
  };

  render() {
    let { currentDeal, currentUser, bidStatus, location } = this.props;
    const parsed = queryString.parse(location.search);

    const { selectedBid, anotherBid, selectEntityOptions } = this.state;
    let entitiesNotUsed = [...selectEntityOptions].filter((entity) => {
      return !(
        bidStatus &&
        bidStatus.myBids &&
        bidStatus.myBids.some((bid) => bid.entity_id === entity.value)
      );
    });

    return (
      <main className="page-deal-add-doc">
        <div className="md-top-deal">
          <div className="container">
            <div className="deal-inner">
              <div className="row">
                <div className="col-md-2">
                  <div className="logo">
                    <div className="inner">
                      <img
                        src={
                          currentDeal &&
                          currentDeal.deal_logo &&
                          `${BaseUrl}/storage/${currentDeal.deal_logo.id}/${currentDeal.deal_logo.file_name}`
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="information">
                    <h1>{currentDeal && currentDeal.company_name}</h1>
                    {currentDeal && currentDeal.asx_code && (
                      <p className="sub-title">
                        ASX:
                        {currentDeal.asx_code}
                      </p>
                    )}
                    <div className="detail">
                      <div className="row">
                        {currentDeal && currentDeal.last_price && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Last Price</p>
                              <p className="number">
                                ${currentDeal.last_price}
                              </p>
                            </div>
                          </div>
                        )}
                        {currentDeal && currentDeal.capital_raise && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Capital Raise Price</p>
                              <p className="number">
                                {`$${currentDeal.capital_raise}`}
                              </p>
                            </div>
                          </div>
                        )}
                        {currentDeal && currentDeal.raise_amount && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Raise Amount</p>
                              <p className="number">
                                {`${renderData(currentDeal.raise_amount)}`}
                              </p>
                            </div>
                          </div>
                        )}
                        {currentDeal && currentDeal.discount && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Discount </p>
                              <p className="number">
                                {currentDeal.discount + " %"}
                              </p>
                            </div>
                          </div>
                        )}
                        {currentDeal && currentDeal.market_capital && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Market Capitalisation</p>
                              <p className="price">
                                {renderData(currentDeal.market_capital)}
                              </p>
                            </div>
                          </div>
                        )}
                        {currentDeal &&
                          currentDeal.free_option &&
                          currentDeal.dividend_option && (
                            <div className="col-sm-2">
                              <div className="inner">
                                <p className="text">Free Options</p>
                                <p className="price">
                                  {`${currentDeal.dividend_option}:${currentDeal.free_option} ${currentDeal?.option_details ? currentDeal.option_details : '' }`}
                                </p>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md-tabs tab-deal-add-doc">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                {currentDeal && currentDeal.bids_due && (
                  <p>
                    <b>Bids Due: </b>{" "}
                    {moment(currentDeal.bids_due, "DD/MM/YYYY HH:mm").format(
                      "MMM. DD, YYYY hh:mm A"
                    )}
                  </p>
                )}
                {currentDeal && currentDeal.settlement_date && (
                  <p>
                    <b>Settlement Date: </b>{" "}
                    {moment(currentDeal.settlement_date, "DD/MM/YYYY").format(
                      "MMM. DD, YYYY"
                    )}
                  </p>
                )}

                <div className="row mt-5">
                  {currentDeal && (
                    <div className="col-md-12 mb-4">
                      {currentDeal && currentDeal.term_sheets && (
                        <div className="smd-file-detail clearfix">
                          <div className="file-image">
                            <img src={FileUpload} alt="" width={42} />
                          </div>
                          <div className="file-meta">
                            <span className="name">Term Sheet</span>
                            <div className="view clearfix">
                              <a
                                target="_blank"
                                href={`${BaseUrl}storage/${currentDeal.term_sheets.id}/${currentDeal.term_sheets.file_name}`}
                              >
                                View document
                              </a>
                            </div>
                            <ModalComponent
                              fileName={currentDeal.term_sheets.file_name}
                              modalOpen={this.state.modalOpen}
                              handleClose={this.modalClose}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {currentDeal && currentDeal.information_overview && (
                    <div className="col-md-12 mb-4">
                      <div className="smd-file-detail clearfix">
                        <div className="file-image">
                          <img src={FileUpload} alt="" width={42} />
                        </div>
                        <div className="file-meta">
                          <span className="name">Company Presentation</span>
                          <div className="view clearfix">
                            <a
                              target="_blank"
                              href={`${BaseUrl}storage/${currentDeal.information_overview.id}/${currentDeal.information_overview.file_name}`}
                            >
                              View Document
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {currentDeal &&
                    currentDeal.documents &&
                    currentDeal.documents.length > 0 &&
                    currentDeal.documents.map((additional_document) => (
                      <div className="col-md-12 mb-4">
                        <div className="smd-file-detail clearfix">
                          <div className="file-image">
                            <img src={FileUpload} alt="" width={42} />
                          </div>
                          <div className="file-meta">
                            <span className="name">
                              {additional_document.name}
                            </span>
                            {additional_document.document && (
                              <div className="view clearfix">
                                <a
                                  target="_blank"
                                  href={`${BaseUrl}storage/${additional_document.document.id}/${additional_document.document.file_name}`}
                                >
                                  View Document
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <p style={{ marginTop: 30 }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentDeal && currentDeal.details,
                    }}
                  />

                  <br />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentDeal && currentDeal.helpful_links,
                    }}
                  />

                  <br />

                  <p><strong>Investment Opportunity Disclaimer</strong></p>

                  <p>This Investment Opportunity correspondence has been prepared by <strong>{currentDeal && currentDeal.company_name} {currentDeal && currentDeal.category == 180 && currentDeal.asx_code && (<span>- ASX:{currentDeal.asx_code}</span>)}</strong>
                  {currentDeal && currentDeal.broker_name && (<span> (<strong>{currentDeal && currentDeal.broker_name}</strong>)</span>)}
                  <span> </span>the company promoting the Investment Opportunity. The purpose of this Investment Opportunity correspondence is to provide the recipient of this Investment Opportunity correspondence (Recipient) with pertinent information concerning this Investment Opportunity to assist the Recipient to make its own assessment of a possible investment and decide whether to proceed with further investigations. It is supplied to the Recipient on the understanding that it is not to be used for any other purpose.</p>
                  <p>This Investment Opportunity correspondence contains statements, opinions, projections, forecasts, and other material (forward-looking statements), based on various assumptions. Those assumptions may or may not prove to be correct. None of the NCG entities or their respective officers, employees, agents, advisers, makes any representation as to the accuracy or likelihood of fulfilment of the forward-looking statements or any of the assumptions upon which they are based. Past performance is no indication of future performance.</p>
                  <p><u>NO REPRESENTATION OR WARRANTY</u></p>
                  <p>No representation or warranty, express or implied, is made as to the fairness, accuracy, completeness or correctness of the statements, estimates, opinions, conclusions, and other information contained in this investor letter. {currentDeal && currentDeal.company_name} does not have any responsibility to update or correct any information contained in this document. Any forecasts and hypothetical examples are subject to uncertainty and are not guaranteed and should not be relied upon. To the maximum extent permitted by law, {currentDeal && currentDeal.company_name} and its affiliates and related bodies corporate, and their respective officers, directors, employees, agents, and advisors (relevant party), accept no responsibility or liability for any information provided in this Investment Opportunity correspondence, including any forward-looking statements and do not provide any warranty of accuracy or reliability in relation to such information or accept any liability to any person who relies on it.</p>
                  <p>180 Markets Pty Ltd (ACN 638 381 129) (180 Markets) is a Corporate Authorised Representative (#1280199) of Non Correlated Capital Pty Ltd (AFSL 499882 ACN 143 882 562) (NCC). The information contained in this Investment Opportunity was not prepared by NCC but was prepared by other parties. While NCC has no reason to believe that the information is inaccurate, the truth or accuracy of the information contained in the Investment Opportunity cannot be warranted or guaranteed. Anyone viewing this Investment Opportunity must obtain and rely upon their own independent financial, investment, tax and legal advice and inquiries. NCC will scrutinise some deals both external broker co-ordinated as well as non-broker 180 Market/Ignite co-ordinated opportunities at its discretion.</p>
                  <p>180 Markets is authorised to advise (general advice only) and deal in wholesale investors only as that term is defined in the Corporations Act 2001. Under no circumstances does 180 Markets take into account the investment objectives, financial situation or particular needs of any particular person. It is not intended to take the place of professional advice and you should not take action on specific issues in reliance on this information. You are advised to obtain your own investment, financial, tax and legal advice before taking any action. Neither NCC nor any of their related parties, their employees or directors, officers, provide any warranty of accuracy or reliability in relation to such information or accept any liability to any person who relies on it.</p>
                  <p>The information contained in the Investment Opportunity are confidential and may not be reproduced in whole or in part.</p>
                  <p>Any investment involves risk of capital loss in whole or in part, and the risk of loss of future and previous earnings.</p>
                  <p><u>ACCEPTANCE</u></p>
                  <p>Each Recipient of this correspondence or any entity or person receiving this Investment Opportunity represents, warrants, and confirms that it accepts the qualifications, limitations and disclaimers set out in this document.</p>
                  <p>You expressly understand and agree that NCC will not be liable for any direct, indirect, special, incidental, consequential or exemplary damages suffered by you, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible assets (even where NCC has been advised of the possibility of such damages).</p>

                  {currentDeal && currentDeal.type === "180" && (
                    <i>
                      180 Markets Pty Ltd reserves the right to close this deal
                      early.
                      <br />
                      180 Markets Pty Ltd or its associated entities may invest
                      in this deal.
                    </i>
                  )}
                </p>
                <p>{}</p>
              </div>
              <div className="col-md-5">
                {currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.is_approved === "Approved" &&
                  !currentUser.si_form_expired &&
                  bidStatus &&
                  currentDeal &&
                  bidStatus.myBids &&
                  (bidStatus.myBids.length === 0 || anotherBid) &&
                  !bidStatus.myIndication &&
                  !parsed.bid &&
                  (currentDeal.status === "Active" ? (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        {currentDeal.type === "180" && (
                          <h3
                            className="text-capitalize"
                            style={{ paddingLeft: 20, paddingTop: 20 }}
                          >
                            Submit Bid for {currentDeal.company_name}
                          </h3>
                        )}

                        <DealsViewForm
                          currentUser={currentUser}
                          onSubmit={this.onSubmitBid}
                          selectEntityOptions={entitiesNotUsed}
                          capRaisePrice={
                            currentDeal && currentDeal.capital_raise
                          }
                          currentDeal={currentDeal}
                          bidStatus={bidStatus}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="md-deal-form">
                      {/* <h3>This Placement has closed</h3> */}
                    </div>
                  ))}

                {currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.is_approved === "Approved" &&
                  !currentUser.si_form_expired &&
                  bidStatus &&
                  currentDeal &&
                  bidStatus.myBids &&
                  bidStatus.myBids.length > 0 &&
                  currentDeal.status !== "Closed" &&
                  !anotherBid &&
                  (currentDeal.status === "Active" ||
                  parseInt(parsed.bid) === 1 ? (
                    <>
                      <h3>Change Bid</h3>
                      {bidStatus.myBids.map((bid) => (
                        <div className="md-deal-form mb-4">
                          <div>
                            <b>Entity: </b>
                            {bid.entity?.entity_name}
                          </div>
                          <div>
                            <b>Share Count:</b> {bid.share_count}
                          </div>
                          <div className="mb-2">
                            <b>Investment Amount:</b>
                            <NumberFormat
                              value={bid.investment_amount}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </div>
                          {bid.allocation_investor_count === 0 && (
                            <div className="form-normal">
                              <button
                                type="button"
                                // className="btn btn-outline-primary btn-medium"
                                className="btn btn-outline-primary btn-medium btn-block"
                                onClick={() =>
                                  this.editBidHandler(bidStatus.uuid, bid.uuid)
                                }
                              >
                                Change Bid
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                      {entitiesNotUsed.length > 0 && (
                        <>
                          <button
                            type="button"
                            className="btn btn-outline-default btn-medium btn-block mt-4"
                            onClick={() => this.onBidNow(bidStatus.uuid)}
                          >
                            Bid with another entity
                          </button>
                        </>
                      )}
                    </>
                  ) : (
                    <div className="md-deal-form">
                      {/* <h3>This Placement has closed</h3> */}
                    </div>
                  ))}

                {currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.is_approved === "Approved" &&
                  !currentUser.si_form_expired &&
                  bidStatus &&
                  currentDeal &&
                  !anotherBid &&
                  currentDeal.status !== "Closed" &&
                  bidStatus.myIndication && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-medium btn-block"
                          onClick={() => this.editBidHandler(bidStatus.uuid)}
                        >
                          Change Indication
                        </button>
                      </div>
                    </div>
                  )}

                {currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  (currentUser.is_approved === "Pending" ||
                    currentUser.is_approved === "Rejected") && (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div className="deal-form-content">
                          <p className="bidMessage">
                            Complete your profile to bid for deals
                          </p>
                          <button
                            className="btn btn-outline-primary btn-block"
                            onClick={() =>
                              this.props.history.push("/user/profile")
                            }
                          >
                            Go to Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                {currentUser.role &&
                currentUser.role.some((role) => role.title === "Investor") &&
                currentUser.is_approved === "Approved" &&
                currentUser.si_form_expired ? (
                  <div className="md-deal-form">
                    <div className="form-normal dealBidMessage">
                      <div className="deal-form-content">
                        <p className="bidMessage">
                          Your current sophisticated investor form has expired.
                          Please upload an updated sophisticated investor form
                          to bid.
                        </p>
                        <button
                          className="btn btn-outline-primary btn-block"
                          onClick={() =>
                            this.props.history.push("/user/profile")
                          }
                        >
                          Go to Profile
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {currentUser.role &&
                  currentUser.role.some(
                    (role) => role.title !== "Investor"
                  ) && (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div className="deal-form-content">
                          <p className="bidMessage">
                            Register as an investor to bid for deal
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                {(currentDeal && !currentUser) ||
                  (currentDeal && !localStorage.getItem("auth_token") && (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div
                          className="deal-form-content"
                          style={{ textAlign: "center" }}
                        >
                          <p className="bidMessage">Login to bid for deals</p>
                          <div className="row" style={{ padding: "20px 20px" }}>
                            <div className="col-sm-12">
                              <button
                                className="btn btn-primary btn-medium btn-block"
                                onClick={() =>
                                  this.props.history.push("/auth/login")
                                }
                              >
                                Login
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {(currentDeal && currentDeal.status === "Closed") ||
                (currentDeal && currentDeal.status === "Finished") ? (
                  <div className="md-deal-form">
                    <div
                      className="form-normal dealBidMessage"
                      style={{ minHeight: 250 }}
                    >
                      <div
                        className="deal-form-content"
                        style={{ textAlign: "center" }}
                      >
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "regular",
                            paddingBottom: 10,
                            paddingTop: 50,
                          }}
                        >
                          This Placement has closed.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}

                {(currentDeal &&
                  currentDeal.status === "Suspended" &&
                  !parsed) ||
                (currentDeal &&
                  currentDeal.status === "Suspended" &&
                  parsed.bid &&
                  parseInt(parsed.bid) !== 1) ? (
                  <div className="md-deal-form">
                    <div
                      className="form-normal dealBidMessage"
                      style={{ minHeight: 250 }}
                    >
                      <div
                        className="deal-form-content"
                        style={{ textAlign: "center" }}
                      >
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "regular",
                            paddingBottom: 10,
                            paddingTop: 50,
                          }}
                        >
                          This deal is already suspended.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  parseInt(parsed.bid) === 1 &&
                  (bidStatus.myBids.length === 0 || anotherBid) && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <DealsViewForm
                          queryString={parsed.bid}
                          currentUser={currentUser}
                          onSubmit={this.onSubmitBid}
                          selectEntityOptions={this.state.selectEntityOptions}
                          capRaisePrice={
                            currentDeal && currentDeal.capital_raise
                          }
                          currentDeal={currentDeal}
                          bidStatus={bidStatus}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    existingEntity: state.entities.entities,
    currentDeal: state.deals.singleDeal,
    currentUser: state.auth.currentUser,
    bidStatus: state.deals.bidStatus,
  };
};

const { fetchEntities } = EntityActions;
let { singlePublicDeal, getSingleDeal } = dealActions;
let { createBid, deleteBid, createIndication } = BidActions;

const mapDispatchToProps = (dispatch) => ({
  fetchAllEntities: (payload, history) =>
    fetchEntities({ dispatch, payload, history }),
  getSingleDealData: (payload) => singlePublicDeal(payload, dispatch),
  getDealStatus: (payload) => getSingleDeal(payload, dispatch),
  createBidSubmit: (payload, history) => createBid(payload, dispatch, history),
  createIndicationSubmit: (payload, history) =>
    createIndication(payload, dispatch, history),
  cancelBid: (payload, history) => deleteBid(payload, dispatch, history),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DealView));

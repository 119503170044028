import React, { Component } from "react";
import { connect } from "react-redux";
import {
  clearWarning,
  clearNotification,
  loginUser,
} from "../../../redux/actions/authActions";
import Alert from "../../common/Alert";
import LoginForm from "../../forms/LoginForm";
import "../../../assets/css/register.scss";
import stripes from "../../../assets/css/img/stripes.svg"
class Login extends Component {
  clearNotification = () => {
    this.props.clearNotification();
  };

  loginHandler = (values) => {
    this.props.loginUser(values, this.props.history);
  };

  render() {
    const { notification, currentUser, profilePending } = this.props;
    const { history } = this.props;
    if (!!Object.keys(currentUser).length) history.push("/user/deals");

    return (
      <main className="page-main page-signup  pt-0 new-signup login">
        <div className="container-fluid p-0 bg-light-blue">
          <div className={!this.props.home ? "row r-row" : ""}>
            <div className="col-md-3 bg-r-dark d-none d-lg-block">
              <div className="stripes">
                {/* <img src={stripes} alt="" /> */}
              </div>
            </div>

            <div className={!this.props.home ? "col-lg-9 col-12" : ""}>
              <div className="form-account-create  vw-60">
                {this.props.location.search === "?redirect=/user/deals" ? (
                  <h1 class="r-title pb-4">You must login to view deals</h1>
                ) : (
                  <h1 class="r-title pb-4">Login</h1>
                )}
                <Alert
                  type="success"
                  message={notification}
                  action={this.clearNotification}
                />
                <div className="form-bg">
                  <LoginForm onSubmit={this.loginHandler} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  notification: state.auth.notification,
  loginError: state.auth.loginError,
  profilePending: state.register.profilePending,
});

const mapDispatchToProps = (dispatch) => ({
  clearNotification: () => dispatch(clearNotification),
  clearWarning: () => dispatch(clearWarning),
  loginUser: (payload, history) => loginUser(dispatch, payload, history),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

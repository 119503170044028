import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import InvestorNotifications from "../../../forms/fields/InvestorNotifications";
import { connect } from "react-redux";
import {
  updatePreferences,
  clearAlert,
} from "../../../../redux/actions/profileActions";
import ButtonLoader from "../../../common/ButtonLoader";
import { Form } from "semantic-ui-react";
require("bootstrap-select");

class SophisticatedPreference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unqualified: false,
      friend_name: "",
      how_you_know_us: "",
      broker_name: "",
      reference_code: "",
      errors: {},
      showDetailsInput: false,
      details: "",
      sophisticated: [
        {
          key: "eachindividualdeal",
          label: "My gross income is more than $250k annually",
          value: "Each individual deal",
          current: true,
        },
        {
          key: "onceaday",
          label: "My net assets are more than 2.5M",
          value: "income",
          current: false,
        },
        {
          key: "newsletter",
          label: "All the above",
          value: "all",
          current: false,
        },
        {
          key: "no",
          label: "None of above",
          value: "none",
          current: false,
        },
      ],
    };
  }

  hasError = (payload) => {
    let formIsValid = true;
    let errors = {};
    if (!payload.sophisticated) {
      errors["sophisticated"] = "This field is required.";

      formIsValid = false;
    }

    this.setState({ errors: errors });

    return formIsValid;
  };

  componentDidMount = () => {
    // let {
    //   sophisticated,
    // } = this.props.currentUser;
    // let newState = {};
    // newState.sophisticated = this.state.sophisticated.map((item) => {
    //   if (item.value === sophisticated) item.current = true;
    //   else item.current = false;
    //   return item;
    // });
    // this.setState({
    //   ...newState,
    // });
  };

  handleNotification = (event, value) => {
    let sophisticated = this.state.sophisticated.map((item) => {
      if (item.value === value) item.current = true;
      else item.current = false;
      return item;
    });
    console.log(sophisticated);
    this.setState({
      sophisticated: sophisticated,
    });
  };

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  getSettings(setting) {
    let option = null;
    switch (setting) {
      case "sophisticated":
        option = this.state.sophisticated.filter(
          (item) => item.current === true
        );
        return option && option[0] ? option[0].value : null;
      default:
        return;
    }
  }

  handleSettings = async (e) => {
    let payload = {};
    const { history } = this.props;
    let sophisticated = this.getSettings("sophisticated");
    payload = {
      sophisticated,
    };

    if (this.hasError(payload)) {
      if (sophisticated === "none") {
        history.push("/user/si-review");
      } else {
        history.push("/user/si-upload");
      }
      // this.props.updatePreferences(payload, history, false);
    }
  };

  render() {
    const { sophisticated } = this.state;
    return (
      <div className="page-create-account investor-account investor-account-01 ">
        <div
          className="form-normal form-create-account message-page"
          style={{ maxWidth: "534px" }}
        >
          <Form onSubmit={this.handleSettings}>
            <h2>Which of the following statement best describes you?</h2>

            <div className="all-fields">
              <div className="field-group-item field-radio-option mb-2">
                <InvestorNotifications
                  label=""
                  data={sophisticated}
                  handleChange={this.handleNotification}
                />
                <span style={{ fontSize: "12px", color: "red" }}>
                  {this.state.errors["sophisticated"]}
                </span>
              </div>
            </div>
            <div className="row is-button-group">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <ButtonLoader
                  type="submit"
                  className="btn btn-primary btn-medium btn-block"
                >
                  Continue
                </ButtonLoader>
              </div>
            </div>
            <div className="row is-button-group">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <Link
                  to="/user/deals"
                  className="btn btn-outline btn-medium btn-block"
                >
                  Back
                </Link>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, markets, assets }) => ({
  currentUser: auth.currentUser,
  preferences: auth.preferences,
  markets: markets.markets,
  assets: assets.assets,
});

const mapDispatchToProps = (dispatch) => ({
  updatePreferences: (payload, history, redirectOnSave) =>
    updatePreferences(dispatch, payload, history, redirectOnSave),
  clearAlert: () => dispatch(clearAlert),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SophisticatedPreference)
);

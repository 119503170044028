import React from "react";
import DataTable from "react-data-table-component";
import { Icon } from "semantic-ui-react";

const LiveStreamsTable = ({ liveStreams, onView, history }) => {
  const handleChange = (row) => {
    history.push(`/live-stream/${row.uuid}`);
  };

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: false,
      left: true,
      cell: (row) => (
        <div className="company-name" onClick={() => handleChange(row)}>
          {row.name && row.name}
        </div>
      ),
    },
    {
      name: "Date",
      selector: "company-name",
      sortable: false,
      left: true,
      cell: (row) => (
        <div className="company-name" onClick={() => handleChange(row)}>
          {row.date && `${row.date}`}   {row.time && `${row.time}`}
        </div>
      ),
    },
    {
      name: "Description",
      selector: "company-name",
      sortable: false,
      left: true,
      cell: (row) => (
        <div className="company-name" onClick={() => handleChange(row)}>
          {row.description && (
            <div
              dangerouslySetInnerHTML={{
                __html: row.description,
              }}
            />
          )}
        </div>
      ),
    },
    {
      name: "View",
      selector: "bid_now",
      sortable: false,
      left: true,
      cell: (row) => (
        <>
        {row.recording && <div className="tbl-title" onClick={() => onView(row)}>
          <span className="bid-now">
            <Icon
              style={{ cursor: "pointer" }}
              color="blue"
              size="huge"
              name="arrow alternate circle right outline"
            />
          </span>
        </div>}
        </>
      ),
    },
  ];

  return (
    <div className="md-table-normal table-responsive">
      <DataTable
        noHeader
        highlightOnHover
        pointerOnHover
        columns={columns}
        data={liveStreams}
        onRowClicked={handleChange}
      />
    </div>
  );
};

export default LiveStreamsTable;

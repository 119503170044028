import React, { Component } from "react";
import InvestorProfileForm from "../../../forms/InvestorProfileForm";
import { connect } from "react-redux";
import { updateInvstorProfile } from "../../../../redux/actions/profileActions";

import EntityActions from "../../../../redux/actions/entityActions";
import { deleteMediaSuccess } from "../../../../redux/actions/accountActions";
import { getCurrentUser } from "../../../../redux/actions/authActions";
import { ExclamationCircleOutlined } from "@ant-design/icons";

class InvestorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        address: null,
        middle_name: null
      },
      wholesale_status: [],
      verification: [],
      open: false,
      documentID: "",
      siForms: []
    };
  }

  open = (id) => {
    this.setState({ open: true, documentID: id });
  };

  close = () => this.setState({ open: false });

  async componentDidMount() {
    await this.props.getCurrentUserData();
    let {
      first_name,
      last_name,
      email,
      phone,
      address,
      middle_name,
    } = this.props.currentUser;
    let profile = { first_name, last_name, email, phone, address, middle_name };
    this.setState({
      profile,
    });
    this.props.fetchEntities({ all: true });
  }

  

  handleSubmit = async (values) => {
    await this.props.updateProfile({
      ...values,
      siForms:
        Object.entries(this.state.siForms).length > 0
          ? this.state.siForms
          : null,
      verification:
        Object.entries(this.state.verification).length > 0
          ? this.state.verification
          : null,
      wholesale_status:
        Object.entries(this.state.wholesale_status).length > 0
          ? this.state.wholesale_status
          : null,
    });
    this.props.getCurrentUserData();
  };

  handleAlert = () => {
    this.props.clearAlert();
  };

  validateForm = () => {
    const errors = {};

    return errors;
  };

  handleUpload = (event) => {
    if (event.target.name === "wholesale_status") {
      return this.setState({
        wholesale_status: event.target.files[0],
      });
    } else {
      return this.setState({
        uploaded: {
          ...this.state.uploaded,
          verification: event.target.files,
        },
      });
    }
  };

  onIDUpload = (files) => {
    this.setState({ verification: files });
  };

  onSIUpload = (files) => {
    this.setState({ siForms: files });
  };

  wholeSaleonUpload = (files) => {
    this.setState({ wholesale_status: files });
  };

  onDocumentDeleteHandler = async (id) => {
    let { deleteMedia, getCurrentUserData } = this.props;
    await deleteMedia({ id: this.state.documentID });
    this.setState({ open: false });
    getCurrentUserData();
  };

  render() {
    let { currentUser } = this.props;
   
    return (
      <div className="page-create-account investor-account investor-account-01">
        <div className="form-normal form-create-account">
          <InvestorProfileForm
            currentUser={this.currentUser}
            onSubmit={this.handleSubmit}
            handleUpload={this.handleUpload}
            onIDUpload={this.onIDUpload}
            onSIUpload={this.onSIUpload}
            wholeSaleonUpload={this.wholeSaleonUpload}
            verificationDocument={currentUser.uploaded_verification}
            siForms={currentUser.sophisticated_investor_forms}
            wholeSaleDocument={currentUser.wholesale_status}
            onDocumentDeleteHandler={this.onDocumentDeleteHandler}
            open={this.open}
            show={this.state.open}
            close={this.close}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

const {
  fetchEntities,
  fetchSingleEntity,
  createEntity,
  updateEntity,
  deleteEntity,
} = EntityActions;

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (payload) => updateInvstorProfile(dispatch, payload),
  fetchEntities: (payload) => fetchEntities({ dispatch, payload }),
  fetchSingleEntity: (payload) => fetchSingleEntity({ dispatch, payload }),
  createEntity: (payload) => createEntity({ dispatch, payload }),
  updateEntity: (payload) => updateEntity({ dispatch, payload }),
  deleteEntity: (payload) => deleteEntity({ dispatch, payload }),
  deleteMedia: (payload) => deleteMediaSuccess(dispatch, payload),
  getCurrentUserData: () => getCurrentUser(dispatch, {}),
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    initialValues: {
      form: state.form,
      first_name: state.auth.currentUser.first_name,
      last_name: state.auth.currentUser.last_name,
      middle_name: state.auth.currentUser.middle_name,
      email: state.auth.currentUser.email,
      address: state.auth.currentUser.address,
      phone: state.auth.currentUser.phone,
      form_of_identifications: Array.isArray(
        state.auth.currentUser.form_of_identifications
      )
        ? {
            passport: state.auth.currentUser.form_of_identifications
              ? state.auth.currentUser.form_of_identifications.some(
                  (form) => form === "passport"
                )
              : false,
            driver_license: state.auth.currentUser.form_of_identifications
              ? state.auth.currentUser.form_of_identifications.some(
                  (form) => form === "driver_license"
                )
              : false,
            aus_id_card: state.auth.currentUser.form_of_identifications
              ? state.auth.currentUser.form_of_identifications.some(
                  (form) => form === "aus_id_card"
                )
              : false,
          }
        : {
            passport:
              state.auth.currentUser.form_of_identifications === "passport"
                ? true
                : false,
            driver_license:
              state.auth.currentUser.form_of_identifications &&
              state.auth.currentUser.form_of_identifications ===
                "driver_license"
                ? true
                : false,
            aus_id_card:
              state.auth.currentUser.form_of_identifications === "aus_id_card"
                ? true
                : false,
          },
    },
    entities: state.entities.entities,
    singleEntity: state.entities.singleEntity,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorProfile);

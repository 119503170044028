import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Modal } from "antd";
import { BaseUrl } from "../../../../../services/settings";
import bid from "../../../../../assets/images/bid.png";
import { Icon } from "semantic-ui-react";
import {
  renderData,
  renderRefreshText,
  customStyles,
  dueDateFormat,
} from "../../../../common/CommonMethods";
import { accountHold } from "../../../../common/HoldAlert";
class DealsMarket extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onView = (id) => {
    const { isEnitityExist, history, dealAccessDenialMessage, isHold } = this.props;
    if (isEnitityExist) {
      if (isHold){
        accountHold(this.props);
        return;
      }
      this.props.history.push(`/user/deal-view/${id}`);
    } else {
      Modal.confirm({
        // title: "Confirm",
        width: 600,
        okButtonProps: {
          type: "primary",
          size: "large",
        },
        cancelButtonProps: {
          size: "large",
        },
        // icon: <ExclamationCircleOutlined />,

        content: <p className="pconfirm">{dealAccessDenialMessage}</p>,
        okText: "My Profile",
        cancelText: "Cancel",
        onOk() {
          history.push("/user/profile");
        },
        onCancel() {},
      });
    }
  };

  handleChange = (row) => {
    const { isEnitityExist, history, dealAccessDenialMessage, isHold } = this.props;
    if (isEnitityExist) {
      if (isHold){
        accountHold(this.props);
        return;
      }
      this.props.history.push(`/user/deal-view/${row.uuid}`);
    } else {
      Modal.confirm({
        width: 600,
        okButtonProps: {
          type: "primary",
          size: "large",
        },
        cancelButtonProps: {
          size: "large",
        },
        // title: "Confirm",
        // icon: <ExclamationCircleOutlined />,
        content: <p className="pconfirm">{dealAccessDenialMessage}</p>,
        okText: "My Profile",
        cancelText: "Cancel",
        onOk() {
          history.push("/user/profile");
        },
        onCancel() {},
      });
    }
  };

  render() {
    const { accessDeals, dealAccessRedMessage } = this.props;
    const handleChange = () => {};
    const columns = [
      {
        name: "",
        selector: "deal_logo",
        sortable: false,
        // style: { minWidth: "120px" },
        cell: (row) => (
          <>
            <div
              onClick={() => this.handleChange(row)}
              className="company-logo"
            >
              {row.deal_logo && (
                <img
                  style={{
                    maxWidth: "70px",
                    maxHeight: "70px",
                    float: "left",
                  }}
                  src={`${BaseUrl}/storage/${row.deal_logo.id}/${row.deal_logo.file_name}`}
                  alt=""
                />
              )}
            </div>
          </>
        ),
      },
      {
        name: "Company",
        selector: "company_name",
        sortable: false,
        className: "company no-wrap",
        style: { minWidth: "130px" },
        cell: (row) => (
          <>
            <div className="wrap" onClick={() => this.handleChange(row)}>
              <span>{row.company_name}</span>
            </div>
          </>
        ),
      },
      {
        name: "ASX Code",
        selector: "ask_code",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.asx_code && row.asx_code}
          </div>
        ),
      },
      {
        name: "Last Price",
        selector: "last_price",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.last_price ? renderData(row.last_price) : ""}
          </div>
        ),
      },
      {
        name: "Capital Raise Price",
        selector: "capital_raise",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.capital_raise ? renderData(row.capital_raise) : ""}
          </div>
        ),
      },
      {
        name: "Raise Amount",
        selector: "raise_amount",
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.raise_amount ? renderData(row.raise_amount) : ""}
          </div>
        ),
      },
      {
        name: "Discount",
        selector: "discount",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {!isNaN(parseInt(row.discount))
              ? parseFloat(row.discount) + "%"
              : ""}
          </div>
        ),
      },

      {
        name: "Market Capitalisation",
        selector: "market_capital",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.market_capital && `${renderData(row.market_capital)}`}
          </div>
        ),
      },
      {
        name: "Bids Due",
        selector: "bids_due",
        sortable: false,
        left: true,
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row.bids_due && dueDateFormat(row.bids_due)}
          </div>
        ),
      },
      {
        name: "View Deal",
        selector: "bid_now",
        sortable: false,
        left: true,
        style: { minWidth: "100px", maxWidth: "150px" },
        cell: (row) => (
          <div className="tbl-title" onClick={() => this.onView(row.uuid)}>
            <span className="bid-now">
              <Icon
                style={{ cursor: "pointer" }}
                color="blue"
                size="huge"
                name="arrow alternate circle right outline"
              />
              {/* <span className="ico"></span> */}
            </span>
          </div>
        ),
      },
      {
        name: "Express Interest",
        selector: "bid_now",
        left: true,
        cell: (row) => (
          <div className="tbl-title">
            <img
              src={bid}
              style={{ cursor: "pointer", width: "38px" }}
              onClick={() => this.props.onBidNow(row.uuid)}
            />
          </div>
        ),
      },
    ];
    return (
      <div className="md-table-normal">
        <h5
          style={{ textAlign: "left", fontSize: "18px", marginBottom: "20px" }}
        >
          We are currently seeking access to these placements. Stay tuned.
        </h5>
        {/* {!renderRefreshText(this.props.deals) && (
          <h5
            style={{
              textAlign: "left",
              fontSize: "18px",
              marginBottom: "20px",
            }}
          >
            Please refresh shortly
          </h5>
        )} */}
        <DataTable
          noHeader
          overflowY
          highlightOnHover
          responsive={true}
          columns={columns}
          data={accessDeals ? this.props.deals : []}
          style={{ width: "100%" }}
          onRowClicked={this.handleChange}
          sortServer={true}
          pointerOnHover={true}
          noDataComponent={accessDeals ? (<div className="p-4"> Stand by! More great Placements are on their way!</div>) : (<div className="deal-access-error outline cursor-pointer">{dealAccessRedMessage}</div>)}
          customStyles={customStyles}
        />
      </div>
    );
  }
}
export default DealsMarket;

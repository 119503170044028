import React, { Component, Fragment } from "react";
import { FormField } from "../../../../layout/FormField";
import { required } from "redux-form-validators";
import { Field, Form, reduxForm, FieldArray } from "redux-form";
import { connect } from "react-redux";
import ButtonLoader from "../../../../common/ButtonLoader";
import EntityActions from "../../../../../redux/actions/entityActions";
import NewDropzone from "../../../../common/NewDropzone";
import Confirm from "../../../../../components/common/Confirm";
import DateDropdown from "../../../../common/DateDropdown";
import { scrollToFirstError } from "../../../../../utils/scroller";
import DraggerComponent from "../../../../common/DraggerComponent/DraggerComponent";
import { withRouter } from "react-router-dom";

class IndividualForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      individuals: [{}],
      dobStartDate: "",
      show: false,
      deletedIndividualId: "",
      fields: [],
      remove: false,
    };
  }

  open = (id, fields) => {
    this.setState({ show: true, deletedIndividualId: id });
  };

  close = () => {
    this.setState({ show: false });
  };

  addIndividual = () => {
    if (this.props.individualsCount) {
      this.onIndividualChangeHandler(null, this.state.individualsCount++);
    }
  };

  onIndividualChangeHandler = (fields, value) => {
    if (value > 0) {
      let obj = {};
      for (let i = 0; i < value; i++) {
        {
          obj[i] = i;
        }
      }

      this.setState({ individuals: [...Object.entries(obj)] });
    } else {
      this.setState({ individuals: [] });
    }
  };

  componentDidMount = () => {
    let dobStartDate = new Date();
    dobStartDate.setFullYear(dobStartDate.getFullYear() - 18);
    this.setState({ dobStartDate });
  };

  removeIndividual = async (index) => {
    if (!index) {
      return;
    }
    let { deleteIndividualEntityById, fetchSingleEntity } = this.props;

    await deleteIndividualEntityById(index, this.props.individualId);
    this.setState({ show: false, remove: true });
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.individualsCount) {
      this.onIndividualChangeHandler(null, this.props.individualsCount);
    }
  };

  renderIndividuals = ({
    fields,
    meta: { touched, error, submitFailed },
    prevProps,
  }) => {
    if (fields.length === 0) {
      fields.push({});
    }

    return (
      <Fragment>
        {fields.map((member, index) => (
          <div className="col-md-12">
            <div className="row mb-3">
              <div className="col-md-8">
                <h2>Individual {index + 1}</h2>
              </div>

              <div className="col-md-4">
                {index > 0 && (
                  <button
                    type="button"
                    style={{ width: "100%" }}
                    className="btn btn-outline-primary btn-medium"
                    onClick={() => {
                      if (fields.getAll()[index].id) {
                        return this.open(fields.getAll()[index].id);
                      }
                      fields.remove(index);
                    }}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-12">
                <div className="field">
                  <label className="title">Title *</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="title"
                      name={`individuals.${index}.title`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Given Name(s) *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`individuals.${index}.given_name`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Middle Name</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`individuals.${index}.middle_name`}
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div>
              </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Surname *</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="sur_name"
                      name={`individuals.${index}.sur_name`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
          
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">
                    Tax File Number(TFN) (Optional)
                  </label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`individuals.${index}.tfn`}
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div>
              </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Address *</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`individuals.${index}.address`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            
              <div className="col-sm-6">
                <div className="field">
                  <label className="title">Suburb *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`individuals.${index}.suburb`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              </div>
            <div className="row mb-3">
              <div className="col-sm-4">
                <div className="field">
                  <label className="title">Postcode *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`individuals.${index}.postcode`}
                      component={FormField}
                      type="number"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="field">
                  <label className="title">State *</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      type="text"
                      name={`individuals.${index}.state`}
                      component={FormField}
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="field">
                  <label className="title">Country *</label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`individuals.${index}.country`}
                      component={FormField}
                      type="text"
                      validate={[required()]}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
            <div className="col-sm-4">
                <div className="field">
                  <label className="title">Account Nickname</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`individuals.${index}.nickname`}
                      component={FormField}
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="field">
                  <label className="title">Country of Birth</label>
                  <div className="field-input">
                    <Field
                      className="form-control entity__input"
                      name="country_of_birth"
                      name={`individuals.${index}.country_of_birth`}
                      component={FormField}
                      type="text"
                      validate={[]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="field">
                  <label className="title">
                    Country of Citizenship or lawful permanent residence
                  </label>
                  <div class="field-input">
                    <Field
                      className="form-control entity__input"
                      name={`individuals.${index}.country_of_citizenship`}
                      component={FormField}
                      type="text"
                      validate={[]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-8">
                <div className="field">
                  <label className="title">Date of Birth *</label>
                  <div className="field-input">
                    {/* <Field
                      className="form-control entity__input"
                      name={`individuals.${index}.dob`}
                      component={DatePickerField}
                      endDate={this.state.dobStartDate}
                      type="text"
                      validate={[required()]}
                    /> */}
                    <Field
                      selectedDate={prevProps.selectedDate}
                      label="Date of Birth"
                      // type='text'
                      id="dob"
                      name={`individuals.${index}.dob`}
                      autoComplete="off"
                      autoCorrect="off"
                      spellCheck="off"
                      component={DateDropdown}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <Fragment>
              <NewDropzone
                multiple={true}
                buttonLabel="Choose document"
                title={"Sophisticated Investor Form"}
                multiDocuments={
                  this.props.initialValues &&
                  this.props.initialValues.individuals[index] &&
                  this.props.initialValues.individuals[index]
                    .wholesale_status_document
                }
                onUpload={(files) =>
                  this.props.onStatusDocumentUpload(index, files)
                }
                onDocumentDeleteHandler={this.props.onDocumentDeleteHandler}
                open={this.props.open}
                close={this.props.close}
                show={this.props.show}
                disableDelete={true}
              />
            </Fragment> */}
            <div className="row mb-3">
              <div className="col-sm-12">
                <div className="field">
                  <label className="title">ID Verification documents *</label>
                  <div className="field">
                    Acceptable forms of ID include: Australian passport,
                    Australian Drivers License, Birth certificate
                  </div>
                  <div class="field-input">
                    {/* <DraggerComponent
                      fileList={
                        prevProps.initialValues &&
                        prevProps.initialValues.individuals[index] &&
                        prevProps.initialValues.individuals[index].verification
                      }
                      onDocumentDeleteHandler={
                        prevProps.onVerificationDeleteHandler
                      }
                      name={`individuals[${index}][verification]`}
                      handleIdUpload={prevProps.handleIdUpload}
                    /> */}
                    <NewDropzone
                      multiple={true}
                      buttonLabel="Choose document"
                      title={""}
                      multiDocuments={
                        prevProps.initialValues &&
                        prevProps.initialValues.individuals[index] &&
                        prevProps.initialValues.individuals[index][
                          "verification"
                        ]
                      }
                      onUpload={(files) =>
                        prevProps.handleIdUpload({
                          [`individuals[${index}][verification]`]: files,
                        })
                      }
                      onDocumentDeleteHandler={
                        prevProps.onVerificationDeleteHandler
                      }
                      open={prevProps.open}
                      close={prevProps.close}
                      show={prevProps.show}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        ))}

        <div className="col-md-12">
          <div className="row mb-3">
            <div className="button-group offset-sm-6 col-sm-6 button-group">
              <button
                type="button"
                className="btn btn-outline-primary btn-medium"
                onClick={() => fields.push({})}
              >
                Add More
              </button>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <p>DVP Information</p>
          </div>
        </div>
        <div className="row mb-3">
          {/* <div className="col-sm-4">
            <div className="field">
              <label className="title">Broker Name</label>
              <div className="field-input">
                <Field
                  className="form-control entity__input"
                  name="broker_name"
                  component={FormField}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="field">
              <label className="title">Broker Phone Number</label>
              <div className="field-input">
                <Field
                  className="form-control entity__input"
                  name="broker_phone"
                  component={FormField}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="field">
              <label className="title">Broker Email</label>
              <div className="field-input">
                <Field
                  className="form-control entity__input"
                  name="broker_email"
                  component={FormField}
                  type="text"
                />
              </div>
            </div>
          </div> */}
          <div className="col-sm-4">
            <div className="field">
              <label className="title">PID</label>
              <div className="field-input">
                <Field
                  className="form-control entity__input"
                  name="pid"
                  component={FormField}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="field">
              <label className="title">HIN</label>
              <div className="field-input">
                <Field
                  className="form-control entity__input"
                  name="hin"
                  component={FormField}
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    const { edit } = this.props;
    return (
      <div>
        <Form onSubmit={this.props.handleSubmit} style={{ marginTop: "40px" }}>
          <FieldArray
            name="individuals"
            component={this.renderIndividuals}
            individuals={this.state.individuals}
            prevProps={this.props}
          />
          <div className="row mb-3">
            <div className="button-group">
              <div className="col-sm-6">
                <ButtonLoader
                  type="submit"
                  className="btn btn-primary btn-medium"
                >
                  Submit
                </ButtonLoader>
              </div>
              {edit && (
                <div className="col-sm-6">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-medium"
                    onClick={() => this.props.history.push("/user/profile")}
                  >
                    Back to Profile
                  </button>
                </div>
              )}
            </div>
          </div>
          <Confirm
            message="Are you sure you want to remove this entry?"
            show={this.state.show}
            handleDelete={() => {
              this.removeIndividual(this.state.deletedIndividualId);
            }}
            handleClose={this.close}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    form: state.form,
    initialValue: {
      number_of_directors: 1,
    },
  };
};

const { deleteIndividualEntity, fetchSingleEntityData } = EntityActions;

const mapDispatchToProps = (dispatch) => ({
  deleteIndividualEntityById: (index, fetchId) =>
    deleteIndividualEntity(dispatch, index, fetchId),
  fetchSingleEntity: (payload) => {
    fetchSingleEntityData({ payload, dispatch });
  },
});

export default reduxForm({
  form: "IndividualForm",
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(connect(mapStateToProps, mapDispatchToProps)(withRouter(IndividualForm)));

import React, { Component } from "react";
import { Link } from "react-router-dom";
import InvestorTerms from "./InvestorTerms";

export default class TermCondition extends Component {
  render() {
    const { match } = this.props;

    return (
      <main class="page-main">
        <div class="bl-about">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="text-about">
                  <h1>{`Terms & Conditions`}</h1>

                  {match.params.type === "company" ||
                  match.params.type === "broker" ? (
                    <div class="excerpt">
                      <p>
                        <strong>180 Markets</strong>
                      </p>
                      <p>
                        <strong>Broker Terms </strong>
                      </p>
                      <ol>
                        <li>
                          <strong>
                            Your acceptance
                            <br />
                            <br />
                          </strong>
                          <ol>
                            <li>
                              In accordance with these terms (
                              <strong>Terms</strong>), 180 Markets PTY LTD ACN
                              638 381 129 (<strong>180 Markets</strong>,
                              <strong>we,</strong> <strong>us, our</strong>)
                              permits you (referred to as <strong>you,</strong>{" "}
                              <strong>your, Broker</strong>) to:
                              <ol>
                                <li>
                                  access and use the public elements of our
                                  website at www.180markets.com.au (
                                  <strong>Website</strong>);
                                </li>
                                <li>
                                  access and use the Broker Portal to post
                                  Opportunities on the Website;
                                </li>
                                <li>receive Bids from Investors;</li>
                                <li>
                                  communicate Allocations and Confirmations to
                                  Investors;
                                </li>
                                <li>
                                  view or interact with any content,
                                  information, communications, advice, text, or
                                  other material provided by us through the
                                  Website, Service or Broker Portal (
                                  <strong>Content</strong>); and
                                </li>
                                <li>communicate with us.</li>
                              </ol>
                            </li>
                            <li>
                              We may from time to time review and amend these
                              Terms, where reasonably necessary to take account
                              of changes to Applicable Law. Any other amendments
                              to these Terms can occur by written agreement.
                            </li>
                            <li>
                              Any capitalised words used in this document but
                              which are not defined here have the meaning given
                              to them in the Master ECM Terms. To the extent of
                              any inconsistency between this document and the
                              Master ECM Terms, this document prevails.
                              <br />
                              <br />
                            </li>
                          </ol>
                        </li>
                        <li>
                          <p>
                            <strong>
                              Changes to the Website, Service and Broker Portal
                            </strong>
                          </p>
                          <p>
                            The Website, Service and Broker Portal are subject
                            to change at any time without notice and may contain
                            errors.
                          </p>
                        </li>

                        <li>
                          <strong>Investor Terms</strong>
                          <strong>
                            <br />
                            <br />
                          </strong>
                          <ol>
                            <li>
                              An Investor's use of the Service and Website will
                              be governed by the Investor Terms. We may from
                              time to time review and update the Investor Terms,
                              including to take account of changes to Applicable
                              Laws, regulations, products or technology. An
                              Investor's use of the Website and Service will be
                              governed by the most recent Terms posted on the
                              Website.
                            </li>
                            <li>
                              You acknowledge that you have been provided with a
                              copy of the Investor Terms.
                              <br />
                              <br />
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>Nature of the </strong>
                          <strong>
                            Broker Portal
                            <br />
                            <br />
                          </strong>
                          <ol>
                            <li>
                              The Broker Portal will enable you, and other
                              Brokers, to:
                              <br />
                              <ol>
                                <li>
                                  provide information about Opportunities to the
                                  Investors;
                                </li>
                                <li>
                                  receive Bids in connection with the
                                  Opportunities;
                                </li>
                                <li>
                                  use our services to provide Confirmations to
                                  Investors and to provide information to
                                  Investors about how to participate in the
                                  Opportunities.
                                </li>
                              </ol>
                            </li>
                            <li>
                              Where an Investor makes a Bid on an Opportunity,
                              that Bid will be
                              <br />
                              <ol>
                                <li>
                                  a binding and irrevocable offer by the
                                  Investor to you (and the Offeror) to acquire
                                  financial products;
                                </li>
                                <li>
                                  governed by the Investor Terms, including the
                                  Master ECM Terms; and
                                </li>
                                <li>
                                  governed by any additional terms specified in
                                  the information you provide to us in
                                  connection with the Opportunity.
                                  <br />
                                  <br />
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>
                            Becoming an Broker
                            <br />
                            <br />
                          </strong>
                          <ol>
                            <li>
                              You may apply to become a Broker by completing the
                              Broker Application Form as provided by 180 Markets
                              to prospective Brokers from time to time and by
                              providing all required information. You agree to
                              provide us with all information which we may
                              require, in our sole discretion, to consider your
                              application to become a Broker and to remain as a
                              Broker, including any information which may be
                              required in connection with the{" "}
                              <em>
                                Anti-money Laundering and Counter-Terrorism
                                Financing Act 2006 (Cth)
                              </em>{" "}
                              or other Applicable Laws.
                            </li>
                            <li>
                              180 Markets may accept or reject your application
                              to be a Broker in its sole discretion.
                              <br />
                              <br />
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>
                            Nature of the Service
                            <br />
                            <br />
                          </strong>
                          <ol>
                            <li>
                              The Service provides the following functionality
                              to Investors:
                              <ol>
                                <li>
                                  access to information about Opportunities
                                  submitted by Brokers;
                                </li>
                                <li>
                                  ability to submit Bids to Brokers in respect
                                  of Opportunities;
                                </li>
                                <li>
                                  ability to receive Confirmations of
                                  Allocations; and
                                </li>
                                <li>
                                  ability to receive information about how to
                                  acquire the relevant financial products.
                                </li>
                              </ol>
                            </li>
                            <li>
                              You acknowledge and agree that we do not act on
                              your behalf in respect of Bids provided through
                              the Service and that the Service does not include
                              the ability for Investors to complete trades with
                              Brokers.
                              <br />
                              <br />
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>
                            Broker Fees
                            <br />
                            <br />
                          </strong>
                          <ol>
                            <li>
                              You must pay to 180 Markets the Broker Fee for
                              each Opportunity you submit.
                            </li>
                            <li>
                              The Broker Fee will be payable:
                              <ol>
                                <li>
                                  within 14 days of you receiving the fees from
                                  the Company ; or
                                </li>
                                <li>
                                  as otherwise agreed between 180 Markets and
                                  you.
                                </li>
                              </ol>
                            </li>
                            <li>
                              At any time, 180 Markets may by written notice
                              vary the Broker Fee for new Opportunities.
                            </li>
                            <li>
                              Failure to pay the Broker Fee when due for payment
                              constitutes a material breach of the Terms and
                              will entitle 180 Markets to revoke your access to
                              the Broker Portal.
                              <br />
                              <br />
                            </li>
                          </ol>
                        </li>

                        <li>
                          <strong>
                            Bids made through the Service
                            <br />
                            <br />
                          </strong>
                          <ol>
                            <li>
                              We will use our best endeavours to pass on any
                              Bids we receive from Investors, but we do not
                              guarantee that we will do so, or do so by a
                              certain time. The reasons for us not passing on a
                              Bid, or not doing so by a certain time, may
                              include where we consider, in our sole discretion,
                              that:
                              <ol>
                                <li>
                                  a Bid is contrary to the terms applicable to
                                  the Investor's use of the Service;
                                </li>
                                <li>
                                  a Bid is ambiguous, incomplete or unclear or ;
                                </li>
                                <li>
                                  a Bid would result in a breach of the law.
                                </li>
                              </ol>
                            </li>
                            <li>
                              On receipt of a Bid, you agree to allocate, or
                              arrange for the Offeror to allocate, financial
                              products to Investors on the same basis, for the
                              same price and subject to the same Scale Back (if
                              any), as any other investors which you arrange to
                              participate in the Opportunity.
                            </li>
                            <li>
                              You will provide us with information as soon as
                              possible in relation to:
                              <ol>
                                <li>the Allocation of each Investor; and</li>
                                <li>
                                  any additional information in relation to the
                                  Opportunity or Allocation which is to be
                                  included in the Confirmation.
                                </li>
                              </ol>
                            </li>
                            <li>
                              On receipt of confirmation from you about an
                              Investor's Allocation, we will use reasonable
                              endeavours to respond within the required time
                              frame.
                            </li>
                            <li>
                              On receipt of confirmation from you about an
                              Investor's Allocation, we will use our best
                              endeavours to provide a Confirmation to an
                              Investor and to include any information we receive
                              from you for inclusion in the Confirmation, but we
                              do not guarantee that we will do so, or do so by a
                              certain time. The reasons for us not doing so, or
                              not doing so by a certain time, may include where
                              we consider, in our sole discretion, that :
                              <ol>
                                <li>
                                  the information is contrary to these Terms;
                                </li>
                                <li>
                                  the information is ambiguous or incomplete; or
                                </li>
                                <li>
                                  passing on the information would result in a
                                  breach of the law.
                                  <br />
                                  <br />
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>
                            Responsibility for Opportunities
                            <br />
                            <br />
                          </strong>
                          You represent and warrant that:
                          <ol>
                            <li>
                              you have conducted reasonable enquiries in
                              relation to any Opportunity which you submit
                              through the Broker Portal;
                            </li>
                            <li>
                              you have conducted reasonable enquiries in
                              relation any information you submit about the
                              Opportunity;
                            </li>
                            <li>
                              the information you submit about the Opportunity
                              is accurate, not misleading and does not omit any
                              material relevant information;
                            </li>
                            <li>
                              the information you provide to us in relation to
                              an Investor's Allocation is accurate and final;
                              and
                            </li>
                            <li>
                              any information you provide to us for inclusion in
                              a Confirmation is accurate, not misleading and
                              does not omit material relevant information.
                              <br />
                              <br />
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>
                            Using the Website, Service and Broker Portal
                            <br />
                            <br />
                          </strong>
                          <ol>
                            <li>
                              You agree to use the Website, Service and Broker
                              Portal in good faith in accordance with these
                              Terms and Applicable Laws.
                            </li>
                            <li>
                              You will comply with any reasonable policies,
                              procedures, operating rules and directions of 180
                              Markets, provided to you in writing from time to
                              time, in relation to the operation of the Service,
                              your access to the Website, Service and Broker
                              Portal and the manner of performance of your
                              obligations under these Terms.
                            </li>
                            <li>
                              You are responsible for all activity through the
                              Service, including any fraudulent, illegal or
                              unauthorised dealings, which are attributable to
                              your conduct. You release and discharge 180
                              Markets from any liability in respect of such
                              activity.
                              <br />
                              <br />
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>
                            Broker obligations
                            <br />
                            <br />
                          </strong>
                          <ol>
                            <li>
                              You represent and warrant that, on each day while
                              these Terms are on foot, you either hold an
                              Australian Financial Services Licence (
                              <strong>AFS Licence</strong>), or have been
                              appointed as an authorised representative under an
                              AFS Licence pursuant to the Corporations Act, with
                              the authorisations and conditions required to
                              provide the financial services contemplated by
                              these Terms.
                            </li>
                            <li>
                              You must not make any representations to any
                              Investors that may cause a reasonable person to
                              consider that 180 Markets endorses or is otherwise
                              responsible for any financial product advice or
                              other financial services provided by you.
                            </li>
                            <li>
                              You must immediately notify 180 Markets if you
                              become aware of:
                              <ul>
                                <li>
                                  any possible breach by you of the Terms, its
                                  AFS Licence or authorisation, the Corporations
                                  Act or ASIC Policy;
                                </li>
                                <li>
                                  any Investor complaint that relates to the
                                  Broker's use of the Broker Portal; or
                                </li>
                                <li>
                                  any event that may be detrimental to 180
                                  Markets, its business and reputation or its
                                  goodwill, or the business and reputation of
                                  any of its associates (as that term is defined
                                  in the Corporations Act 2001 (Cth)).
                                  <br />
                                  <br />
                                </li>
                              </ul>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>
                            Access to Investors
                            <br />
                            <br />
                          </strong>
                          <ol>
                            <li>
                              You acknowledge that we are providing you with
                              access to the Investors in accordance with these
                              Terms.
                            </li>
                            <li>
                              You agree that you will not directly, or
                              indirectly, jointly or with any other person, by
                              any means whatsoever, intentionally do, or
                              endeavour to do, any of the following while these
                              Terms are on foot or at any time after
                              termination:
                              <ol>
                                <li>
                                  (i) contact any person that is a former or
                                  current Investor, other than:
                                  <ol>
                                    <li>
                                      (A) as reasonably necessary in connection
                                      with administering or managing an existing
                                      investment made by the Investor in an
                                      Opportunity; or
                                    </li>
                                    <li>
                                      (B) to the extent an Investor was a client
                                      of the Broker prior to commencement of
                                      this Agreement.; or
                                    </li>
                                  </ol>
                                </li>
                                <li>
                                  solicit, or entice away from us any person
                                  that is a former or current Investor or induce
                                  any such person to terminate, reduce or vary
                                  their business with us.
                                  <br />
                                  <br />
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>
                            Termination
                            <br />
                            <br />
                          </strong>
                          <ol>
                            <li>
                              You or 180 Markets may terminate these Terms
                              immediately by giving the other party written
                              notice if that party has materially breached these
                              Terms.
                            </li>
                            <li>
                              You or 180 Markets may terminate these Terms
                              without cause by giving the other 10 business days
                              prior written notice.
                            </li>
                            <li>
                              Upon termination of these Terms, you will no
                              longer have access to the Website Service or
                              Broker Portal, other than those portions of the
                              Website which are publicly available. Any such use
                              of the Website by you will continue to be subject
                              to these Terms.
                            </li>
                            <li>
                              Despite any other provision in these Terms of Use,
                              this clause 13 and clauses 12, 15, 16, 17, 18(b),
                              21, 22, 23, 25, 26 and 27 survive the expiry or
                              termination of these Terms.
                              <br />
                              <br />
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>
                            Third party information
                            <br />
                            <br />
                          </strong>
                          <ol>
                            <li>
                              The Website, Service and Broker Portal incorporate
                              Third Party Information.
                            </li>
                            <li>
                              180 Markets is not responsible for the Third Party
                              Information and makes no representation as to the
                              accuracy, reliability, timeliness or
                              appropriateness of the Third Party Information.
                            </li>
                            <li>
                              Any recommendations or statements of opinion
                              contained in the Third Party Information are those
                              of the relevant third party and are not adopted by
                              or attributable to 180 Markets.
                              <br />
                              <br />
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>Disclosure</strong> <br />
                          <br />
                          You must, at all times, provide us with a current
                          Financial Services Guide for inclusion on the Website.
                          <br />
                          <br />
                        </li>
                        <li>
                          <strong>
                            Intellectual property
                            <br />
                            <br />
                          </strong>
                          <ol>
                            <li>
                              All intellectual property rights (including
                              copyright and patents) in the Website, Service or
                              Broker Portal and all components of them are owned
                              or licensed by us unless otherwise indicated. You
                              must not copy, modify or transmit any part of the
                              Website, Service or Broker Portal except as
                              permitted in these Terms.
                            </li>
                            <li>
                              The Website, Service or Broker Portal contain
                              trademarks, logos, service names and trade names
                              of 180 Markets or third parties that may be
                              registered or otherwise protected by law. These
                              include the 180 Markets logo. You are not
                              permitted to use any trademarks, logos, service
                              names or trade names appearing on the Website,
                              Service or Broker Portal.
                            </li>
                            <li>
                              We grant you a non-exclusive and non-transferable
                              licence to use the Website and, if you are an
                              Investor, the Service, for your own personal use.
                              You may not download (other than page caching) or
                              modify the Website, or the contents of the Service
                              or any portion them.
                              <br />
                              <br />
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>
                            Privacy
                            <br />
                          </strong>{" "}
                          <br />
                          Any Personal Information submitted by you to 180
                          Markets is subject to and will be handled in
                          accordance with our privacy policy (
                          <strong>Privacy Policy </strong>) at{" "}


                        
                        
                          <a href={"/privacy-policy"} target="_blank">
                            {" "}
                            https://180markets.com.au/privacy-policy
                          </a>
                          You agree that, by using the Website, Service or
                          Broker Portal or communicating with us, you have read
                          the Privacy Policy, understood its’ contents and
                          consented to its’ requirements.
                          <br />
                          <br />
                        </li>
                        <li>
                          <strong>Your Content</strong> <br /> <br />
                          <ol>
                            <li>
                              Subject to certain restrictions and limits
                              outlined in this clause and clause 19, the Website
                              and Service may allow you to upload, post, link
                              to, store, communicate, send or transmit content (
                              <strong>Your Content</strong>) on the Website or
                              via the Service.
                            </li>
                            <li>
                              You grant to us a non-exclusive, royalty-free,
                              perpetual, irrevocable and fully sub-licensable
                              right to use, modify, reproduce, adapt,
                              communicate, display, perform and distribute Your
                              Content in relation to and only for the purpose of
                              our operation of the Website and Service.
                            </li>
                            <li>
                              You represent and warrant that Your Content does
                              not infringe any third party intellectual property
                              rights and that you own or otherwise control all
                              of the rights to the content or that you have
                              obtained all necessary authorisations and consents
                              to post, link to, store or communicate Your
                              Content and to grant us the rights in clause
                              18(b), that Your Content is accurate, that the use
                              of Your Content does not violate these Terms and
                              will not cause any loss or injury to any person.
                              You agree to be solely responsible for any of Your
                              Content that you post to the Website and through
                              the Service.
                            </li>
                            <li>
                              180 Markets has the right, but not the obligation,
                              to monitor any Content (including Your Content)
                              made available on the Website or Service. We
                              reserve the right, in our absolute discretion, to
                              block, modify or remove any Content (including
                              Your Content) without notice, and will not be
                              liable in any way for possible consequences of
                              such actions.
                            </li>
                            <li>
                              If you have a complaint regarding any Content, our
                              sole obligation will be to review any written
                              complaint notified to us and, if we see fit, in
                              our sole discretion, to modify or remove the
                              particular Content.
                              <br />
                              <br />
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>Prohibited uses</strong> <br /> <br />
                          In using the Website, Service or Broker Portal, you
                          must not engage or attempt to engage in any activities
                          that:
                          <ol>
                            <li>
                              violate any applicable local, state, federal or
                              international law including, without limitation
                              the{" "}
                              <em>
                                Spam Act 2003 (Cth), Copyright Act 1968 (Cth)
                              </em>
                              , principles of law or equity established by
                              decisions of courts and statutes, regulations or
                              by-laws of the Commonwealth of Australia, or any
                              State or Territory of the Commonwealth of
                              Australia or a government agency;
                            </li>
                            <li>
                              result in the placement, posting, uploading of,
                              linking to, sending, storing or otherwise
                              communicating or distributing in any way content
                              that is (or is likely to be considered)
                              inappropriate, defamatory, abusive, profane,
                              infringing, obscene, indecent, obscene, or
                              unlawful material or information;
                            </li>
                            <li>
                              violate the rights of any third party (including,
                              without limitation abusing, stalking, threatening
                              or otherwise, infringement of copyright,
                              trademark, or other intellectual property right,
                              misappropriation of trade secrets, Confidential
                              Information, electronic fraud, invasion of
                              privacy, pornography, obscenity or libel);
                            </li>
                            <li>
                              interfere with or disrupt any other third parties
                              (including other users of the Website Service, or
                              Broker Portal), equipment, functions, features,
                              the Website, Service or Broker Portal;
                            </li>
                            <li>
                              introduce or allow the introduction, transmission,
                              distribution or uploading of any, virus or other
                              potentially harmful programs, materials,
                              information or malicious code into the Website,
                              Service, or Broker Portal or any related network;
                            </li>
                            <li>
                              use any device or process to retrieve, index,
                              "data mine" or in any way reproduce or circumvent
                              the navigational structure or presentation of the
                              Website, Service, Broker Portal or their contents;
                            </li>
                            <li>
                              involve distribution of unsolicited advertising or
                              chain letters, repeated harassment of other users
                              or third parties, impersonating another user,
                              falsifying a users’ network identity for improper
                              or illegal purposes, gaining unauthorised access
                              to any parts of the Website, Service or Broker
                              Portal, sending unsolicited bulk emails or calls,
                              continuing to send someone emails after being
                              asked to stop and using a network to gain
                              unauthorised entry to any other machine accessible
                              via a network;
                            </li>
                            <li>
                              involve the unauthorised use of any machine or
                              network, denial of service attacks, falsification
                              of header information or user identification
                              information, monitoring or scanning the networks
                              of others;
                            </li>
                            <li>
                              gain unauthorised access to the Website, Service
                              or Broker Portal;
                            </li>
                            <li>
                              disrupt, impair, alter or otherwise interfere with
                              the functions, features, Content of the Website,
                              Service or Broker Portal; restrict or inhibit any
                              other visitor from using the Website, Service or
                              Broker Portal, including, without limitation, by
                              means of "hacking" or defacing a portion of this
                              Website, Service or Broker Portal;
                            </li>
                            <li>
                              express or imply that statements you make are
                              endorsed by 180 Markets, without 180 Markets's
                              prior written consent;
                            </li>
                            <li>
                              modify, adapt, decompile, reverse engineer,
                              disassemble or otherwise reduce the Website,
                              Service, or Broker Portal to a human-perceivable
                              form;
                            </li>
                            <li>
                              remove any copyright, trademark or other
                              proprietary rights notices contained in the
                              Website, Service or Broker Portal;
                            </li>
                            <li>
                              harvest or collect information about this
                              Website's visitors or members without their
                              express consent;
                            </li>
                            <li>
                              are commercial, including selling, modifying,
                              displaying, distributing or otherwise using any
                              Content, in whole or in part, for any public or
                              commercial purpose without 180 Market's prior
                              written consent, marketing, advertising or
                              promoting goods or services, collecting and using
                              any product lists or pricing for the benefit of
                              other merchants, or re-selling, sublicensing or
                              translating the Website, Broker Portal or Service;
                            </li>
                            <li>
                              use any meta-tags or any other "hidden text"
                              utilising 180 Markets's name or 180 Markets
                              Content without 180 Markets's express written
                              permission;
                            </li>
                            <li>
                              frame the Website, or utilise framing techniques
                              on any part of the Website, without 180 Markets's
                              express written permission; or
                            </li>
                            <li>
                              provide access to the Website, Service, Broker
                              Portal to persons who are not Investors or have
                              not paid the relevant fee for the service. <br />{" "}
                              <br />
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>Registration and Login</strong> <br /> <br />
                          <ol>
                            <li>
                              Certain parts of the Website, Service and Broker
                              Portal are only accessible to Brokers who have
                              “logged in” or paid the relevant fee.
                            </li>
                            <li>
                              While you are a Broker, you represent and warrant
                              that you are the person identified in the
                              Application Form and that all information you have
                              supplied to us in connection with your use of the
                              Website and Service is true and correct.
                            </li>
                            <li>
                              While you are a Broker, you represent and warrant
                              that you have all necessary authorisations to
                              provide the services which these Terms contemplate
                              that you would provide and that all information
                              you have supplied to us in connection with your
                              use of the Website, Broker Portal and Service is
                              and remains true and correct.
                            </li>
                            <li>
                              When using the Website, or Service or Broker
                              Portal, you are responsible for maintaining the
                              confidentiality of your login details and for
                              restricting access by third parties to your
                              account. You agree to be liable if your login
                              details are used by an unauthorised person and are
                              fully responsible for all activities that are
                              conducted under your membership. If you believe
                              that any of your login details have been
                              compromised, lost or misplaced, you must contact
                              us immediately by email at{" "}
                              <a href="mailto:info@180markets.com.au">
                                info@180markets.com.au
                              </a>
                              . You must exercise particular caution when
                              accessing the Website, Service or Broker Portal
                              from a public or shared computer or mobile device
                              so that others are not able to view or record your
                              login details or other personal information.{" "}
                              <br /> <br />
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>
                            Disclaimer of warranties and limitation of liability
                            <br />
                            <br />
                          </strong>
                          <ol>
                            <li>
                              You agree that you have not relied on any
                              representation, description, illustration or
                              specification that is not expressly stated in
                              these Terms.
                            </li>
                            <li>
                              Nothing in these Terms excludes, restricts or
                              modifies any consumer guarantee, right or remedy
                              conferred on you by Schedule 2 to the{" "}
                              <em>Competition and Consumer Act 2010</em> (
                              <strong>ACL</strong>), or any other Applicable
                              Law, that cannot be excluded, restricted or
                              modified by agreement.
                            </li>
                            <li>
                              To the extent permitted by law (including the
                              ACL), 180 Markets excludes all warranties, whether
                              express or implied (not including any consumer
                              guarantees under the ACL), including any
                              warranties or representations concerning
                              availability of the Website, Service or Broker
                              Portal, quality, completeness, accuracy,
                              suitability, acceptability or fitness for purpose
                              in relation to the Website, Service or Broker
                              Portal including Content, all links to or from the
                              Website, Service, or Broker Portal and the goods
                              and services advertised or accessible using the
                              Website, Service, or Broker Portal. Subject to the
                              consumer guarantees provided for in consumer
                              protection legislation (including the ACL), we do
                              not warrant that you will have continuous access
                              to the Website, Service, or Broker Portal. We will
                              not be liable in the event that the Website or the
                              full functionality of the Service is unavailable
                              to you or due to computer downtime attributable to
                              malfunctions, upgrades, preventative or remedial
                              maintenance activities or interruption in
                              telecommunications supply.
                            </li>
                            <li>
                              We do not guarantee the delivery of communications
                              over the internet as such communications rely on
                              third party service providers. The exchange of
                              information electronically is vulnerable to
                              interception by third parties and we do not
                              guarantee the security or confidentiality of
                              information available through the Website, Service
                              or Broker Portal nor the security of the Website,
                              Service or Broker Portal. Whilst we strive to
                              protect information transmitted via the Website,
                              Service or Broker Portal, any such information is
                              transmitted at your own risk.
                            </li>
                            <li>
                              To the extent permitted by law (including the
                              ACL), 180 Markets liability in respect of any
                              non-excludable warranties or conditions relating
                              to the Website, Service or Broker Portal including
                              Content, all links to or from the Website,
                              Service, Broker Portal and the goods and services
                              advertised or accessible using the content is
                              limited to resupplying the relevant Website,
                              Service or Broker Portal or the reasonable cost of
                              resupplying the relevant Website, Service or
                              Broker Portal, whichever 180 Markets sees fit to
                              provide.
                            </li>
                            <li>
                              For all other claims or liability, and to the
                              extent permitted by law, the maximum liability of
                              180 Markets and its employees, agents or
                              contractors for any loss, damage, claim, cost or
                              expense whatsoever arising out of or in connection
                              with these Terms, the Website, Service or Broker
                              Portal, all links to or from the Website, Service
                              or Broker Portal and the goods and services
                              advertised or accessible using the Website,
                              Service or Broker Portal will be the amount of any
                              Membership Fee received from you in the preceding
                              12 months in the aggregate of all claims.
                            </li>
                            <li>
                              Except where 180 Markets fails to meet a consumer
                              guarantee under the ACL and notwithstanding any
                              other provision of these Terms, 180 Markets will
                              not be liable to you for:
                              <ol>
                                <li>
                                  any damage, loss or expense resulting from or
                                  caused by:
                                  <ol>
                                    <li>
                                      any act of 180 Markets which was carried
                                      out in good faith (notwithstanding that
                                      such act may have been in breach of these
                                      Terms or negligent);
                                    </li>
                                    <li>
                                      any act or omission of any Investor or any
                                      other third party;
                                    </li>
                                    <li>
                                      any inaccurate or incorrect Third Party
                                      Information;
                                    </li>
                                    <li>
                                      any inaccurate or incorrect information in
                                      Your Content or otherwise provided by you;
                                    </li>
                                    <li>
                                      any event or circumstance beyond 180
                                      Markets reasonable control including,
                                      without limitation, a Force Majeure Event;
                                      and
                                    </li>
                                    <li>
                                      any breach of these Terms, negligence,
                                      default, fraud or dishonesty by you;
                                    </li>
                                  </ol>
                                </li>
                                <li>
                                  any direct or indirect lost profit, loss of
                                  opportunity, incidental, consequential or
                                  special damages, howsoever the loss is caused
                                  and regardless of whether it was foreseeable
                                  or not.
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>

                        <li>
                          <strong>
                            Indemnity
                            <br />
                            <br />
                          </strong>{" "}
                          You agree to indemnify 180 Markets in respect of all
                          loss, damage, costs, expenses (including legal fees on
                          a full indemnity basis), fines, penalties, claims,
                          demands and proceedings incurred howsoever arising,
                          whether at common law (including negligence) or under
                          statute, in connection with any of the following:
                          <ol>
                            <li>any breach of these Terms by you;</li>
                            <li>
                              your use of the Website, Service or Broker Portal
                              and Your Content (as applicable); and
                            </li>
                            <li>
                              your communications with 180 Markets or Investors.
                              <br />
                              <br />
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>Public announcements</strong> <br />
                          <br />A party must not make, or authorise or cause to
                          be made, any public announcement relating to the
                          negotiations between the parties or the terms or the
                          subject matter of these Terms unless:
                          <ol>
                            <li>
                              (a) it has the prior written consent of the other
                              party; or
                            </li>
                            <li>
                              (b) it is required to do so by law.
                              <br />
                              <br />
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>
                            Jurisdiction and law
                            <br />
                            <br />
                          </strong>
                          These Terms are governed by and must be construed in
                          accordance with the laws of the State of Victoria,
                          Australia. You submit to the exclusive jurisdiction of
                          the courts of that State and the Commonwealth of
                          Australia in respect of all matters arising out of or
                          relating to these Terms, their performance and subject
                          matter.
                          <br />
                          <br />
                        </li>
                        <li>
                          <strong>Severability</strong> <br />
                          <br />
                          Each provision of these Terms is severable from the
                          others and no severance of a provision will affect any
                          other provision.
                          <br />
                          <br />
                        </li>
                        <li>
                          <strong>Contacting </strong>
                          <strong>
                            180 Markets
                            <br />
                          </strong>
                          <br />
                          If you have questions about the Website, Service,
                          Broker Portal, these Terms or the Privacy Policy,
                          please contact 180 Markets by emailing{" "}
                          <a href="mailto:info@180markets.com.au">
                            info@180markets.com.au
                          </a>
                          <br />
                          <br />
                        </li>

                        <li>
                          <strong>Definitions and interpretation</strong> <br />
                          <br />
                          <p>
                            In these Terms unless the context requires
                            otherwise:
                          </p>
                          <p>
                            <strong>Allocation </strong>has the meaning given to
                            that term in the Master ECM Terms and relates to the
                            number of financial products allocated to an
                            Investor in response to a Bid, as set out in a
                            Confirmation.
                          </p>
                          <p>
                            <strong>Applicable Law</strong> means the{" "}
                            <em>Corporations Act 2001 (Cth)</em>, and any other
                            statute, statutory instrument or general law that is
                            applicable to a party in connection with these
                            Terms, including ASIC Policy;
                          </p>
                          <p>
                            <strong>ASIC</strong> means the Australian
                            Securities and Investments Commission established
                            under the Australian Securities and Investments
                            Commission Act 2001 (Cth) and its successors;
                          </p>
                          <p>
                            <strong>ASIC Policy</strong> means regulatory
                            guides, legislative instruments and class orders
                            issued by ASIC;
                          </p>
                          <p>
                            <strong>Bid </strong>has the meaning given to that
                            term in the Master ECM Terms and includes a binding
                            and irrevocable offer by an Investor to acquire
                            financial products of a particular value in
                            connection with an Opportunity, on the terms
                            specified in the Opportunity;
                          </p>
                          <p>
                            <strong>Broker Application Form</strong> means the
                            application form to become a Broker made available
                            on the Website or otherwise by 180 Markets from time
                            to time;
                          </p>
                          <p>
                            <strong>Broker Fee</strong> means the fee payable by
                            you to 180 Markets for allowing an Opportunity to be
                            submitted to the Broker Portal, as notified to the
                            Broker by 180 Markets prior to the Opportunity being
                            submitted;
                          </p>
                          <p>
                            <strong>Broker Portal</strong> means those elements
                            of the Website and the Service made available to
                            Brokers to enable them to utilise the functionality
                            set out in these Terms;
                          </p>
                          <p>
                            <strong>Business Day</strong> means a day other than
                            Saturday or Sunday on which banks are open for
                            general banking business in Melbourne;
                          </p>
                          <p>
                            <strong>Confidential Information</strong> means all
                            information belonging or relating to 180 Markets
                            and/or the Service, whether oral, graphic,
                            electronic, written or in any other form, that:
                          </p>
                          <ol>
                            <li>
                              is or should reasonably be regarded as,
                              confidential to 180 Markets; or
                            </li>
                            <li>
                              is not generally available to the public at the
                              time of disclosure other than by reason of a
                              breach of these Terms;
                            </li>
                          </ol>
                          <p>
                            <strong>Confirmation </strong>means a confirmation
                            from us to an Investor in relation to the number of
                            financial products Allocated to the Investor by you
                            in respect of a Bid.
                          </p>
                          <p>
                            <strong>Content</strong> means any content made
                            available on the Website or Service;
                          </p>
                          <p>
                            <strong>Current Price </strong>means, in respect of
                            an Opportunity, the most recent data available to
                            the Service.
                          </p>
                          <p>
                            <strong>Force Majeure Event </strong>means any act,
                            event or cause including:
                          </p>
                          <ol>
                            <li>
                              (a) an act of God, peril of the sea, accident of
                              navigation, war, sabotage, riot, act of terrorism,
                              insurrection, civil commotion, national emergency
                              (whether in fact or law), martial law, fire,
                              lightning, flood, cyclone, earthquake, landslide,
                              storm or other adverse weather conditions,
                              explosion, power shortage, strike or other labour
                              difficulty (whether or not involving employees of
                              the party concerned), epidemic, quarantine,
                              radiation or radioactive contamination;
                            </li>
                            <li>
                              (b) an action or inaction of a government agency,
                              including expropriation, restraint, prohibition,
                              intervention, requisition, requirement, direction
                              or embargo by legislation, regulation, decree or
                              other legally enforceable order; or
                            </li>
                            <li>
                              (c) breakdown of plant, machinery or equipment or
                              shortages of labour, transportation, fuel, power
                              or plant, machinery, equipment or material, <br />
                              <br />
                            </li>
                          </ol>
                          <p>
                            To the extent that the act, event or cause directly
                            or indirectly results in a party being prevented
                            from or delayed in performing one or more of its
                            obligations under these Terms and that act, event or
                            cause is beyond the reasonable control of that
                            party;
                          </p>
                          <p>
                            <strong>Investor </strong>means a user who has
                            subscribed for access to the Service as an
                            "investor" in accordance with the Investor Terms;
                          </p>
                          <p>
                            <strong>Investor Terms </strong>means the terms and
                            conditions on which an Investor uses the Website and
                            Service;
                          </p>
                          <p>
                            <strong>Master ECM Terms </strong>means the "Master
                            ECM Terms" available on the AFMA website at{" "}
                            <a href="http://www.afma.com.au/standards/documentation.html">
                              www.afma.com.au/standards/documentation.html
                            </a>{" "}
                            and which are incorporated into and form part of the
                            Investor Terms;
                          </p>
                          <p>
                            <strong>Month</strong> means a period extending from
                            a date in one calendar month to the corresponding
                            date in the following month;
                          </p>
                          <p>
                            <strong>Opportunity </strong>means an opportunity to
                            purchase financial products (such as shares through
                            an initial public offering or placement) which has
                            been submitted for inclusion in the Service by a
                            Broker;
                          </p>
                          <p>
                            <strong>Personal Information </strong>means the
                            name, address, date of birth, credit card details,
                            login details of an individual and any other
                            information entered into the Service about an
                            individual which the Service identifies as "personal
                            information";{" "}
                          </p>
                          <p>
                            <strong>Scale Back </strong>means, in respect of a
                            Bid for an Opportunity, a reduction in the amount of
                            financial products available to the Investor;
                          </p>
                          <p>
                            <strong>Service </strong>has the meaning given in
                            clause 1;
                          </p>
                          <p>
                            <strong>Third Party Information </strong>means
                            information supplied by another Broker, an Investor
                            or a third party and incorporated into the Website,
                            Service or Broker Portal, including:
                          </p>
                          <ol>
                            <li>
                              information about Opportunities, including
                              information obtained from ASX Limited and
                              information submitted by other Brokers;
                            </li>
                            <li>
                              research and analysis in relation to
                              Opportunities;
                            </li>
                            <li>
                              Content provided by Investors;
                              <br />
                              <br />
                            </li>
                          </ol>
                          <p>
                            <strong>Your Content </strong>means the content
                            uploaded, posted, linked to, stored, communicated,
                            sent or transmitted on the Website or via the
                            Service in accordance with clause 18(a).
                          </p>
                          <p>
                            <strong>Interpretations</strong>
                          </p>
                          <p>
                            In these Terms unless the context requires
                            otherwise:
                          </p>
                          <ol>
                            <li>
                              the singular includes the plural and vice versa;
                            </li>
                            <li>a gender includes the other genders;</li>
                            <li>
                              the headings are used for convenience only and do
                              not affect the interpretation of these Terms;
                            </li>
                            <li>
                              other grammatical forms of defined words or
                              expressions have corresponding meanings;
                            </li>
                            <li>
                              a reference to a document includes the document as
                              modified from time to time and any document
                              replacing it;
                            </li>
                            <li>
                              a reference to a party is to a party to these
                              Terms and a reference to a party to a document
                              includes the party's executors, administrators,
                              successors and permitted assigns and substitutes;
                            </li>
                            <li>
                              if something is to be or may be done on a day that
                              is not a Business Day then it must be done on the
                              next Business Day;
                            </li>
                            <li>
                              the word "person" includes a natural person,
                              partnership, body corporate, association,
                              governmental or local authority, agency and any
                              body or entity whether incorporated or not;
                            </li>
                            <li>
                              the word "month" means calendar month and the word
                              "year" means 12 months;
                            </li>
                            <li>
                              the words "in writing" include any communication
                              sent by letter, facsimile transmission or email or
                              any other form of communication capable of being
                              read by the recipient;
                            </li>
                            <li>
                              a reference to a thing includes a part of that
                              thing;
                            </li>
                            <li>
                              a reference to all or any part of a statute, rule,
                              regulation or ordinance (<strong>statute</strong>)
                              includes that statute as amended, consolidated,
                              re-enacted or replaced from time to time;
                            </li>
                            <li>
                              wherever "include", "for example" or any form of
                              those words or similar expressions is used, it
                              must be construed as if it were followed by
                              "(without being limited to)";
                            </li>
                            <li>
                              money amounts in these Terms, the Website and
                              Service are stated in Australian currency unless
                              otherwise specified;
                            </li>
                            <li>
                              a reference to time is to Melbourne, Australia
                              time;
                            </li>
                            <li>
                              a reference to any agency or body, if that agency
                              or body ceases to exist or is reconstituted,
                              renamed or replaced or has its powers or functions
                              removed (<strong>defunct body</strong>), means the
                              agency or body which performs most closely the
                              functions of the defunct body;
                            </li>
                            <li>
                              any agreements, representation, warranty or
                              indemnity in favour of two or more parties
                              (whether those parties are included in the same
                              defined term or not) is for the benefit of them
                              jointly and separately; and
                            </li>
                            <li>
                              any agreements, representation, warranty or
                              indemnity by two or more parties (whether those
                              parties are included in the same defined term or
                              not) binds them jointly and separately.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                  ) : (
                    <InvestorTerms />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

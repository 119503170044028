import React, { Fragment, Component } from "react";
import { reduxForm, Form } from "redux-form";
import { scrollToFirstError } from "../../../../utils/scroller";
import ButtonLoader from "../../../common/ButtonLoader";
import NewDropzone from "../../../common/NewDropzone";

class SophisticatedPreferenceUpload extends Component {
  render() {
    let { handleSubmit } = this.props;
    return (
      <main className="page-signup page-create-account ">
        <div className="container">
          <div class="form-normal form-create-account profile-upgrade">
            <h1 className="text-center mb-2">Sophisticated Investor Form</h1>
            <Form onSubmit={handleSubmit}>
              <div className="all-fields">
                <div className="row mb-3" style={{ marginTop: 20 }}>
                  <div className="col-sm-12">
                    <Fragment>
                      <NewDropzone
                        multiple={true}
                        buttonLabel="Choose document"
                        onDocumentDeleteHandler={
                          this.props.onDocumentDeleteHandler
                        }
                        multiDocuments={this.props.siForms}
                        onUpload={(files) => {
                          this.props.onSIUpload(files);
                        }}
                        open={this.props.open}
                        close={this.props.close}
                        show={this.props.show}
                        disableDelete={true}
                      />
                    </Fragment>
                  </div>
                </div>
                <div className="row is-button-group">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <ButtonLoader
                      type="submit"
                      className="btn btn-primary btn-medium btn-block"
                    >
                      Continue
                    </ButtonLoader>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </main>
    );
  }
}

export default reduxForm({
  form: "InvestorProfileForm",
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(SophisticatedPreferenceUpload);

import React, { Component } from "react";
import { Modal } from "antd";
import { withRouter } from "react-router-dom";
import IpoForm from "../forms/Ipo/IpoForm/IpoForm";
import NumberFormat from "react-number-format";

class BidIpoModal extends Component {
  state = {
    loading: false,
    visible: false,
    anotherBid: false,
    selectedBid: null,
  };

  //   handleOk = () => {
  //     this.setState({ loading: true });
  //     setTimeout(() => {
  //       this.setState({ loading: false, visible: false });
  //     }, 3000);
  //   };

  onSubmitIpoBid = (values) => {
    this.setState({
      anotherBid: false,
      selectedBid: null,
    });
    const { onSubmitBid } = this.props;
    onSubmitBid(values);
    this.props.handleCancel(values);
  };

  entityNotUsedYet = (selectedbid) => {
    return [...this.props.selectEntityOptions].filter((entity) => {
      return !(
        this.props.selectedIpo.myBids &&
        this.props.selectedIpo.myBids.some(
          (bid) => bid.entity_id === entity.value && selectedbid &&  bid.id !== selectedbid.id
        )
      );
    });
  };

  editIpoBidHandler = (selectedBid) => {
    this.setState({
      anotherBid: false,
      selectedBid: selectedBid !== undefined ? selectedBid : null,
    });

    this.props.editIpoBidHandler();
  };

  handleCancel = (payload) => {
    this.setState({
      anotherBid: false,
      selectedBid: null,
    });
    this.props.handleCancel(payload);
  };

  onBidNow = (id) => {
    this.setState({
      anotherBid: true,
      selectedBid: null,
    });

    // this.props.onBidNow(id, true);
  };

  render() {
    const {
      visible,
      selectEntityOptions,
      showEditIpoBidForm,
      selectedIpo,
      currentUser,
    } = this.props;
    const { selectedBid, anotherBid } = this.state;
    let entitiesNotUsed = [...selectEntityOptions].filter((entity) => {
      return !(
        selectedIpo.myBids &&
        selectedIpo.myBids.some((bid) => bid.entity_id === entity.value)
      );
    });

    return (
      <div>
        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          {currentUser.role &&
            currentUser.role.some((role) => role.title === "Investor") &&
            currentUser.is_approved === "Approved" &&
            selectedIpo.type === "180" &&
            selectedIpo.status === "Active" &&
            selectedIpo.myBids &&
            (selectedIpo.myBids.length === 0 || anotherBid) && (
              <IpoForm
                onSubmit={this.onSubmitIpoBid}
                selectEntityOptions={entitiesNotUsed}
                currentDeal={selectedIpo}
                ipo={selectedIpo}
                buttonTitle="Submit"
              />
            )}
          {/* {currentUser.role &&
            currentUser.role.some((role) => role.title === "Investor") &&
            currentUser.is_approved === "Approved" &&
            selectedIpo.type === "180" &&
            selectedIpo.status === "Suspended" &&
            !selectedIpo.mybid && (
              <IpoForm
                onSubmit={this.onSubmitIpoBid}
                selectEntityOptions={selectEntityOptions}
                currentDeal={selectedIpo}
                ipo={selectedIpo}
                buttonTitle={
                  selectedIpo && selectedIpo.mybid ? "Update" : "Submit"
                }
              />
            )} */}
          {currentUser.role &&
            currentUser.role.some((role) => role.title === "Investor") &&
            currentUser.is_approved === "Approved" &&
            selectedIpo.type === "market" &&
            !selectedIpo.myindication && (
              <IpoForm
                onSubmit={this.onSubmitIpoBid}
                selectEntityOptions={selectEntityOptions}
                currentDeal={selectedIpo}
                buttonTitle={
                  selectedIpo && selectedIpo.myindication ? "Update" : "Submit"
                }
              />
            )}
          {selectedIpo.type === "180" &&
            selectedBid &&
            showEditIpoBidForm && (
              <IpoForm
                onSubmit={this.onSubmitIpoBid}
                selectEntityOptions={this.entityNotUsedYet(selectedBid)}
                currentDeal={selectedIpo}
                buttonTitle={
                  selectedBid ? "Update" : "Submit"
                }
                initialValues={
                  selectedIpo &&
                  selectedBid && {
                    bid_id: selectedBid ? selectedBid.uuid : null,
                    entity_id: selectedBid.entity_id,
                    share_count: selectedBid.share_count,
                    investment_amount: selectedBid.investment_amount,
                  }
                }
              />
            )}
          {selectedIpo.type === "market" &&
            selectedIpo.myindication &&
            showEditIpoBidForm && (
              <IpoForm
                onSubmit={this.onSubmitIpoBid}
                selectEntityOptions={selectEntityOptions}
                currentDeal={selectedIpo}
                buttonTitle={
                  selectedIpo && selectedIpo.myindication ? "Update" : "Submit"
                }
                initialValues={
                  selectedIpo &&
                  selectedIpo.myindication && {
                    investment_amount:
                      selectedIpo.myindication.investment_amount,
                  }
                }
              />
            )}
          {selectedIpo.type === "180" &&
            !showEditIpoBidForm &&
            !anotherBid &&
            selectedIpo.myBids &&
            selectedIpo.myBids.length > 0 && (
              <div>
                <h3>Change Bid</h3>
                {selectedIpo.myBids.map((bid, index) => (
                  <div style={{ height: "180px" }}>
                     <div>
                        <b>Entity:</b> {bid.entity?.entity_name}
                      </div>
                    <div>
                      <b>Share Count:</b> {bid.share_count}
                    </div>
                    <div className="mb-2">
                      <b>Investment Amount:</b>
                      <NumberFormat
                        value={bid.investment_amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </div>
                    {bid.allocation_investor_count === 0 && (
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-medium btn-block mt-4"
                        onClick={() => this.editIpoBidHandler(bid)}
                      >
                        Change Bid
                      </button>
                    )}
                  </div>
                ))}
                {entitiesNotUsed.length > 0 && (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-default btn-medium btn-block mt-4"
                      onClick={() => this.onBidNow(selectedIpo.uuid)}
                    >
                      Bid with another entity
                    </button>
                  </>
                )}
              </div>
            )}

          {selectedIpo.type === "market" &&
            !showEditIpoBidForm &&
            selectedIpo.myindication && (
              <div style={{ height: "200px" }}>
                <h3>Change Indication</h3>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-medium btn-block mt-4"
                  onClick={() => this.editIpoBidHandler()}
                >
                  Change Indication
                </button>
              </div>
            )}

          {currentUser.role &&
            currentUser.role.some((role) => role.title === "Investor") &&
            currentUser.is_approved === "Pending" && (
              <div className="md-deal-form">
                <div className="form-normal dealBidMessage">
                  <div className="deal-form-content">
                    <p className="bidMessage">
                      Complete your profile to bid for IPO
                    </p>
                    <button
                      className="btn btn-outline-primary btn-block"
                      onClick={() => this.props.history.push("/user/profile")}
                    >
                      Go to Profile
                    </button>
                  </div>
                </div>
              </div>
            )}
          {selectedIpo ? (
            selectedIpo.status === "Closed" ||
            selectedIpo.status === "Finished" ? (
              <div className="md-deal-form">
                <div className="form-normal dealBidMessage">
                  <div
                    className="deal-form-content"
                    style={{ textAlign: "center" }}
                  >
                    <p
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                      className="bidMessage"
                    >
                      This IPO has closed.
                    </p>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}
          {selectedIpo ? (
            selectedIpo.status === "Suspended" ? (
              <div className="md-deal-form">
                <div className="form-normal dealBidMessage">
                  <div
                    className="deal-form-content"
                    style={{ textAlign: "center" }}
                  >
                    <p
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                      className="bidMessage"
                    >
                      This IPO is already suspended.
                    </p>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}
        </Modal>
      </div>
    );
  }
}

export default withRouter(BidIpoModal);
